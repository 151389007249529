import { useTranslation } from '@dagens/frontend-i18n';
import { useAppSelector } from '../../../../_common/hooks/reduxHooks';
import { Consumer } from '../../../../types/Consumer';

export type ConsumersWithChildren = Partial<Consumer> & {
  children?: ConsumersWithChildren[];
};

export const allCustomersOption = {
  _id: 'ALL_CUSTOMERS'
} as const;

// Converts a list of customers to a list where every item is a child of the "All customers" option
// The parent/child hierarchy is used to help the behavior:
// - picking "All customers" should replace existing customers
// - picking customers should replace "All customers"
export const useCreatePricingConsumerOptions = (
  consumers: Consumer[]
): ConsumersWithChildren[] => {
  const { t } = useTranslation();
  return [
    {
      ...allCustomersOption,
      name: t('producer:AllCustomers'),
      children: consumers
    }
  ];
};

// Fetches customers from API and converts them to a list of options
export const useConsumers = () => {
  const { consumers, req } = useAppSelector(
    ({ consumers: { items = [], req } }) => {
      return { consumers: items, req };
    }
  );
  const withAll = useCreatePricingConsumerOptions(consumers);
  return { consumers: withAll, req };
};
