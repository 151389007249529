import { useTranslation } from '@dagens/frontend-i18n';
import {
  FieldValues,
  Path,
  useController,
  UseControllerProps,
  useFormContext
} from 'react-hook-form';
import { useContext } from 'react';
import { FormFieldContext } from '../fields/field';
import { RequiredRule, ValidationFn, validationResolver } from './validation';

export type FieldProps<
  Form extends FieldValues,
  Name extends Path<Form>
> = Omit<
  UseControllerProps<Form, Name>,
  'rules' | 'defaultValue' | 'control' | 'name'
> & {
  name: Name;
  required?: RequiredRule<Form, Name> | undefined;
  validate?: ValidationFn<Form, Name> | undefined;
  deps?: Path<Form>[];
};

// Initializes the field controller with custom validation rules
// The validation provided by react-hook-form is limited
// These custom validation rules allow for validating the field based on form values,
// as well as providing dynamic required validation
// It also provides a default error messages
export const useField = <Form extends FieldValues, Name extends Path<Form>>({
  name,
  disabled,
  shouldUnregister,
  required,
  validate,
  deps
}: FieldProps<Form, Name>) => {
  const { requireds: requiredFromField, deps: depsFromField } =
    useContext(FormFieldContext);
  const { t } = useTranslation();
  const { control, getValues } = useFormContext<Form>();
  const controller = useController({
    name,
    rules: {
      deps: deps ?? (depsFromField as Path<Form>[] | undefined),
      validate: value =>
        validationResolver({
          value,
          required:
            required ?? (requiredFromField?.at(0) as RequiredRule<Form, Name>),
          validate,
          formData: getValues,
          defaultRequiredError: t('common:requiredField'),
          defaultValidationError: t('common:invalidValue')
        })
    },
    shouldUnregister,
    disabled,
    control
  });
  return controller;
};
