import { css } from 'styled-components';
import Spacings, { SpacingNames } from '../theme/spacings';

export type MarginProps = {
  mx?: SpacingNames;
  my?: SpacingNames;
  mt?: SpacingNames;
  mr?: SpacingNames;
  mb?: SpacingNames;
  ml?: SpacingNames;
};

const Margins = css<MarginProps>`
  ${({ mx }) => {
    return (
      mx && `margin-left: ${Spacings[mx]}px; margin-right: ${Spacings[mx]}px;`
    );
  }}
  ${({ my }) => {
    return (
      my && `margin-top: ${Spacings[my]}px; margin-bottom: ${Spacings[my]}px;`
    );
  }}
  ${({ mt }) => {
    return mt && `margin-top: ${Spacings[mt]}px;`;
  }}
  ${({ mr }) => {
    return mr && `margin-right: ${Spacings[mr]}px;`;
  }}
  ${({ mb }) => {
    return mb && `margin-bottom: ${Spacings[mb]}px;`;
  }}
  ${({ ml }) => {
    return ml && `margin-left: ${Spacings[ml]}px;`;
  }}
`;

export default Margins;
