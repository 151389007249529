import { useTranslation } from '@dagens/frontend-i18n';
import styled from 'styled-components';
import { Icon } from '@dagens/carrot';
import Container from '@carrot-deprecated/container';
import Theme from '@carrot-deprecated/theme';
import Input from '_common/components/input/Input.style';

const Unbutton = styled.button`
  display: flex;
  align-items: center;
  background: transparent;
  padding: ${Theme.Spacings.xs}px;
  position: absolute;
  right: 10px;
  top: 10px;
  border-radius: ${Theme.BorderRadius.regular}px;

  :hover {
    background-color: ${Theme.Colours.brightPurple};
  }
`;

type SearchInputProps = {
  hasActiveSearch?: boolean;
  searchStringInput?: string;
  searchPlaceholder?: string;
  onSearchChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onKeyDown?: React.KeyboardEventHandler<HTMLInputElement>;
  onResetSearchString: () => void;
};
const SearchInput = ({
  hasActiveSearch = false,
  searchStringInput = '',
  searchPlaceholder,
  onSearchChange,
  onKeyDown,
  onResetSearchString
}: SearchInputProps) => {
  const { t } = useTranslation();
  return (
    <Container position="relative" mb="xs">
      <Input
        type="search"
        value={searchStringInput}
        onChange={onSearchChange}
        {...(onKeyDown && { onKeyDown: onKeyDown })}
        placeholder={searchPlaceholder || t('common:searchProductsProducers')}
      />
      {hasActiveSearch && (
        <Unbutton type="button" onClick={onResetSearchString}>
          <Icon icon="clear" size="small" />
        </Unbutton>
      )}
    </Container>
  );
};
export default SearchInput;
