import {
  CheckboxesTree as CarrotCheckboxesTree,
  TreeValue,
  type CheckboxesTreeProps as CarrotCheckboxesTreeProps
} from '@dagens/carrot';
import { PathValue, FieldValues } from 'react-hook-form';
import { MatchingKeys } from '../types/fields';
import { FieldProps, useField } from '../utils/use-field';

// Fields from the Carrot component that are handled by react-hook-form
type ExcludedFields = 'error' | 'value' | 'onChange';

export type CheckboxesTreeProps<
  Form extends FieldValues,
  Name extends MatchingKeys<Form, TreeValue<T>[]>,
  T
> = Omit<CarrotCheckboxesTreeProps<TreeValue<T>>, ExcludedFields> &
  FieldProps<Form, Name>;

export const CheckboxesTree = <
  Form extends FieldValues,
  Name extends MatchingKeys<Form, TreeValue<T>[]>,
  T
>({
  name,
  required,
  validate,
  shouldUnregister,
  disabled,
  ...props
}: CheckboxesTreeProps<Form, Name, T>) => {
  const { field } = useField({
    name,
    required,
    validate,
    shouldUnregister,
    disabled
  });

  const toValue = (v: PathValue<Form, Name>) => (!v ? [] : v);

  return (
    <CarrotCheckboxesTree
      name={name}
      value={toValue(field.value as PathValue<Form, Name>)}
      onChange={v => field.onChange(v as PathValue<Form, Name>)}
      {...props}
    />
  );
};
