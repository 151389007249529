import { PropsWithChildren } from 'react';
import { Checkbox as HeadlessCheckbox, Field, Label } from '@headlessui/react';
import { tv } from '../../utils/tv';
import { Icon } from '../../theme/icon';
import { Button } from '../button';

const style = tv({
  slots: {
    field: `
      flex
      gap-xs
      p-[2px]

      focus:outline-none
    `,
    button: `
      rounded

      focus-visible:!focus-style

      focus:outline-none
    `,
    label: `cursor-pointer`,
    check: `
      mt-[2px]
      flex
      size-[20px]
      flex-shrink-0
      cursor-pointer
      items-center
      justify-center
      rounded-small
      border
      border-black

      focus-visible:!focus-style

      focus:outline-none
    `,
    checked: `
      flex
      size-full
      items-center
      justify-center
      bg-black
    `
  }
});

export type CheckboxVariant = 'checkbox' | 'button';

export type CheckboxProps = PropsWithChildren<{
  variant?: CheckboxVariant;
  checked: boolean;
  onChange: (checked: boolean) => void;
}>;

const ClassicCheckbox = ({
  checked,
  onChange,
  children
}: Omit<CheckboxProps, 'variant'>) => {
  const { field, label, check, checked: checkedStyle } = style();
  return (
    <Field className={field()}>
      <HeadlessCheckbox
        checked={checked}
        onChange={onChange}
        className={check()}
      >
        {checked && (
          <div className={checkedStyle()}>
            <Icon icon="check" size="small" color="white" />
          </div>
        )}
      </HeadlessCheckbox>
      <Label className={label()}>{children}</Label>
    </Field>
  );
};

const ButtonCheckbox = ({
  checked,
  onChange,
  children
}: Omit<CheckboxProps, 'variant'>) => {
  const { button } = style();
  return (
    <HeadlessCheckbox
      checked={checked}
      onChange={onChange}
      className={button()}
    >
      <Button
        variant={checked ? 'secondary' : 'ghost'}
        size="regular"
        tabIndex={-1}
      >
        {children}
      </Button>
    </HeadlessCheckbox>
  );
};

export const Checkbox = (props: CheckboxProps) => {
  return props.variant === 'button' ? (
    <ButtonCheckbox {...props} />
  ) : (
    <ClassicCheckbox {...props} />
  );
};
