import { ForwardedRef, forwardRef, TextareaHTMLAttributes } from 'react';
import { inputStyle } from '../input/styles';

type HTMLTextareaProps = TextareaHTMLAttributes<HTMLTextAreaElement>;

export type TextareaProps = {
  placeholder?: HTMLTextareaProps['placeholder'];
  maxLength?: HTMLTextareaProps['maxLength'];
  rows?: HTMLTextareaProps['rows'];
  error?: boolean;
  value: string;
  onChange: HTMLTextareaProps['onChange'];
};

const Component = (
  { error, ...props }: TextareaProps,
  ref: ForwardedRef<HTMLTextAreaElement>
) => {
  const { container, input } = inputStyle({ error });

  return (
    <label className={container()}>
      <textarea {...props} ref={ref} className={input()} />
    </label>
  );
};

export const Textarea = forwardRef(Component);
