import { PropsWithChildren } from 'react';

import { twMerge } from 'tailwind-merge';
import { tv } from '../../utils/tv';
import { cellStyle } from './cell';

const style = tv({
  base: `
    text-start
    !text-paragraphSmallBold
    text-secondary
  `
});

type Props = PropsWithChildren<{
  hidden?: boolean;
}>;

export const HeaderCell = ({ hidden, children }: Props) => {
  return (
    <th className={twMerge(cellStyle(), style())}>
      {hidden ? <div className="sr-only">{children}</div> : children}
    </th>
  );
};
