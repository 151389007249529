import { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import REQ, { ReqType } from '../../../../utils/REQ';
import {
  useAppDispatch,
  useAppSelector
} from '../../../../_common/hooks/reduxHooks';
import { BillingFormValues } from '../../../components/customer-billing-form';
import { fetchConsumers } from '../../../reducers/consumers';
import { patchManagedCustomerBillingInformation } from '../../../../api';

// Navigate to next step and pass the form value
export const useOnSave = (customerId: string) => {
  const navigate = useNavigate();
  const [saveReq, setSaveReq] = useState<ReqType>(REQ.INIT);
  const dispatch = useAppDispatch();
  const { producerId, userId } = useAppSelector(({ auth }) => {
    return { producerId: auth._id, userId: auth.uid };
  });

  const onSave = useCallback(async (data: BillingFormValues) => {
    setSaveReq(REQ.PENDING);
    if (!producerId || !userId) {
      setSaveReq(REQ.ERROR);
      return;
    }

    try {
      if (
        !(
          data.billingName &&
          data.primaryEmail &&
          data.reminderEmail &&
          data.billingAddress &&
          typeof data.billingAddress != 'string'
        )
      ) {
        setSaveReq(REQ.ERROR);
        return;
      }
      await patchManagedCustomerBillingInformation(producerId, customerId, {
        billingName: data.billingName,
        department: data.department || undefined,
        eanNumber: data.eanNumber || undefined,
        primaryEmail: data.primaryEmail,
        reminderEmail: data.reminderEmail,
        address: data.billingAddress
      });
      dispatch(fetchConsumers({ producerId }));
      setSaveReq(REQ.SUCCESS);
      setTimeout(() => {
        navigate(`/customers/${customerId}`);
      }, 1000);
    } catch (error) {
      setSaveReq(REQ.ERROR);
    }
  }, []);

  return { onSave, saveReq };
};
