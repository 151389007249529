import { useTranslation } from '@dagens/frontend-i18n';
import React from 'react';
import { DateRange } from 'react-day-picker';
import Typography from '@carrot-deprecated/typography';
import Container from '@carrot-deprecated/container';
import Theme from '@carrot-deprecated/theme';
import { ButtonAnchor } from '_common/components/button/Button.style';
import DateRangePicker from '_common/components/date-range-picker/DateRangePicker';
import SectionHOC from '_common/components/section-hoc/SectionHOC';
import { useAppSelector } from '_common/hooks/reduxHooks';
import useWindowWidth from '_common/hooks/useWindowWidth';
import TradeReportLink from '_common/pages/profile/TradeReportLink';
import { baseUrl } from 'config';
import { getEndOfYear, getStartOfYear, slugifyDate } from 'utils/date/format';
import { numberOfMonths } from 'utils/dayPicker';
import { DOWNLOAD_MY_DATA, track } from 'utils/mixpanel';
import { formatPeriod } from 'utils/season';

const initialDates = {
  from: getStartOfYear(new Date()),
  to: getEndOfYear(new Date())
};

const dateDefault = (dates: DateRange) => {
  return dates.to === null ? initialDates : dates;
};

const ProfileDataExport = () => {
  const { t } = useTranslation();
  const windowWidth = useWindowWidth() ?? 0;
  const [dates, setDates] = React.useState<DateRange>(initialDates);

  const { accountId, roleType } = useAppSelector(({ auth }) => {
    return {
      accountId: auth._id,
      roleType: auth._type
    };
  });

  const roleTypeQuery = roleType === 'producers' ? 'producerId' : 'consumerId';

  return (
    <Container>
      <TradeReportLink />
      <Typography variant="paragraphBold" as="label">
        {t('common:ExportRawData')}
      </Typography>
      <DateRangePicker
        dates={dates}
        onChange={newDates => {
          return setDates(dateDefault(newDates));
        }}
        monthsToShow={numberOfMonths({ width: windowWidth, desktop: 3 })}
      />
      <Typography variant="paragraphSmall" color="secondary">
        {t('common:DatesSelected', {
          date: formatPeriod(dates)
        })}
      </Typography>
      <ButtonAnchor
        onClick={() => {
          track(DOWNLOAD_MY_DATA, {
            type: 'csv',
            chosenDates: {
              from: slugifyDate(dates.from),
              to: slugifyDate(dates.to)
            }
          });
        }}
        target="_blank"
        rel="noopener noreferrer"
        href={`${baseUrl}/export/orderLines?startDate=${slugifyDate(
          dates.from
        )}&endDate=${slugifyDate(
          dates.to
        )}&${roleTypeQuery}=${accountId}&format=csv`}
        variant="borderless"
        mt={Theme.Spacings.s}
        mr={Theme.Spacings.xs}
      >
        {t('common:DownloadCSVFile')}
      </ButtonAnchor>
      <ButtonAnchor
        onClick={() => {
          track(DOWNLOAD_MY_DATA, {
            type: 'xlsx',
            chosenDates: {
              from: slugifyDate(dates.from),
              to: slugifyDate(dates.to)
            }
          });
        }}
        target="_blank"
        rel="noopener noreferrer"
        href={`${baseUrl}/export/orderLines?startDate=${slugifyDate(
          dates.from
        )}&endDate=${slugifyDate(
          dates.to
        )}&${roleTypeQuery}=${accountId}&format=xlsx`}
        variant="borderless"
        mt={Theme.Spacings.s}
        mr={Theme.Spacings.xs}
      >
        {t('common:DownloadExcelFile')}
      </ButtonAnchor>
    </Container>
  );
};

export default SectionHOC(ProfileDataExport);
