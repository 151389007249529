import { Input } from '@dagens/carrot';
import { useTranslation } from '@dagens/frontend-i18n';
import { ComboboxInput } from '@headlessui/react';
import { ChangeEvent } from 'react';

type Props = {
  query: string;
  onChange: (query: ChangeEvent<HTMLInputElement>) => void;
};

export const CommandPaletteInput = ({ query, onChange }: Props) => {
  const { t } = useTranslation();
  return (
    <ComboboxInput
      // eslint-disable-next-line jsx-a11y/no-autofocus
      autoFocus
      as={Input}
      value={query}
      onChange={onChange}
      placeholder={t('common:Search')}
    />
  );
};
