import { useFormContext } from '@dagens/frontend-forms';
import { useTranslation } from '@dagens/frontend-i18n';
import { ActionButton } from '../../../../components/action-button';
import { type ReqType } from '../../../../utils/REQ';
import { MinimumOrderFormValues } from './form';

type Props = {
  saveReq: ReqType;
  onSubmit: (data: MinimumOrderFormValues) => void;
};

export const MinimumOrderAmountFormSubmitButton = ({
  saveReq,
  onSubmit
}: Props) => {
  const { t } = useTranslation();
  const { handleSubmit } = useFormContext<MinimumOrderFormValues>();
  return (
    <ActionButton.Save
      onClick={handleSubmit(onSubmit)}
      redirectTo={-1}
      saveReq={saveReq}
    >
      {t('producer:MinimumOrderSaveButtonText')}
    </ActionButton.Save>
  );
};
