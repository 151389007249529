import { PropsWithChildren } from 'react';
import { tv } from '../../utils/tv';

const style = tv({
  base: `
    flex
    flex-col
    items-center
    justify-center
    overflow-hidden
    py-[12px]
  `
});

type Props = PropsWithChildren;

export const NavContext = ({ children }: Props) => {
  return <div className={style()}>{children}</div>;
};
