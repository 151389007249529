import { PropsWithChildren } from 'react';
import { tv } from '../../../utils/tv';

const style = tv({
  base: `
    text-paragraphBold
    text-black
  `,
  variants: {
    secondary: {
      true: `text-secondary`
    }
  }
});

type Props = PropsWithChildren<{
  secondary?: boolean;
}>;

export const InfoSectionItemDetails = ({ secondary, children }: Props) => {
  return <dd className={style({ secondary })}>{children}</dd>;
};
