import { ModalAction } from './presets/modal-action';
import { NavIcon } from './presets/nav-icon';
import { ButtonComponent } from './components';
import { DropdownItem } from './presets/dropdown-item';
import { CalloutToggle } from './presets/callout-toggle';

export const Button = Object.assign(ButtonComponent, {
  CalloutToggle,
  DropdownItem,
  ModalAction,
  NavIcon
});
