import { Translation } from '@dagens/frontend-i18n';
import { Navigate } from 'react-router-dom';
import { Illustrations } from '../../../utils/Illustrations';
import SignupIntroduction from './SignupIntroduction';
import SignupProducerFields from '_common/components/signup/SignupProducerFields';
import SignupRequestButton from '_common/components/signup/SignupRequestButton';
import { useSignupProducer } from '_common/hooks/useCreateRole';
import usePageView from '_common/hooks/usePageView';
import { Page } from '@components/page';
import { createProducer, createUser } from 'api';
import {
  PRODUCER_SIGNUP_FAILED,
  PRODUCER_SIGNUP_PAGE_VIEW,
  PRODUCER_SIGNUP_SUBMITTED,
  track
} from 'utils/mixpanel';
import REQ from 'utils/REQ';

const SignupProducers = () => {
  const [
    req,
    setReq,
    fields,
    onFieldChange,
    validation,
    validate,
    organization,
    onOrganizationChanged
  ] = useSignupProducer();

  usePageView(PRODUCER_SIGNUP_PAGE_VIEW);

  const onSubmit = async () => {
    if (!validate()) return;

    const {
      name,
      contactPerson,
      address,
      phone,
      orgNumber,
      email,
      password,
      howDidYouHearAboutUs
    } = fields;

    setReq(REQ.PENDING);
    track(PRODUCER_SIGNUP_SUBMITTED);

    try {
      const { userId } = await createUser({
        email: email.trim(),
        password,
        source: name
      });

      await createProducer({
        name,
        contactPerson,
        address,
        phone,
        orgNumber,
        email: email.trim(),
        userId,
        howDidYouHearAboutUs
      });

      setReq(REQ.SUCCESS);
    } catch (e) {
      setReq(REQ.ERROR);
      track(PRODUCER_SIGNUP_FAILED);
    }
  };

  const {
    name,
    contactPerson,
    address,
    phone,
    orgNumber,
    email,
    password,
    passwordRepeat,
    howDidYouHearAboutUs,
    acceptTerms
  } = fields;

  const { field: validationErrorField, message: validationErrorMessage } =
    validation;

  if (req === REQ.SUCCESS) {
    return <Navigate to="/login?calloutType=verifyEmail" />;
  }

  return (
    <Page>
      <Translation>
        {t => {
          return (
            <>
              <SignupIntroduction
                illustration={Illustrations.PROUD_PRODUCER}
                header={t('signup:producerPageHeader')}
                introductionText={t('signup:ProducerSignupIntroductionText')}
                forOtherUser={t('signup:SignUpForConsumers')}
                otherUserQuery="/letsgo"
              />
              <form
                onSubmit={e => {
                  e.preventDefault();
                  onSubmit();
                }}
              >
                <SignupProducerFields
                  name={name}
                  contactPerson={contactPerson}
                  address={address}
                  phone={phone}
                  orgNumber={orgNumber}
                  email={email}
                  password={password}
                  passwordRepeat={passwordRepeat}
                  howDidYouHearAboutUs={howDidYouHearAboutUs}
                  acceptTerms={acceptTerms}
                  validationErrorField={validationErrorField}
                  onChange={onFieldChange}
                  organization={organization}
                  onOrganizationChanged={onOrganizationChanged}
                />
                <SignupRequestButton
                  req={req}
                  onSubmit={onSubmit}
                  validationErrorMessage={validationErrorMessage}
                />
              </form>
            </>
          );
        }}
      </Translation>
    </Page>
  );
};

export default SignupProducers;
