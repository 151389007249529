import { useTranslation } from '@dagens/frontend-i18n';
import React, { ChangeEventHandler } from 'react';
import { UnstyledButton } from '../button/Button.style';
import Typography from '@carrot-deprecated/typography';
import Container from '@carrot-deprecated/container';
import Theme from '@carrot-deprecated/theme';
import TextArea from '_common/components/text-area/TextArea.style';
import Icon from '_common/components/utils/Icon';
import Tag from '_common/components/utils/Tag';
import { useAppDispatch, useAppSelector } from '_common/hooks/reduxHooks';
import { updateMessage } from '_common/reducers/checkout';
import { getDeliveryDateProducerKey } from '_consumer/reducers/productsForSale';
import { TOGGLE_ORDER_NOTE_TEXT_AREA, track } from 'utils/mixpanel';

type BasketProducerHeaderProps = {
  producerName?: string;
  producerId: string;
  deliveryDate: string;
  deliveryTagTextKey: string;
};

const BasketProducerHeader = ({
  producerName = '',
  producerId,
  deliveryDate,
  deliveryTagTextKey
}: BasketProducerHeaderProps) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [showMessageInput, toggleShowMessageInput] = React.useState(false);

  const { messageKey, message = '' } = useAppSelector(
    ({ checkout: { messages } }) => {
      const key = getDeliveryDateProducerKey(deliveryDate, producerId);
      return {
        messageKey: key,
        message: messages?.[key]
      };
    }
  );

  const onMessageChange: ChangeEventHandler<HTMLTextAreaElement> = ({
    target: { value }
  }) => {
    dispatch(updateMessage({ messageKey, value }));
  };

  return (
    <Container mt="l">
      <Container
        flex
        justify="flex-start"
        alignItems="center"
        wrap="wrap-reverse"
      >
        <Typography variant="inputLabel" mr="xxs" id={messageKey}>
          {producerName}
        </Typography>
        <UnstyledButton
          style={{ padding: 0 }}
          onClick={() => {
            track(TOGGLE_ORDER_NOTE_TEXT_AREA);
            toggleShowMessageInput(!showMessageInput);
          }}
        >
          <Icon
            mr={Theme.Spacings.xxs}
            icon={message ? 'orderMessageFilled' : 'orderMessage'}
            $size="m"
          />
        </UnstyledButton>
        <Tag
          bgColor={
            deliveryTagTextKey === 'DagensDelivery'
              ? Theme.Colours.dagensPurple
              : Theme.Colours.noteworthy
          }
          text={t(`common:${deliveryTagTextKey}`)}
        />
      </Container>
      {showMessageInput && (
        <Container my="s">
          <TextArea
            id={messageKey}
            value={message}
            onChange={onMessageChange}
            placeholder={t('common:writeMessageTo', {
              recipient: producerName
            })}
          />
        </Container>
      )}
    </Container>
  );
};

export default BasketProducerHeader;
