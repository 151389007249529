import Container from '../../../carrot-deprecated/container';
import Typography from '@carrot-deprecated/typography';
import { getOrderLinePrice } from '_common/reducers/orders';
import { OrderLine } from 'types/Order';

type Props = {
  line: OrderLine;
  originalLine?: OrderLine;
};

export const ChangedTotalCell = ({ line, originalLine }: Props) => {
  const orderedUnitsWithUnit = getOrderLinePrice(line);
  const originalOrderedUnitsWithUnit = originalLine
    ? getOrderLinePrice(originalLine)
    : null;

  return (
    <Container flex direction="column">
      <Typography variant="paragraphSmall">
        {orderedUnitsWithUnit} kr
      </Typography>
      {originalLine &&
        orderedUnitsWithUnit !== originalOrderedUnitsWithUnit && (
          <Typography
            variant="paragraphSmall"
            color="secondary"
            style={{ textDecoration: 'line-through' }}
          >
            {originalOrderedUnitsWithUnit} kr
          </Typography>
        )}
    </Container>
  );
};
