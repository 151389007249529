import { Form } from '@dagens/frontend-forms';
import { Box, PageHeader } from '@dagens/carrot';
import { useTranslation } from '@dagens/frontend-i18n';
import { useState } from 'react';
import { Page } from '../../../../components/page';
import {
  useAppDispatch,
  useAppSelector
} from '../../../../_common/hooks/reduxHooks';
import usePageView from '../../../../_common/hooks/usePageView';
import {
  SELF_DELIVERY_FEE_FORM_PAGE_VIEW,
  SELF_DELIVERY_FEE_SAVED_EMPTY,
  SELF_DELIVERY_FEE_SAVED_WITH_VALUE,
  track
} from '../../../../utils/mixpanel';
import REQ, { ReqType } from '../../../../utils/REQ';
import { RootState } from '../../../../store';

import { selfDeliveryFeesUpdated } from '../../../../_common/reducers/auth';
import { ProducerDeliveryFee } from '../../../../types/Producer';
import {
  deleteProducerDeliveryFee,
  patchProducerDeliveryFee,
  postProducerDeliveryFee
} from '../../../../api';
import Typography from '../../../../carrot-deprecated/typography';
import { SelfDeliveryFeeForm, SelfDeliveryFeeFormValues } from './form';
import { SelfDeliveryFeeFormSubmitButton as Submit } from './submit-button';

const useSkip = () => {
  const skip = () => {
    track(SELF_DELIVERY_FEE_SAVED_EMPTY);
  };
  return skip;
};

const useDeleteProducerFee = () => {
  const dispatch = useAppDispatch();

  const deleteFee = async (producerId: string, _id: string) => {
    await deleteProducerDeliveryFee(producerId, _id);
    track(SELF_DELIVERY_FEE_SAVED_EMPTY);
    dispatch(selfDeliveryFeesUpdated([]));
  };

  return deleteFee;
};

const useUpdateProducerFee = () => {
  const dispatch = useAppDispatch();

  const update = async (
    producerId: string,
    previousFee: ProducerDeliveryFee,
    newPrice: number,
    automatic: boolean
  ) => {
    await patchProducerDeliveryFee(producerId, previousFee._id, {
      price: newPrice,
      automatic
    });
    track(SELF_DELIVERY_FEE_SAVED_WITH_VALUE);
    dispatch(
      selfDeliveryFeesUpdated([{ ...previousFee, price: newPrice, automatic }])
    );
  };

  return update;
};

const useCreateProducerFee = () => {
  const dispatch = useAppDispatch();

  const create = async (
    producerId: string,
    price: number,
    automatic: boolean
  ) => {
    const data = await postProducerDeliveryFee(producerId, {
      price,
      automatic
    });
    track(SELF_DELIVERY_FEE_SAVED_WITH_VALUE);
    dispatch(selfDeliveryFeesUpdated([data]));
  };

  return create;
};

export const SelfDeliveryFeePage = () => {
  const skip = useSkip();
  const deleteFee = useDeleteProducerFee();
  const update = useUpdateProducerFee();
  const create = useCreateProducerFee();

  usePageView(SELF_DELIVERY_FEE_FORM_PAGE_VIEW);
  const { producerId, defaultSelfDeliveryFee } = useAppSelector(
    ({ auth: { _id: producerId, selfDeliveryFees } }: RootState) => {
      return {
        producerId,
        defaultSelfDeliveryFee: selfDeliveryFees?.at(0)
      };
    }
  );

  const { t } = useTranslation();
  const [req, setReq] = useState<ReqType>(REQ.INIT);

  const onSave = async ({ price, automatic }: SelfDeliveryFeeFormValues) => {
    setReq(REQ.PENDING);
    if (!producerId) {
      setReq(REQ.ERROR);
      return;
    }

    const isAutomatic = Boolean(automatic.at(0));
    try {
      if (!price && !defaultSelfDeliveryFee) {
        skip();
      } else if (!price && defaultSelfDeliveryFee) {
        await deleteFee(producerId, defaultSelfDeliveryFee._id);
      } else if (price && defaultSelfDeliveryFee) {
        await update(producerId, defaultSelfDeliveryFee, price, isAutomatic);
      } else if (price) {
        await create(producerId, price, isAutomatic);
      }
      setReq(REQ.SUCCESS);
    } catch (e) {
      setReq(REQ.ERROR);
    }
  };

  return (
    <Form.Provider
      defaultValue={{
        price: defaultSelfDeliveryFee?.price ?? 0,
        automatic: defaultSelfDeliveryFee?.automatic ? [true] : []
      }}
    >
      <Page
        bottom={
          <Box.BottomSheet>
            <Submit saveReq={req} onSubmit={onSave} />
          </Box.BottomSheet>
        }
      >
        <PageHeader title={t('producer:SelfDeliveryFeePageHeader')} />
        <Box.FormWrapper>
          <Form.Section>
            <Typography variant="paragraph">
              {t('producer:SelfDeliveryFeePageDescription')}
            </Typography>
            <SelfDeliveryFeeForm onSubmit={onSave} />
          </Form.Section>
        </Box.FormWrapper>
      </Page>
    </Form.Provider>
  );
};
