import { Nav } from '@dagens/carrot';
import { useTranslation } from '@dagens/frontend-i18n';
import { useAppSelector } from '../../../../_common/hooks/reduxHooks';
import { PlanService } from '../../../../services/PlanService';
import { MenuLogout, MenuSecondaryItems } from './shared';

export const MenuProducer = () => {
  const { t } = useTranslation();
  const { name, email, webshop, hasWebshop } = useAppSelector(({ auth }) => {
    return {
      name: auth.name,
      email: auth.loginEmail,
      webshop: auth.shopUrl,
      hasWebshop: PlanService.hasFeature(auth.plan, 'WEBSHOP')
    };
  });

  return (
    <Nav.Menu heading={t('common:Menu')}>
      <Nav.MenuGroup title={t('common:Account', { name })}>
        <Nav.MenuPrimaryItem text={t('producer:MyPage')} to="/profile" />
        {hasWebshop && (
          <Nav.MenuPrimaryItem
            text={t('producer:YourWebshop')}
            to={webshop ? `/${webshop}` : '/profile'}
          />
        )}
        <Nav.MenuPrimaryItem
          text={t('common:BillingAccountHeader')}
          to="/billing-account"
        />
      </Nav.MenuGroup>
      <Nav.MenuDivider />
      <Nav.MenuGroup title={t('common:You', { email })}>
        <Nav.MenuPrimaryItem
          text={t('common:header.switchRole')}
          to="/switch-role"
        />
      </Nav.MenuGroup>
      <Nav.MenuDivider />
      <MenuSecondaryItems />
      <Nav.MenuDivider />
      <MenuLogout />
    </Nav.Menu>
  );
};
