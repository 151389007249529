import { Nav } from '@dagens/carrot';
import { MenuAuth } from '../menu/auth';
import { useMediaQuery } from '../../../../_common/hooks/useMediaQueries';

export const HeaderNavAuth = () => {
  const { desktopUp } = useMediaQuery();
  return (
    <Nav.Bar
      left={desktopUp && <Nav.LogoHome />}
      center={null}
      right={<MenuAuth />}
    />
  );
};
