import { useTranslation } from '@dagens/frontend-i18n';
import Typography from '@carrot-deprecated/typography';
import Callout from '@carrot-deprecated/callout';
import { useAppSelector } from '_common/hooks/reduxHooks';

const EmptyBasket = () => {
  const { t } = useTranslation();
  const numProducts = useAppSelector(({ productsForSale: { items = [] } }) => {
    return items.length;
  });

  return (
    <Callout my="xs" colour="lightGrey">
      <Typography variant="paragraph" mb="xs">
        {t('consumer:emptyBasket', { count: numProducts })}
      </Typography>
      <Typography variant="paragraph">
        {t('consumer:moneyToProducer')}
      </Typography>
    </Callout>
  );
};

export default EmptyBasket;
