import { useWatch } from '@dagens/frontend-forms';
import { ProductFormValues } from '..';

type Props = {
  defaultValue?: string | null;
};

// The title of the edit product page is the value of the name field
// This value is only within the form context, so we need to use portals
export const ProductEditFormPageTitle = ({ defaultValue }: Props) => {
  const value = useWatch<ProductFormValues>({
    name: 'name',
    defaultValue
  }) as string;
  return value ?? defaultValue;
};
