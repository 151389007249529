import { PropsWithChildren } from 'react';
import { To } from 'react-router-dom';
import { Link } from '../../../style-components/link';

type Props = PropsWithChildren<{
  to: To;
}>;

export const BottomActionsCancel = ({ to, children }: Props) => {
  return (
    <Link variant="borderless" size="small" color="error" to={to}>
      {children}
    </Link>
  );
};
