import { formFieldFactory } from '@dagens/frontend-forms';
import { useTranslation } from '@dagens/frontend-i18n';
import { getAddress } from '../../../api';
import { StructuredAddress } from '../../../types/Logistics';
import { BillingAddressFeedback } from './billing-address-feedback';
import { BillingFormValues } from '.';

const searchAddress = async (query: string | null) => {
  if (!query || query === '') {
    return null;
  }

  try {
    const result = await getAddress({ address: query });
    return [result];
  } catch (error) {
    return null;
  }
};

const addressToString = (address?: StructuredAddress | string | null) => {
  if (!address) {
    return '';
  }
  if (typeof address === 'string') {
    return address;
  }
  return `${address.addressLine1}, ${address.postalCode} ${address.city}`;
};

const { Field, Search } = formFieldFactory<BillingFormValues>();

type Props = {
  defaultBillingAddress?: StructuredAddress | string | null;
};

export const CustomerBillingAddress = ({
  defaultBillingAddress: defaultBillingAddress
}: Props) => {
  const { t } = useTranslation();
  return (
    <Field
      to="billingAddress"
      label={t('producer:EditCustomerBillingAddress')}
      feedback={
        <BillingAddressFeedback defaultBillingAddress={defaultBillingAddress} />
      }
      required
    >
      <Search
        name="billingAddress"
        placeholder={t(
          'common:deliveryInformationFormFieldDeliveryAddressPlaceholder'
        )}
        searchFn={searchAddress}
        displayValue={addressToString}
        debounce={500}
        required
        validate={v => typeof v !== 'string'}
        emptyOptionsText={t(
          'common:deliveryInformationFormFieldDeliveryAddressNotFound'
        )}
      />
    </Field>
  );
};
