import { css } from 'styled-components';
import Spacings, { SpacingNames } from '../theme/spacings';

export type PositionProps = {
  position?:
    | 'relative'
    | 'absolute'
    | 'fixed'
    | 'sticky'
    | 'initial'
    | 'inherit';
  top?: SpacingNames;
  right?: SpacingNames;
  bottom?: SpacingNames;
  left?: SpacingNames;
};

const Positions = css<PositionProps>`
  ${({ position }) => {
    return position && `position: ${position};`;
  }};

  ${({ top }) => {
    return top && `top: ${Spacings[top]}px;`;
  }}
  ${({ right }) => {
    return right && `right: ${Spacings[right]}px;`;
  }}
  ${({ bottom }) => {
    return bottom && `bottom: ${Spacings[bottom]}px;`;
  }}
  ${({ left }) => {
    return left && `left: ${Spacings[left]}px;`;
  }}
`;

export default Positions;
