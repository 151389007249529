import { useFormContext } from '@dagens/frontend-forms';
import { useTranslation } from '@dagens/frontend-i18n';
import { BottomActions, Icon } from '@dagens/carrot';
import { AddCustomerFormValues } from '../types';

type Props = {
  onSubmit: (data: AddCustomerFormValues) => void;
};

export const AddCustomerBillingNextButton = ({ onSubmit }: Props) => {
  const { t } = useTranslation();
  const { handleSubmit } = useFormContext<AddCustomerFormValues>();

  return (
    <BottomActions.Submit onClick={handleSubmit(onSubmit)}>
      {t('producer:AddCustomer.continueCreation')}
      <Icon icon="forward" size="small" />
    </BottomActions.Submit>
  );
};
