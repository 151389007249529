import { themeAnimations, themeKeyframes } from './animations';
import { themeBorderRadius } from './border-radius';
import { themeBorderWidth } from './border-width';
import { themeColors } from './colors';
import { focusStylePlugin } from './focus';
import { themeMaxWidth } from './max-width';
import { themeScreens } from './screens';
import { themeSpacing } from './spacing';
import {
  themeTransitionDelay,
  themeTransitionDuration,
  themeTransitionTimingFunction,
  transitionPlugin
} from './transition';
import {
  textPlugin,
  themeFontFamily,
  themeFontSize,
  themeFontWeight,
  themeLetterSpacing,
  themeLineHeight
} from './typography';

export const tailwindTheme = {
  animation: themeAnimations,
  borderRadius: themeBorderRadius,
  borderWidth: themeBorderWidth,
  colors: themeColors,
  fontFamily: themeFontFamily,
  fontSize: themeFontSize,
  fontWeight: themeFontWeight,
  keyframes: themeKeyframes,
  letterSpacing: themeLetterSpacing,
  lineHeight: themeLineHeight,
  maxWidth: themeMaxWidth,
  screens: themeScreens,
  spacing: themeSpacing,
  transitionDelay: themeTransitionDelay,
  transitionDuration: themeTransitionDuration,
  transitionTimingFunction: themeTransitionTimingFunction
};

export const tailwindPlugins = [focusStylePlugin, textPlugin, transitionPlugin];
