import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Box } from '@dagens/carrot';
import { tr, useTranslation } from '@dagens/frontend-i18n';
import i18n from 'i18next';
import Logger from '../../../services/Logger';
import Container from '@carrot-deprecated/container';
import Typography from '@carrot-deprecated/typography';
import OrderLineTable from '_common/components/orderline-table/OrderLineTable';
import OrderAdjustmentSummaryTable from '_common/pages/order/OrderAdjustmentSummaryTable';
import {
  getTotalOrderPrice,
  hasOrderLineBeenUpdated
} from '_common/reducers/orders';
import ConsumerContactInfo from '_producer/pages/order/ConsumerContactInfo';
import { ActionButton } from '@components/action-button';
import { Page } from '@components/page';
import {
  CreateOrderAdjustmentPayload,
  postOrderAdjustment,
  UpdateOrderDeliveryFeePayload,
  UpdateOrderLinePayload
} from 'api';
import { Order, OrderLine, OrderProducerDeliveryFee } from 'types/Order';
import { formatDate } from 'utils/date/format';
import REQ, { ReqType } from 'utils/REQ';
import { formatNok2Decimals } from 'utils/texts';

type CreateOrderAdjustmentProps = {
  order: Order;
};

const getOrderAdjustmentOverviewRows = (
  order: Order,
  adjustedLines: OrderLine[],
  orderDeliveryFee?: OrderProducerDeliveryFee,
  adjustedDeliveryFee?: number
) => {
  const initialOrderTotal = getTotalOrderPrice(
    order.orderLines,
    orderDeliveryFee?.finalValue
  );
  const adjustedOrderTotal = getTotalOrderPrice(
    adjustedLines,
    adjustedDeliveryFee
  );
  const diff = adjustedOrderTotal - initialOrderTotal;
  return [
    {
      description: `${tr(i18n, 'common:InitialOrder')} (${order.orderNumberString})`,
      amount: formatNok2Decimals(initialOrderTotal)
    },
    {
      description: `${tr(i18n, 'common:Adjustment')}`,
      amount: formatNok2Decimals(diff)
    },
    {
      description: tr(i18n, 'common:AdjustedOrderTotal'),
      amount: formatNok2Decimals(adjustedOrderTotal)
    }
  ];
};

type LocationState = { returnPath: string } | undefined;

// TODO support some feedback in the very unlikely event that producer ends up on this page for an order that has already been adjusted
export const CreateOrderAdjustment = ({
  order
}: CreateOrderAdjustmentProps) => {
  const {
    consumer: { name },
    deliveryDate,
    producerDeliveryFee
  } = order;

  const { t } = useTranslation();
  const location = useLocation();
  const state = location.state as LocationState;
  const [req, setReq] = useState<ReqType>(REQ.INIT);
  const [updatedOrderLines, setUpdatedOrderLines] = useState<
    UpdateOrderLinePayload[]
  >([]);
  const [updatedDeliveryFee, setUpdatedDeliveryFee] = useState<
    number | undefined
  >(order.producerDeliveryFee?.finalValue);

  const mergeLine = (
    orderLine: OrderLine,
    updatedOrderLine: UpdateOrderLinePayload
  ) => {
    return {
      ...orderLine,
      nrOfOrderedUnitsDelivered: updatedOrderLine.nrOfOrderedUnitsDelivered,
      nrOfPricedUnitsDelivered: updatedOrderLine.nrOfPricedUnitsDelivered,
      pricingAtTimeOfOrder: {
        ...orderLine.pricingAtTimeOfOrder,
        nokPerPricedUnit: updatedOrderLine.nokPerPricedUnit
      }
    };
  };

  const saveOrderLine = (payload: UpdateOrderLinePayload) => {
    const filteredUpdatedOrderLines = updatedOrderLines.filter(ol => {
      return ol.lineKey !== payload.lineKey;
    });
    const originalOrderLine = order.orderLines.find(ol => {
      return ol._key === payload.lineKey;
    });
    if (!originalOrderLine) {
      Logger.error(
        new Error(
          `Trying to save order line that does not exist in order: ${payload.lineKey}`
        )
      );
      return;
    }

    const mergedLine = mergeLine(originalOrderLine, payload);
    const hasChanged =
      hasOrderLineBeenUpdated(mergedLine) ||
      originalOrderLine.pricingAtTimeOfOrder.nokPerPricedUnit !==
        payload.nokPerPricedUnit;

    if (hasChanged) {
      setUpdatedOrderLines([...filteredUpdatedOrderLines, payload]);
    } else {
      setUpdatedOrderLines([...filteredUpdatedOrderLines]);
    }
  };

  const saveDeliveryFee = (
    _: string,
    payload: UpdateOrderDeliveryFeePayload
  ) => {
    const hasChanged = updatedDeliveryFee !== payload.price;
    if (hasChanged && producerDeliveryFee) {
      setUpdatedDeliveryFee(payload.price);
    }
  };

  const mergeLines = () => {
    const lines = order.orderLines.map(ol => {
      const updatedOrderLine = updatedOrderLines.find(uol => {
        return uol.lineKey === ol._key;
      });
      if (updatedOrderLine) {
        return mergeLine(ol, updatedOrderLine);
      }
      return ol;
    });
    return lines;
  };

  const onSubmit = async () => {
    setReq(REQ.PENDING);
    const payload: CreateOrderAdjustmentPayload = {
      orderLines: updatedOrderLines.map(ol => {
        return {
          lineKey: ol.lineKey,
          nrOfOrderedUnitsDelivered: ol.nrOfOrderedUnitsDelivered,
          nrOfPricedUnitsDelivered: ol.nrOfPricedUnitsDelivered,
          nokPerPricedUnit: ol.nokPerPricedUnit
        };
      }),
      deliveryFee: updatedDeliveryFee
    };
    try {
      await postOrderAdjustment(order._id, payload);
      setReq(REQ.SUCCESS);
    } catch (e) {
      setReq(REQ.ERROR);
    }
  };

  const isAdjusted =
    updatedOrderLines.length > 0 ||
    updatedDeliveryFee !== producerDeliveryFee?.finalValue;

  return (
    <Page
      bottom={
        <Box.BottomSheet>
          <ActionButton.Save
            saveReq={req}
            onClick={onSubmit}
            disabled={!isAdjusted}
            redirectTo={state?.returnPath ? -1 : `/orders/${order.secret}`}
          >
            {t('producer:OrderAdjustments.saveButtonLabel')}
          </ActionButton.Save>
        </Box.BottomSheet>
      }
    >
      <Typography variant="secondaryHeading" as="h1">
        {t('producer:OrderAdjustments.pageTitle', {
          orderNr: order.orderNumberString
        })}
      </Typography>
      <Typography variant="paragraph">
        {t('producer:DeliveryToCustomerOnDate', {
          name,
          date: formatDate(deliveryDate)
        })}
      </Typography>
      <ConsumerContactInfo consumer={order.consumer} />
      <Typography variant="paragraphBold" as="p">
        {t('producer:OrderAdjustments.adjustOrderLabel')}
      </Typography>
      <Typography variant="paragraph" mb="s">
        {t('producer:OrderAdjustments.adjustmentSettlementInformaiton')}
      </Typography>
      <OrderLineTable
        orderId={order._id}
        orderLines={mergeLines()}
        originalOrderLines={order.orderLines}
        orderDeliveryFee={
          producerDeliveryFee !== undefined
            ? {
                ...producerDeliveryFee,
                valueAtTimeOfOrder: producerDeliveryFee?.finalValue,
                finalValue: updatedDeliveryFee
              }
            : undefined
        }
        orderDeliveryType={order.deliveryType}
        isReadOnly={false}
        isOrderAdjustment
        onSaveOrderLine={saveOrderLine}
        onSaveDeliveryFee={saveDeliveryFee}
      />
      {isAdjusted && (
        <Container my="xl">
          <OrderAdjustmentSummaryTable
            rows={getOrderAdjustmentOverviewRows(
              order,
              mergeLines(),
              producerDeliveryFee,
              updatedDeliveryFee
            )}
          />
        </Container>
      )}
    </Page>
  );
};
