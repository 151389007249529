import { useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { AddCustomerFormValues } from '../types';
import { getAddress } from '../../../../api';

// Navigate to next step and pass the organization value
export const useOnSave = () => {
  const navigate = useNavigate();
  const routeState = useLocation().state as AddCustomerFormValues | null;

  const onSave = useCallback(async (state: AddCustomerFormValues) => {
    // If the organization doesn't change (for example, when going back and forward again)
    // keep the same values as before
    let nextRouteState: AddCustomerFormValues | null = null;
    if (routeState?.organization?.orgNumber === state.organization?.orgNumber) {
      nextRouteState = routeState;
    } else {
      const deliveryAddress = state.organization?.address
        ? await getAddress({ address: state.organization?.address })
        : state.organization?.address;
      nextRouteState = {
        ...state,
        organization: state.organization ?? null,
        customerName: state.organization?.name ?? null,
        contactPhone: state.organization?.phone ?? null,
        notificationEmail: state.organization?.email ?? null,
        billingName: state.organization?.name ?? null,
        billingAddress: deliveryAddress ?? null,
        primaryEmail: state.organization?.email ?? null,
        deliveryAddress: deliveryAddress ?? null,
        defaultOrganizationDeliveryAddress: deliveryAddress ?? null
      };
    }

    navigate('/customers/add/review-billing-information', {
      state: nextRouteState
    });
  }, []);

  return { onSave };
};
