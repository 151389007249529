import { useTranslation } from '@dagens/frontend-i18n';
import styled from 'styled-components';

import { NotificationSettings } from '../../../_common/components/notification-settings';
import Typography from '@carrot-deprecated/typography';
import SectionHOC from '_common/components/section-hoc/SectionHOC';

const MaxWidthContainer = styled.div`
  width: 100%;
  max-width: 490px;
`;

const ProfileNotificationsSection = () => {
  const { t } = useTranslation();
  return (
    <MaxWidthContainer>
      <Typography variant="paragraph" mb="m">
        {t('producer:NotificationsDescription')}
      </Typography>
      <NotificationSettings />
    </MaxWidthContainer>
  );
};

export default SectionHOC(ProfileNotificationsSection);
