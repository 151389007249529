import { formatPhoneNumber } from '@dagensmat/core';
import Typography from '@carrot-deprecated/typography';
import Container from '@carrot-deprecated/container';
import { SpacingNames } from '@carrot-deprecated/theme/spacings';

type OrderContactDetailsProps = {
  name?: string;
  contactPerson?: string;
  phone?: string;
  consumerEmail?: string;
  my?: SpacingNames;
};

const OrderContactDetails = ({
  name,
  contactPerson,
  phone,
  consumerEmail,
  my = 'm'
}: OrderContactDetailsProps) => {
  return (
    <Container my={my}>
      <Typography variant="tertiaryHeading" as="h2">
        {name}
      </Typography>
      <Typography variant="paragraph">{contactPerson}</Typography>
      {consumerEmail && (
        <Typography variant="paragraph">
          <Typography variant="link" href={`mailTo:${consumerEmail}`}>
            {consumerEmail}
          </Typography>
        </Typography>
      )}
      <Typography variant="paragraph">
        <Typography variant="link" href={`tel:${phone}`}>
          {formatPhoneNumber(phone)}
        </Typography>
      </Typography>
    </Container>
  );
};

export default OrderContactDetails;
