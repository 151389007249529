import { Button, ButtonGroup, DateRange } from '@dagens/carrot';
import { useTranslation } from '@dagens/frontend-i18n';
import { DateUtils, LocaleUtils } from '@dagens/utils';

type Props = {
  activePeriod: DateRange | undefined;
  onClickDelete: () => void;
  onClickSave: () => void;
};

export const PeriodPickerActionsEdit = ({
  activePeriod,
  onClickDelete,
  onClickSave
}: Props) => {
  const { t, i18n } = useTranslation();

  return (
    <ButtonGroup.Wrap>
      <Button variant="ghost" onClick={onClickDelete}>
        {t('producer:productSeasonCalendarResetButton2')}
      </Button>
      <Button
        variant="primary"
        onClick={onClickSave}
        disabled={!activePeriod || !activePeriod.from || !activePeriod.to}
      >
        {t('producer:productSeasonCalendarSaveButton2', {
          period: DateUtils.periodToString(activePeriod, {
            locale: LocaleUtils.localeFromLanguage(i18n.language)
          })
        })}
      </Button>
    </ButtonGroup.Wrap>
  );
};
