import { PropsWithChildren } from 'react';
import { tv } from '../../../utils/tv';

const style = tv({
  base: `
    flex
    flex-col
    gap-s
  `
});

type Props = PropsWithChildren;

export const InfoSectionItems = ({ children }: Props) => {
  return <dl className={style()}>{children}</dl>;
};
