import { tr } from '@dagens/frontend-i18n';
import i18n from 'i18next';
import { CountryCode } from '@dagensmat/core';
import { StructuredAddress } from '../types/Logistics';

export const getCountryNameByCode = (code: CountryCode): string => {
  switch (code) {
    case CountryCode.NO:
      return tr(i18n, 'common:Norway');
    case CountryCode.DK:
      return tr(i18n, 'common:Denmark');
    default:
      return 'Unknown';
  }
};

export const formatStructuredAddress = (
  structuredAddress: StructuredAddress,
  includeCountry = true
) => {
  const { addressLine1, postalCode, city, country } = structuredAddress;
  const countryName = getCountryNameByCode(country);
  return `${addressLine1}, ${postalCode} ${city}${includeCountry ? `, ${countryName}` : ''}`;
};
