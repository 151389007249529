import { useLocation } from 'react-router-dom';
import { useAppSelector } from '_common/hooks/reduxHooks';
import { hasConsumerOnboarded, isGuestConsumer } from 'utils/role';

const useOnboarding = (): {
  hasNotOnboarded: () => boolean;
  getOnboardingPath: () => string;
} => {
  const { pathname } = useLocation();

  const { _type, onboarding, isGuest } = useAppSelector(({ auth }) => {
    return {
      _type: auth._type,
      onboarding: auth.onboarding || {},
      isGuest: Boolean(auth._id && isGuestConsumer(auth._id))
    };
  });

  const hasNotOnboarded = () => {
    return (
      _type === 'consumers' && !hasConsumerOnboarded(onboarding) && !isGuest
    );
  };

  const getOnboardingPath = () => {
    return `/onboarding/1?returnTo=${encodeURIComponent(pathname.slice(1))}`;
  };

  return { hasNotOnboarded, getOnboardingPath };
};

export default useOnboarding;
