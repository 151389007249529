import { Trans, Translation } from '@dagens/frontend-i18n';
import Typography from '@carrot-deprecated/typography';

const FaqsLinkText = () => {
  return (
    <Translation>
      {t => {
        return (
          <Typography variant="paragraph" mt="l">
            <Trans
              t={t}
              i18nKey="common:CheckFAQsPageForQuestions"
              values={{ linkText: t('common:FAQsPage') }}
              components={{
                FAQPageLink: (
                  <Typography
                    variant="link"
                    href="https://dagens.farm/faqs"
                    target="_blank"
                    rel="noopener noreferrer"
                  />
                )
              }}
            />
          </Typography>
        );
      }}
    </Translation>
  );
};

export default FaqsLinkText;
