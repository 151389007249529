import { useTranslation } from '@dagens/frontend-i18n';
import DeliveryDateOptions from './DeliveryDateOptions';
import Typography from '@carrot-deprecated/typography';
import { useAppDispatch, useAppSelector } from '_common/hooks/reduxHooks';
import { updateSelectedDeliveryDate } from '_consumer/reducers/productsForSale';
import { SELECT_DELIVERY_DAY_PRODUCT_SEARCH, track } from 'utils/mixpanel';

const DeliveryDateSelection = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { availableDeliveryDates } = useAppSelector(({ productsForSale }) => {
    return productsForSale;
  });

  const setDeliveryDate = (date: string) => {
    dispatch(updateSelectedDeliveryDate(date));
    track(SELECT_DELIVERY_DAY_PRODUCT_SEARCH);
  };

  return (
    <>
      <Typography variant="inputLabel" mt="m" mb="xxs">
        {t('consumer:WhenAreYouOrderingFor')}
      </Typography>
      <DeliveryDateOptions
        availableDeliveryDates={availableDeliveryDates}
        setDeliveryDate={setDeliveryDate}
      />
    </>
  );
};

export default DeliveryDateSelection;
