import { useTranslation } from '@dagens/frontend-i18n';
import Typography from '@carrot-deprecated/typography';
import Container from '@carrot-deprecated/container';
import Theme from '@carrot-deprecated/theme';
import ResponsiveImage from '_common/components/utils/ResponsiveImage.style';
import ImageToTheRightContainer from '_common/components/utils/ImageToTheRightContainer.style';

type SignupIntroductionProps = {
  illustration: string;
  header: string;
  introductionText: string;
  forOtherUser: string;
  otherUserQuery: string;
};

const SignupIntroduction = ({
  illustration,
  header,
  introductionText,
  forOtherUser,
  otherUserQuery
}: SignupIntroductionProps) => {
  const { t } = useTranslation();
  return (
    <ImageToTheRightContainer>
      <div>
        <Typography variant="primaryHeading" my="xl">
          {header}
        </Typography>
        <Typography variant="paragraph">{introductionText}</Typography>
        <br />
        <Container flex alignItems="baseline" justify="start">
          <Typography variant="paragraph" mr="xxs">
            {forOtherUser}
          </Typography>
          <Typography variant="routerLink" to={otherUserQuery}>
            {t('signup:SignUpHere')}
          </Typography>
        </Container>
      </div>
      <ResponsiveImage
        src={illustration}
        alt="Sign up illustration"
        style={{ padding: Theme.Spacings.s }}
      />
    </ImageToTheRightContainer>
  );
};

export default SignupIntroduction;
