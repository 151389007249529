import { Trans as I18nTrans, TransProps } from 'react-i18next';
import { OverrideProperties, SetRequired } from 'type-fest';

import { BaseTranslateKey, TFunction, TranslateKey } from './types';

type Props =
  | SetRequired<
      OverrideProperties<TransProps<BaseTranslateKey>, { t: TFunction }>,
      'count'
    >
  | OverrideProperties<TransProps<TranslateKey>, { t: TFunction }>;

export const Trans = ({ t, ...props }: Props) => {
  // @ts-expect-error - the props should be assignable to I18nTrans as it stands
  return <I18nTrans {...props} t={t} />;
};
