import { Form, formFieldFactory } from '@dagens/frontend-forms';
import { useTranslation } from '@dagens/frontend-i18n';

export type MinimumOrderFormValues = {
  minimumOrderAmount: number | null;
  minimumOrderAmountEnforced: boolean;
};

const { Field, Radio, NumberInput } =
  formFieldFactory<MinimumOrderFormValues>();

type Props = {
  onSubmit: (data: MinimumOrderFormValues) => void;
};

export const MinimumOrderForm = ({ onSubmit }: Props) => {
  const { t } = useTranslation();

  return (
    <Form onSubmit={onSubmit}>
      <Form.Section>
        <Field
          to="minimumOrderAmount"
          label={t('producer:MinimumOrderAmountLabelText')}
          helpText={t('producer:MinimumOrderAmountHelperText')}
        >
          <NumberInput
            name="minimumOrderAmount"
            unit={t('producer:MinimumOrderAmountUnit')}
            small
          />
        </Field>
        <Field
          to="minimumOrderAmountEnforced"
          label={t('producer:moaStrictMinimum')}
          required
        >
          <Radio
            name="minimumOrderAmountEnforced"
            options={[true, false]}
            displayValue={value =>
              value
                ? {
                    label: t('producer:moaStrict'),
                    description: t('producer:moaStrictDescription')
                  }
                : {
                    label: t('producer:moaFlexible'),
                    description: t('producer:moaFlexibleDescription')
                  }
            }
          />
        </Field>
      </Form.Section>
    </Form>
  );
};
