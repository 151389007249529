import { PeriodPickerValue } from '@dagens/frontend-forms';
import { Category, Certification } from '../../../types/Product';
import { ProductCreateForm } from './create';
import { ProductEditForm } from './edit';

export type ProductFormValues = {
  name: string | null;
  image: {
    previewUrl?: string;
    file?: File;
  };
  category: Category[];
  type: string | null;
  certifications: Certification[];
  processedState: 'RAW' | 'PROCESSED' | null;
  temperatureZone: 'FROZEN' | 'REFRIGERATED' | 'DRY' | null;
  description: string | null;
  salesStatus: 'FOR_SALE' | 'ON_REQUEST' | 'NOT_FOR_SALE' | null;
  seasonCalendar: PeriodPickerValue;
  capacities: PeriodPickerValue;
};

export const ProductForm = {
  Create: ProductCreateForm,
  Edit: ProductEditForm
};
