import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useAppSelector } from '../../hooks/reduxHooks';
import { isOrderInvoiceSentToDagens } from '../../reducers/orders';
import ConsumerOrderPage from '_consumer/pages/order/ConsumerOrderPage';
import ProducerOrderPage from '_producer/pages/order/ProducerOrderPage';
import { Page } from '@components/page';
import { getOrderAndAdjustments, getOrders } from 'api';
import { Order, OrderKind } from 'types/Order';
import REQ, { ReqType } from 'utils/REQ';

const OrderPageFetcher = () => {
  const { accountId, accountType } = useAppSelector(
    ({ auth: { _id: accountId, _type: accountType } }) => {
      return {
        accountId,
        accountType
      };
    }
  );
  const [req, setReq] = useState<ReqType>(REQ.INIT);
  const [order, setOrder] = useState<Order>();
  const [orderAndAdjustments, setOrderAndAdjustments] = useState<OrderKind[]>(
    []
  );
  const { secret } = useParams<{ secret: string }>();

  const getOrder = async () => {
    setReq(REQ.PENDING);
    try {
      const orders = await getOrders({ secret });
      if (orders.length > 0) {
        setOrder(orders[0]);
        if (isOrderInvoiceSentToDagens(orders[0])) {
          const { data } = await getOrderAndAdjustments(orders[0]._id, {
            accountType,
            accountId
          });
          setOrderAndAdjustments(data);
        }
        setReq(REQ.SUCCESS);
      } else {
        setReq(REQ.NOT_FOUND);
      }
    } catch (e) {
      setReq(REQ.ERROR);
    }
  };

  const onUpdateOrder = async () => {
    await getOrders({ secret })
      .then(orders => {
        setOrder(orders[0]);
      })
      .catch(() => {
        setReq(REQ.ERROR);
        throw new Error(`Could not update order line for secret ${secret}`);
      });
  };

  useEffect(() => {
    getOrder();
  }, [secret]);

  if (req !== REQ.SUCCESS) {
    return <Page.Status req={req} />;
  }
  if (!order) {
    return <Page.NotFound />;
  }

  return accountType === 'consumers' ? (
    <ConsumerOrderPage
      order={order}
      orderAndAdjustments={orderAndAdjustments}
    />
  ) : (
    <ProducerOrderPage
      order={order}
      orderAndAdjustments={orderAndAdjustments}
      onUpdateOrder={onUpdateOrder}
    />
  );
};

export default OrderPageFetcher;
