import { useTranslation } from '@dagens/frontend-i18n';
import { PricingPlanList } from './PricingPlanList';
import Typography from '@carrot-deprecated/typography';
import Container from '@carrot-deprecated/container';
import SectionHOC from '_common/components/section-hoc/SectionHOC';
import { ButtonLink } from '_common/components/button/Button.style';

type ProfilePlanAndPaymentSectionProps = {
  transactionCut: number;
  transactionCutForSelfDelivery: number;
};

const ProfilePlanAndPaymentSection = ({
  transactionCut,
  transactionCutForSelfDelivery
}: ProfilePlanAndPaymentSectionProps) => {
  const { t } = useTranslation();
  return (
    <Container>
      <Typography variant="inputLabel" mb="s">
        {t('common:PricingPlan')}
      </Typography>
      <PricingPlanList
        transactionCut={transactionCut}
        transactionCutForSelfDelivery={transactionCutForSelfDelivery}
      />
      <Typography variant="inputLabel" mb="xxs">
        {t('common:BillingAccountHeader')}
      </Typography>
      <Typography variant="paragraphSmall" color="secondary" mb="xs">
        {t('common:BillingAccountLinkDescription')}
      </Typography>
      <ButtonLink to="/billing-account" variant="ghost">
        {t('common:BillingAccountLinkButton')}
      </ButtonLink>
    </Container>
  );
};
export default SectionHOC(ProfilePlanAndPaymentSection);
