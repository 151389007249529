import { Navigate, useLocation } from 'react-router-dom';
import { BottomActions, Box, Nav, PageHeader } from '@dagens/carrot';
import { useTranslation } from '@dagens/frontend-i18n';
import { Form } from '@dagens/frontend-forms';
import { Page } from '../../../../components/page';
import { AddCustomerFormValues, defaultValue } from '../types';
import { CustomerDeliveryForm } from '../../../components/customer-delivery-form';
import { useMediaQuery } from '../../../../_common/hooks/useMediaQueries';
import { useOnSave } from './use-on-save';
import { AddCustomerDeliveryBackButton as Back } from './back-button';
import { DeliveryBottomActions } from './bottom-actions';

export const AddCustomerDeliveryPage = () => {
  const { desktopUp } = useMediaQuery();
  const { t } = useTranslation();
  const { onSave, saveReq } = useOnSave();
  const { state }: { state: AddCustomerFormValues } = useLocation();

  if (
    !state?.organization ||
    !state?.billingName ||
    !state?.primaryEmail ||
    !state?.reminderEmail ||
    !state?.customerName ||
    !state?.contactPersonName ||
    !state?.contactPhone ||
    !state?.notificationEmail
  ) {
    return <Navigate to="/customers/add/start" />;
  }

  const title = t('producer:AddCustomer.reviewDeliveryInformationPageHeader');
  const subtitle = `${t('producer:AddCustomer.headerAnnotation')} (4/4)`;

  return (
    <Form.Provider defaultValue={{ ...defaultValue, ...state }}>
      <Page
        backButton={<Back />}
        navMobileText={
          <>
            <Nav.ContextTitle>{title}</Nav.ContextTitle>
            <Nav.ContextSubtitle>{subtitle}</Nav.ContextSubtitle>
          </>
        }
        header={
          desktopUp && (
            <Box.FullWidth>
              <PageHeader title={title} subtitle={subtitle} />
            </Box.FullWidth>
          )
        }
        bottom={
          <BottomActions border>
            <DeliveryBottomActions saveReq={saveReq} onSubmit={onSave} />
          </BottomActions>
        }
      >
        <Box.FormWrapper>
          <Form onSubmit={onSave}>
            <CustomerDeliveryForm
              defaultDeliveryAddress={state.defaultOrganizationDeliveryAddress}
            />
          </Form>
        </Box.FormWrapper>
      </Page>
    </Form.Provider>
  );
};
