import { useTranslation } from '@dagens/frontend-i18n';
import { Callout, Image } from '@dagens/carrot';

export const MinimumOrderPageCallout = () => {
  const { t } = useTranslation();

  return (
    <Callout.Expandable title={t('producer:MinimumOrderPageCalloutHeader')}>
      <Callout.Section text={t('producer:MinumumOrderPageCalloutText')} />
      <Callout.Section
        text={t('producer:MinumumOrderPageCalloutImageHeader')}
      />
      <Image
        src="/assets/Customer_experience_preview_picture.png"
        alt={t('producer:MinumumOrderPageCalloutImageHeader')}
      />
    </Callout.Expandable>
  );
};
