import { useTranslation } from '@dagens/frontend-i18n';
import Theme from '@carrot-deprecated/theme';
import { ButtonLink } from '_common/components/button/Button.style';
import useRedirectLogin from '_common/hooks/useRedirectLogin';
import { PRODUCER_SHOP_CLICK_PRODUCT_SIGNUP_CTA, track } from 'utils/mixpanel';

const SignUpToTradeButton = () => {
  const { t } = useTranslation();

  const { get: getRedirectLogin } = useRedirectLogin();

  return (
    <ButtonLink
      my={Theme.Spacings.xs}
      variant="primary"
      to={`/letsgo?redirectLogin=${getRedirectLogin()}`}
      onClick={event => {
        event.stopPropagation();
        track(PRODUCER_SHOP_CLICK_PRODUCT_SIGNUP_CTA);
      }}
    >
      {t('common:SignUpToTradeButton')}
    </ButtonLink>
  );
};

export default SignUpToTradeButton;
