import { Nav } from '@dagens/carrot';
import { useNavLinksProducer } from '../../hooks/use-nav-links-producer';
import { MenuProducer } from '../menu/producer';
import {
  useHeaderNavCenter,
  useHeaderNavLeft,
  useHeaderNavRight
} from './shared';
import { HeaderNavProps } from '.';

export const HeaderNavProducer = ({
  backButton,
  backButtonOnlyOnMobile,
  withLogo,
  returnTo,
  mobileText,
  alwaysShowMenu,
  mobileAction
}: HeaderNavProps) => {
  const links = useNavLinksProducer();
  const left = useHeaderNavLeft({
    withLogo,
    backButton,
    backButtonOnlyOnMobile,
    returnTo
  });
  const center = useHeaderNavCenter({ links, mobileText });
  const right = useHeaderNavRight({
    menu: <MenuProducer />,
    alwaysShowMenu,
    mobileAction
  });

  return <Nav.Bar left={left} center={center} right={right} />;
};
