import { DateUtils } from '@dagens/utils';
import { useState } from 'react';
import { tv } from '../../../utils/tv';

const style = tv({
  slots: {
    container: `
      absolute
      inset-0
      z-30
    `,
    label: `
      !text-allCaps
      absolute
      top-0
      w-auto
      rounded
      bg-black
      p-xxs
      text-white
    `,
    line: `
      absolute
      bottom-0
      top-xs
      border-l
      border-black
    `
  }
});

type Props = {
  text?: string;
};

export const SeasonCalendarToday = ({ text }: Props) => {
  const { container, label, line } = style();
  const [labelRef, setLabelRef] = useState<HTMLSpanElement | null>(null);

  const left = ((DateUtils.dayOfYear(new Date()) - 1) / 365) * 100;
  const labelWidth = labelRef?.clientWidth;
  const labelLeft = `calc(${left}% - ${(labelWidth ?? 0) / 2}px)`;
  const lineLeft = `calc(${left}% - 1px)`;

  return (
    <div className={container()}>
      <div className={line()} style={{ left: lineLeft }}></div>
      <span
        ref={ref => setLabelRef(ref)}
        className={label()}
        style={{ left: labelLeft }}
      >
        {text}
      </span>
    </div>
  );
};
