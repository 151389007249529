import { ReactNode } from 'react';
import { Tag } from '../../../style-components/tag';
import { tv } from '../../../utils/tv';
import { FormFieldErrorMessage } from '../../../style-components/form-field/parts/error-message';

const style = tv({
  slots: {
    container: `
      flex
      flex-col
      gap-xxs
      rounded
      border
      border-black
      p-s
    `,
    header: `
      flex
      justify-between
      gap-s
    `,
    title: `
      flex-1
      text-paragraphBold
    `,
    value: `text-paragraphSmall`,
    actions: `
      mt-xs
      flex
      gap-s
    `
  },
  variants: {
    error: {
      true: {
        container: `border-error`
      }
    }
  }
});

type Props = {
  title: string;
  tag?: string;
  value: string;
  actions?: ReactNode;
  error?: string;
};

export const PriceCard = ({ title, tag, value, actions, error }: Props) => {
  const {
    container,
    header,
    title: titleStyle,
    value: valueStyle,
    actions: actionsStyle
  } = style();
  return (
    <>
      <div className={container({ error: Boolean(error) })}>
        <div className={header()}>
          <div className={titleStyle()}>{title}</div>
          {tag && <Tag color="noteworthy" text={tag} />}
        </div>
        <div className={valueStyle()}>{value}</div>
        {actions && <div className={actionsStyle()}>{actions}</div>}
      </div>
      {error && <FormFieldErrorMessage>{error}</FormFieldErrorMessage>}
    </>
  );
};
