import { ReactComponent as ginger } from './dagens-shapes/ginger.svg';
import { ReactComponent as onion } from './dagens-shapes/onion.svg';
import { IconDefinitions } from './types';

export const dagensShapesIcons = {
  ginger: {
    medium: ginger
  },
  onion: {
    medium: onion
  }
} as const satisfies IconDefinitions;

export type DagensShapesIcon = keyof typeof dagensShapesIcons;
