import { Badge, NavLinkProps } from '@dagens/carrot';
import { useTranslation } from '@dagens/frontend-i18n';
import { useState, useRef, useEffect } from 'react';
import { useAppSelector } from '../../../_common/hooks/reduxHooks';
import { countTotalUnseenMessages } from '../../../_common/reducers/messages';
import { countProductsInBasket } from '../../../_consumer/reducers/basket';

export const useNavLinksConsumer = () => {
  const { t } = useTranslation();
  const [animateFavoriteIcon, setAnimateFavoriteIcon] = useState(false);
  const { basketCount, messageCount, basketAboveMinAmount, favoritesCount } =
    useAppSelector(({ auth, messages: { items }, basket }) => {
      return {
        basketCount: countProductsInBasket(basket.items),
        messageCount: countTotalUnseenMessages(items, auth._id),
        basketAboveMinAmount: basket.isAboveMinimumOrderThreshold,
        favoritesCount: auth.favorites?.length ?? 0
      };
    });
  const previousFavoritesCount = useRef<number>();

  useEffect(() => {
    setAnimateFavoriteIcon(
      previousFavoritesCount.current !== undefined &&
        favoritesCount > previousFavoritesCount.current
    );
    previousFavoritesCount.current = favoritesCount;
    setTimeout(() => {
      if (previousFavoritesCount.current === favoritesCount) {
        setAnimateFavoriteIcon(false);
      }
    }, 500);
  }, [favoritesCount]);

  return [
    {
      icon: 'home',
      label: t('common:Home'),
      to: '/',
      activeOn: [
        '/profile',
        '/billing-account',
        '/delivery-information',
        'switch-role'
      ]
    },
    {
      icon: 'orders',
      label: t('common:Orders'),
      to: messageCount !== 0 ? '/orders/product-requests' : '/orders',
      badge: messageCount !== 0 && <Badge text={messageCount.toString()} />
    },
    {
      icon: animateFavoriteIcon ? 'favoriteFilled' : 'favorite',
      iconAnimation: animateFavoriteIcon ? 'heartbeat' : undefined,
      label: t('consumer:Favorites'),
      to: '/favourites'
    },
    {
      icon: 'producer',
      label: t('common:header.producers'),
      to: '/producers'
    },
    {
      icon: basketCount > 0 ? 'basket' : 'basketEmpty',
      label: t('consumer:Basket'),
      to: '/basket',
      badge: basketCount !== 0 && (
        <Badge text={basketCount.toString()} warning={!basketAboveMinAmount} />
      )
    }
  ] satisfies NavLinkProps[];
};
