import {
  Button,
  ImageInput as CarrotImageInput,
  Icon,
  Image
} from '@dagens/carrot';
import { useTranslation } from '@dagens/frontend-i18n';
import { FieldValues } from 'react-hook-form';
import { ImageFile } from '../types/image-file';
import { MatchingKeys } from '../types/fields';
import { FieldProps, useField } from '../utils/use-field';

export type ImageInputProps<
  Form extends FieldValues,
  Name extends MatchingKeys<Form, ImageFile | null>
> = FieldProps<Form, Name>;

export const ImageInput = <
  Form extends FieldValues,
  Name extends MatchingKeys<Form, ImageFile | null>
>({
  name,
  required,
  validate,
  shouldUnregister,
  disabled
}: ImageInputProps<Form, Name>) => {
  const { t } = useTranslation();
  const { field } = useField({
    name,
    required,
    validate,
    shouldUnregister,
    disabled
  });

  return (
    <>
      {field.value?.previewUrl && (
        <Image
          square
          src={field.value.previewUrl}
          alt={t('common:productImage')}
        />
      )}

      <CarrotImageInput
        trigger={
          field.value?.file || field.value?.previewUrl ? (
            <Button variant="ghost">{t('common:changeImage')}</Button>
          ) : (
            <Button variant="secondary">
              <Icon icon="add" size="small" />
              {t('common:addImage2')}
            </Button>
          )
        }
        onChange={(file, previewUrl) => field.onChange({ file, previewUrl })}
      />
    </>
  );
};
