import { useFormContext } from '@dagens/frontend-forms';
import { useTranslation } from '@dagens/frontend-i18n';
import { PricingFormValues } from '..';
import { ActionButton } from '../../../../components/action-button';
import REQ, { type ReqType } from '../../../../utils/REQ';

type Props = {
  saveReq: ReqType;
  onSubmit: (data: PricingFormValues) => void;
};

export const PricingFormSubmitButton = ({ saveReq, onSubmit }: Props) => {
  const { t } = useTranslation();
  const {
    formState: { isValid, isSubmitted },
    handleSubmit
  } = useFormContext<PricingFormValues>();
  return (
    <ActionButton.Save
      onClick={handleSubmit(onSubmit)}
      feedbackText={{
        [REQ.PENDING]: t('producer:PricingSavingChangesButtonFeedback'),
        [REQ.SUCCESS]: t('producer:PricingChangesSavedButtonFeedback')
      }}
      saveReq={saveReq}
    >
      {!isValid && isSubmitted
        ? t('producer:PricingSaveChangesMissingFieldsFeedback')
        : t('producer:PricingSaveChangesButtonFeedback')}
    </ActionButton.Save>
  );
};
