import plugin from 'tailwindcss/plugin';

export const themeTransitionDelay = {};

export const themeTransitionDuration = {};

export const themeTransitionTimingFunction = {};

export const transitionPlugin = plugin(({ addBase }) => {
  addBase({
    '.transition-regular': {
      transition: '240ms ease-in-out'
    },
    '.transition-quick': {
      transition: '100ms ease-in-out'
    }
  });
});
