import { useTranslation } from '@dagens/frontend-i18n';
import { PlanService } from '../../../../services/PlanService';
import ProducerSalesModules from './ProducerSalesModules';
import Typography from '@carrot-deprecated/typography';
import Container from '@carrot-deprecated/container';
import { PRODUCER_HOME_CLICK_SHOP_LINK, track } from 'utils/mixpanel';

import { useAppSelector } from '_common/hooks/reduxHooks';

const ProducerSalesSection = () => {
  const { t } = useTranslation();
  const { handle, hasAddedWebshopLink, hasReceivedFirstOrder, hasWebshop } =
    useAppSelector(({ auth }) => {
      return {
        handle: auth.handle,
        hasAddedWebshopLink: auth.onboardingHistory?.addedWebshopLink,
        hasReceivedFirstOrder: auth.onboardingHistory?.receivedFirstOrder,
        hasWebshop: PlanService.hasFeature(auth.plan, 'WEBSHOP')
      };
    });
  const activeLink = `${window.location.host}/${handle}`;

  if (!hasAddedWebshopLink && !hasReceivedFirstOrder) return null;

  return (
    <Container mt="xl">
      <Container mb="s">
        <Typography variant="tertiaryHeading">
          {t('producer:increaseYourSalesHeading')}
        </Typography>
        <Typography variant="paragraph" mb="xs">
          {t('producer:increaseYourSalesDescription')}
        </Typography>
        {hasWebshop && (
          <Typography variant="paragraph">
            {t('producer:ViewAndShareYourShop')}{' '}
            <Typography
              variant="routerLink"
              to={`/${handle}`}
              onClick={() => {
                return track(PRODUCER_HOME_CLICK_SHOP_LINK);
              }}
            >
              {activeLink}
            </Typography>
          </Typography>
        )}
      </Container>

      <ProducerSalesModules />
    </Container>
  );
};

export default ProducerSalesSection;
