import { useState } from 'react';

import { DeliveryType } from '../../../types/Logistics';
import EditableOrderLineTableRow from './EditableOrderLineTableRow';
import OrderLineTableRow from './OrderLineTableRow';
import { DeliveryFeeRow } from './DeliveryFeeRow';
import { EditableDeliveryFeeRow } from './EditableDeliveryFeeRow';
import { OrderLine, OrderProducerDeliveryFee } from 'types/Order';
import { UpdateOrderDeliveryFeePayload, UpdateOrderLinePayload } from 'api';

type Props = {
  orderId: string;
  orderLines: OrderLine[];
  originalOrderLines?: OrderLine[];
  orderDeliveryFee?: OrderProducerDeliveryFee;
  orderDeliveryType?: DeliveryType;
  isReadOnly?: boolean;
  isOrderAdjustment?: boolean;
  isProducerOrder?: boolean;
  onUpdateOrder?: () => void;
  onSaveOrderLine?: (payload: UpdateOrderLinePayload) => Promise<void> | void;
  onSaveDeliveryFee?: (
    orderId: string,
    payload: UpdateOrderDeliveryFeePayload
  ) => Promise<void> | void;
  addingDeliveryFee?: boolean;
  onFinishAddingDeliveryFee?: (value: boolean) => void;
};

const OrderLineTableBody = ({
  orderLines,
  originalOrderLines,
  orderId,
  orderDeliveryFee,
  orderDeliveryType,
  isReadOnly = false,
  isOrderAdjustment,
  isProducerOrder,
  onUpdateOrder,
  onSaveOrderLine,
  onSaveDeliveryFee,
  addingDeliveryFee,
  onFinishAddingDeliveryFee
}: Props) => {
  const [editingKey, setEditingKey] = useState<string | undefined>(undefined);

  const toggleEditing = (key: string | undefined) => {
    setEditingKey(editingKey === key ? undefined : key);
  };

  const canSeeFee = orderDeliveryType === DeliveryType.SELF_DELIVERY;

  // The fee should only be displayed if it has a value – either the value at the time of order or the final value
  const displayFee =
    canSeeFee &&
    orderDeliveryFee &&
    (Boolean(orderDeliveryFee.valueAtTimeOfOrder) ||
      Boolean(orderDeliveryFee.finalValue));

  // If the fee is being edited, we should show the editable row
  const editFee =
    canSeeFee &&
    orderDeliveryFee &&
    editingKey === 'deliveryFee' &&
    !isReadOnly;
  const addFee = canSeeFee && addingDeliveryFee;

  return (
    <tbody>
      {orderLines.map(line => {
        const originalLine = originalOrderLines?.find(
          ol => ol._key === line._key
        );
        return line._key === editingKey && !isReadOnly ? (
          <EditableOrderLineTableRow
            key={line._key}
            orderId={orderId}
            toggleEditing={toggleEditing}
            line={line}
            originalLine={originalLine}
            isOrderAdjustment={isOrderAdjustment}
            isProducerOrder={isProducerOrder}
            onUpdateOrder={onUpdateOrder}
            onSaveOrderLine={onSaveOrderLine}
          />
        ) : (
          <OrderLineTableRow
            key={line._key}
            onClick={() => toggleEditing(line._key)}
            line={line}
            originalLine={originalLine}
            isReadOnly={isReadOnly}
          />
        );
      })}
      {displayFee && !editFee && (
        <DeliveryFeeRow
          orderDeliveryFee={orderDeliveryFee}
          onClick={() => toggleEditing('deliveryFee')}
          isReadOnly={isReadOnly}
        />
      )}
      {editFee && (
        <EditableDeliveryFeeRow
          orderId={orderId}
          orderDeliveryFee={orderDeliveryFee}
          isOrderAdjustment={isOrderAdjustment}
          toggleEditing={() => toggleEditing('deliveryFee')}
          onSave={onSaveDeliveryFee}
        />
      )}
      {addFee && (
        <EditableDeliveryFeeRow
          orderId={orderId}
          orderDeliveryFee={{
            ref: undefined,
            finalValue: undefined,
            valueAtTimeOfOrder: 0
          }}
          isOrderAdjustment={isOrderAdjustment}
          toggleEditing={value => {
            onFinishAddingDeliveryFee?.(Boolean(value));
          }}
          onSave={onSaveDeliveryFee}
        />
      )}
    </tbody>
  );
};

export default OrderLineTableBody;
