import { useEffect, useRef } from 'react';

export const useInfiniteScroll = (hasMore: boolean, loadMore: () => void) => {
  const scrollerRef = useRef<HTMLDivElement>(null);
  const mainElement = document.getElementsByTagName('main').item(0);
  useEffect(() => {
    const scrollListener = () => {
      const threshold = 250;
      const scrollerHeight = scrollerRef.current?.clientHeight;
      const mainScrollTop = mainElement?.scrollTop;
      if (scrollerHeight === undefined || mainScrollTop === undefined) {
        return;
      }

      if (hasMore && mainScrollTop + threshold > scrollerHeight) {
        loadMore();
      }
    };
    mainElement?.addEventListener('scroll', scrollListener);
    return () => {
      mainElement?.removeEventListener('scroll', scrollListener);
    };
  });
  return { scrollerRef };
};
