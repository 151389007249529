import { useTranslation } from '@dagens/frontend-i18n';
import { addDays, isBefore } from 'date-fns';

import { useAppSelector } from '../../../_common/hooks/reduxHooks';
import { Page } from '../../../components/page';
import { Order } from '../../../types/Order';
import { OnboardingHistoryKeys } from '../../../types/Producer';
import REQ from '../../../utils/REQ';
import OrdersPageHOC from './OrdersPageHOC';
import ReverseInvoiceOnboardingHandler from './ReverseInvoiceOnboardingHandler';
import {
  countActiveUnopenedOrders,
  getTotalOrdersPrice,
  splitInvoicedAndNotInvoicedOrders
} from '_common/reducers/orders';
import TextLink from '_common/components/text-link/TextLink.style';
import OrderGroup from '_common/components/order-group/OrderGroup';

type Props = {
  notInvoicedOrders: Order[];
  onboardingStep: {
    messageKey: string;
    isOnboarding: boolean;
  };
};

const OrdersInvoicePage = ({
  notInvoicedOrders = [],
  onboardingStep = {
    messageKey: OnboardingHistoryKeys.receivedFirstPayment,
    isOnboarding: false
  }
}: Props) => {
  const { t } = useTranslation();
  const toSettleAmount = getTotalOrdersPrice(notInvoicedOrders);
  const numOrdersToSettle = notInvoicedOrders.length;
  return (
    <>
      <ReverseInvoiceOnboardingHandler
        numOrdersToSettle={numOrdersToSettle}
        onboardingStep={onboardingStep}
        toSettleAmount={toSettleAmount}
      />
      {numOrdersToSettle > 0 && (
        <OrderGroup
          header={t('producer:OrdersNotSettled')}
          orders={notInvoicedOrders}
        />
      )}
      <TextLink to="/orders/history">{t('common:ViewOrderHistory')}</TextLink>
    </>
  );
};

const OrdersInvoicePageFetcher = () => {
  const { notInvoicedOrders, numUnopenedOrders, onboardingStep, req } =
    useAppSelector(
      ({ auth: { onboardingHistory }, orders: { items, req } }) => {
        const { notInvoicedOrders } = splitInvoicedAndNotInvoicedOrders(items);
        const numUnopenedOrders = countActiveUnopenedOrders(items);
        const onboardingStatus =
          onboardingHistory?.[OnboardingHistoryKeys.receivedFirstPayment];
        return {
          req,
          numUnopenedOrders,
          notInvoicedOrders,
          onboardingStep: {
            messageKey: 'producer:orders.sendToInvoice',
            isOnboarding:
              !onboardingStatus ||
              isBefore(new Date(), addDays(new Date(onboardingStatus), 7))
          }
        };
      }
    );

  if (req !== REQ.SUCCESS) {
    return <Page.Status req={req} />;
  }

  return (
    <OrdersPageHOC numUnopenedOrders={numUnopenedOrders}>
      <OrdersInvoicePage
        notInvoicedOrders={notInvoicedOrders}
        onboardingStep={onboardingStep}
      />
    </OrdersPageHOC>
  );
};

export default OrdersInvoicePageFetcher;
