/* eslint no-param-reassign: ["error", { "props": true, "ignorePropertyModificationsFor": ["state"] }] */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { useAppSelector } from '../hooks/reduxHooks';
import { PlanService } from '../../services/PlanService';
import { hasUnseenMessagesInThread } from './messages';
import { getProductRequests } from 'api';
import { type AppDispatch, type RootState } from 'store';
import REQ, { combineReqs, ReqType } from 'utils/REQ';

/** Actions */

type ProductRequestsState = {
  req: ReqType;
  items: ProductRequestItem[];
  subscription?: number;
};

type Contact = {
  _id: string;
  contactPerson: string;
  emails: string[];
  name: string;
  phone: string;
};

export type ProductRequestItem = {
  _id: string;
  _createdAt: string;
  consumer: Contact;
  messageThreadId: string;
  opened: string;
  producer: Contact;
  product: {
    name: string;
    type: string;
    _id: string;
  };
};

const initialState: ProductRequestsState = {
  req: REQ.INIT,
  items: [],
  subscription: undefined
};

const productRequestsSlice = createSlice({
  name: 'productRequests',
  initialState,
  reducers: {
    fetchProductRequestsRequest(state) {
      state.req = REQ.PENDING;
      state.items = [];
    },
    fetchProductRequestsError(state) {
      state.req = REQ.ERROR;
      state.items = [];
    },
    fetchProductRequestsSuccess(
      state,
      action: PayloadAction<ProductRequestItem[]>
    ) {
      state.req = REQ.SUCCESS;
      state.items = action.payload;
    },
    openProductRequest(
      state,
      action: PayloadAction<ProductRequestItem['_id'] | undefined>
    ) {
      state.items = state.items.map(productRequest => {
        return productRequest._id === action.payload
          ? { ...productRequest, opened: new Date().toISOString() }
          : productRequest;
      });
    },
    registerProductRequestsSubscription(state, action: PayloadAction<number>) {
      clearInterval(state.subscription);
      state.subscription = action.payload;
    },
    clearProductRequests(state) {
      clearInterval(state.subscription);
      state.req = REQ.INIT;
      state.items = [];
    }
  }
});

export default productRequestsSlice.reducer;

/** Action creators */

export const {
  fetchProductRequestsRequest,
  fetchProductRequestsError,
  fetchProductRequestsSuccess,
  openProductRequest,
  registerProductRequestsSubscription,
  clearProductRequests
} = productRequestsSlice.actions;

const fetchProductRequests = (
  roleId: RootState['auth']['_id'],
  { clearStore = true }: { clearStore?: boolean } = {}
) => {
  return (dispatch: AppDispatch) => {
    if (clearStore) {
      dispatch(fetchProductRequestsRequest());
    }

    getProductRequests({ roleId })
      .then(productRequests => {
        dispatch(fetchProductRequestsSuccess(productRequests));
      })
      .catch(() => {
        dispatch(fetchProductRequestsError());
      });
  };
};

const startInterval = (
  roleId: RootState['auth']['_id'],
  intervalTime = 30000
) => {
  return (dispatch: AppDispatch) => {
    const subscription = window.setInterval(() => {
      dispatch(fetchProductRequests(roleId, { clearStore: false }));
    }, intervalTime);

    dispatch(registerProductRequestsSubscription(subscription));
  };
};

export const setUpProductRequests = (roleId: RootState['auth']['_id']) => {
  return (dispatch: AppDispatch) => {
    dispatch(fetchProductRequests(roleId));
    dispatch(startInterval(roleId));
  };
};

/** Selectors */

export const useProductRequests = () => {
  return useAppSelector(
    ({
      auth: { _id: roleId, _type: roleType, plan } = {} as RootState['auth'],
      productRequests: { items: productRequestItems, req: productRequestReq },
      messages: { items: messageItems, req: messageReq }
    }) => {
      return {
        hasWebshop: PlanService.hasFeature(plan, 'WEBSHOP'),
        req: combineReqs([productRequestReq, messageReq]),
        productRequests: productRequestItems
          ? productRequestItems.map(
              ({
                _id,
                _createdAt,
                messageThreadId,
                opened,
                producer,
                consumer
              }) => {
                return {
                  _id,
                  _createdAt,
                  hasNewMessage: hasUnseenMessagesInThread(
                    messageItems,
                    messageThreadId,
                    roleId
                  ),
                  isOpened: Boolean(roleType === 'consumers' || opened),
                  counterPartName:
                    roleType === 'consumers' ? producer.name : consumer.name,
                  pathname: `${
                    roleType === 'consumers' ? '/orders' : ''
                  }/product-requests/${_id}`
                };
              }
            )
          : []
      };
    }
  );
};
