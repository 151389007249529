import { css } from 'styled-components';
import Spacings, { SpacingNames } from '../theme/spacings';

export type FlexProps = {
  justify?:
    | 'flex-start'
    | 'flex-end'
    | 'center'
    | 'space-between'
    | 'space-around'
    | 'space-evenly'
    | 'start'
    | 'end'
    | 'left';
  direction?: 'row' | 'row-reverse' | 'column' | 'column-reverse';
  wrap?: 'nowrap' | 'wrap' | 'wrap-reverse';
  alignItems?:
    | 'stretch'
    | 'flex-start'
    | 'flex-end'
    | 'center'
    | 'baseline'
    | 'first baseline'
    | 'last baseline'
    | 'start'
    | 'end'
    | 'self-start'
    | 'self-end';
  minHeight?: string;
  gap?: SpacingNames;
};

const Flex = css<FlexProps>`
  display: flex;
  ${({ justify }) => {
    return justify && `justify-content: ${justify};`;
  }};
  ${({ direction }) => {
    return direction && `flex-direction: ${direction};`;
  }};
  ${({ wrap = 'wrap' }) => {
    return wrap && `flex-wrap: ${wrap};`;
  }};
  ${({ alignItems }) => {
    return alignItems && `align-items: ${alignItems};`;
  }};
  ${({ gap }) => {
    return gap && `gap: ${Spacings[gap as SpacingNames]}px;`;
  }};
  ${({ minHeight }) => {
    return minHeight && `min-height: ${minHeight};`;
  }};
`;

export default Flex;
