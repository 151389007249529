import { useTranslation } from '@dagens/frontend-i18n';
import Typography from '@carrot-deprecated/typography';
import Container from '@carrot-deprecated/container';
import { ButtonAnchor } from '_common/components/button/Button.style';

type OrderListHeadingProps = {
  settlementNumber: string;
  attachmentUrl?: string;
};

const OrderListHeading = ({
  settlementNumber,
  attachmentUrl
}: OrderListHeadingProps) => {
  const { t } = useTranslation();
  return (
    <Container flex justify="space-between" alignItems="center">
      <Typography variant="tertiaryHeading" as="h2" mb="none">
        {`${t('producer:Settlement')} ${settlementNumber}`}
      </Typography>
      {!!attachmentUrl && (
        <ButtonAnchor
          target="_blank"
          rel="noopener noreferrer"
          href={attachmentUrl}
          variant="borderless"
        >
          {t('producer:DownloadSettlement')}
        </ButtonAnchor>
      )}
    </Container>
  );
};

export default OrderListHeading;
