import { InfoSectionContainer } from './parts/container';
import { InfoSectionItemDetails } from './parts/item-details';
import { InfoSectionItemTerm } from './parts/item-term';
import { InfoSectionItems } from './parts/items';
import { InfoSectionTitle } from './parts/title';

export const InfoSection = Object.assign(InfoSectionContainer, {
  Title: InfoSectionTitle,
  Items: InfoSectionItems,
  Term: InfoSectionItemTerm,
  Details: InfoSectionItemDetails
});
