import { Box } from '@dagens/carrot';
import DeliveryDateBanner from '_consumer/pages/landing-page/parts/DeliveryDateBanner';
import SearchPage from '_consumer/pages/landing-page/parts/SearchPage';
import { useAppSelector } from '_common/hooks/reduxHooks';
import { Page } from '@components/page';

const CustomerLandingPage = () => {
  const { selectedDeliveryDate } = useAppSelector(
    ({ productsForSale: { selectedDeliveryDate: deliveryDate } }) => {
      return {
        selectedDeliveryDate: deliveryDate
      };
    }
  );

  return (
    <Page
      header={
        <Box.FullWidth>
          {selectedDeliveryDate && <DeliveryDateBanner />}
        </Box.FullWidth>
      }
    >
      <SearchPage />
    </Page>
  );
};
export default CustomerLandingPage;
