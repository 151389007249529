import {
  Radio as CarrotRadio,
  type RadioProps as CarrotRadioProps
} from '@dagens/carrot';
import { Path, PathValue, FieldValues } from 'react-hook-form';
import { FieldProps, useField } from '../utils/use-field';

// Fields from the Carrot component that are handled by react-hook-form
type ExcludedFields = 'value' | 'onChange';

export type RadioProps<
  Form extends FieldValues,
  Name extends Path<Form>,
  Value extends PathValue<Form, Name>
> = Omit<CarrotRadioProps<Value>, ExcludedFields> & FieldProps<Form, Name>;

export const Radio = <
  Form extends FieldValues,
  Name extends Path<Form>,
  Value extends PathValue<Form, Name>
>({
  name,
  required,
  validate,
  shouldUnregister,
  disabled,
  ...props
}: RadioProps<Form, Name, Value>) => {
  const { field } = useField({
    name,
    required,
    validate,
    shouldUnregister,
    disabled
  });

  return (
    <CarrotRadio
      name={name}
      value={field.value}
      onChange={v => field.onChange(v)}
      {...props}
    />
  );
};
