const measure = {
  kg: 'kg',
  liter: 'liter'
} as const;

const scaledMeasure = {
  g: 'g',
  ml: 'ml'
} as const;

const combined = {
  piece: 'stk'
} as const;

const packed = {
  package: 'pakke',
  crate: 'kasse',
  tray: 'brett'
} as const;

export type PriceUnit =
  | (typeof measure)[keyof typeof measure]
  | (typeof combined)[keyof typeof combined]
  | (typeof packed)[keyof typeof packed];

export type MeasureUnit =
  | (typeof measure)[keyof typeof measure]
  | (typeof scaledMeasure)[keyof typeof scaledMeasure];

export type OrderUnit =
  | (typeof packed)[keyof typeof packed]
  | (typeof combined)[keyof typeof combined];

export const isMeasureUnit = (unit?: string) => {
  if (!unit) {
    return false;
  }
  const measureUnits = [...Object.values(measure)] as string[];
  return measureUnits.includes(unit);
};

export const isPackedUnit = (unit?: string) => {
  if (!unit) {
    return false;
  }
  const packedUnits = [...Object.values(packed)] as string[];
  return packedUnits.includes(unit);
};

export const UnitUtils = {
  measure,
  scaledMeasure,
  combined,
  packed,

  isMeasureUnit,
  isPackedUnit
} as const;
