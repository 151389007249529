import { Translation } from '@dagens/frontend-i18n';
import { find } from 'lodash';
import { useParams } from 'react-router-dom';
import { PlanService } from '../../../services/PlanService';
import Typography from '@carrot-deprecated/typography';
import AddButtonLink from '_common/components/button/AddButtonLink';
import { ButtonContainer } from '_common/components/button/Button.style';
import MessageThread from '_common/components/message-thread/MessageThread';
import OrderContactDetails from '_common/components/order-contact-details/OrderContactDetails';
import RequestedProductLine from '_common/components/requested-product-line/RequestedProductLine';
import { useAppDispatch, useAppSelector } from '_common/hooks/reduxHooks';
import { getMessagesInThread } from '_common/reducers/messages';
import { openProductRequest } from '_common/reducers/productRequests';
import { Page } from '@components/page';
import { postOpenProductRequest } from 'api';
import REQ, { combineReqs } from 'utils/REQ';

const ProductRequestPage = () => {
  const { id } = useParams<{ id: string }>();
  const dispatch = useAppDispatch();

  const { userId, productRequest, messageThreadId, messages } = useAppSelector(
    ({
      auth,
      productRequests: { items: productRequestItems },
      messages: { items: messageItems }
    }) => {
      const request = find(productRequestItems, { _id: id });
      return {
        userId: auth._id,
        productRequest: find(productRequestItems, { _id: id }),
        messageThreadId: request?.messageThreadId,
        messages: getMessagesInThread(messageItems, request?.messageThreadId)
      };
    }
  );

  if (!productRequest?.opened) {
    postOpenProductRequest({ productRequestId: productRequest?._id });
    dispatch(openProductRequest(productRequest?._id));
  }

  return (
    <Translation>
      {t => {
        return (
          <Page>
            <Typography variant="primaryHeading" mt="m">
              {t('common:ProductRequest_one')}
            </Typography>
            <OrderContactDetails
              name={productRequest?.consumer.name}
              contactPerson={productRequest?.consumer.contactPerson}
              phone={productRequest?.consumer.phone}
            />
            <RequestedProductLine product={productRequest?.product} />
            <ButtonContainer>
              <AddButtonLink
                to="/orders/add/delivery-date"
                state={{
                  returnPath: true,
                  consumer: productRequest?.consumer
                }}
                buttonText={t('common:MakeOrder')}
              />
            </ButtonContainer>
            <MessageThread
              threadId={messageThreadId}
              thread={messages}
              counterPartId={productRequest?.consumer._id}
              userId={userId}
            />
          </Page>
        );
      }}
    </Translation>
  );
};

const ProductRequestPageFetcher = () => {
  const { hasWebshop, req } = useAppSelector(
    ({
      auth: { plan },
      productRequests: { req: productRequestReq },
      messages: { req: messagesReq }
    }) => {
      return {
        hasWebshop: PlanService.hasFeature(plan, 'WEBSHOP'),
        req: combineReqs([productRequestReq, messagesReq])
      };
    }
  );

  if (!hasWebshop) {
    return <Page.NotFound />;
  }

  if (req !== REQ.SUCCESS) {
    return <Page.Status req={req} />;
  }

  return <ProductRequestPage />;
};

export default ProductRequestPageFetcher;
