import { Button, Icon, Link, Table, Tag } from '@dagens/carrot';
import { useTranslation } from '@dagens/frontend-i18n';
import { tv } from 'tailwind-variants';
import { useAppSelector } from '../../../_common/hooks/reduxHooks';
import { formatDate } from '../../../utils/date/format';
import { PlanService } from '../../../services/PlanService';
import { CustomerActionsModal } from '_producer/pages/customer-list-page/customer-actions-modal';
import { Consumer } from 'types/Consumer';

const style = tv({
  slots: {
    table: 'grid-cols-[1.5fr,1fr,1fr,140px,max-content]',
    callNameTag: `
      flex
      flex-row
      gap-xs
      pr-xl
    `,
    cellName: `
      truncate
      text-paragraphSmallBold
      text-black
    `,
    cellContactPerson: `
      text-paragraphSmall
      text-black
    `,
    cellEmail: `truncate`,
    cellButton: `mt-[-12px]`
  }
});

type Props = {
  consumers: Consumer[];
  activeConsumer?: Consumer | null;
  onConsumerClick?: (consumer: Consumer) => void;
  onModalClose?: () => void;
  linkTo?: string;
};

export const CustomerTable = ({
  consumers,
  activeConsumer,
  onConsumerClick,
  onModalClose,
  linkTo
}: Props) => {
  const {
    cellName,
    callNameTag,
    cellContactPerson,
    cellEmail,
    cellButton,
    table
  } = style();
  const { t } = useTranslation();

  const { producerId, hasMarketAccess } = useAppSelector(({ auth }) => {
    return {
      producerId: auth._id,
      hasMarketAccess: PlanService.hasFeature(auth.plan, 'MARKET_ACCESS')
    };
  });

  return (
    <>
      <Table className={table()}>
        <Table.Head>
          <Table.Row noHover header>
            <Table.HeaderCell>{t('common:Consumer')}</Table.HeaderCell>
            <Table.HeaderCell>{t('common:Contact')}</Table.HeaderCell>
            <Table.HeaderCell>{t('common:Address')}</Table.HeaderCell>
            <Table.HeaderCell>{t('producer:LastActivity')}</Table.HeaderCell>
            <Table.HeaderCell hidden>{t('common:Actions')}</Table.HeaderCell>
          </Table.Row>
        </Table.Head>
        <Table.Body>
          {consumers.map(consumer => {
            const {
              _id,
              name,
              contactPerson,
              emails,
              phone,
              structuredDeliveryAddress: { addressLine1, postalCode, city },
              lastOrder
            } = consumer;

            return (
              <Table.Row key={_id} relative={Boolean(linkTo)}>
                <Table.Cell>
                  <div className={callNameTag()}>
                    <div className={cellName()}>{name}</div>
                    {consumer.createdByAccount === producerId &&
                      hasMarketAccess && (
                        <Tag
                          text={t('producer:CustomerListManagedTag')}
                          color={'lightGrey'}
                        />
                      )}
                  </div>
                  <div className={cellContactPerson()}>
                    <div>{contactPerson}</div>
                  </div>
                </Table.Cell>
                <Table.Cell>
                  <div>{phone}</div>
                  {emails.map(email => (
                    <div key={email} className={cellEmail()}>
                      {email}
                    </div>
                  ))}
                </Table.Cell>
                <Table.Cell>
                  <div>{`${addressLine1}, ${postalCode} ${city}`}</div>
                </Table.Cell>
                <Table.Cell>
                  {lastOrder && (
                    <div>{formatDate(lastOrder, 'd. MMM, yyyy')}</div>
                  )}
                </Table.Cell>
                <Table.Cell verticalAlign="top">
                  <div className={cellButton()}>
                    {linkTo ? (
                      <Link.NavIcon to={linkTo} state={{ consumer }} fillParent>
                        <Icon icon="forward" size="medium" />
                      </Link.NavIcon>
                    ) : (
                      <Button.NavIcon
                        onClick={() => onConsumerClick?.(consumer)}
                      >
                        <Icon icon="meatballs" size="small" />
                      </Button.NavIcon>
                    )}
                  </div>
                </Table.Cell>
              </Table.Row>
            );
          })}
        </Table.Body>
      </Table>
      {activeConsumer && onModalClose && (
        <CustomerActionsModal
          activeConsumer={activeConsumer}
          onClose={onModalClose}
        />
      )}
    </>
  );
};
