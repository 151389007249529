import { Translation as I18nTranslation, TransProps } from 'react-i18next';
import { OverrideProperties } from 'type-fest';

import { TFunction, TranslateKey } from './types';

type Props = OverrideProperties<
  TransProps<TranslateKey>,
  { children: (t: TFunction) => JSX.Element }
>;

export const Translation = ({ children, ...props }: Props) => {
  return <I18nTranslation {...props}>{children}</I18nTranslation>;
};
