/* eslint no-param-reassign: ["error", { "props": true, "ignorePropertyModificationsFor": ["state"] }] */
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getStatistics } from 'api';
import REQ, { ReqType } from 'utils/REQ';

export const fetchStatistics = createAsyncThunk(
  'consumers/fetchStatistics',
  async (options: { consumerId: string }) => {
    const response = await getStatistics(options);
    return response;
  }
);

type ProducerStatistics = {
  producerId: string;
  numberOfOrders: number;
};

type StatisticsState = {
  req: ReqType;
  items: ProducerStatistics[];
  productsOrderedBefore: string[];
};

const initialState: StatisticsState = {
  req: REQ.INIT,
  items: [],
  productsOrderedBefore: []
};

const statisticsSlice = createSlice({
  name: 'statistics',
  initialState,
  reducers: {
    clearStatistics() {
      return initialState;
    }
  },
  extraReducers(builder) {
    builder
      .addCase(
        fetchStatistics.fulfilled,
        (
          state,
          action: PayloadAction<{
            statisticsPerProducer: ProducerStatistics[];
            productsOrderedBefore: string[];
          }>
        ) => {
          state.req = REQ.SUCCESS;
          state.items = action.payload.statisticsPerProducer;
          state.productsOrderedBefore = action.payload.productsOrderedBefore;
        }
      )
      .addCase(fetchStatistics.pending, state => {
        state.req = REQ.PENDING;
        state.items = [];
        state.productsOrderedBefore = [];
      })
      .addCase(fetchStatistics.rejected, state => {
        state.req = REQ.ERROR;
        state.items = [];
        state.productsOrderedBefore = [];
      });
  }
});

export default statisticsSlice.reducer;

export const { clearStatistics } = statisticsSlice.actions;
