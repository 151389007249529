import { formFieldFactory } from '@dagens/frontend-forms';
import { useTranslation } from '@dagens/frontend-i18n';
import { getAddress } from '../../../api';
import { StructuredAddress } from '../../../types/Logistics';
import { DeliveryAddressFeedback } from './delivery-address-feedback';
import { DeliveryFormValues } from '.';

const searchAddress = async (query: string | null) => {
  if (!query || query === '') {
    return null;
  }

  try {
    const result = await getAddress({ address: query });
    return [result];
  } catch (error) {
    return null;
  }
};

const addressToString = (address?: StructuredAddress | string | null) => {
  if (!address) {
    return '';
  }
  if (typeof address === 'string') {
    return address;
  }
  return `${address.addressLine1}, ${address.postalCode} ${address.city}`;
};

const { Field, Search } = formFieldFactory<DeliveryFormValues>();

type Props = {
  defaultDeliveryAddress?: StructuredAddress | string | null;
};

export const CustomerDeliveryAddress = ({ defaultDeliveryAddress }: Props) => {
  const { t } = useTranslation();
  return (
    <Field
      to="deliveryAddress"
      label={t('common:deliveryInformationFormFieldDeliveryAddressLabel')}
      helpText={t(
        'common:deliveryInformationFormFieldDeliveryAddressDescription'
      )}
      feedback={
        <DeliveryAddressFeedback
          defaultDeliveryAddress={defaultDeliveryAddress}
        />
      }
      required
    >
      <Search
        name="deliveryAddress"
        placeholder={t(
          'common:deliveryInformationFormFieldDeliveryAddressPlaceholder'
        )}
        searchFn={searchAddress}
        displayValue={addressToString}
        debounce={500}
        required
        validate={v => typeof v !== 'string'}
        emptyOptionsText={t(
          'common:deliveryInformationFormFieldDeliveryAddressNotFound'
        )}
        // eslint-disable-next-line jsx-a11y/no-autofocus
        autoFocus
      />
    </Field>
  );
};
