import { useTranslation } from '@dagens/frontend-i18n';
import { Box, PageHeader } from '@dagens/carrot';
import { ProducerWithNumProductsForSale } from '../../../types/Producer';
import { useAppSelector } from '../../../_common/hooks/reduxHooks';
import SearchInput from '@components/search-input/SearchInput';
import usePageView from '_common/hooks/usePageView';
import useSimpleSearch from '_common/hooks/useSimpleSearch';
import { GridContainer } from '_consumer/pages/landing-page/parts/Grid';
import ProducerCard from '_consumer/components/producer/ProducerCard';
import { countProducerProducts } from '_consumer/reducers/producers';
import { Page } from '@components/page';
import getProducerSearchFunction from 'algolia/producerIndex';
import { filterSearchHits } from 'utils/array';
import { PRODUCER_OVERVIEW_PAGE_VIEW } from 'utils/mixpanel';
import REQ, { combineReqs } from 'utils/REQ';

type Props = {
  producers: ProducerWithNumProductsForSale[];
  roleId: string | undefined;
  distributionAreaId: string | undefined;
  isMeyersAccount: boolean | undefined;
};

const ProducerListPage = ({
  roleId,
  distributionAreaId,
  isMeyersAccount,
  producers = []
}: Props) => {
  usePageView(PRODUCER_OVERVIEW_PAGE_VIEW);

  const searchFunction = getProducerSearchFunction({
    roleId,
    onlyIds: true,
    distributionAreaId,
    isMeyersAccount
  });

  const [searchString, onChange, onClear, hits] =
    useSimpleSearch(searchFunction);

  const filteredProducers = filterSearchHits(hits, producers);
  const { t } = useTranslation();
  return (
    <Page
      header={
        <Box.FullWidth>
          <PageHeader
            title={t('common:header.producers')}
            subtitle={t('producer:independentProducers', {
              count: producers.filter(p => {
                return p.numProductsForSale > 0;
              }).length
            })}
          />
          <SearchInput
            hasActiveSearch={!!searchString}
            searchStringInput={searchString}
            onSearchChange={onChange}
            searchPlaceholder={t('consumer:SearchForProducers')}
            onResetSearchString={onClear}
          />
        </Box.FullWidth>
      }
    >
      <GridContainer>
        {filteredProducers.map(producer => {
          return <ProducerCard key={producer._id} producer={producer} />;
        })}
      </GridContainer>
    </Page>
  );
};

const ProductListPageFetcher = () => {
  const { distributionAreaId, isMeyersAccount, producers, req, roleId } =
    useAppSelector(
      ({
        auth: { _id: roleId, distributionAreaId, isMeyersAccount },
        producers: { items: producerItems, req: producerReq },
        productsForSale: { items: productItems, req: productReq }
      }) => {
        return {
          req: combineReqs([producerReq, productReq]),
          roleId,
          distributionAreaId,
          isMeyersAccount,
          producers: countProducerProducts(producerItems, productItems)
        };
      }
    );

  if (req !== REQ.SUCCESS) {
    return <Page.Status req={req} />;
  }

  return (
    <ProducerListPage
      roleId={roleId}
      distributionAreaId={distributionAreaId}
      isMeyersAccount={isMeyersAccount}
      producers={producers}
    />
  );
};

export default ProductListPageFetcher;
