import { Form, useWatch } from '@dagens/frontend-forms';
import { useTranslation } from '@dagens/frontend-i18n';
import { BillingFormValues } from '.';

type BillingFeedbackProps = {
  defaultPrimaryEmail?: string | null;
};

export const PrimaryEmailFeedback = ({
  defaultPrimaryEmail
}: BillingFeedbackProps) => {
  const { t } = useTranslation();
  const primaryEmail = useWatch<BillingFormValues>().primaryEmail;

  if (!defaultPrimaryEmail || primaryEmail !== defaultPrimaryEmail) {
    return null;
  }

  return (
    <Form.FeedbackAlert>
      {t('producer:AddCustomer:retrievedFromCvrRegistry')}
    </Form.FeedbackAlert>
  );
};
