import { Layout, Box } from '@dagens/carrot';

import { ReactNode, useContext, useEffect } from 'react';
import ModalContainer from '../../../_common/components/modal/ModalContainer';
import REQ, { ReqType } from '../../../utils/REQ';
import { BottomNav } from '../parts/bottom-nav';
import { HeaderNav } from '../parts/header-nav';
import { RouteContext } from '../../../router/components/context';
import { ContentLoader } from '@components/content-loader';

export type PageBaseProps = {
  backButton?: ReactNode;
  backButtonOnlyOnMobile?: boolean;
  hideNav?: boolean;
  navMobileText?: ReactNode;
  navMobileAction?: ReactNode;

  header?: ReactNode;
  bottom?: ReactNode;
  modal?: ReactNode;
  children?: ReactNode;
  req?: ReqType | ReqType[];
  noContentPadding?: boolean;
};

export const PageBase = ({
  backButton,
  backButtonOnlyOnMobile,
  hideNav,
  navMobileText,
  navMobileAction,

  header,
  bottom,
  modal,
  children,
  req,
  noContentPadding
}: PageBaseProps) => {
  const { isHomePage, returnTo, pushHistory } = useContext(RouteContext);

  useEffect(() => {
    if (req !== REQ.INIT && req !== REQ.PENDING) {
      pushHistory?.();
    }
  }, []);

  return (
    <Layout.Page
      header={
        <>
          {!hideNav && (
            <HeaderNav
              backButton={backButton}
              backButtonOnlyOnMobile={backButtonOnlyOnMobile}
              alwaysShowMenu={isHomePage}
              returnTo={returnTo}
              withLogo={!returnTo}
              mobileText={navMobileText}
              mobileAction={navMobileAction}
            />
          )}
          {header}
        </>
      }
      content={
        <Box.FullWidth>
          {req !== undefined && req !== 'SUCCESS' ? (
            <ContentLoader req={req} />
          ) : (
            children
          )}
        </Box.FullWidth>
      }
      bottom={bottom !== undefined ? bottom : <BottomNav />}
      modal={modal !== undefined ? modal : <ModalContainer />}
      noContentPadding={noContentPadding}
    />
  );
};
