import { useMediaQuery } from '@dagens/carrot';
import { ConsumerOnboarding, CountryCode } from '@dagensmat/core';
import { get } from 'lodash';
import queryString from 'query-string';
import { PropsWithChildren, useEffect } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import {
  useAppDispatch,
  useAppSelector
} from '../../../_common/hooks/reduxHooks';
import PageOne from './PageOne';
import PageTwo from './PageTwo';
import Container from '@carrot-deprecated/container';
import Typography from '@carrot-deprecated/typography';
import { hasConsumerOnboarded } from 'utils/role';
import { postConsumerOnboardingUpdate } from 'api/consumer';
import { Page } from '@components/page';
import { consumerOnboardingUpdated } from '_common/reducers/auth';
import useHideHeader from '_common/hooks/useHideHeader';

const ContentWrapper = ({ children }: PropsWithChildren) => {
  const { tabletUp } = useMediaQuery();
  return <Container my={tabletUp ? 'xl' : 's'}>{children}</Container>;
};

const OnboardingPage = () => {
  const { contactPerson, country, hasOnboarded, name, userId } = useAppSelector(
    ({
      auth: { _id: userId, contactPerson, name, onboarding = {}, organization }
    }) => {
      return {
        userId,
        contactPerson,
        name,
        hasOnboarded: hasConsumerOnboarded(onboarding),
        country: get(organization, 'address.country', CountryCode.NO)
      };
    }
  );
  const dispatch = useAppDispatch();
  const { step } = useParams();
  const { set: setHideHeader, unset: unsetHideHeader } = useHideHeader();
  const navigate = useNavigate();
  const { search } = useLocation();
  const { returnTo = '' } = queryString.parse(search);

  useEffect(() => {
    setHideHeader();
    return () => {
      unsetHideHeader();
    };
  }, []);

  let currentStep = step !== undefined ? parseInt(step, 10) || 1 : 1;

  const steps = [PageOne, PageTwo];

  if (currentStep > steps.length || currentStep < 0) {
    navigate(`/${returnTo}`);
    return null;
  }

  const PageToShow = steps[currentStep - 1];

  const finishConsumerOnboarding = () => {
    if (!hasOnboarded) {
      postConsumerOnboardingUpdate({
        userId,
        onboardingStep: ConsumerOnboarding.HAS_SEEN_INTRO_SCREEN
      });
      dispatch(
        consumerOnboardingUpdated(ConsumerOnboarding.HAS_SEEN_INTRO_SCREEN)
      );
    }
    navigate(`/${returnTo}`);
  };

  return (
    <Page hideNav header={null} bottom={null}>
      <ContentWrapper>
        <Typography variant="allCapsTitle">
          {currentStep}/{steps.length}
        </Typography>
        <PageToShow
          contactPerson={contactPerson}
          name={name}
          next={() => {
            currentStep += 1;

            if (currentStep > steps.length) {
              finishConsumerOnboarding();
            } else {
              navigate(`/onboarding/${currentStep}${search}`);
            }
          }}
          skip={() => {
            finishConsumerOnboarding();
          }}
          country={country}
        />
      </ContentWrapper>
    </Page>
  );
};

export default OnboardingPage;
