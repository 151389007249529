import Container from '../../../carrot-deprecated/container';
import Typography from '@carrot-deprecated/typography';
import {
  getDeliveredUnitsWithUnitText,
  hasOrderLineBeenUpdated
} from '_common/reducers/orders';
import { OrderLine } from 'types/Order';
import { formatTextWithUnits } from 'utils/texts';

type Props = {
  line: OrderLine;
};

export const ChangedQuantityCell = ({ line }: Props) => {
  const {
    nrOfUnits,
    pricingAtTimeOfOrder: { orderedUnit }
  } = line;
  const orderedUnitsWithUnit = formatTextWithUnits(orderedUnit, nrOfUnits);

  return (
    <Container flex direction="column">
      <Typography variant="paragraphSmall">
        {getDeliveredUnitsWithUnitText(line)}
      </Typography>
      {hasOrderLineBeenUpdated(line) && (
        <Typography
          variant="paragraphSmall"
          color="secondary"
          style={{ textDecoration: 'line-through' }}
        >
          {orderedUnitsWithUnit}
        </Typography>
      )}
    </Container>
  );
};
