import { formFieldFactory } from '@dagens/frontend-forms';
import { useTranslation } from '@dagens/frontend-i18n';
import { ProductFormValues } from '..';

const { Field, Radio } = formFieldFactory<ProductFormValues>();

export const ProductFormTemperatureZone = () => {
  const { t } = useTranslation();
  return (
    <Field
      to="temperatureZone"
      label={t('producer:productTemperatureZoneSelectorHeader')}
      required
    >
      <Radio
        name="temperatureZone"
        options={['REFRIGERATED', 'DRY', 'FROZEN']}
        displayValue={value =>
          value &&
          {
            REFRIGERATED: {
              label: t('producer:productTemperatureZoneSelectorOption2')
            },
            DRY: {
              label: t('producer:productTemperatureZoneSelectorOption3')
            },
            FROZEN: {
              label: t('producer:productTemperatureZoneSelectorOption1')
            }
          }[value]
        }
      />
    </Field>
  );
};
