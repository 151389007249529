import { ByComparator } from '@dagens/utils';
import { Radio as HeadlessRadio, RadioGroup } from '@headlessui/react';
import { InputHTMLAttributes } from 'react';
import { tv } from '../../utils/tv';
import { nbsp } from '../../utils/nbsp';

const style = tv({
  slots: {
    container: `
      flex
      flex-col
      gap-xxs
      text-paragraph
    `,
    radio: `
      cursor-pointer
      p-[2px]

      focus:outline-none
    `,
    label: `
      flex
      gap-xs
      rounded
      p-[2px]
    `,
    check: `
      mt-[2px]
      flex
      size-[20px]
      flex-shrink-0
      items-center
      justify-center
      rounded-full
      border
      border-secondary
    `,
    checked: `
      size-xs
      rounded-full
      bg-black
    `,
    description: `
      text-paragraphSmall
      text-secondary
    `
  },
  variants: {
    checked: {
      true: {
        check: `border-black`
      }
    },
    focused: {
      true: {
        label: `focus-style`
      }
    }
  }
});

type InputProps = InputHTMLAttributes<HTMLInputElement>;

export type RadioProps<T> = {
  name?: InputProps['name'];
  options: T[];
  displayValue?: (
    value?: T
  ) => { label: string; description?: string } | string | undefined;
  by?: ByComparator<T>;

  value: T;
  onChange: (value: T) => void;
};

export const Radio = <T,>({
  value,
  options,
  onChange,
  displayValue,
  by
}: RadioProps<T>) => {
  const {
    container,
    radio,
    label: labelStyle,
    check,
    checked: checkedStyle,
    description: descriptionStyle
  } = style();
  return (
    <RadioGroup
      value={value}
      onChange={onChange}
      className={container()}
      by={by}
    >
      {options.map(option => {
        const text = displayValue
          ? displayValue(option)
          : { label: undefined, description: undefined };
        const labelText =
          typeof text === 'string'
            ? text
            : (text?.label ?? option?.toString() ?? nbsp);
        return (
          <HeadlessRadio
            key={labelText}
            className={radio()}
            value={option}
            onChange={() => onChange?.(option)}
          >
            {({ checked, focus }) => (
              <div className={labelStyle({ focused: focus })}>
                <div className={check({ checked })}>
                  {checked && <div className={checkedStyle()} />}
                </div>
                <div>
                  {labelText}
                  {typeof text !== 'string' && text?.description && (
                    <div className={descriptionStyle()}>
                      {text?.description ?? nbsp}
                    </div>
                  )}
                </div>
              </div>
            )}
          </HeadlessRadio>
        );
      })}
    </RadioGroup>
  );
};
