import { MeasureUnit, OrderUnit, PriceUnit } from '@dagens/utils';
import { Form } from '@dagens/frontend-forms';
import { Pricing } from '../../../types/Product';
import { ConsumersWithChildren } from './hooks/use-consumers';
import { PricingFormFields as Fields } from './parts/fields';

type Props = {
  defaultValue?: PricingFormValues;
  productCategoryId?: string;
  otherPrices?: Pricing[];
  onSubmit: (data: PricingFormValues) => void;
};

export type PricingFormValues = {
  customers: ConsumersWithChildren[];
  priceValue: number | null;
  priceUnit: PriceUnit;
  measureValue: number | null;
  measureUnit: MeasureUnit | null;
  orderEqualsPrice: boolean;
  orderValue: number | null;
  orderUnit: OrderUnit | null;
  additionalInfo: string | null;
};

export const PricingForm = ({
  defaultValue,
  productCategoryId,
  otherPrices,
  onSubmit
}: Props) => {
  return (
    <Form onSubmit={onSubmit}>
      <Fields
        defaultValue={defaultValue}
        otherPrices={otherPrices}
        categoryId={productCategoryId}
      />
    </Form>
  );
};
