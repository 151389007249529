import { useTranslation } from '@dagens/frontend-i18n';
import CheckboxButtons from '_common/components/radio-buttons/CheckboxButtons';
import { type ConsumerProfileChanger } from '_consumer/pages/profile/ConsumerProfilePage';

type EmailNotificationsProps = {
  onFieldsChange: ConsumerProfileChanger;
  unsubscribeFavoriteNotification?: boolean;
  unsubscribeWeeklyReminderNotification?: boolean;
  disabled?: boolean;
};

const EmailNotifications = ({
  onFieldsChange,
  unsubscribeFavoriteNotification,
  unsubscribeWeeklyReminderNotification,
  disabled
}: EmailNotificationsProps) => {
  const selectedOptions = [
    !unsubscribeFavoriteNotification && 'FAVORITE_NOTIFICATION',
    !unsubscribeWeeklyReminderNotification && 'WEEKLY_REMINDER_NOTIFICATION'
  ].filter((option): option is string => {
    return Boolean(option);
  });

  const onChange = (change: string[]) => {
    onFieldsChange({
      unsubscribeFavoriteNotification: !change.includes(
        'FAVORITE_NOTIFICATION'
      ),
      unsubscribeWeeklyReminderNotification: !change.includes(
        'WEEKLY_REMINDER_NOTIFICATION'
      )
    });
  };

  const { t } = useTranslation();

  return (
    <CheckboxButtons
      headerText={t('consumer:WhatTypeOfEmailsNotifications')}
      options={[
        {
          key: 'FAVORITE_NOTIFICATION',
          value: t('consumer:EmailFavoriteNotification')
        },
        {
          key: 'WEEKLY_REMINDER_NOTIFICATION',
          value: t('consumer:EmailWeeklySummaryNotification')
        }
      ]}
      selectedOptions={selectedOptions}
      onChange={onChange}
      margin="m"
      disabled={disabled}
    />
  );
};

export default EmailNotifications;
