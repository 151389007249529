import {
  Select as CarrotSelect,
  type SelectProps as CarrotSelectProps
} from '@dagens/carrot';
import { FieldValues, PathValue, Path } from 'react-hook-form';
import { FieldProps, useField } from '../utils/use-field';

// Fields from the Carrot component that are handled by react-hook-form
type ExcludedFields = 'error' | 'value' | 'onChange';

export type SelectProps<
  Form extends FieldValues,
  Name extends Path<Form>,
  Value extends PathValue<Form, Name>
> = Omit<CarrotSelectProps<Value>, ExcludedFields> & FieldProps<Form, Name>;

export const Select = <
  Form extends FieldValues,
  Name extends Path<Form>,
  Value extends PathValue<Form, Name>
>({
  name,
  required,
  validate,
  shouldUnregister,
  disabled,
  ...props
}: SelectProps<Form, Name, Value>) => {
  const {
    field,
    fieldState: { error }
  } = useField({
    name,
    required,
    validate,
    shouldUnregister,
    disabled
  });

  return (
    <CarrotSelect
      error={Boolean(error)}
      onChange={field.onChange}
      value={field.value}
      {...props}
    />
  );
};
