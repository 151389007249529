import { Fragment } from 'react/jsx-runtime';
import { tv } from '../../utils/tv';

const style = tv({
  slots: {
    container: `
      flex
      flex-col
      gap-xs
      rounded
      bg-lightGrey
      p-s
    `,
    title: `text-paragraphBold`,
    details: `
      grid
      grid-cols-[max-content,1fr]
      gap-x-m
      gap-y-xs
    `,
    key: `text-paragraphSmall`,
    value: `
      text-paragraphSmall
      text-secondary
    `
  }
});

type Props = {
  title: string;
  details: Record<string, string>;
};

export const ContactCard = ({ title, details }: Props) => {
  const {
    container,
    title: titleStyle,
    details: detailsStyle,
    key: keyStyle,
    value: valueStyle
  } = style();
  return (
    <div className={container()}>
      <div className={titleStyle()}>{title}</div>
      <dl className={detailsStyle()}>
        {Object.entries(details).map(([key, value]) => (
          <Fragment key={key}>
            <dt className={keyStyle()}>{key}</dt>
            <dd className={valueStyle()}>{value}</dd>
          </Fragment>
        ))}
      </dl>
    </div>
  );
};
