import { useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../_common/hooks/reduxHooks';
import { checkAuth } from '../../_common/reducers/auth';
import { RootState } from '../../store';
import { ProducerLayout } from '../../_producer/layout';
import { ConsumerLayout } from '../../_consumer/layout';

export const Layout = () => {
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(checkAuth());
  }, []);
  const { authenticated, role } = useAppSelector(({ auth }: RootState) => {
    return {
      authenticated: auth.authenticated,
      role: auth._type
    };
  });

  if (authenticated === undefined) {
    return <Outlet />;
  }

  if (role === 'producers') {
    return <ProducerLayout />;
  }
  if (role === 'consumers') {
    return <ConsumerLayout />;
  }

  return <Outlet />;
};
