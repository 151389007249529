import { PropsWithChildren } from 'react';
import { tv } from '../../utils/tv';

export const fullWidthStyle = tv({
  base: `
    m-auto
    max-w-desktop
    justify-center
    px-s

    print:max-w-none
    print:p-xxs
  `,
  variants: {
    noPadding: {
      true: `!px-0`
    }
  }
});

type Props = PropsWithChildren<{
  noPadding?: boolean;
}>;

export const BoxFullWidth = ({ noPadding, children }: Props) => {
  return <div className={fullWidthStyle({ noPadding })}>{children}</div>;
};
