import { Icon } from '../../theme/icon';
import { IconSize } from '../../theme/icon/types';
import { tv } from '../../utils/tv';

const style = tv({
  slots: {
    container: `relative`,
    background: `
      absolute
      inset-0
      -z-10
      animate-ping
      rounded-full
      bg-brightPurple
    `
  }
});

type Props = {
  size: IconSize;
};

export const LoaderIcon = ({ size }: Props) => {
  const { container, background } = style();
  return (
    <div className={container()}>
      <Icon icon="onion" color="dagensPurple" size={size} />
      <div className={background()} />
    </div>
  );
};
