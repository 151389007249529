import { ComboboxOption } from '@headlessui/react';
import { Fragment } from 'react/jsx-runtime';
import { tv } from 'tailwind-variants';
import { useTranslation } from '@dagens/frontend-i18n';
import { CommandPaletteResult } from '@components/command-palette/result';
import Loader from '_common/components/loader/Loader';
import { Order } from 'types/Order';
import { ReqType } from 'utils/REQ';

const style = tv({
  slots: {
    title: `
      mb-xxs
      px-xs
      text-microText
    `,
    list: `
      flex
      flex-col
      justify-start
    `
  }
});

type Props = {
  req: ReqType;
  results: Order[];
};

export const CommandPaletteResultsOrders = ({ req, results }: Props) => {
  const { t } = useTranslation();
  const { title, list } = style();
  if (results.length === 0 && req === 'SUCCESS') {
    return null;
  }

  return (
    <li key="pages">
      <h2 className={title()}>{t('common:Orders')}</h2>
      {req === 'PENDING' ? (
        <Loader />
      ) : (
        <ul className={list()}>
          {results.map(item => {
            const to = `/orders/${item.secret}`;
            return (
              <ComboboxOption
                as={Fragment}
                key={item.orderNumberString}
                value={to}
              >
                {({ focus }) => (
                  <CommandPaletteResult
                    icon="orders"
                    text={item.orderNumberString}
                    to={to}
                    focused={focus}
                  />
                )}
              </ComboboxOption>
            );
          })}
        </ul>
      )}
    </li>
  );
};
