import { ButtonGroup, Icon, Link } from '@dagens/carrot';
import { useTranslation } from '@dagens/frontend-i18n';
import { tv } from 'tailwind-variants';
import { Illustrations } from 'utils/Illustrations';

const style = tv({
  slots: {
    container: `
      mt-xl
      flex
      flex-col-reverse
      items-center
      justify-between
      gap-x-jumbo
      gap-y-xl

      desktop:flex-row
    `,
    text: `
      flex
      flex-col
      gap-m
    `,
    title: 'text-tertiaryHeading',
    description: 'text-paragraph',
    illustration: `
      aspect-auto
      h-full
      max-h-[160px]
    `
  }
});

type Props = {
  title: string;
  description: string;
};

export const CustomerListEmpty = ({ title, description }: Props) => {
  const {
    container,
    text,
    title: titleStyle,
    description: descriptionStyle,
    illustration
  } = style();
  const { t } = useTranslation();
  return (
    <div className={container()}>
      <div className={text()}>
        <h1 className={titleStyle()}>{title}</h1>
        <p className={descriptionStyle()}>{description}</p>
        <ButtonGroup.NoWrap>
          <Link variant="primary" size="regular" to="/customers/add/start">
            <Icon icon="add" size="small" />
            {t('producer:AddCustomer.headerAnnotation')}
          </Link>
          <Link
            variant="ghost"
            size="regular"
            to="/profile/delivery-route-request/start"
          >
            {t('producer:DeliveryRouteRequestPage.ButtonText')}
          </Link>
        </ButtonGroup.NoWrap>
      </div>
      <img
        src={Illustrations.INTERESTED_CHEF}
        alt="Rootless user illustration"
        className={illustration()}
      />
    </div>
  );
};
