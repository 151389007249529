import { ReactNode } from 'react';

import { tv } from '../../utils/tv';
import { Box } from '../../layout/box';

const style = tv({
  slots: {
    container: `
      border-b-lightGrey

      desktop:border-b-thin
    `,
    nav: `
      grid
      grid-cols-[48px,minmax(0,1fr),48px]
      grid-rows-1
      gap-xs
      py-xxs
    `,
    left: `
      flex
      items-center
      justify-start
    `,
    right: `
      flex
      items-center
      justify-end
    `
  }
});

type Props = {
  left: ReactNode;
  center: ReactNode;
  right: ReactNode;
};

export const NavBar = ({ left, center, right }: Props) => {
  const { container, nav, left: leftStyle, right: rightStyle } = style();
  return (
    <div className={container()}>
      <Box.FullWidth noPadding>
        <nav className={nav()}>
          <div className={leftStyle()}>{left}</div>
          <div>{center}</div>
          <div className={rightStyle()}>{right}</div>
        </nav>
      </Box.FullWidth>
    </div>
  );
};
