/* eslint-disable @typescript-eslint/no-duplicate-enum-values */
enum FontSizes {
  primaryHeading = 34,
  secondaryHeading = 28,
  tertiaryHeading = 20,
  paragraph = 16,
  paragraphBold = 16,
  paragraphSmall = 14,
  paragraphSmallBold = 14,
  leadText = 20,
  leadTextBold = 20,
  inputLabel = 16,
  inputText = 16,
  button = 14,
  allCapsTitle = 12
}
export default FontSizes;
