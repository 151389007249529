import { Notifier } from 'airbrake-browser-esm';
import React, { Component } from 'react';
import ErrorMessage from '_common/components/error-message/ErrorMessage';
import Logger from 'services/Logger';

type ErrorBoundaryState = {
  hasError: boolean;
};
type ErrorBoundaryProps = {
  airbrake: Notifier;
  children: React.ReactChild;
};
class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  state: ErrorBoundaryState = { hasError: false };

  componentDidCatch(error: Error, info: React.ErrorInfo) {
    const { airbrake } = this.props;
    this.setState({ hasError: true });

    Logger.error(error);

    airbrake.notify({
      error,
      params: { info }
    });
  }

  render() {
    const { hasError } = this.state;
    const { children } = this.props;

    if (hasError) {
      return <ErrorMessage />;
    }

    return children;
  }
}

export default ErrorBoundary;
