import { useWatch } from '@dagens/frontend-forms';
import { AddCustomerFormValues } from '../types';
import { BackButton } from '../../../../components/page/parts/back-button';

export const AddCustomerContactBackButton = () => {
  const state = useWatch<AddCustomerFormValues>();
  return (
    <BackButton
      returnTo={{
        pathname: '/customers/add/review-billing-information',
        state
      }}
    />
  );
};
