import { tv } from '../../../utils/tv';
import { SeasonCalendarRow } from '../parts/row';
import { SeasonCalendarHeader } from '../parts/header';
import { Range } from '../types';

export const style = tv({
  slots: {
    container: `
      flex
      flex-col
      gap-s
    `,
    title: 'text-paragraphSmallBold',
    calendar: `
      relative
      grid
      grid-cols-12
      p-xxs
      text-center
    `
  }
});

type Props = {
  title?: string;
  ranges: Partial<Range>[];
};

export const SeasonCalendarSingle = ({ title, ranges }: Props) => {
  const { container, title: titleStyle, calendar } = style();
  return (
    <div className={container()}>
      <div className={titleStyle()}>{title}</div>
      <div className={calendar()}>
        <SeasonCalendarHeader />
        <SeasonCalendarRow ranges={ranges} />
      </div>
    </div>
  );
};
