import { InputHTMLAttributes } from 'react';
import { ComparisonUtils, ByComparator } from '@dagens/utils';
import { tv } from '../../utils/tv';
import { nbsp } from '../../utils/nbsp';
import { Checkbox, type CheckboxVariant } from '../checkbox';

const style = tv({
  base: `
    flex
    flex-col
    gap-xxs
  `,
  variants: {
    button: {
      true: `
        flex-row
        flex-wrap
      `
    }
  }
});

type InputProps = InputHTMLAttributes<HTMLInputElement>;

export type CheckboxesProps<T> = {
  variant?: CheckboxVariant;
  name?: InputProps['name'];
  options: T[];
  displayValue?: (value?: T) => string | undefined;
  by?: ByComparator<T>;

  value: T[];
  onChange: (value: T[]) => void;
};

export const Checkboxes = <T,>({
  variant,
  value,
  options,
  onChange,
  displayValue,
  by
}: CheckboxesProps<T>) => {
  const handleChange = (checked: boolean, option: T) => {
    if (checked) {
      onChange([...value, option]);
    } else {
      onChange(value.filter(v => !ComparisonUtils.equal(by, v, option)));
    }
  };

  return (
    <div className={style({ button: variant === 'button' })}>
      {options.map(option => {
        const text = displayValue ? displayValue(option) : option?.toString();
        return (
          <Checkbox
            key={text}
            variant={variant}
            checked={value.some(v => ComparisonUtils.equal(by, v, option))}
            onChange={checked => handleChange(checked, option)}
          >
            {text ?? nbsp}
          </Checkbox>
        );
      })}
    </div>
  );
};
