import {
  Input as CarrotInput,
  type InputProps as CarrotInputProps
} from '@dagens/carrot';
import {
  FieldValues,
  get,
  Path,
  PathValue,
  useFormContext
} from 'react-hook-form';
import debounce from 'lodash/debounce';
import { useCallback, useMemo, useState } from 'react';
import { FieldProps, useField } from '../utils/use-field';

// Fields from the Carrot component that are handled by react-hook-form
type ExcludedFields = 'error' | 'value' | 'onChange';

export type SearchBackgroundProps<
  Form extends FieldValues,
  Name extends Path<Form>,
  Value extends PathValue<Form, Name>
> = Omit<CarrotInputProps, ExcludedFields> & {
  onChange: (value: string | null) => Promise<Value | null> | Value | null;
  debounce?: number;
  displayValue?: (value?: Value) => string | undefined;
  onLoadingChange?: (loading: boolean) => void;
} & FieldProps<Form, Name>;

export const SearchBackground = <
  Form extends FieldValues,
  Name extends Path<Form>,
  Value extends PathValue<Form, Name>
>({
  name,
  required,
  validate,
  shouldUnregister,
  disabled,
  onChange,
  displayValue,
  onLoadingChange,
  debounce: wait,
  ...props
}: SearchBackgroundProps<Form, Name, Value>) => {
  const [loading, setLoading] = useState(false);
  const { getValues } = useFormContext<Form>();
  const {
    field,
    fieldState: { error }
  } = useField({
    name,
    required,
    validate,
    shouldUnregister,
    disabled
  });

  const throttledOnChange = useMemo(() => {
    return debounce(async (v: string | null) => {
      const resolvedValue = await onChange(v);
      if (get(getValues(), name) === v) {
        field.onChange(resolvedValue ?? v);
        setLoading(false);
        onLoadingChange?.(false);
      }
    }, wait ?? 0);
  }, [onChange, field.onChange]);

  const onChangeInternal = useCallback(
    (v: string | null) => {
      setLoading(true);
      onLoadingChange?.(true);
      throttledOnChange.cancel();
      field.onChange(v);
      throttledOnChange(v);
    },
    [field.onChange, throttledOnChange]
  );

  return (
    <CarrotInput
      error={!loading && Boolean(error)}
      onChange={e => onChangeInternal(e.target.value)}
      value={
        displayValue?.(field.value) ??
        (typeof field.value === 'string' ? field.value : '')
      }
      after={<CarrotInput.Loader loading={loading} />}
      {...props}
    />
  );
};
