import { Form, FormField } from '@dagens/carrot';
import { PricingFormValues } from '..';
import { Pricing } from '../../../../types/Product';
import { PricingFormPriceStructure as PriceStructure } from './price-structure';
import { PricingFormCustomers as Customers } from './customers';
import { PricingFormMeasureUnit as MeasureUnit } from './measure-unit';
import { PricingFormOrderUnit as OrderUnit } from './order-unit';
import { PricingFormPrice as Price } from './price';
import { PricingFormAdditionalInfo as AdditionalInfo } from './additional-info';
import { PricingFormPricePreview as Preview } from './preview';
import { PricingFormTips } from './tips';

type Props = {
  defaultValue?: PricingFormValues;
  otherPrices?: Pricing[];
  categoryId?: string;
};

export const PricingFormFields = ({
  otherPrices,
  defaultValue,
  categoryId
}: Props) => {
  return (
    <Form.Section>
      <PricingFormTips />

      <Customers otherPrices={otherPrices} />

      <FormField.Group withExternalFeedback>
        <Price />
        <PriceStructure defaultPriceValue={defaultValue?.priceValue} />
      </FormField.Group>

      <MeasureUnit defaultValue={defaultValue} categoryId={categoryId} />

      <OrderUnit defaultValue={defaultValue} />

      <AdditionalInfo />

      <Preview />
    </Form.Section>
  );
};
