import {
  Box,
  Nav,
  useMediaQuery,
  PageHeader,
  Icon,
  Link
} from '@dagens/carrot';
import { useTranslation } from '@dagens/frontend-i18n';
import { useEffect, useMemo } from 'react';

import { tv } from 'tailwind-variants';
import {
  useAppDispatch,
  useAppSelector
} from '../../../_common/hooks/reduxHooks';
import { clearBasket } from '../../../_consumer/reducers/basket';
import { Page } from '@components/page';
import SearchInput from '@components/search-input/SearchInput';
import { useQueryParams } from '_common/hooks/useQueryParams';
import { CustomerList } from '_producer/pages/customer-list-page/customer-list';
import { CustomerTable } from '_producer/pages/customer-list-page/customer-table';
import { CustomerListEmpty } from '_producer/pages/customer-list-page/empty';
import { ContentLoader } from '@components/content-loader';
import REQ from 'utils/REQ';

const QUERY_KEY = 'query';

const style = tv({
  slots: {
    customerCountContainer: 'mb-s',
    customerCount: 'text-paragraphSmallBold',
    customerCountText: 'text-paragraphSmall'
  }
});

const AddOrderStart = () => {
  const { t } = useTranslation();
  const { customerCount, customerCountContainer, customerCountText } = style();
  const { desktopUp } = useMediaQuery();
  const { getQueryValue, setQueryValue } = useQueryParams();
  const searchQuery = getQueryValue(QUERY_KEY);
  const dispatch = useAppDispatch();

  const { producerId, consumers, req } = useAppSelector(
    ({ auth, consumers: { items, req } }) => {
      return {
        producerId: auth._id,
        consumers: items,
        req
      };
    }
  );

  useEffect(() => {
    dispatch(clearBasket());
  }, []);

  const hasCustomers = consumers.length >= 1;

  const filteredConsumers = useMemo(() => {
    return consumers.filter(consumer => {
      const searchFields = [
        consumer.name,
        ...consumer.emails,
        consumer.phone,
        consumer.contactPerson,
        consumer.structuredDeliveryAddress.addressLine1,
        consumer.structuredDeliveryAddress.addressLine2,
        consumer.structuredDeliveryAddress.city,
        consumer.structuredDeliveryAddress.country,
        consumer.structuredDeliveryAddress.postalCode,
        consumer.createdByAccount === producerId
          ? t('producer:CustomerListManagedTag')
          : ''
      ].join(' ');

      const hiddenCustomerTypes = ['B2C'];

      return (
        (!searchQuery ||
          searchFields.toLowerCase().includes(searchQuery.toLowerCase())) &&
        !hiddenCustomerTypes.includes(consumer.type)
      );
    }) /*
      .sort((a, b) => {
        const nameCompare = a.name.localeCompare(b.name);

        if (!a.lastOrder && b.lastOrder) {
          return 1;
        }
        if (a.lastOrder && !b.lastOrder) {
          return -1;
        }
        if (a.lastOrder && b.lastOrder) {
          return (
            b.lastOrder.slice(0, 10).localeCompare(a.lastOrder.slice(0, 10)) ||
            nameCompare
          );
        }

        return nameCompare;
      }) */;
  }, [searchQuery, consumers]);

  if (req !== REQ.SUCCESS) {
    return <Page.Status req={req} />;
  }

  const title = t('producer:ChooseCustomer');
  const subtitle = `${t('common:MakeNewOrder')} (1/4)`;

  return (
    <Page
      navMobileText={
        <Nav.ContextTitle>
          <>
            <Nav.ContextTitle>{title}</Nav.ContextTitle>
            <Nav.ContextSubtitle>{subtitle}</Nav.ContextSubtitle>
          </>
        </Nav.ContextTitle>
      }
      navMobileAction={
        <Link.NavIcon to="/customers/add/start">
          <Icon icon="add" />
        </Link.NavIcon>
      }
      header={
        hasCustomers && (
          <Box.FullWidth>
            {desktopUp && <PageHeader title={title} subtitle={subtitle} />}
            <SearchInput
              searchPlaceholder={t('common:SearchInCustomers')}
              searchStringInput={searchQuery || ''}
              onSearchChange={({ target: { value } }) => {
                return setQueryValue(QUERY_KEY, value);
              }}
              onResetSearchString={() => {
                return setQueryValue(QUERY_KEY, '');
              }}
            />
            <div className={customerCountContainer()}>
              <span className={customerCount()}>
                {filteredConsumers.length}
              </span>
              <span className={customerCountText()}>
                {' '}
                {t('common:customers')}
              </span>
            </div>
          </Box.FullWidth>
        )
      }
      noContentPadding
      bottom={null}
    >
      <ContentLoader req={req}>
        {!hasCustomers ? (
          <CustomerListEmpty
            title={t('producer:NoCustomersAvailableTitle')}
            description={t('producer:NoCustomersAvailableDescription')}
          />
        ) : filteredConsumers.length < 1 ? (
          <CustomerListEmpty
            title={t('producer:CustomerListNoResultsTitle')}
            description={t('producer:CustomerListNoResultsDescription')}
          />
        ) : desktopUp ? (
          <CustomerTable
            consumers={filteredConsumers}
            linkTo="/orders/add/delivery-date"
          />
        ) : (
          <CustomerList
            consumers={filteredConsumers}
            linkTo="/orders/add/delivery-date"
          />
        )}
      </ContentLoader>
    </Page>
  );
};

export default AddOrderStart;
