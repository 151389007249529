import {
  Input as CarrotInput,
  type InputProps as CarrotInputProps
} from '@dagens/carrot';
import { FieldValues, PathValue } from 'react-hook-form';
import { MatchingKeys } from '../types/fields';
import { FieldProps, useField } from '../utils/use-field';

// Fields from the Carrot component that are handled by react-hook-form
type ExcludedFields = 'error' | 'value' | 'onChange';

export type InputProps<
  Form extends FieldValues,
  Name extends MatchingKeys<Form, string | null>
> = Omit<CarrotInputProps, ExcludedFields> & FieldProps<Form, Name>;

export const Input = <
  Form extends FieldValues,
  Name extends MatchingKeys<Form, string | null>
>({
  name,
  required,
  validate,
  shouldUnregister,
  disabled,
  ...props
}: InputProps<Form, Name>) => {
  const {
    field,
    fieldState: { error }
  } = useField({
    name,
    required,
    validate,
    shouldUnregister,
    disabled
  });

  const stringToValue = (s: string) => (s === '' ? null : s);
  const valueToString = (v: string | null) => (v === null ? '' : v);

  return (
    <CarrotInput
      error={Boolean(error)}
      onChange={e =>
        field.onChange(stringToValue(e.target.value) as PathValue<Form, Name>)
      }
      value={valueToString(field.value)}
      {...props}
    />
  );
};
