import { GetOrganizationFoundResponse } from '../../../api';
import { ValidTimeSlot } from '../../../types/Consumer';
import { StructuredAddress } from '../../../types/Logistics';

export type AddCustomerFormValues = {
  organization: GetOrganizationFoundResponse | null;

  billingName: string | null;
  department: string | null;
  eanNumber: string | null;
  primaryEmail: string | null;
  reminderEmail: string | null;
  billingAddress: StructuredAddress | string | null;

  customerName: string | null;
  contactPersonName: string | null;
  contactPhone: string | null;
  notificationEmail: string | null;

  deliveryAddress: StructuredAddress | string | null;
  deliveryWindowFrom: ValidTimeSlot | null;
  deliveryWindowTo: ValidTimeSlot | null;
  deliveryInstructions: string | null;

  defaultOrganizationDeliveryAddress: StructuredAddress | string | null;
};

export const defaultValue = {
  organization: undefined,
  billingName: null,
  department: null,
  eanNumber: null,
  primaryEmail: null,
  reminderEmail: null,
  billingAddress: null,
  customerName: null,
  contactPersonName: null,
  contactPhone: null,
  notificationEmail: null,
  deliveryAddress: null,
  deliveryWindowFrom: '08:00',
  deliveryWindowTo: '16:00',
  deliveryInstructions: null
};
