import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { AddCustomerFormValues } from '../types';

// Navigate to next step and pass the form value
export const useOnSave = () => {
  const navigate = useNavigate();

  const onSave = useCallback(async (state: AddCustomerFormValues) => {
    navigate('/customers/add/review-contact-information', { state });
  }, []);

  return { onSave };
};
