import styled from 'styled-components';
import { useInfiniteScroll } from '../../../../utils/infiniteScroll';
import Theme from '@carrot-deprecated/theme';
import { MediaQuery } from 'utils/mediaQueries';

export const GridContainer = styled.div`
  --span: 1;
  display: grid;
  grid-template-columns: repeat(var(--span), 1fr);
  grid-template-rows: auto;
  gap: ${Theme.Spacings.xs}px;

  ${MediaQuery.tabletUp} {
    --span: 2;
    gap: ${Theme.Spacings.s}px;
  }

  ${MediaQuery.desktopUp} {
    --span: 3;
  }
`;

type GridProps = {
  children: React.ReactChild;
  hasMore: boolean;
  loadMore: () => void;
};

const Grid = ({ hasMore, loadMore, children }: GridProps) => {
  const { scrollerRef } = useInfiniteScroll(hasMore, loadMore);
  return (
    <div ref={scrollerRef}>
      <GridContainer>{children}</GridContainer>
    </div>
  );
};

export default Grid;
