import { PropsWithChildren } from 'react';
import { ThemeColor } from '../../../theme/colors';
import { calloutStyle } from '../styles';

type Props = PropsWithChildren<{
  small?: boolean;
  title?: string;
  variant?: Extract<ThemeColor, 'lightGrey' | 'noteworthy'>;
}>;

export const Callout = ({ small, title, variant, children }: Props) => {
  const { base, title: titleStyle } = calloutStyle({ small, variant });
  return (
    <div className={base()}>
      {title && <div className={titleStyle()}>{title}</div>}
      {children}
    </div>
  );
};
