import { Button } from '../../button';

type Props = {
  open: boolean;
  onClick: () => void;
};

export const FormFieldExplanationToggle = ({ open, onClick }: Props) => {
  return <Button.CalloutToggle type="question" open={open} onClick={onClick} />;
};
