import { formFieldFactory } from '@dagens/frontend-forms';
import { useTranslation } from '@dagens/frontend-i18n';
import { ProductFormValues } from '..';

const { Field, Radio } = formFieldFactory<ProductFormValues>();

export const ProductFormSaleStatus = () => {
  const { t } = useTranslation();
  return (
    <Field
      to="salesStatus"
      label={t('producer:productSalesStatusHeader')}
      required
    >
      <Radio
        name="salesStatus"
        options={['FOR_SALE', 'ON_REQUEST', 'NOT_FOR_SALE']}
        displayValue={value =>
          value &&
          {
            FOR_SALE: {
              label: t('common:FOR_SALE'),
              description: t('producer:productForSaleExplanation')
            },
            ON_REQUEST: {
              label: t('common:ON_REQUEST'),
              description: t('producer:productOnRequestExplanation')
            },
            NOT_FOR_SALE: {
              label: t('common:NOT_FOR_SALE'),
              description: t('producer:productNotForsaleExplanation')
            }
          }[value]
        }
      />
    </Field>
  );
};
