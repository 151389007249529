import { useTranslation } from '@dagens/frontend-i18n';

import { NumberInput } from '../../../carrot-deprecated/input-number';
import Icon from '../utils/Icon';
import {
  Button,
  Picker as PickerStyle,
  SmallButton,
  SmallPicker as SmallPickerStyle
} from './Picker.style';
import Typography from '@carrot-deprecated/typography';
import {
  ADD_TO_BASKET,
  BASKET_ADD_TO_BASKET,
  BASKET_DECREASE_BASKET_QUANTITY,
  BASKET_INCREASE_BASKET_QUANTITY,
  BASKET_REMOVE_FROM_BASKET,
  DECREASE_BASKET_QUANTITY,
  INCREASE_BASKET_QUANTITY,
  INPUT_BASKET_QUANTITY,
  REMOVE_FROM_BASKET
} from 'utils/mixpanel';

export type PickerVariant = 'regular' | 'small' | 'smallCard';

type PickerProps = {
  nofUnits: number;
  unit: string | undefined;
  setNofUnits: (origin: string, ofUnits: number) => void;
  minValue?: number;
  variant?: PickerVariant;
  decimal?: boolean;
};

const Picker = ({
  nofUnits,
  unit,
  setNofUnits,
  minValue = 0,
  variant = 'regular',
  decimal = false
}: PickerProps) => {
  const { t } = useTranslation();

  const regularPicker = (
    <PickerStyle>
      <Button
        onClick={e => {
          e.stopPropagation();
          setNofUnits(
            nofUnits === 1 ? REMOVE_FROM_BASKET : DECREASE_BASKET_QUANTITY,
            Math.max(minValue, nofUnits - 1)
          );
        }}
      >
        <Icon icon="remove" />
      </Button>
      <NumberInput
        decimal={decimal}
        value={nofUnits}
        unit={t(`units:${unit}`, { count: nofUnits })}
        onChange={e => {
          setNofUnits(INPUT_BASKET_QUANTITY, e as number);
        }}
      />
      <Button
        onClick={e => {
          e.stopPropagation();
          setNofUnits(
            nofUnits === 0 ? ADD_TO_BASKET : INCREASE_BASKET_QUANTITY,
            nofUnits + 1
          );
        }}
      >
        <Icon icon="add" />
      </Button>
    </PickerStyle>
  );

  const smallPicker = (
    <SmallPickerStyle>
      <SmallButton
        variant="borderless"
        onClick={e => {
          e.stopPropagation();
          setNofUnits(
            nofUnits === 1
              ? BASKET_REMOVE_FROM_BASKET
              : BASKET_DECREASE_BASKET_QUANTITY,
            Math.max(minValue, nofUnits - 1)
          );
        }}
      >
        <Icon icon="remove" fill="deepPurple" />
      </SmallButton>
      <span
        role="presentation"
        style={{
          width: variant === 'smallCard' ? '90px' : '56px',
          textAlign: 'center'
        }}
      >
        <Typography variant="paragraphSmall">
          {nofUnits} {unit}
        </Typography>
      </span>
      <SmallButton
        variant="borderless"
        onClick={e => {
          e.stopPropagation();
          setNofUnits(
            nofUnits === 0
              ? BASKET_ADD_TO_BASKET
              : BASKET_INCREASE_BASKET_QUANTITY,
            nofUnits + 1
          );
        }}
      >
        <Icon icon="add" fill="deepPurple" />
      </SmallButton>
    </SmallPickerStyle>
  );

  if (variant === 'small' || variant === 'smallCard') return smallPicker;
  return regularPicker;
};

export default Picker;
