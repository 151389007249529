import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import REQ, { ReqType } from '../../../../utils/REQ';
import { getManagedCustomer, ManagedCustomer } from '../../../../api';
import { RootState } from '../../../../store';
import { useAppSelector } from '../../../../_common/hooks/reduxHooks';

export const useFetchCustomer = () => {
  const { id } = useParams<{ id: string }>();
  const [req, setReq] = useState<ReqType>(REQ.INIT);
  const [consumer, setConsumer] = useState<ManagedCustomer | null>(null);
  const producerId = useAppSelector(({ auth }: RootState) => auth._id);

  useEffect(() => {
    const fetchConsumer = async () => {
      setReq(REQ.PENDING);
      const consumer =
        producerId && id ? await getManagedCustomer(producerId, id) : null;
      setConsumer(consumer?.data ?? null);
      setReq(REQ.SUCCESS);
    };

    fetchConsumer();
  }, [id]);

  return { req, consumer };
};
