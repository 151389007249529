import { capitalize } from '@dagensmat/core';
import { xor } from 'lodash';
import { mapOptions, UnitOption } from './RadioButtons';
import Container from '@carrot-deprecated/container';
import Theme from '@carrot-deprecated/theme';
import { SpacingNames } from '@carrot-deprecated/theme/spacings';
import { MarginProps } from 'utils/style';

import FormLabel from '_common/components/input/FormLabel';
import Button from '_common/components/button/Button.style';

type CheckboxButtonsProps = {
  headerText: string;
  options: UnitOption[];
  selectedOptions: string[];
  onChange: (buttonOption: string[]) => void;
  margin?: SpacingNames;
  disabled?: boolean;
  optional?: boolean;
} & MarginProps;

const CheckboxButtons = ({
  headerText,
  options,
  selectedOptions,
  onChange,
  margin,
  disabled = false,
  optional = false
}: CheckboxButtonsProps) => {
  return (
    <Container my={margin}>
      <>
        <FormLabel labelText={headerText} optional={optional} />
        {mapOptions(options).map(({ key, value }) => {
          return (
            <Button
              mr={Theme.Spacings.xxs}
              mb={Theme.Spacings.xs}
              onClick={() => {
                onChange(xor(selectedOptions, [key]));
              }}
              key={key}
              variant={
                key && selectedOptions.includes(key) ? 'secondary' : 'ghost'
              }
              type="button"
              disabled={disabled}
            >
              {capitalize(value)}
            </Button>
          );
        })}
      </>
    </Container>
  );
};

export default CheckboxButtons;
