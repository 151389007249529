import { DateUtils } from '@dagens/utils';
import { tv } from '../../../utils/tv';
import { Range } from '../types';

const style = tv({
  base: `
    absolute
    -z-10
    h-full
    rounded
    bg-availability
  `,
  variants: {
    cutStart: { true: `rounded-l-small` },
    cutEnd: { true: `rounded-r-small` }
  }
});

type Props = {
  ranges: Partial<Range>[];
};

export const SeasonCalendarRow = ({ ranges }: Props) => {
  const today = new Date();

  // Filter out ranges that are not relevant for the current year
  // And cut off the ranges that are not in the current year
  const relevantRanges = ranges
    .filter((range): range is Range => {
      return (
        range.from?.getFullYear() === today.getFullYear() ||
        range.to?.getFullYear() === today.getFullYear()
      );
    })
    .map(range => {
      const cutStart = range.from.getFullYear() !== today.getFullYear();
      const cutEnd = range.to.getFullYear() !== today.getFullYear();

      return {
        from: cutStart ? new Date(today.getFullYear(), 0, 1) : range.from,
        to: cutEnd ? new Date(today.getFullYear(), 11, 31) : range.to,
        cutStart,
        cutEnd
      };
    });

  return relevantRanges.map(({ from, to, cutStart, cutEnd }) => {
    const left = ((DateUtils.dayOfYear(from) - 1) / 365) * 100;
    const right = (1 - (DateUtils.dayOfYear(to) - 1) / 365) * 100;
    return (
      <div
        key={from.toISOString()}
        className={style({ cutStart, cutEnd })}
        style={{
          left: `${left}%`,
          right: `${right}%`
        }}
      />
    );
  });
};
