import { debounce, map } from 'lodash';
import { useCallback, useEffect, useState } from 'react';

export default function useSimpleSearch(
  searchFunction: (
    searchString: string,
    filters?: Record<string, any>
  ) => Promise<any[]>
): [
  string | undefined,
  (e: React.FormEvent<HTMLInputElement>) => void,
  () => void,
  string[]
] {
  const [searchString, setSearchString] = useState<string>('');
  const [hits, setHits] = useState<string[] | undefined>();
  const handleInputChange = ({
    currentTarget: { value }
  }: React.FormEvent<HTMLInputElement>) => {
    return setSearchString(value);
  };

  const search = useCallback(
    debounce(async () => {
      const hitObjects = await searchFunction(searchString);
      setHits(map(hitObjects, '_id'));
    }, 400),
    [searchString]
  );

  useEffect(() => {
    search();
    return search.cancel;
  }, [searchString, search]);

  return [
    searchString,
    handleInputChange,
    () => {
      return setSearchString('');
    },
    hits ?? []
  ];
}
