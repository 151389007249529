import { useTranslation } from '@dagens/frontend-i18n';
import { allCustomersOption } from '../../components/pricing-form/hooks/use-consumers';
import { useDefaultValue as usePricingDefaultValue } from '../add-pricing/use-default-value';
import { useAppSelector } from '../../../_common/hooks/reduxHooks';

// Basic default values for the product form
export const useDefaultValue = () => {
  const { t } = useTranslation();
  const { consumers, req } = useAppSelector(
    ({ consumers: { items = [], req } }) => {
      return { consumers: items, req };
    }
  );

  const priceDefaultValue = usePricingDefaultValue();
  const defaultValue = {
    name: null,
    image: {},
    category: [],
    type: null,
    certifications: [],
    processedState: 'RAW' as const,
    temperatureZone: 'REFRIGERATED' as const,
    description: null,
    salesStatus: 'FOR_SALE' as const,
    seasonCalendar: null,
    capacities: null,

    ...priceDefaultValue,
    customers: [
      {
        ...allCustomersOption,
        name: t('producer:AllCustomers'),
        children: consumers
      }
    ]
  };
  return { defaultValue, req };
};
