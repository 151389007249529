import { useTranslation } from '@dagens/frontend-i18n';
import { FC } from 'react';

import { AddEmail } from './add-email';
import { Email } from './email';
import { Feedback } from './feedback';
import { useEmails } from './use-emails';
import Container from '@carrot-deprecated/container';
import Typography from '@carrot-deprecated/typography';

export const NotificationSettings: FC = () => {
  const { t } = useTranslation();
  const {
    emails,
    hideRemoveButton,
    feedback,
    onAdd,
    onAddInputChange,
    onRemove,
    onRemoveSuccess,
    onRemoveError
  } = useEmails();

  return (
    <Container mb="xl">
      <Typography variant="paragraphBold" as="p" mb="xs">
        {t('consumer:EmailAddressAlerts')}
      </Typography>
      <ul>
        {emails?.map(email => {
          return (
            <Email
              key={email}
              email={email}
              hideRemoveButton={hideRemoveButton}
              onRemove={onRemove}
              onRemoveSuccess={onRemoveSuccess}
              onRemoveError={onRemoveError}
            />
          );
        })}
      </ul>
      <AddEmail onAdd={onAdd} onAddInputChange={onAddInputChange} />
      <Feedback value={feedback} />
    </Container>
  );
};
