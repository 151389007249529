import { useTranslation } from '@dagens/frontend-i18n';
import { useState } from 'react';
import { Button, Icon } from '@dagens/carrot';
import Container from '@carrot-deprecated/container';
import { SpacingNames } from '@carrot-deprecated/theme/spacings';
import { postOrderUpdate } from 'api';
import { ActionButton } from '@components/action-button';
import OldIcon from '_common/components/utils/Icon';
import FormField from '_common/components/input/FormField';
import REQ, { ReqType } from 'utils/REQ';
import Typography from '@carrot-deprecated/typography';

type OrderReferenceProps = {
  orderId: string;
  readOnly: boolean;
  orderReference?: string;
  my?: SpacingNames;
  onReferenceChange?: () => Promise<void>;
};

const AddOrEditButton = ({
  orderReference,
  onClick
}: {
  orderReference?: string;
  onClick: () => void;
}) => {
  const { t } = useTranslation();
  const text = orderReference
    ? t('common:Edit')
    : t('common:AddOrderReference');
  const icon = orderReference ? (
    <OldIcon icon="editSmall" fill="deepPurple" />
  ) : (
    <Icon icon="add" size="small" />
  );
  return (
    <Button variant="borderless" onClick={onClick} size="small">
      {icon}
      {text}
    </Button>
  );
};

const OrderReference = ({
  orderId,
  orderReference,
  my,
  readOnly,
  onReferenceChange
}: OrderReferenceProps) => {
  const { t } = useTranslation();
  const [submitReq, setSubmitReq] = useState<ReqType>(REQ.INIT);
  const [editMode, setEditMode] = useState(false);
  const [editableOrderReference, setEditableOrderReference] = useState(
    orderReference || ''
  );

  const submit = async () => {
    setSubmitReq(REQ.PENDING);
    try {
      await postOrderUpdate({
        orderId,
        orderReference: editableOrderReference.trim()
      });
      await onReferenceChange?.();
      setSubmitReq(REQ.INIT);
      setEditMode(false);
    } catch (e) {
      setSubmitReq(REQ.ERROR);
    }
  };

  if (readOnly && !orderReference) return null;

  if (!editMode) {
    return (
      <Container my={my}>
        <Typography variant="paragraphBold" as="p">
          {t('common:OrderReference')}
        </Typography>
        {!readOnly && (
          <Typography variant="paragraphSmall" color="secondary">
            {t('common:OrderReferenceInputHelpText')}
          </Typography>
        )}
        <Typography
          variant="paragraph"
          as="span"
          mr="xxs"
          style={{ verticalAlign: 'text-bottom' }}
        >
          {orderReference}
        </Typography>
        {!readOnly && (
          <AddOrEditButton
            orderReference={orderReference}
            onClick={() => {
              setEditMode(true);
            }}
          />
        )}
      </Container>
    );
  }

  return (
    <Container my={my} width="halfTabletUp">
      <FormField
        name="orderReference"
        type="text"
        labelText={t('common:OrderReference')}
        helpText={t('common:OrderReferenceInputHelpText')}
        value={editableOrderReference}
        margin={{ mb: 'xxs' }}
        onChange={e => {
          setSubmitReq(REQ.INIT);
          setEditableOrderReference(e.target.value);
        }}
      />
      <Container mt="s">
        <ActionButton.Save size="regular" saveReq={submitReq} onClick={submit}>
          {t('common:SaveOrderReference')}
        </ActionButton.Save>
      </Container>
    </Container>
  );
};

export default OrderReference;
