import { Form, useWatch } from '@dagens/frontend-forms';
import { useTranslation } from '@dagens/frontend-i18n';
import { StructuredAddress } from '../../../types/Logistics';
import { BillingFormValues } from '.';

const addressEquals = (
  address1?: Partial<StructuredAddress> | string | null,
  address2?: Partial<StructuredAddress> | string | null
) => {
  if (!address1 || !address2) {
    return false;
  }
  if (typeof address1 === 'string' || typeof address2 === 'string') {
    return address1 === address2;
  }

  return (
    address1.addressLine1 === address2.addressLine1 &&
    address1.addressLine2 === address2.addressLine2 &&
    address1.city === address2.city &&
    address1.postalCode === address2.postalCode
  );
};

type BillingAddressFeedbackProps = {
  defaultBillingAddress?: StructuredAddress | string | null;
};

export const BillingAddressFeedback = ({
  defaultBillingAddress
}: BillingAddressFeedbackProps) => {
  const { t } = useTranslation();
  const billingAddress = useWatch<BillingFormValues>().billingAddress;

  if (
    !defaultBillingAddress ||
    !addressEquals(billingAddress, defaultBillingAddress)
  ) {
    return null;
  }

  return (
    <Form.FeedbackAlert>
      {t('producer:AddCustomer:retrievedFromCvrRegistry')}
    </Form.FeedbackAlert>
  );
};
