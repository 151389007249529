import { forSaleStatuses } from '@dagensmat/core';
import ProductForSaleCard from './ProductForSaleCard';
import ProductOnRequestCard from './ProductOnRequestCard';
import { useAppSelector } from '_common/hooks/reduxHooks';
import { ProductForSale } from 'types/Product';
import { isGuestConsumer } from 'utils/role';

type ProductCardProps = {
  product: ProductForSale;
  onClick: (id: string) => void;
};

const ProductCard = ({ product, onClick }: ProductCardProps) => {
  const { forSaleStatus, availableIn } = product;
  const { isGuest, isAuthenticated, isInSameDA, isProducer, isOwnShop } =
    useAppSelector(
      ({ auth: { _id, authenticated, distributionAreaId, _type } }) => {
        return {
          isGuest: Boolean(_id && isGuestConsumer(_id)),
          isAuthenticated: authenticated,
          isInSameDA:
            distributionAreaId !== undefined &&
            availableIn.includes(distributionAreaId),
          isProducer: _type === 'producers',
          isOwnShop: product.producer._id === _id
        };
      }
    );

  switch (forSaleStatus) {
    case forSaleStatuses.FOR_SALE:
      return (
        <ProductForSaleCard
          product={product}
          isGuest={isGuest}
          isAuthenticated={isAuthenticated}
          isInSameDA={isInSameDA}
          isProducer={isProducer}
          isOwnShop={isOwnShop}
          onClick={() => {
            onClick(product._id);
          }}
        />
      );
    case forSaleStatuses.ON_REQUEST:
      return (
        <ProductOnRequestCard
          product={product}
          isGuest={isGuest}
          isAuthenticated={isAuthenticated}
          isInSameDA={isInSameDA}
          isProducer={isProducer}
          isOwnShop={isOwnShop}
          onClick={() => {
            onClick(product._id);
          }}
        />
      );
    default:
      return null;
  }
};

export default ProductCard;
