import { useTranslation } from '@dagens/frontend-i18n';
import { PropsWithChildren } from 'react';
import { useLocation } from 'react-router-dom';

import { PageHeader } from '@dagens/carrot';
import ErrorMessage from '../../_common/components/error-message/ErrorMessage';
import Loader from '../../_common/components/loader/Loader';
import { useAppSelector } from '../../_common/hooks/reduxHooks';
import REQ, { combineReqs, ReqType } from '../../utils/REQ';

type Props = PropsWithChildren<{
  req?: ReqType | ReqType[];
}>;

// For use within a Page component only!
export const ContentLoader = ({ req, children }: Props) => {
  const { t } = useTranslation();
  const location = useLocation();
  const { userId, userRole } = useAppSelector(({ auth }) => ({
    userId: auth._id,
    userRole: auth._type
  }));

  const combinedStatus = Array.isArray(req) ? combineReqs(req) : req;

  switch (combinedStatus) {
    case REQ.INIT:
    case REQ.PENDING:
      return <Loader />;
    case REQ.NOT_FOUND:
      return (
        <PageHeader
          title={t('common:pageNotFound')}
          subtitle={t('common:goBack')}
        />
      );
    case REQ.ERROR:
      return (
        <ErrorMessage
          error={
            new Error('Page could not load - REQ.ERROR!', {
              cause: {
                userId,
                userRole,
                pathname: location.pathname,
                search: location.search
              }
            })
          }
        />
      );
    default:
      return <>{children}</>;
  }
};
