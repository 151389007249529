import {
  FormField as CarrotFormField,
  FormFieldProps as CarrotFormFieldProps
} from '@dagens/carrot';
import {
  FieldValues,
  get,
  Path,
  useFormContext,
  useFormState,
  useWatch
} from 'react-hook-form';
import { useTranslation } from '@dagens/frontend-i18n';
import { createContext, PropsWithChildren } from 'react';
import { getRequiredError, RequiredRule } from '../utils/validation';

type FormFieldContextValue<
  Form extends FieldValues,
  Name extends Path<Form>
> = {
  requireds?: RequiredRule<Form, Name>[];
  deps?: Path<Form>[];
};

export const FormFieldContext = createContext<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  FormFieldContextValue<any, any>
>({});
const FormFieldProvider = <Form extends FieldValues, Name extends Path<Form>>({
  requireds,
  deps,
  children
}: PropsWithChildren<FormFieldContextValue<Form, Name>>) => {
  return (
    <FormFieldContext.Provider value={{ requireds, deps }}>
      {children}
    </FormFieldContext.Provider>
  );
};

type BaseProps = Omit<
  CarrotFormFieldProps,
  'errorMessage' | 'valueLength' | 'optional'
>;

export type FormFieldProps<
  Form extends FieldValues,
  Name extends Path<Form>
> = BaseProps &
  (
    | {
        to: Name;
        required?: RequiredRule<Form, Name>;
        deps?: Path<Form>[];
      }
    | {
        to: Name[];
        required?: RequiredRule<Form, Name> | RequiredRule<Form, Name>[];
        deps?: Path<Form>[];
      }
  );

export const FormField = <Form extends FieldValues, Name extends Path<Form>>({
  to,
  required,
  deps,
  children,
  ...props
}: FormFieldProps<Form, Name>) => {
  const { t } = useTranslation();
  const { getValues } = useFormContext<Form>();
  const formValue = useWatch<Form>();

  const fields = Array.isArray(to) ? to : [to];
  const requireds = Array.isArray(required)
    ? required
    : required
      ? [required]
      : undefined;
  const value = fields.map(name => get(formValue, name));
  const firstValue = value.at(0);

  const { errors } = useFormState<Form>();
  const error = fields
    .map(name => get(errors, name))
    .filter(error => error)
    .at(0);

  const definedRequired = fields.some((field, i) =>
    Boolean(
      getRequiredError(
        get(formValue, field),
        requireds?.at(i),
        getValues,
        t('common:requiredField')
      )
    )
  );

  return (
    <FormFieldProvider requireds={requireds} deps={deps}>
      <CarrotFormField
        {...props}
        valueLength={
          props.maxLength !== undefined && typeof firstValue === 'string'
            ? firstValue.length
            : undefined
        }
        optional={definedRequired ? undefined : t('common:optional')}
        errorMessage={error?.message}
      >
        {children}
      </CarrotFormField>
    </FormFieldProvider>
  );
};
