import { Button, Box, PageHeader, useMediaQuery, Nav } from '@dagens/carrot';
import { useTranslation } from '@dagens/frontend-i18n';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { CountryCode } from '@dagensmat/core';
import NewRadioButtons from '../../../_common/components/radio-buttons/NewRadioButtons';
import { getHubsByCountry } from '../../../api';
import Logger from '../../../services/Logger';
import { DeliveryType, Hub } from '../../../types/Logistics';
import { useThrowError } from '../../../utils/errorHandling';
import REQ, { ReqType } from '../../../utils/REQ';
import { PlanService } from '../../../services/PlanService';
import DeliveryOption from './DeliveryOption';
import Container from '@carrot-deprecated/container';
import { Page } from '@components/page';
import { useAppSelector } from '_common/hooks/reduxHooks';

const getAvailableDistributionAreasFromHubs = (hubsByCountry: Hub[]) => {
  const distributionAreas: { id: string; name: string }[] = [];
  hubsByCountry.forEach(hub => {
    hub.routeOptions.forEach(routeOption => {
      if (
        !distributionAreas.some(area => {
          return area.id === routeOption.distributionAreaId;
        })
      ) {
        distributionAreas.push({
          id: routeOption.distributionAreaId,
          name: routeOption.distributionAreaName
        });
      }
    });
  });
  return distributionAreas;
};

const DeliveryRouteRequestPageStart = () => {
  const { desktopUp } = useMediaQuery();
  const [hubsByCountry, setHubsByCountry] = useState<Hub[]>([]);
  const [fetchHubsReq, setFetchHubsReq] = useState<ReqType>(REQ.INIT);
  const { t } = useTranslation();
  const throwAsyncError = useThrowError();
  const navigate = useNavigate();
  const [deliveryType, setDeliveryType] = useState<DeliveryType>();
  const [distributionAreaId, setDistributionAreaId] = useState<
    string | undefined | null
  >();
  const availableDistributionAreas =
    getAvailableDistributionAreasFromHubs(hubsByCountry);

  const { country, producerName, hasWebshop, hasLogistics } = useAppSelector(
    ({ auth }) => {
      return {
        country: auth.organization?.address.country,
        producerName: auth.name,
        hasWebshop: PlanService.hasFeature(auth.plan, 'WEBSHOP'),
        hasLogistics: PlanService.hasFeature(auth.plan, 'LOGISTICS_SERVICE')
      };
    }
  );

  const fetchHubsByCountry = async () => {
    if (!country) {
      return;
    }

    setFetchHubsReq(REQ.PENDING);
    await getHubsByCountry({ country })
      .then(res => {
        setFetchHubsReq(REQ.SUCCESS);
        setHubsByCountry(res);
      })
      .catch(e => {
        setFetchHubsReq(REQ.ERROR);
        Logger.error(e);
        throwAsyncError(e);
      });
  };

  const handleDeliveryTypeChange = (typeOrId: string | null | undefined) => {
    if (typeOrId === DeliveryType.SELF_DELIVERY) {
      setDeliveryType(DeliveryType.SELF_DELIVERY);
      setDistributionAreaId(undefined);
    } else {
      setDeliveryType(DeliveryType.DAGENS_DELIVERY);
      setDistributionAreaId(typeOrId);
    }
  };

  useEffect(() => {
    fetchHubsByCountry();
  }, []);

  useEffect(() => {
    if (!deliveryType && availableDistributionAreas.length > 0) {
      handleDeliveryTypeChange(availableDistributionAreas[0].id);
    }
  }, [availableDistributionAreas]);

  if (!hasWebshop) {
    return <Page.NotFound />;
  }

  if (country === CountryCode.DK || !hasLogistics) {
    navigate('/profile/delivery-route-request/self-delivery-form', {
      state: { deliveryType: DeliveryType.SELF_DELIVERY, returnTo: '/profile' }
    });
    return;
  }

  if (fetchHubsReq !== REQ.SUCCESS) {
    return <Page.Status req={fetchHubsReq} />;
  }

  if (!availableDistributionAreas) {
    return <Page.NotFound />;
  }

  const title = t('producer:DeliveryRouteRequest.PageHeader');
  const subtitle = producerName;

  return (
    <Page
      navMobileText={
        <>
          <Nav.ContextTitle>{title}</Nav.ContextTitle>
          <Nav.ContextSubtitle>{subtitle}</Nav.ContextSubtitle>
        </>
      }
      header={
        desktopUp && (
          <Box.FullWidth>
            <PageHeader title={title} subtitle={subtitle} />
          </Box.FullWidth>
        )
      }
      bottom={
        <Box.BottomSheet>
          <Button
            size="fullWidth"
            variant="primary"
            onClick={() => {
              navigate(
                `/profile/delivery-route-request${
                  deliveryType === DeliveryType.DAGENS_DELIVERY
                    ? '/hubs'
                    : '/self-delivery-form'
                }`,
                {
                  state: {
                    returnPath: true,
                    deliveryType,
                    distributionAreaId,
                    hubsByCountry
                  }
                }
              );
            }}
          >
            {t('producer:DeliveryRouteRequest.NextButtonStart')}
          </Button>
        </Box.BottomSheet>
      }
    >
      <Container mb="l">
        <NewRadioButtons
          labelText={t(
            'producer:DeliveryRouteRequest.RadioButtonHowAndWhereLabelText'
          )}
          radioGroupName="selectDeliveryType"
          currentValue={distributionAreaId || deliveryType}
          onChange={daId => {
            return handleDeliveryTypeChange(daId);
          }}
          options={availableDistributionAreas
            .map(da => {
              return {
                key: da.id,
                value: t(
                  'producer:DeliveryRouteRequest.RadioButtonDeliveryTypeOptionTo',
                  { distributionAreaName: da.name }
                ),
                explanationText: t(
                  'producer:DeliveryRouteRequest.RadioButtonDeliveryTypeDagensExplanationText'
                )
              };
            })
            .concat({
              key: DeliveryType.SELF_DELIVERY,
              value: t(
                'producer:DeliveryRouteRequest.RadioButtonDeliveryTypeOptionSelfDelivery'
              ),
              explanationText: t(
                'producer:DeliveryRouteRequest.RadioButtonDeliveryTypeSelfExplanationText'
              )
            })}
        />
      </Container>
      <Container gap="xs" mb="m">
        {deliveryType === DeliveryType.DAGENS_DELIVERY ? (
          <DeliveryOption
            deliveryTypesToShow={[DeliveryType.DAGENS_DELIVERY]}
          />
        ) : (
          <DeliveryOption deliveryTypesToShow={[DeliveryType.SELF_DELIVERY]} />
        )}
      </Container>
    </Page>
  );
};

export default DeliveryRouteRequestPageStart;
