import { useTranslation } from '@dagens/frontend-i18n';
import { formFieldFactory } from '@dagens/frontend-forms';
import { ProductFormValues } from '..';

const { Field, PeriodPicker } = formFieldFactory<ProductFormValues>();

export const ProductFormSoldOutDays = () => {
  const { t } = useTranslation();
  return (
    <PeriodPicker
      name="capacities"
      mergeOverlapping
      formField={({ children }) => (
        <Field
          to="capacities"
          label={t('producer:productSoldOutLabel')}
          helpText={t('producer:productSoldOutExplanation')}
        >
          {children}
        </Field>
      )}
    />
  );
};
