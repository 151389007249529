import { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ProducerProduct } from '../../../types/Product';
import { PricingFormValues } from '../../components/pricing-form';
import { formValuesToPricing } from '../../components/pricing-form/utils/to-pricing';
import {
  allCustomersOption,
  ConsumersWithChildren
} from '../../components/pricing-form/hooks/use-consumers';
import { useAppDispatch, useAppSelector } from '_common/hooks/reduxHooks';
import { productEdited as updateProductInStore } from '_producer/reducers/products';
import { postProductFieldsUpdate } from 'api';
import { ADD_OFFER_CLICK_SAVE_OFFER, track } from 'utils/mixpanel';
import REQ, { ReqType } from 'utils/REQ';

// Get customer names, ids, and count
// Or (ALL_CUSTOMERS, ALL_CUSTOMERS, 1) if the first option is selected
// Only the first option is checked because if ALL_CUSTOMERS is selected, it's the only option
const toTrackingData = (customers: ConsumersWithChildren[]) => {
  if (customers.at(0)?._id === allCustomersOption._id) {
    return {
      consumerNames: [allCustomersOption._id],
      consumerIds: [allCustomersOption._id],
      numberOfConsumers: 1
    };
  }

  const consumerNames = customers.map(({ name }) => name);
  const consumerIds = customers.map(({ _id }) => _id);
  const numberOfConsumers = customers.length;
  return { consumerNames, consumerIds, numberOfConsumers };
};

// Save Add pricing form
// Appends current price with to prices
// Sends updated product to the API
// Updates product in the store
// Sends tracking data to Mixpanel
export const useOnSave = ({ product }: { product?: ProducerProduct }) => {
  const dispatch = useAppDispatch();
  const [saveReq, setSaveReq] = useState<ReqType>(REQ.INIT);
  const { consumers } = useAppSelector(({ consumers: { items = [] } }) => {
    return { consumers: items };
  });
  const navigate = useNavigate();

  const onSave = useCallback(
    async (data: PricingFormValues) => {
      if (saveReq !== REQ.INIT || !product) {
        return;
      }

      const pricing = formValuesToPricing(data);
      try {
        setSaveReq(REQ.PENDING);
        const prices = [...product.prices, pricing];
        await postProductFieldsUpdate({
          productId: product._id,
          prices
        });
        dispatch(updateProductInStore({ ...product, prices }));
        setSaveReq(REQ.SUCCESS);
        track(ADD_OFFER_CLICK_SAVE_OFFER, toTrackingData(consumers));
        setTimeout(() => {
          navigate(-1);
        }, 2000);
      } catch (error) {
        setSaveReq(REQ.ERROR);
      }
    },
    [consumers, product]
  );

  return { onSave, saveReq };
};
