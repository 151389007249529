import { useMediaQuery } from '../../../../_common/hooks/useMediaQueries';
import { useAppSelector } from '../../../../_common/hooks/reduxHooks';
import { BottomNavConsumer } from './consumer';
import { BottomNavProducer } from './producer';

export const BottomNav = () => {
  const { role } = useAppSelector(({ auth: { _type: role } }) => ({ role }));
  const { desktopUp } = useMediaQuery();

  if (desktopUp) {
    return null;
  }

  if (role === 'consumers') {
    return <BottomNavConsumer />;
  }
  if (role === 'producers') {
    return <BottomNavProducer />;
  }

  return null;
};
