import { capitalize } from '@dagensmat/core';
import Container from '@carrot-deprecated/container';
import { Spacings } from '@carrot-deprecated/theme';
import { MarginProps } from '@carrot-deprecated/utils/margin-props';
import Typography from '@carrot-deprecated/typography';
import Button from '_common/components/button/Button.style';

export type UnitOption = {
  key: string;
  value: string;
  explanationText?: string;
  disabled?: boolean;
};

export const mapOptions = (options: UnitOption[]) => {
  return options.map(option => {
    return typeof option === 'string' ? { key: option, value: option } : option;
  });
};

type RadioButtonsProps = {
  headerText: string;
  unitOptions: UnitOption[];
  unit: string | null;
  unitChanged: (key: UnitOption['key']) => void;
  explanationText: string | null;
  useRawString?: boolean;
} & Pick<MarginProps, 'my'>;

const RadioButtons = ({
  headerText,
  unitOptions,
  unit,
  unitChanged,
  explanationText,
  my,
  useRawString = false
}: RadioButtonsProps) => {
  return (
    <Container my={my}>
      <Typography variant="inputLabel">{headerText}</Typography>
      <>
        {mapOptions(unitOptions).map(({ key, value }) => {
          return (
            <Button
              mr={Spacings.xxs}
              mt={Spacings.xs}
              onClick={() => {
                unitChanged(key);
              }}
              key={key}
              variant={unit === key ? 'secondary' : 'ghost'}
            >
              {useRawString ? value : capitalize(value)}
            </Button>
          );
        })}
      </>
      {explanationText && (
        <Typography variant="paragraphSmall" my="xs">
          {explanationText}
        </Typography>
      )}
    </Container>
  );
};

export default RadioButtons;
