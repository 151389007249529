import { useEffect, useState } from 'react';
import { useAppSelector } from '../../../../_common/hooks/reduxHooks';
import { RootState } from '../../../../store';
import { getCertifications } from '../../../../api';
import REQ, { ReqType } from '../../../../utils/REQ';
import { Certification } from '../../../../types/Product';

// Fetch available product certifications for the producer's country
export const useCertifications = () => {
  const [certifications, setCertifications] = useState<Certification[]>([]);
  const [req, setReq] = useState<ReqType>(REQ.INIT);

  const countryCode = useAppSelector(
    ({ auth: { organization } }: RootState) => {
      return organization ? organization.address.country : undefined;
    }
  );

  useEffect(() => {
    const fetchCertifications = async () => {
      setReq(REQ.PENDING);
      const certifications = await getCertifications({ countryCode });
      setCertifications(certifications);
      setReq(REQ.SUCCESS);
    };

    fetchCertifications();
  }, [countryCode]);

  return { certifications, req };
};
