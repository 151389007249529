import { Callout, Image } from '@dagens/carrot';
import { useTranslation } from '@dagens/frontend-i18n';

export const PricingFormTips = () => {
  const { t } = useTranslation();
  return (
    <Callout.Expandable title={t('producer:tipsForPricingProductsTitle')}>
      <Callout.Section text={t('producer:tipsForPricingProductsParagraph1')} />
      <Callout.Section
        subtitle={t('producer:tipsForPricingProductsHeader2')}
        text={t('producer:tipsForPricingProductsParagraph2')}
      />
      <Callout.Section
        subtitle={t('producer:tipsForPricingProductsHeader3')}
        text={t('producer:tipsForPricingProductsParagraph3')}
      />
      <Callout.Section
        subtitle={t('producer:tipsForPricingProductsHeader4')}
        text={t('producer:tipsForPricingProductsParagraph4')}
      />
      <Callout.Section
        subtitle={t('producer:tipsForPricingProductsHeader5')}
        text={t('producer:tipsForPricingProductsParagraph5')}
      />
      <Callout.Section
        subtitle={t('producer:tipsForPricingProductsHeader6')}
        text={t('producer:tipsForPricingProductsParagraph6')}
      />
      <Image src="/assets/TIPS_PRICING_EXAMPLE.jpg" alt="Pricing example" />
    </Callout.Expandable>
  );
};
