import { PropsWithChildren } from 'react';
import { tv } from '../../utils/tv';

const style = tv({
  base: `
    col-span-full
    grid
    grid-cols-subgrid
    border-y-thin
    border-y-black
  `
});

type Props = PropsWithChildren;

export const Foot = ({ children }: Props) => {
  return <thead className={style()}>{children}</thead>;
};
