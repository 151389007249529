import { PropsWithChildren } from 'react';
import { tv } from '../../../utils/tv';

const style = tv({
  base: `
    text-paragraphSmall
    text-secondary
  `
});

type Props = PropsWithChildren;

export const InfoSectionItemTerm = ({ children }: Props) => {
  return <dt className={style()}>{children}</dt>;
};
