import { FC, PropsWithChildren } from 'react';
import Typography from '@carrot-deprecated/typography';

export const FormFieldHelpText: FC<PropsWithChildren> = ({ children }) => {
  return (
    <Typography variant="paragraphSmall" color="secondary">
      {children}
    </Typography>
  );
};
