import i18next from 'i18next';
import { tr } from '@dagens/frontend-i18n';

export const routesProducers = [
  { path: '/', title: tr(i18next, 'common:Home'), icon: 'home' },
  {
    path: '/profile',
    title: tr(i18next, 'consumer:MyProfile'),
    icon: 'profile'
  },
  {
    path: '/billing-account',
    title: tr(i18next, 'common:BillingAccountHeader'),
    icon: 'profile'
  },
  {
    path: '/delivery-information',
    title: tr(i18next, 'common:DeliveryInformation'),
    icon: 'profile'
  },
  {
    path: '/producers',
    title: tr(i18next, 'common:header.producers'),
    icon: 'profile'
  },
  { path: '/orders', title: tr(i18next, 'common:MyOrders'), icon: 'orders' },
  {
    path: '/orders/overview',
    title: `${tr(i18next, 'common:MyOrders')}, ${tr(i18next, 'common:Product_other')}`,
    icon: 'orders'
  },
  {
    path: '/orders/history',
    title: tr(i18next, 'common:OrderHistory'),
    icon: 'orders'
  },
  {
    path: '/orders/product-requests',
    title: `${tr(i18next, 'common:MyOrders')}, ${tr(i18next, 'consumer:Requests')}`,
    icon: 'messages'
  },
  { path: '/basket', title: tr(i18next, 'consumer:Checkout'), icon: 'basket' },
  {
    path: '/switch-role',
    title: tr(i18next, 'common:header.switchRole'),
    icon: 'profile'
  },
  {
    path: '/favourites',
    title: tr(i18next, 'consumer:MyFavorites'),
    icon: 'favorite'
  }
] as const;
