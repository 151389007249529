import heic2any from 'heic2any';
import {
  ChangeEvent,
  ForwardedRef,
  forwardRef,
  ReactNode,
  useId,
  useRef
} from 'react';
import { tv } from '../../utils/tv';

const style = tv({
  slots: {
    input: 'sr-only',
    label: `
      inline-block
      cursor-pointer
    `
  }
});

export type ImageInputProps = {
  trigger: ReactNode;
  onChange: (file: File, reader: string | null) => void;
};

const Component = (
  { trigger, onChange }: ImageInputProps,
  ref: ForwardedRef<HTMLInputElement>
) => {
  const id = useId();
  const labelRef = useRef<HTMLLabelElement>(null);
  const { input, label } = style();

  const convertPossibleHeicFile = async (file: File) => {
    if (file.type === 'image/heic') {
      const resultBlob = await heic2any({
        blob: file,
        toType: 'image/jpeg',
        quality: 0.3
      });
      return new File([resultBlob as BlobPart], 'image.jpeg', {
        type: 'image/jpeg'
      });
    }
    return file;
  };

  const handleChange = async (e: ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const inputFile = e.target.files?.[0];
    if (!inputFile) {
      return;
    }

    const reader = new FileReader();
    const file = await convertPossibleHeicFile(inputFile);
    reader.onloadend = () => {
      onChange?.(file, reader.result as string | null);
    };

    reader.readAsDataURL(file);
  };

  return (
    <>
      <input
        onChange={handleChange}
        id={id}
        type="file"
        accept="image/*"
        alt="product-image"
        ref={ref}
        className={input()}
      />
      <div>
        <label htmlFor={id} className={label()} ref={labelRef}>
          {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
          <div
            onClick={() => {
              labelRef.current?.click();
            }}
          >
            {trigger}
          </div>
        </label>
      </div>
    </>
  );
};

export const ImageInput = forwardRef(Component);
