import { useFormContext } from '@dagens/frontend-forms';
import { useTranslation } from '@dagens/frontend-i18n';
import { BottomActions } from '@dagens/carrot';
import REQ, { ReqType } from '../../../../utils/REQ';
import { AddCustomerFormValues } from '../types';

type Props = {
  saveReq: ReqType;
  onSubmit: (data: AddCustomerFormValues) => void;
};

export const DeliveryBottomActions = ({ onSubmit, saveReq }: Props) => {
  const { t } = useTranslation();
  const { handleSubmit } = useFormContext<AddCustomerFormValues>();

  if (saveReq === REQ.PENDING) {
    return (
      <BottomActions.Feedback>
        {t('producer:AddCustomer.creatingCustomer')}
      </BottomActions.Feedback>
    );
  }

  if (saveReq === REQ.ERROR) {
    return (
      <BottomActions.Feedback error>
        {t('producer:AddCustomer.errorCreatingCustomer')}
      </BottomActions.Feedback>
    );
  }

  if (saveReq === REQ.SUCCESS) {
    return (
      <BottomActions.Feedback success>
        {t('producer:AddCustomer.customerCreated')}
      </BottomActions.Feedback>
    );
  }

  return (
    <>
      <BottomActions.Cancel to="/customers">
        {t('common:Cancel')}
      </BottomActions.Cancel>
      <BottomActions.Submit onClick={handleSubmit(onSubmit)}>
        {t(
          'producer:AddCustomer.reviewDeliveryInformationButtonCreateCustomerText'
        )}
      </BottomActions.Submit>
    </>
  );
};
