import { useTranslation } from '@dagens/frontend-i18n';
import { useMediaQuery } from '@dagens/carrot';
import Typography from '@carrot-deprecated/typography';

type Props = {
  isReadOnly?: boolean;
};

const OrderLineTableHeader = ({ isReadOnly }: Props) => {
  const { t } = useTranslation();
  const { mobileOnly } = useMediaQuery();
  return (
    <thead>
      <tr>
        <th>
          <Typography variant="paragraphSmallBold" color="secondary">
            {t('common:Product_one')}
          </Typography>
        </th>
        <th>
          <Typography variant="paragraphSmallBold" color="secondary">
            {t('common:Quantity')}
          </Typography>
        </th>
        {!mobileOnly && (
          <th>
            <Typography variant="paragraphSmallBold" color="secondary">
              {t('common:Price')}
            </Typography>
          </th>
        )}
        <th className="!text-right">
          <Typography variant="paragraphSmallBold" color="secondary">
            {t('common:Total')}
          </Typography>
        </th>
        {!isReadOnly && <th></th>}
      </tr>
    </thead>
  );
};

export default OrderLineTableHeader;
