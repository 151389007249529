import { Nav } from '@dagens/carrot';
import { useTranslation } from '@dagens/frontend-i18n';
import { MenuLogout, MenuSecondaryItems } from './shared';

export const MenuAuth = () => {
  const { t } = useTranslation();
  return (
    <Nav.Menu heading={t('common:Menu')}>
      <MenuSecondaryItems />
      <Nav.MenuDivider />
      <MenuLogout />
    </Nav.Menu>
  );
};
