import { ReactNode } from 'react';
import Typography from '@carrot-deprecated/typography';
import Container from '@carrot-deprecated/container';
import { ColourNames } from '@carrot-deprecated/theme/colours';
import Icon from '_common/components/utils/Icon';

type FormValidationMessageProps = {
  message: ReactNode;
  fill?: ColourNames;
};

const FormValidationMessage = ({
  message,
  fill
}: FormValidationMessageProps) => {
  return (
    <Container
      flex
      wrap="nowrap"
      alignItems="flex-start"
      justify="flex-start"
      mt="xxs"
    >
      <Container mr="xxs">
        <Icon icon="alert" $size="m" fill={fill} />
      </Container>
      <Typography variant="paragraph" as="span">
        {message}
      </Typography>
    </Container>
  );
};

export default FormValidationMessage;
