import { tv } from '../../../utils/tv';

const style = tv({
  base: `
    text-secondaryHeading
    text-black
  `
});

type Props = {
  text: string;
};

export const InfoSectionTitle = ({ text }: Props) => {
  return <h2 className={style()}>{text}</h2>;
};
