import { PropsWithChildren } from 'react';
import { tv } from '../../utils/tv';

const style = tv({
  base: `
    w-full
    truncate
    text-center
    text-paragraphSmall
    text-secondary
  `
});

type Props = PropsWithChildren;

export const NavContextSubtitle = ({ children }: Props) => {
  return <div className={style()}>{children}</div>;
};
