export type PlanFeatures = {
  ORDER_AND_CUSTOMER_MANAGEMENT: boolean;
  PRODUCT_INVENTORY_MANAGEMENT: boolean;
  AUTOMATIC_INVOICING: boolean;
  PICKING_AND_PACKING_DOCUMENTS: boolean;
  TRADE_DATA: boolean;
  SUPPORT: boolean;
  COMPLIANCE_REPORTS: boolean;
  MARKET_ACCESS: boolean;
  WEBSHOP: boolean;
  PRICING_INSIGHTS: boolean;
  IN_PLATFORM_MESSAGING: boolean;
  LARGE_BUYER_ACCESS: boolean;
  MARKETING_AND_PROMOTION: boolean;
  ADVANCED_TRADE_REPORTS: boolean;
  SIMPLIFIED_ACCOUNTING: boolean;
  PAYMENT_CERTAINTY: boolean;
  ACCOUNTING_INTEGRATION: boolean;
  LOGISTICS_SERVICE: boolean;
  GUIDED_ONBOARDING: boolean;
};

export type Plan = {
  _id: string;
  name: string;
  description: string;
  features: PlanFeatures;
  price: number;
  transactionFee: number;
};

export const PlanService = {
  hasFeature: (plan: Plan | undefined, feature: keyof PlanFeatures) => {
    return !plan || Boolean(plan.features[feature]);
  }
};
