import { tv } from '../../../utils/tv';

const style = tv({
  slots: {
    container: `mb-s`,
    subtitle: `text-paragraphSmallBold`,
    text: `
      whitespace-pre-line
      text-paragraphSmall
    `
  }
});

type Props = {
  subtitle?: string;
  text?: string;
};

export const CalloutSection = ({ subtitle, text }: Props) => {
  const { container, subtitle: subtitleStyle, text: textStyle } = style();
  return (
    <div className={container()}>
      {subtitle && <div className={subtitleStyle()}>{subtitle}</div>}
      {text && <div className={textStyle()}>{text}</div>}
    </div>
  );
};
