import styled from 'styled-components';
import Theme from '@carrot-deprecated/theme';

export const FooterContainer = styled.div`
  width: 100%;
`;

export const SystemFeedbackContainer = styled.div`
  text-align: center;
  padding: ${Theme.Spacings.xs + Theme.Spacings.xxs}px 0;
`;
