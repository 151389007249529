import { ChangeEventHandler, FC } from 'react';

import { FormFieldInputProps } from '../form-field/types';
import { InputStyles } from './styles';

type Props = FormFieldInputProps & {
  placeholder?: string | null;
  value?: string;
  maxLength?: number;
  onChange?: ChangeEventHandler<HTMLInputElement>;
};

export const TextInput: FC<Props> = ({
  placeholder,
  value,
  onChange,
  showError,
  invalid,
  maxLength
}) => {
  return (
    <InputStyles
      type="text"
      value={value}
      placeholder={placeholder ?? undefined}
      maxLength={maxLength}
      onChange={onChange}
      showError={invalid && showError}
    />
  );
};

export type TextInputProps = Props;
