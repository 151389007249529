import { Form, formFieldFactory } from '@dagens/frontend-forms';
import { useTranslation } from '@dagens/frontend-i18n';
import { validateEmail } from '../../../utils/validation';
import { NameFeedback } from './name-feedback';

export type ContactFormValues = {
  customerName: string | null;
  contactPersonName: string | null;
  contactPhone: string | null;
  notificationEmail: string | null;
};

const { Field, Input } = formFieldFactory<ContactFormValues>();

type Props = {
  defaultCustomerName?: string | null;
};

export const CustomerContactForm = ({ defaultCustomerName }: Props) => {
  const { t } = useTranslation();
  return (
    <Form.Section>
      <Field
        to="customerName"
        label={t(
          'producer:AddCustomer.reviewContactInformationFieldCustomerNameLabel'
        )}
        helpText={t(
          'producer:AddCustomer:reviewContactInformationFieldCustomerNameDescription'
        )}
        feedback={<NameFeedback defaultCustomerName={defaultCustomerName} />}
        required
      >
        {/* eslint-disable-next-line jsx-a11y/no-autofocus */}
        <Input name="customerName" autoFocus />
      </Field>

      <Field
        to="contactPersonName"
        label={t(
          'producer:AddCustomer.reviewContactInformationFieldContactPersonFullNameLabel'
        )}
        required
      >
        <Input name="contactPersonName" />
      </Field>

      <Field
        to="contactPhone"
        label={t(
          'producer:AddCustomer.reviewContactInformationFieldContactPersonPhoneLabel'
        )}
        required
      >
        <Input name="contactPhone" type="tel" />
      </Field>

      <Field
        to="notificationEmail"
        label={t(
          'producer:AddCustomer.reviewContactInformationFieldNotificationEmailLabel'
        )}
        helpText={t(
          'producer:AddCustomer:reviewContactInformationFieldNotificationEmailDescription'
        )}
        required
      >
        <Input
          name="notificationEmail"
          validate={value => {
            const valid = value !== null && validateEmail(value);
            return valid;
          }}
          type="email"
        />
      </Field>
    </Form.Section>
  );
};
