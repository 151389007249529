import { Form } from '@dagens/frontend-forms';
import { createPortal } from 'react-dom';
import { Pricing, ProducerProduct } from '../../../types/Product';
import { ProductFormDeleteButton as Delete } from './parts/delete-button';
import { ProductFormFields as Fields } from './parts/fields';
import { ProductEditFormPageTitle as PageTitle } from './parts/title';
import { ProductFormValues } from '.';

export type ProductFormPricesValues = {
  prices: Pricing[];
};

type Props = {
  product: ProducerProduct;
  onDelete: () => void;
  onSubmit: (data: ProductFormValues & ProductFormPricesValues) => void;
  titleContainer: HTMLSpanElement | null;
};

// The prices are edited in a separate form in when editing a product
export const ProductEditForm = ({
  product,
  onDelete,
  onSubmit,
  titleContainer
}: Props) => {
  return (
    <Form onSubmit={onSubmit}>
      {/* The page title changes with the product name, so it needs the form context */}
      {titleContainer && createPortal(<PageTitle />, titleContainer)}

      <Fields flow="edit" product={product} />

      <Delete onDelete={onDelete} />
    </Form>
  );
};
