import { ReactNode, useState } from 'react';
import { tv } from '../../../utils/tv';
import { FormFieldExplanation } from './explanation';
import { FormFieldLabel } from './label';
import { FormFieldOptional } from './optional';
import { FormFieldExplanationToggle } from './explanation-toggle';

const style = tv({
  base: `
    flex
    items-end
    gap-xs
  `
});

type Props = {
  label: ReactNode;
  optional?: string;
  explanation?: string;
  small?: boolean;
};

export const LabelContainer = ({
  explanation,
  label,
  optional,
  small
}: Props) => {
  const [explanationOpen, setExplanationOpen] = useState(false);
  return (
    <>
      <div className={style()}>
        <FormFieldLabel small={small}>{label}</FormFieldLabel>
        {optional && <FormFieldOptional>{optional}</FormFieldOptional>}
        {explanation && (
          <FormFieldExplanationToggle
            open={explanationOpen}
            onClick={() => setExplanationOpen(o => !o)}
          />
        )}
      </div>
      {explanation && explanationOpen && (
        <FormFieldExplanation>{explanation}</FormFieldExplanation>
      )}
    </>
  );
};
