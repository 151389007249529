import { useTranslation } from '@dagens/frontend-i18n';
import ProductReference from '../product-reference/ProductReference';
import Typography from '../../../carrot-deprecated/typography';
import { formatNok2Decimals } from '../../../utils/texts';
import {
  DELIVERY_FEE_LINE_CLASS,
  DeliveryFeeLine,
  ProductLine
} from './ProductsInBasket.style';
import Theme from '@carrot-deprecated/theme';
import EzPicker from '_common/components/picker/EzPicker';
import { ProductWithBasket } from '_consumer/reducers/productsForSale';

type ProductProps = {
  product: ProductWithBasket;
  date: string;
};

const Product = ({ product, date }: ProductProps) => {
  return (
    <ProductLine>
      <ProductReference
        product={product}
        variant="small"
        mr={Theme.Spacings.xs}
      />
      <EzPicker
        product={product}
        selectedDate={date}
        minValue={0}
        variant="small"
      />
    </ProductLine>
  );
};

type DeliveryFeeProps = {
  producerDeliveryFee: number;
};

const DeliveryFee = ({ producerDeliveryFee }: DeliveryFeeProps) => {
  const { t } = useTranslation();
  return (
    <DeliveryFeeLine className={DELIVERY_FEE_LINE_CLASS}>
      <Typography variant="paragraphSmall" mr="xxs" as="span">
        {t('consumer:ProducerDeliveryFee')}
      </Typography>
      <Typography variant="paragraphSmall">
        {formatNok2Decimals(producerDeliveryFee)}
      </Typography>
    </DeliveryFeeLine>
  );
};

type ProductsInBasketProps = {
  products: ProductWithBasket[];
  deliveryDate: string;
  producerDeliveryFee?: number;
};

const ProductsInBasket = ({
  products,
  deliveryDate,
  producerDeliveryFee
}: ProductsInBasketProps) => {
  return (
    <>
      {products.map(product => {
        return (
          <Product
            key={`${product._id}`}
            product={product}
            date={deliveryDate}
          />
        );
      })}
      {producerDeliveryFee && (
        <DeliveryFee producerDeliveryFee={producerDeliveryFee} />
      )}
    </>
  );
};

export default ProductsInBasket;
