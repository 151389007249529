import styled from 'styled-components';
import { DeliveryType } from '../../../types/Logistics';
import Theme from '../../../carrot-deprecated/theme';
import { MediaQuery } from '../../../utils/mediaQueries';
import OrderLineTableBody from './OrderLineTableBody';
import OrderLineTableFooter from './OrderLineTableFooter';
import OrderLineTableHeader from './OrderLineTableHeader';
import { getTotalOrderPrice } from '_common/reducers/orders';
import { UpdateOrderDeliveryFeePayload, UpdateOrderLinePayload } from 'api';
import { OrderLine, OrderProducerDeliveryFee } from 'types/Order';

export const GridTable = styled.table<{ isReadOnly: boolean }>`
  display: grid;
  width: 100%;
  border-collapse: collapse;
  grid-template-columns: ${({ isReadOnly }) =>
    isReadOnly ? `4fr 2fr 2fr 1fr` : `4fr 2fr 2fr 1fr max-content`};
  gap: 0 ${Theme.Spacings.xxs}px;

  ${MediaQuery.mobileOnly} {
    grid-template-columns: ${({ isReadOnly }) =>
      isReadOnly ? `3fr 2fr 1fr` : `3fr 2fr 1fr max-content`};
  }

  tr {
    display: grid;
    grid-column: 1 / -1;
    grid-template-columns: subgrid;
    border-bottom: 1px solid ${Theme.Colours.lightGrey};
    padding: 0 ${Theme.Spacings.xs}px;
  }

  th,
  td {
    text-align: left;
    padding: ${Theme.Spacings.xs}px 0;
    word-break: break-word;
  }

  thead {
    display: contents;
    tr {
      th {
        color: ${Theme.Colours.secondary};
        text-transform: capitalize;
      }
    }
  }

  tbody {
    display: contents;
    tr:hover {
      background-color: ${Theme.Colours.brightPurple};
    }
  }

  tfoot {
    display: contents;
  }
`;

type Props = {
  orderId: string;
  orderLines: OrderLine[];
  originalOrderLines?: OrderLine[];
  orderDeliveryFee?: OrderProducerDeliveryFee;
  orderDeliveryType: DeliveryType;
  isReadOnly?: boolean;
  isOrderAdjustment?: boolean;
  isProducerOrder?: boolean;
  onUpdateOrder?: () => void;
  onSaveOrderLine?: (payload: UpdateOrderLinePayload) => Promise<void> | void;
  onSaveDeliveryFee?: (
    orderId: string,
    payload: UpdateOrderDeliveryFeePayload
  ) => Promise<void> | void;
  addingDeliveryFee?: boolean;
  onFinishAddingDeliveryFee?: (value: boolean) => void;
};

const OrderLineTable = ({
  orderId,
  orderLines,
  originalOrderLines,
  orderDeliveryFee,
  orderDeliveryType,
  isReadOnly = false,
  isOrderAdjustment,
  isProducerOrder,
  onUpdateOrder,
  onSaveOrderLine,
  onSaveDeliveryFee,
  addingDeliveryFee,
  onFinishAddingDeliveryFee
}: Props) => {
  return (
    <GridTable isReadOnly={isReadOnly}>
      <OrderLineTableHeader />
      <OrderLineTableBody
        orderId={orderId}
        orderLines={orderLines}
        originalOrderLines={originalOrderLines}
        orderDeliveryFee={orderDeliveryFee}
        orderDeliveryType={orderDeliveryType}
        isReadOnly={isReadOnly}
        isOrderAdjustment={isOrderAdjustment}
        isProducerOrder={isProducerOrder}
        onUpdateOrder={onUpdateOrder}
        onSaveOrderLine={onSaveOrderLine}
        onSaveDeliveryFee={onSaveDeliveryFee}
        addingDeliveryFee={addingDeliveryFee}
        onFinishAddingDeliveryFee={onFinishAddingDeliveryFee}
      />
      <OrderLineTableFooter
        originalTotalPrice={getTotalOrderPrice(
          originalOrderLines || orderLines,
          orderDeliveryFee?.valueAtTimeOfOrder
        )}
        totalPrice={getTotalOrderPrice(
          orderLines,
          orderDeliveryFee?.finalValue
        )}
      />
    </GridTable>
  );
};

export default OrderLineTable;
