import { PropsWithChildren } from 'react';
import { tv } from '../../utils/tv';

const style = tv({
  base: `
    flex
    flex-wrap
    items-center
    gap-xxs
  `
});

type Props = PropsWithChildren;

export const ButtonGroupWrap = ({ children }: Props) => {
  return <div className={style()}>{children}</div>;
};
