import { useTranslation } from '@dagens/frontend-i18n';
import {
  roundNumber,
  CombinedUnit,
  PackedUnit,
  type UnitType
} from '@dagensmat/core';
import Typography from '@carrot-deprecated/typography';
import { Pricing } from 'types/Product';
import { B } from 'utils/fonts';
import { isSimplePricing } from 'utils/product-pricing';

type PricingTextProps = {
  pricing?: Pricing;
  prices?: Pricing[];
};

const isPackedUnitOrPcs = (unit: UnitType) => {
  return (
    unit === PackedUnit.crate ||
    unit === PackedUnit.package ||
    unit === PackedUnit.tray ||
    unit === CombinedUnit.pcs
  );
};

const shouldShowKgPricing = (pricing: Pricing) => {
  return (
    pricing.inputMeasureUnit &&
    pricing.inputMeasureUnit === 'kg' &&
    isPackedUnitOrPcs(pricing.orderedUnit) &&
    isSimplePricing(pricing)
  );
};

const PricingText = ({ pricing, prices }: PricingTextProps) => {
  if (pricing === undefined) {
    return null;
  }

  const {
    inputMeasureUnitValue,
    pricedUnitsPerOrderedUnit,
    pricedUnit,
    nokPerPricedUnit,
    unitSizeDescription
  } = pricing;

  const ratio = roundNumber(pricedUnitsPerOrderedUnit);
  const price = roundNumber(nokPerPricedUnit);

  const { t } = useTranslation();
  return (
    <div>
      {prices && prices.length > 1 ? (
        <Typography variant="paragraphSmall">
          {t('producer:MultipleOffers')}
        </Typography>
      ) : (
        <>
          <Typography variant="paragraphSmall">
            {!isSimplePricing(pricing) && (
              <>
                <B>{`${ratio} ${t(`units:${pricedUnit}`, {
                  count: ratio
                })}`}</B>
                {` á `}
              </>
            )}
            <B>{price} kr</B>
            {` per ${t(`units:${pricedUnit}`, { count: 1 })}`}
            {shouldShowKgPricing(pricing) && inputMeasureUnitValue
              ? ` (${roundNumber(price / inputMeasureUnitValue)} kr/kg)`
              : null}
          </Typography>
          {unitSizeDescription && (
            <Typography variant="paragraphSmall" color="secondary">
              {unitSizeDescription}
            </Typography>
          )}
        </>
      )}
    </div>
  );
};

export default PricingText;
