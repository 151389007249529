import { Combobox as HeadlessCombobox } from '@headlessui/react';
import { ByComparator } from '@dagens/utils';
import { ComboboxCustomOptions } from './parts/options';
import { ComboboxInput } from './parts/input';
import { useComboboxState } from './use-state';

export type ComboboxProps<T> = {
  placeholder?: string;
  error?: boolean;
  loading?: boolean;
  options: T[] | null;
  emptyOptionsText: string;
  disabledOptions?: T[];
  displayValue?: (value?: T) => string | undefined;
  by?: ByComparator<T>;

  value: T;
  onChange: (value: T) => void;
  onInputChange?: (value: string) => void;
  autoFocus?: boolean;
};

export const Combobox = <T,>({
  error,
  loading,
  placeholder,
  value,
  options,
  emptyOptionsText,
  disabledOptions,
  onChange,
  onInputChange,
  displayValue,
  by,
  autoFocus
}: ComboboxProps<T>) => {
  const { query, setQuery, valueToString, onChangeInternal, resetQuery } =
    useComboboxState<T>({
      displayValue,
      onChange
    });

  return (
    <HeadlessCombobox
      immediate
      value={value}
      onChange={onChangeInternal}
      onClose={resetQuery}
      by={by}
    >
      {({ open }) => (
        <div className="relative">
          <ComboboxInput
            error={error}
            open={open}
            value={value}
            placeholder={placeholder}
            loading={loading}
            valueToString={valueToString}
            onChange={v => {
              setQuery(v);
              onInputChange?.(v);
            }}
            // eslint-disable-next-line jsx-a11y/no-autofocus
            autoFocus={autoFocus}
          />
          <ComboboxCustomOptions
            options={options}
            emptyOptionsText={emptyOptionsText}
            disabledOptions={disabledOptions}
            value={value}
            valueToString={valueToString}
            by={by}
            query={query}
          />
        </div>
      )}
    </HeadlessCombobox>
  );
};
