import { useTranslation } from '@dagens/frontend-i18n';
import { find } from 'lodash';
import { useParams } from 'react-router-dom';
import { Box, PageHeader } from '@dagens/carrot';
import { Form } from '@dagens/frontend-forms';
import { ProducerProduct } from '../../../types/Product';
import { PricingForm } from '../../components/pricing-form';
import { PricingFormSubmitButton as Submit } from '../../components/pricing-form/parts/submit-button';
import { useOnSave } from './use-on-save';
import { useDefaultValue } from './use-default-value';
import { useAppSelector } from '_common/hooks/reduxHooks';
import usePageView from '_common/hooks/usePageView';
import { Page } from '@components/page';
import { ADD_OFFER_PAGE_VIEW } from 'utils/mixpanel';
import REQ from 'utils/REQ';
import { formatProductNameAndType } from 'utils/texts';

type Props = {
  product?: ProducerProduct;
};

const AddPricingPage = ({ product }: Props) => {
  usePageView(ADD_OFFER_PAGE_VIEW);

  const { t } = useTranslation();
  const { onSave, saveReq } = useOnSave({ product });
  const defaultValue = useDefaultValue();

  if (!product) {
    return null;
  }

  return (
    <Form.Provider defaultValue={defaultValue}>
      <Page
        bottom={
          <Box.BottomSheet>
            <Submit saveReq={saveReq} onSubmit={onSave} />
          </Box.BottomSheet>
        }
      >
        <PageHeader
          title={t('producer:AddPricingHeader')}
          subtitle={formatProductNameAndType(product)}
        />
        <Box.FormWrapper>
          <PricingForm
            defaultValue={defaultValue}
            productCategoryId={product.categoryId}
            otherPrices={product.prices}
            onSubmit={onSave}
          />
        </Box.FormWrapper>
      </Page>
    </Form.Provider>
  );
};

const AddPricingPageFetcher = () => {
  const { id } = useParams();
  const { product, req } = useAppSelector(
    ({ producerProducts: { items, req } }) => {
      const product = find(items, { _id: id });

      return {
        req: req === REQ.SUCCESS && !product ? REQ.NOT_FOUND : req,
        product
      };
    }
  );

  if (req !== REQ.SUCCESS) {
    return <Page.Status req={req} />;
  }

  return <AddPricingPage product={product} />;
};

export default AddPricingPageFetcher;
