import { useTranslation } from '@dagens/frontend-i18n';
import styled from 'styled-components';
import { postOrderStatuses } from '../../../api';
import Typography from '@carrot-deprecated/typography';
import Theme from '@carrot-deprecated/theme';
import { Order } from 'types/Order';
import { MediaQuery } from 'utils/mediaQueries';

import ConfirmButton from '_common/components/confirm-button/ConfirmButton';

const Container = styled.div`
  border-top: 1px solid ${Theme.Colours.lightGrey};
  padding: ${Theme.Spacings.m}px 0;

  ${MediaQuery.print} {
    display: none;
  }
`;

type CancelOrderProps = {
  order: Order;
  onCancel: () => void;
};

const CancelOrder = ({ order, onCancel }: CancelOrderProps) => {
  const { t } = useTranslation();
  const { _id: orderId, statuses: { cancelled, deliveredToConsumer } = {} } =
    order;

  const onConfirm = async () => {
    await postOrderStatuses({ orderIds: [orderId], status: 'cancelled' });
    onCancel();
  };

  if (cancelled || deliveredToConsumer) return null;
  return (
    <Container>
      <Typography variant="paragraphBold" as="p">
        {t('producer:CancelOrder')}
      </Typography>
      <Typography variant="paragraphSmall" my="s" color="secondary">
        {t('producer:IfYouHaveAgreedWithCustomerToCancelDoSoAsSoonAsPossible')}
      </Typography>
      <ConfirmButton
        confirmText={t('producer:SureYouWantToCancelThisOrder')}
        confirmedText={t('producer:OrderWasCancelled')}
        buttonText={t('producer:CancelTheOrder')}
        rejectButtonText={t('common:NoWait')}
        confirmButtonText={t('common:YesIAmSure')}
        buttonVariant="error"
        onConfirm={onConfirm}
      />
    </Container>
  );
};

export default CancelOrder;
