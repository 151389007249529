import { useTranslation } from '@dagens/frontend-i18n';
import React from 'react';
import { PageHeader } from '@dagens/carrot';
import { DeliveryWindow } from '../../../types/Consumer';
import { Illustrations } from '../../../utils/Illustrations';
import { StructuredAddress } from '../../../types/Logistics';
import Container from '@carrot-deprecated/container';
import Typography from '@carrot-deprecated/typography';
import { Page } from '@components/page';
import EditDeliveryInformationRedirector from '_common/pages/deliveryInformation/EditDeliveryInformationRedirector';

type OrderConfirmationProps = {
  headerText: string;
  subTitle: string;
  deliveryAddress: StructuredAddress;
  deliveryWindow?: DeliveryWindow;
  deliveryInfo?: string;
  deliveryDateCount: number;
};

const OrderConfirmation = ({
  headerText,
  subTitle,
  deliveryAddress,
  deliveryWindow,
  deliveryInfo,
  deliveryDateCount
}: OrderConfirmationProps) => {
  const { t } = useTranslation();

  const deliveryCountRef = React.useRef(deliveryDateCount);

  return (
    <Page>
      <PageHeader title={headerText} />
      <Typography variant="paragraph" mb="l">
        {subTitle}
      </Typography>
      <Typography variant="paragraphSmall" color="secondary" mb="s">
        {t('consumer:YourOrderWillBeDeliveredTo', {
          count: deliveryCountRef.current || 1
        })}
      </Typography>
      <EditDeliveryInformationRedirector
        deliveryAddress={deliveryAddress}
        deliveryWindow={deliveryWindow}
        deliveryInfo={deliveryInfo}
        redirectTo="/orders"
        showHeader={false}
      />
      <Container flex justify="end">
        <img
          alt="Illustrasjon av bestillingsbekreftelse med masse godt i kassen"
          style={{
            height: 'auto',
            maxWidth: '360px',
            width: '100%',
            display: 'block',
            margin: '20px 0'
          }}
          src={Illustrations.CUSTOMER_CARRYING_ONION_GREEN}
        />
      </Container>
    </Page>
  );
};

export default OrderConfirmation;
