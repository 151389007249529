import { PropsWithChildren } from 'react';
import { tv } from 'tailwind-variants';
import { Icon } from '../../../theme/icon';

const style = tv({
  base: `
    flex
    h-xl
    w-full
    items-center
    justify-center
    gap-xxs
    text-paragraph
  `,
  variants: {
    standalone: {
      true: `justify-start`
    },
    error: {
      true: `text-error`
    }
  }
});

type Props = PropsWithChildren<{
  error?: boolean;
  success?: boolean;
  standalone?: boolean;
}>;

export const BottomActionsFeedback = ({
  error,
  success,
  standalone,
  children
}: Props) => {
  return (
    <div className={style({ error, standalone })}>
      {success && <Icon icon="success" color="success" />}
      {children}
    </div>
  );
};
