import { useTranslation } from '@dagens/frontend-i18n';
import LinkListElement from '_common/components/link-list-element/LinkListElement';
import { orderKindIsOrder } from '_common/reducers/orders';
import { OrderKind } from 'types/Order';
import { getDateShort } from 'utils/date/format';

const getLinkProps = (orderKind: OrderKind) => {
  const { t } = useTranslation();

  if (orderKindIsOrder(orderKind)) {
    return {
      pathname: `/orders/${orderKind.secret}`,
      header: `${orderKind.consumer.name} (${orderKind.orderNumberString})`,
      subTitle: `${t('common:Delivered')} ${getDateShort(
        orderKind.deliveryDate
      )}`
    };
  }
  return {
    pathname: `/orders/${orderKind.adjustedOrder.secret}`,
    header: `${t('common:AdjustmentFor')} ${orderKind.consumer.name} (${
      orderKind.orderNumberString
    })`,
    subTitle: `${t('common:AdjustedOrder')} ${
      orderKind.adjustedOrder.orderNumberString
    } ${getDateShort(orderKind._createdAt)}`
  };
};

type OrderHistoryLinkProps = {
  order: OrderKind;
};

export const OrderHistoryLink = ({ order }: OrderHistoryLinkProps) => {
  const { pathname, header, subTitle } = getLinkProps(order);
  return (
    <LinkListElement
      key={order._id}
      to={pathname}
      state={{ returnPath: true }}
      header={header}
      subTitle={subTitle}
    />
  );
};
