import { forwardRef, HTMLProps, Ref } from 'react';
import { FormFieldInputProps } from 'carrot-deprecated/form-field/types';

type Props = Omit<HTMLProps<HTMLInputElement>, 'onChange'> &
  FormFieldInputProps & {
    onChange: (value: number) => void;
  };

const Component = (
  { onChange, ...rest }: Props,
  ref: Ref<HTMLInputElement>
) => {
  return (
    <input
      type="tel"
      pattern="d*"
      maxLength={4}
      onChange={({ target: { value: newValue } }) => {
        onChange(Number(newValue.replace(/\D/g, '')));
      }}
      ref={ref}
      onClick={e => {
        return e.stopPropagation();
      }}
      {...rest}
    />
  );
};

export const IntegerInput = forwardRef(Component);
