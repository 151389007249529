import { Box } from '../../layout/box';
import { BottomActionsCancel } from './parts/cancel';
import { BottomActionsFeedback } from './parts/feedback';
import { BottomActionsSubmit } from './parts/submit';

export const BottomActions = Object.assign(Box.BottomSheet, {
  Cancel: BottomActionsCancel,
  Submit: BottomActionsSubmit,
  Feedback: BottomActionsFeedback
});
