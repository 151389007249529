import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { ColorValue } from 'theme';
import margins, { MarginProps } from 'utils/style';

export default styled(Link)<
  {
    align?: string;
    color?: ColorValue;
  } & MarginProps
>`
  display: block;
  text-align: ${({ align = 'right' }) => {
    return align;
  }};
  color: ${({ color }) => {
    return color;
  }};
  ${margins}
`;
