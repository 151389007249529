import { PropsWithChildren } from 'react';
import { tv } from '../../../utils/tv';

const style = tv({
  base: `
    flex
    items-center
    gap-xxs
  `,
  variants: {
    twoColumns: {
      true: `
        grid
        grid-cols-[1fr,1fr]
        items-center
        gap-xxs
      `
    },
    numberWithSelect: {
      true: `
        grid
        grid-cols-[110px,150px]
        items-center
        gap-xxs
      `
    },
    withExternalFeedback: {
      true: `
        flex
        flex-col
        items-start
        !gap-s
      `
    }
  }
});

type Props = PropsWithChildren<{
  numberWithSelect?: boolean;
  twoColumns?: boolean;
  withExternalFeedback?: boolean;
}>;

export const FormFieldGroup = ({
  numberWithSelect,
  twoColumns,
  withExternalFeedback,
  children
}: Props) => {
  return (
    <div
      className={style({ numberWithSelect, twoColumns, withExternalFeedback })}
    >
      {children}
    </div>
  );
};
