import { MenuItem } from './presets/menu-item';
import { ModalAction } from './presets/modal-action';
import { NavIcon } from './presets/nav-icon';
import { LinkComponent } from './components';

export const Link = Object.assign(LinkComponent, {
  MenuItem,
  ModalAction,
  NavIcon
});
