import { ForwardedRef, forwardRef } from 'react';
import { tv } from '../../../utils/tv';

import { ButtonComponentInternal, ButtonComponentProps } from '../components';
import { ButtonStyleProps } from '../styles';

export const navIconStyle = tv({
  base: `
    size-xl
    items-center
    justify-center
    !p-0
    text-black
  `
});

type Props = Omit<
  ButtonComponentProps,
  ButtonStyleProps | 'className' | 'type'
>;

const Component = (props: Props, ref: ForwardedRef<HTMLButtonElement>) => {
  return (
    <ButtonComponentInternal
      {...props}
      ref={ref}
      variant="borderless"
      size="regular"
      className={navIconStyle()}
    />
  );
};

export const NavIcon = forwardRef(Component);
