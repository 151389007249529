import { createTV } from 'tailwind-variants';
import { themeColors } from '../theme/colors';
import { themeBorderWidth } from '../theme/border-width';
import { themeFontSize } from '../theme/typography';

// The theme object needs to be specified to handle conflicts
// between classes with the same prefix but different properties.
// Like `border-{color}` and `border-{width}`
// https://github.com/dcastil/tailwind-merge/blob/v2.2.2/docs/configuration.md#theme
export const tv = createTV({
  twMergeConfig: {
    extend: {
      theme: {
        colors: Object.keys(themeColors),
        borderWidth: Object.keys(themeBorderWidth)
      }
    },
    override: {
      classGroups: {
        'font-size': [
          { text: [...Object.keys(themeFontSize), 'link', 'allCaps'] }
        ]
      }
    }
  }
});
