import {
  ButtonHTMLAttributes,
  forwardRef,
  PropsWithChildren,
  Ref
} from 'react';
import { twMerge } from 'tailwind-merge';
import { Simplify } from 'type-fest';

import { buttonStyle, ButtonStyles } from '../styles';

type ButtonProps = Simplify<ButtonHTMLAttributes<HTMLButtonElement>>;

export type ButtonComponentProps = Simplify<
  PropsWithChildren<
    ButtonStyles & {
      type?: ButtonProps['type'];
      className?: ButtonProps['className'];
      disabled?: ButtonProps['disabled'];
      tabIndex?: ButtonProps['tabIndex'];
      onClick?: ButtonProps['onClick'];
    }
  >
>;

const InternalComponent = (
  {
    alignment,
    fillParent,
    focused,
    size,
    variant,
    color,

    // react props
    type,
    className,
    children,
    disabled,
    tabIndex,
    onClick
  }: ButtonComponentProps,
  ref: Ref<HTMLButtonElement>
) => {
  return (
    <button
      type={type ?? 'button'}
      ref={ref}
      className={twMerge(
        buttonStyle({
          alignment,
          fillParent,
          focused,
          size,
          variant,
          color
        }),
        className
      )}
      disabled={disabled}
      onClick={onClick}
      tabIndex={tabIndex}
    >
      {children}
    </button>
  );
};

export const ButtonComponentInternal = forwardRef(InternalComponent);

const Component = (
  props: Omit<ButtonComponentProps, 'className'>,
  ref: Ref<HTMLButtonElement>
) => {
  return <ButtonComponentInternal {...props} ref={ref} />;
};

export const ButtonComponent = forwardRef(Component);
