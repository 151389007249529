import { PropsWithChildren } from 'react';
import { twMerge } from 'tailwind-merge';

type Props = PropsWithChildren<{
  className: string;
}>;

export const Table = ({ className, children }: Props) => {
  return <table className={twMerge(className, 'grid')}>{children}</table>;
};
