import {
  definedNumber,
  MeasureUnit,
  ScaledMeasureUnit,
  Unit
} from '@dagensmat/core';
import { uuid } from '@sanity/uuid';
import { some } from 'lodash';
import { SetOptional } from 'type-fest';
import { ProductWithBasket } from '../_consumer/reducers/productsForSale';
import { ALL_CUSTOMERS } from '../config';
import { EditablePricing, Pricing } from 'types/Product';
import { SanityRef } from 'types/Sanity';

type PricingLike = SetOptional<EditablePricing, 'isSimplePricing'>;

const VALID_INPUT_MEASURE_UNITS = [
  ...Object.values(MeasureUnit),
  ...Object.values(ScaledMeasureUnit)
];

export const isMeasureUnitValid = ({
  inputMeasureUnit,
  inputMeasureUnitValue
}: Pick<
  EditablePricing | Pricing,
  'inputMeasureUnit' | 'inputMeasureUnitValue'
>) => {
  const numberValue = Number(inputMeasureUnitValue);
  return (
    inputMeasureUnit &&
    VALID_INPUT_MEASURE_UNITS.includes(inputMeasureUnit) &&
    numberValue > 0
  );
};

const isPricing = (price: PricingLike): price is Pricing => {
  return (
    typeof price.nokPerPricedUnit !== 'string' &&
    typeof price.pricedUnitsPerOrderedUnit !== 'string'
  );
};

export const isEditablePricing = (
  price: Pricing | EditablePricing
): price is EditablePricing => {
  return typeof (price as EditablePricing).isSimplePricing !== 'undefined';
};

export const pricingComplete = (price: Pricing | EditablePricing) => {
  if (!isPricing(price)) return false;

  const {
    pricedUnit,
    orderedUnit,
    nokPerPricedUnit,
    pricedUnitsPerOrderedUnit
  } = price;
  return (
    !!pricedUnit &&
    !!orderedUnit &&
    definedNumber(nokPerPricedUnit) &&
    nokPerPricedUnit >= 0 &&
    definedNumber(pricedUnitsPerOrderedUnit) &&
    pricedUnitsPerOrderedUnit > 0
  );
};

// Remove the ALL_CUSTOMERS special consumer from the list of special consumers
// This is done because the ALL_CUSTOMERS special consumer is necessary to specify if the value
// is set in the form, but it should not be passed to the backend
const stripAllCustomers = (specialConsumers?: SanityRef[]) => {
  return specialConsumers?.filter(consumer => {
    return consumer._key !== ALL_CUSTOMERS;
  });
};

export const convertPricing = (editablePrice: PricingLike): Pricing => {
  if (!isPricing(editablePrice)) {
    throw new Error('You can not convert an incomplete price');
  }

  const {
    _type,
    _key,
    availableTo,
    pricedUnit,
    nokPerPricedUnit,
    orderedUnit,
    pricedUnitsPerOrderedUnit,
    inputMeasureUnit,
    inputMeasureUnitValue,
    unitSizeDescription,
    specialConsumers
  } = editablePrice;

  return {
    _type,
    _key,
    availableTo,
    pricedUnit,
    nokPerPricedUnit,
    orderedUnit,
    pricedUnitsPerOrderedUnit,
    unitSizeDescription,
    specialConsumers: stripAllCustomers(specialConsumers),
    ...(isMeasureUnitValid(editablePrice) && {
      inputMeasureUnitValue,
      inputMeasureUnit
    })
  };
};

export const convertPrices = (prices: (Pricing | EditablePricing)[]) => {
  return prices.map(convertPricing);
};

export const isSimplePricing = (pricing: Pricing) => {
  return pricing.pricedUnit === pricing.orderedUnit;
};

export const makeEditable = (
  pricing: Pricing | EditablePricing
): EditablePricing => {
  if (isEditablePricing(pricing)) {
    return pricing;
  }

  const {
    _key,
    _type,
    availableTo,
    pricedUnit,
    nokPerPricedUnit,
    orderedUnit,
    pricedUnitsPerOrderedUnit,
    unitSizeDescription,
    inputMeasureUnit = Unit.kg,
    inputMeasureUnitValue,
    specialConsumers
  } = pricing;

  return {
    _key,
    _type,
    availableTo,
    pricedUnit,
    nokPerPricedUnit,
    orderedUnit,
    pricedUnitsPerOrderedUnit,
    unitSizeDescription,
    inputMeasureUnit,
    inputMeasureUnitValue,
    specialConsumers,
    isSimplePricing: isSimplePricing(pricing)
  };
};

const findDefaultConsumerPrice = (prices: Pricing[]) => {
  return prices.find(({ specialConsumers }) => {
    return (
      !specialConsumers ||
      specialConsumers.length < 1 ||
      specialConsumers.every(consumer => {
        return consumer._ref === ALL_CUSTOMERS;
      })
    );
  });
};

const isSpecialConsumer = (pricing: Pricing, consumerId?: string) => {
  return some(pricing.specialConsumers, { _ref: consumerId });
};

export const getCustomOfferForConsumer = (
  prices: Pricing[] | undefined,
  consumerId?: string
) => {
  return prices?.find(productPricing => {
    return isSpecialConsumer(productPricing, consumerId);
  });
};

export const findRightConsumerPrice = (
  prices: Pricing[],
  consumerId?: string
) => {
  return (
    getCustomOfferForConsumer(prices, consumerId) ||
    findDefaultConsumerPrice(prices)
  );
};

export const getUniqueConsumers = (prices: Pricing[]): SanityRef[] => {
  return Array.from(
    new Set(
      prices
        .flatMap(({ specialConsumers }) => {
          return specialConsumers;
        })
        .filter((item): item is SanityRef => {
          return item !== undefined;
        })
    )
  );
};

export const isCustomOffer = (pricing: Pricing): boolean => {
  const { specialConsumers } = pricing;
  return Boolean(specialConsumers && specialConsumers.length > 0);
};

export const hasCustomOffer = (prices: Pricing[]): boolean => {
  return prices.some(isCustomOffer);
};

export const isRegularPrice = (pricing: Partial<Pricing | EditablePricing>) => {
  const { specialConsumers = [] } = pricing;
  return (
    !specialConsumers ||
    specialConsumers.every(consumer => {
      return consumer._ref === ALL_CUSTOMERS;
    }) ||
    specialConsumers.length === 0
  );
};

export const basketContainsCustomOffer = (
  productsInBasket: ProductWithBasket[]
) => {
  return productsInBasket.some(({ hasSpecialPricing }) => {
    return hasSpecialPricing;
  });
};

export const getInitialPricing = (): EditablePricing => {
  return {
    _type: 'productPricing',
    _key: uuid(),
    availableTo: ['CONSUMERS'],
    pricedUnit: Unit.kg,
    nokPerPricedUnit: '',
    orderedUnit: Unit.crate,
    pricedUnitsPerOrderedUnit: '',
    unitSizeDescription: '',
    isSimplePricing: false,
    inputMeasureUnit: Unit.kg,
    inputMeasureUnitValue: 1
  };
};
