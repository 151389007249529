import { useEffect, useState } from 'react';
import { Icon } from '@dagens/carrot';
import { useTranslation } from '@dagens/frontend-i18n';
import { range } from 'lodash';
import { DeliveryWindow, ValidTimeSlot } from '../../types/Consumer';
import SelectWithLabel from '../../_common/components/select/SelectWithLabel';
import Container from '@carrot-deprecated/container';
import Typography from '@carrot-deprecated/typography';

const getTimeSlot = (hour: number) => {
  return hour < 10 ? `0${hour}:00` : `${hour.toString()}:00`;
};

const getTimeSlots = (min: number, max: number): ValidTimeSlot[] => {
  const hours = range(min, max + 1, 1);
  const timeSlotToString = hours.map(hour => {
    return getTimeSlot(hour);
  });
  return timeSlotToString as ValidTimeSlot[];
};

const parseTimeSlot = (timeslot: string) => {
  return parseInt(timeslot.split(':')[0], 10);
};

type Props = {
  deliveryWindow: DeliveryWindow;
  earliestDeliveryWindowHour: number;
  latestDeliveryWindowHour: number;
  minDeliveryWindowDuration: number;
  onDeliveryWindowChange: (deliveryWindow: DeliveryWindow) => void;
  onErrorChange?: (hasError: boolean) => void;
};

/**
 * @package
 */
export const DeliveryWindowField = ({
  deliveryWindow,
  earliestDeliveryWindowHour,
  latestDeliveryWindowHour,
  minDeliveryWindowDuration,
  onDeliveryWindowChange,
  onErrorChange
}: Props) => {
  const [error, setError] = useState<string | null>(null);
  const { t } = useTranslation();
  const { start, end } = deliveryWindow;
  const timeSlots = getTimeSlots(
    earliestDeliveryWindowHour,
    latestDeliveryWindowHour
  );

  useEffect(() => {
    if (start > end) {
      setError(
        t(
          'common:deliveryInformationFormFieldDeliveryWindowErrorStartBeforeEnd'
        )
      );
      onErrorChange && onErrorChange(true);
    } else if (
      parseTimeSlot(end) - parseTimeSlot(start) <
      minDeliveryWindowDuration
    ) {
      setError(
        t(
          'common:deliveryInformationFormFieldDeliveryWindowErrorTooShortDuration'
        )
      );
      onErrorChange && onErrorChange(true);
    } else {
      setError(null);
      onErrorChange && onErrorChange(false);
    }
  }, [deliveryWindow]);

  return (
    <div>
      <Typography variant="inputLabel">
        {t('common:deliveryInformationFormFieldDeliveryWindowLabel')}
      </Typography>
      <Typography variant="paragraphSmall" color="secondary">
        {t('common:deliveryInformationFormFieldDeliveryWindowDescription', {
          minimumHours: minDeliveryWindowDuration
        })}
      </Typography>
      <Container flex direction="row" wrap="nowrap" gap="s">
        <Container>
          <SelectWithLabel
            labelText={t(
              'common:deliveryInformationFormFieldDeliveryWindowFromLabel'
            )}
            labelType="helpText"
            options={timeSlots.map(timeSlot => {
              return { value: timeSlot, name: timeSlot };
            })}
            selectedValue={start}
            onChange={(newValue: string) => {
              onDeliveryWindowChange({ start: newValue as ValidTimeSlot, end });
            }}
          />
        </Container>
        <Container>
          <SelectWithLabel
            labelText={t(
              'common:deliveryInformationFormFieldDeliveryWindowToLabel'
            )}
            labelType="helpText"
            options={timeSlots.map(timeSlot => {
              return { value: timeSlot, name: timeSlot };
            })}
            selectedValue={end}
            onChange={newValue => {
              onDeliveryWindowChange({ end: newValue as ValidTimeSlot, start });
            }}
          />
        </Container>
      </Container>
      {/* TODO: Create this into feedback comp */}
      {error && (
        <Typography variant="paragraph" mt="s">
          <Icon color="error" icon="alert" />
          {error}
        </Typography>
      )}
    </div>
  );
};
