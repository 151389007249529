import {
  Textarea as CarrotTextarea,
  type TextareaProps as CarrotTextareaProps
} from '@dagens/carrot';
import { FieldValues } from 'react-hook-form';
import { MatchingKeys } from '../types/fields';
import { FieldProps, useField } from '../utils/use-field';

// Fields from the Carrot component that are handled by react-hook-form
type ExcludedFields = 'error' | 'value' | 'onChange';

export type TextareaProps<
  Form extends FieldValues,
  Name extends MatchingKeys<Form, string | null>
> = Omit<CarrotTextareaProps, ExcludedFields> & FieldProps<Form, Name>;

export const Textarea = <
  Form extends FieldValues,
  Name extends MatchingKeys<Form, string | null>
>({
  name,
  required,
  validate,
  shouldUnregister,
  disabled,
  ...props
}: TextareaProps<Form, Name>) => {
  const {
    field,
    fieldState: { error }
  } = useField({
    name,
    required,
    validate,
    shouldUnregister,
    disabled
  });

  const stringToValue = (s: string) => (s === '' ? null : s);
  const valueToString = (v: string | null) => (v === null ? '' : v);

  return (
    <CarrotTextarea
      error={Boolean(error)}
      onChange={e => field.onChange(stringToValue(e.target.value))}
      value={valueToString(field.value)}
      {...props}
    />
  );
};
