import { Dialog, DialogBackdrop } from '@headlessui/react';
import { PropsWithChildren, useEffect } from 'react';
import { tv } from 'tailwind-variants';

// eslint-disable-next-line tailwindcss/no-custom-classname
const style = tv({
  slots: {
    dialog: `
      relative
      z-20
    `,
    backdrop: `
      fixed
      inset-0
      bg-lightGrey/25
      transition-opacity

      data-[closed]:opacity-0

      data-[enter]:duration-300
      data-[enter]:ease-out

      data-[leave]:duration-200
      data-[leave]:ease-in
    `,
    content: `
      fixed
      inset-0
      z-10
      w-screen
      overflow-y-auto
      p-m
    `
  }
});

type Props = PropsWithChildren<{
  open: boolean;
  onOpen: () => void;
  onClose: () => void;
}>;

export const CommandPaletteModal = ({
  open,
  children,
  onOpen,
  onClose
}: Props) => {
  const { dialog, backdrop, content } = style();
  useEffect(() => {
    const attachShortcut = (event: KeyboardEvent) => {
      if (
        (event.key === 'k' && event.metaKey) ||
        (event.key === 'k' && event.ctrlKey)
      ) {
        event.preventDefault();
        onOpen();
      }
    };
    document.addEventListener('keydown', attachShortcut);
    return () => {
      document.removeEventListener('keydown', attachShortcut);
    };
  }, []);

  return (
    <Dialog
      transition
      className={dialog()}
      open={open}
      onClose={() => {
        onClose();
      }}
    >
      <DialogBackdrop transition className={backdrop()} />
      <div className={content()}>{children}</div>
    </Dialog>
  );
};
