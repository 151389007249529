import { formFieldFactory } from '@dagens/frontend-forms';
import { useTranslation } from '@dagens/frontend-i18n';
import { useCertifications } from '../hooks/use-certifications';
import { ProductFormValues } from '..';
import Loader from '../../../../_common/components/loader/Loader';

const { Field, Checkboxes } = formFieldFactory<ProductFormValues>();

export const ProductFormCertifications = () => {
  const { t } = useTranslation();
  const { certifications, req: certificationsReq } = useCertifications();

  if (certificationsReq !== 'SUCCESS') {
    return <Loader />;
  }

  return (
    <Field
      to="certifications"
      label={t('producer:productCertificationsLabelText')}
    >
      <Checkboxes
        name="certifications"
        options={certifications}
        variant="button"
        displayValue={certification =>
          certification && t(`common:${certification.displayNameKey}`)
        }
        by="_id"
      />
    </Field>
  );
};
