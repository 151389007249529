import { OrderUnit } from '@dagens/utils';
import { usePricingToString } from '../../../_producer/components/pricing-form/hooks/use-pricing-to-string';
import Typography from '@carrot-deprecated/typography';
import { ColourNames } from '@carrot-deprecated/theme/colours';
import { EditablePricing, Pricing } from 'types/Product';
import {
  convertPricing,
  isEditablePricing,
  pricingComplete
} from 'utils/product-pricing';

type PricingDisplayProps = {
  price?: Pricing | EditablePricing;
  previousPrice?: Pricing | EditablePricing;
  color?: ColourNames;
};
const PricingDisplay = ({
  price,
  previousPrice,
  color = 'black'
}: PricingDisplayProps) => {
  const parsedPrice =
    price && isEditablePricing(price) ? convertPricing(price) : price;
  const parsedPreviousPrice =
    previousPrice && isEditablePricing(previousPrice)
      ? convertPricing(previousPrice)
      : previousPrice;

  const {
    orderedUnit,
    nokPerPricedUnit,
    pricedUnitsPerOrderedUnit,
    pricedUnit
  } = parsedPrice ?? {};

  const {
    orderedUnit: previousOrderedUnit,
    nokPerPricedUnit: previousNokPerPricedUnit,
    pricedUnitsPerOrderedUnit: previousPricedUnitsPerOrderedUnit,
    pricedUnit: previousPricedUnit
  } = parsedPreviousPrice ?? {};

  const priceString = usePricingToString(
    nokPerPricedUnit,
    pricedUnit,
    pricedUnitsPerOrderedUnit,
    orderedUnit as OrderUnit,
    pricedUnit === orderedUnit
  );

  const previousPriceString = usePricingToString(
    previousNokPerPricedUnit,
    previousPricedUnit,
    previousPricedUnitsPerOrderedUnit,
    previousOrderedUnit as OrderUnit,
    previousPricedUnit === previousOrderedUnit
  );

  if (!price || !pricingComplete(price)) return null;

  return (
    <>
      <Typography variant="paragraphSmall" color={color}>
        {priceString}
      </Typography>
      {previousPriceString && priceString !== previousPriceString && (
        <Typography
          variant="paragraphSmall"
          style={{ textDecoration: 'line-through' }}
          color={color}
        >
          {previousPriceString}
        </Typography>
      )}
    </>
  );
};

export default PricingDisplay;
