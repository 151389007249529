import { Button, ButtonGroup, DateRange } from '@dagens/carrot';
import { useTranslation } from '@dagens/frontend-i18n';
import { DateUtils, LocaleUtils } from '@dagens/utils';

type Props = {
  activePeriod: DateRange | undefined;
  onClickReset: () => void;
  onClickAdd: () => void;
};

export const PeriodPickerActionsAdd = ({
  activePeriod,
  onClickReset,
  onClickAdd
}: Props) => {
  const { t, i18n } = useTranslation();

  return (
    <ButtonGroup.Wrap>
      <Button variant="ghost" onClick={onClickReset}>
        {t('common:reset')}
      </Button>
      <Button
        variant="primary"
        onClick={onClickAdd}
        disabled={!activePeriod || !activePeriod.from || !activePeriod.to}
      >
        {t('producer:productSeasonCalendarSaveButton1', {
          period: DateUtils.periodToString(activePeriod, {
            locale: LocaleUtils.localeFromLanguage(i18n.language)
          })
        })}
      </Button>
    </ButtonGroup.Wrap>
  );
};
