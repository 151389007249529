import { Icon, InfoSection, Link } from '@dagens/carrot';
import { useTranslation } from '@dagens/frontend-i18n';
import { AddressUtils } from '@dagens/utils';
import { Consumer } from '../../../../types/Consumer';

const { Title, Items, Term, Details } = InfoSection;

type Props = {
  consumer: Consumer | undefined;
};

export const DeliveryInfo = ({ consumer }: Props) => {
  const { t } = useTranslation();
  return (
    <InfoSection>
      <Title text={t('common:DeliveryInformation')} />
      <Items>
        <div>
          <Term>{t('common:DeliveryAddress')}</Term>
          <Details>
            {AddressUtils.toString(consumer?.structuredDeliveryAddress)}
          </Details>
        </div>
        <div>
          <Term>
            {t('common:deliveryInformationFormFieldDeliveryWindowLabel')}
          </Term>
          <Details>
            {consumer?.deliveryWindow.start} – {consumer?.deliveryWindow.end}
          </Details>
        </div>
        <div>
          <Term>{t('producer:EditCustomerDeliveryInstructions')}</Term>
          <Details secondary={!consumer?.deliveryInfo}>
            {consumer?.deliveryInfo ?? t('common:None')}
          </Details>
        </div>
      </Items>
      <div>
        <Link variant="ghost" to={`/customers/${consumer?._id}/delivery`}>
          <Icon icon="edit" />
          {t('producer:EditCustomerDeliveryInformation')}
        </Link>
      </div>
    </InfoSection>
  );
};
