import { css } from 'styled-components';
import BorderWeights, { BorderWeightNames } from '../theme/border-weights';
import Colours, { ColourNames } from '../theme/colours';
import BorderRadius, { BorderRadiusNames } from '../theme/border-radius';

export type BorderProps = {
  borderWidth?: BorderWeightNames;
  borderColor?: ColourNames;
  borderRadius?: BorderRadiusNames;
};

const Borders = css<BorderProps>`
  ${({ borderWidth, borderColor, borderRadius }) => {
    return (
      (borderWidth || borderColor || borderRadius) &&
      `
      border-style: solid;
      border-width: ${BorderWeights['regular']}px;
      `
    );
  }}
  ${({ borderWidth }) => {
    return borderWidth && `border-width: ${BorderWeights[borderWidth]}px;`;
  }};
  ${({ borderColor = 'black' }) => {
    return borderColor && `border-color: ${Colours[borderColor]};`;
  }};
  ${({ borderRadius }) => {
    return borderRadius && `border-radius: ${BorderRadius[borderRadius]}px;`;
  }}
`;

export default Borders;
