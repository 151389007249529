import { DateUtils } from '@dagens/utils';
import { tv } from '../../../utils/tv';

const style = tv({
  base: `
    text-allCaps
    contents
  `
});

export const SeasonCalendarHeader = () => {
  const months = DateUtils.getMonths();
  return (
    <div className={style()}>
      {months.map(month => (
        <div key={month}>{month.at(0)?.toLocaleUpperCase()}</div>
      ))}
    </div>
  );
};
