import { getTotalOrderPrice } from '../../../../_common/reducers/orders';
import Logger from '../../../../services/Logger';
import { OrderLine } from '../../../../types/Order';
import { ProductForSale } from '../../../../types/Product';
import {
  CREATE_ORDER_FLOW_CLICK_PLACE_ORDER,
  track
} from '../../../../utils/mixpanel';
import { getCustomOfferForConsumer } from '../../../../utils/product-pricing';
import { ProductWithUnit } from '../../add-orderline/ProductList';

export const trackOrderFlowPlaceOrder = (
  orderLines: OrderLine[],
  selectedProducts: ProductWithUnit[],
  consumerId: string | undefined,
  consumerName: string | undefined,
  products: ProductForSale[],
  deliveryFeePrice?: number
) => {
  try {
    const orderTotal = getTotalOrderPrice(orderLines, deliveryFeePrice);
    const containsCustomOffer = selectedProducts.some(selectedProduct => {
      const product = products.find(p => {
        return p._id === selectedProduct.productId;
      });
      return getCustomOfferForConsumer(product?.prices, consumerId);
    });
    track(CREATE_ORDER_FLOW_CLICK_PLACE_ORDER, {
      numProducts: orderLines.length,
      containsCustomOffer,
      orderTotal,
      consumerName
    });
  } catch (error) {
    Logger.error(error as Error);
  }
};
