import { da } from 'date-fns/locale/da';
import { nb } from 'date-fns/locale/nb';
import { enUS } from 'date-fns/locale/en-US';
import { AvailableLanguage, AvailableLanguages } from './language';

const localeFromLanguage = (language?: AvailableLanguage | string) => {
  switch (language) {
    case AvailableLanguages.NORWEGIAN:
      return nb;
    case AvailableLanguages.DANISH:
      return da;
    case AvailableLanguages.ENGLISH:
      return enUS;
    default:
      return enUS;
  }
};

export const LocaleUtils = {
  da,
  enUS,
  nb,
  localeFromLanguage
};

export { type Locale } from 'date-fns';
