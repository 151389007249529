import { Form, useWatch } from '@dagens/frontend-forms';
import { useTranslation } from '@dagens/frontend-i18n';
import { ContactFormValues } from '.';

type NameFeedbackProps = {
  defaultCustomerName?: string | null;
};

export const NameFeedback = ({ defaultCustomerName }: NameFeedbackProps) => {
  const { t } = useTranslation();
  const customerName = useWatch<ContactFormValues>().customerName;

  if (!defaultCustomerName || customerName !== defaultCustomerName) {
    return null;
  }

  return (
    <Form.FeedbackAlert>
      {t('producer:AddCustomer:retrievedFromCvrRegistry')}
    </Form.FeedbackAlert>
  );
};
