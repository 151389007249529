import Typography from '@carrot-deprecated/typography';
import Callout from '@carrot-deprecated/callout';
import { SpacingNames } from '@carrot-deprecated/theme/spacings';

type OnboardingMessageProps = {
  message: string;
  showStep: boolean;
  mt?: SpacingNames;
  mb?: SpacingNames;
};

const InContextOnboardingMessage = ({
  message,
  showStep,
  mt,
  mb = 's'
}: OnboardingMessageProps) => {
  if (!showStep) {
    return null;
  }

  return (
    <Callout colour="noteworthy" mb={mb} mt={mt}>
      <Typography variant="paragraph">{message}</Typography>
    </Callout>
  );
};
export default InContextOnboardingMessage;
