import { useTranslation } from '@dagens/frontend-i18n';
import { Box, PageHeader } from '@dagens/carrot';
import { Form } from '@dagens/frontend-forms';
import { ProductForm } from '../../components/product-form';
import { RootState } from '../../../store';
import Loader from '../../../_common/components/loader/Loader';
import { ProductFormSubmitButton as Submit } from '../../components/product-form/parts/submit-button';
import { useOnSave } from './use-on-save';
import { useDefaultValue } from './use-default-value';
import { useAppSelector } from '_common/hooks/reduxHooks';
import usePageView from '_common/hooks/usePageView';
import { Page } from '@components/page';
import { ADD_PRODUCT_PAGE_VIEW } from 'utils/mixpanel';

export const AddProductPage = () => {
  usePageView(ADD_PRODUCT_PAGE_VIEW);

  const { t } = useTranslation();
  const { onSave, saveReq } = useOnSave();
  const { defaultValue, req } = useDefaultValue();
  const producerName = useAppSelector(({ auth: { name } }: RootState) => name);

  return (
    <Form.Provider defaultValue={defaultValue}>
      <Page
        bottom={
          <Box.BottomSheet>
            <Submit flow="create" saveReq={saveReq} onSubmit={onSave} />
          </Box.BottomSheet>
        }
      >
        <PageHeader
          title={t('producer:productAddPageHeader')}
          subtitle={t('producer:productAddNewFromProducer', {
            producerName
          })}
        />
        <Box.FormWrapper>
          {req !== 'SUCCESS' ? (
            <Loader />
          ) : (
            <ProductForm.Create defaultValue={defaultValue} onSubmit={onSave} />
          )}
        </Box.FormWrapper>
      </Page>
    </Form.Provider>
  );
};
