import styled from 'styled-components';
import Theme from '@carrot-deprecated/theme';

export const DELIVERY_FEE_LINE_CLASS = 'delivery-fee-line';

export const ProductLine = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: baseline;
  border-bottom: 1px solid ${Theme.Colours.lightGrey};
  &:has(+ .${DELIVERY_FEE_LINE_CLASS}) {
    border-bottom: 1px solid ${Theme.Colours.black};
  }
`;

export const DeliveryFeeLine = styled(ProductLine)`
  padding: ${Theme.Spacings.xs}px 0;
  border-bottom: 1px solid ${Theme.Colours.black};
`;
