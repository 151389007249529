import { useTranslation } from '@dagens/frontend-i18n';
import { useState } from 'react';
import { Button, ButtonGroup, useMediaQuery } from '@dagens/carrot';
import styled from 'styled-components';
import { NumberInput } from '../../../carrot-deprecated/input-number';
import { UpdateOrderDeliveryFeePayload } from '../../../api';
import { OrderProducerDeliveryFee } from '../../../types/Order';
import { DELIVERY_FEE_ROW_CLASS } from './DeliveryFeeRow';
import { ChangedPriceCell } from './ChangedPriceCell';
import Typography from '@carrot-deprecated/typography';
import Theme from '@carrot-deprecated/theme';
import Container from '@carrot-deprecated/container';
import { InputContainer } from '_common/components/input/Input.style';
import { ExpandedContent } from '_common/components/table/CommonTable.style';
import Icon from '_common/components/utils/Icon';
import { ActionButton } from '@components/action-button';
import REQ, { ReqType } from 'utils/REQ';

const StyledRow = styled.tr`
  cursor: pointer;
  border-bottom: none;
  background-color: ${Theme.Colours.brightPurple};
`;

const toNumber = (value: string | number): number => {
  return typeof value === 'string' ? Number(value.replace(',', '.')) : value;
};

const ConfirmButton = ({
  isOrderAdjustment,
  onClick,
  disabled,
  req
}: {
  isOrderAdjustment: boolean;
  onClick: () => void;
  disabled: boolean;
  req: ReqType;
}) => {
  const { t } = useTranslation();

  if (isOrderAdjustment) {
    return (
      <Container py="s">
        <Button variant="primary" onClick={onClick}>
          {t('producer:OrderAdjustments.doneAdjustingFee')}
        </Button>
      </Container>
    );
  }
  return (
    <Container py="s">
      <ActionButton.Save
        size="regular"
        onClick={onClick}
        disabled={disabled}
        saveReq={req}
      >
        {t('common:SaveChanges')}
      </ActionButton.Save>
    </Container>
  );
};

type Props = {
  orderId: string;
  orderDeliveryFee: OrderProducerDeliveryFee;
  isOrderAdjustment?: boolean;
  toggleEditing?: (val?: string) => void;
  onSave?: (
    orderId: string,
    payload: UpdateOrderDeliveryFeePayload
  ) => Promise<void> | void;
};

export const EditableDeliveryFeeRow = ({
  orderId,
  orderDeliveryFee,
  isOrderAdjustment = false,
  toggleEditing,
  onSave
}: Props) => {
  const { t } = useTranslation();
  const [req, setReq] = useState<ReqType>(REQ.INIT);
  const _id = orderDeliveryFee.ref?._id;
  const initialPrice = orderDeliveryFee.finalValue ?? 0;
  const [price, setPrice] = useState<number | string>(initialPrice);
  const { mobileOnly } = useMediaQuery();

  const updateLines = () => {
    setReq(REQ.PENDING);
    const updatedOrderLine: UpdateOrderDeliveryFeePayload = {
      price: toNumber(price)
    };

    const possiblePromise = onSave?.(orderId, updatedOrderLine);

    Promise.resolve(possiblePromise)
      .then(() => {
        setReq(REQ.SUCCESS);
        toggleEditing?.();
      })
      .catch(() => {
        setReq(REQ.ERROR);
      });
  };

  return (
    <>
      <StyledRow
        className={DELIVERY_FEE_ROW_CLASS}
        onClick={() => toggleEditing?.()}
      >
        <td className={mobileOnly ? 'col-span-2' : 'col-span-3'}>
          <Typography variant="paragraphSmall">
            {t('producer:deliveryFee')}
          </Typography>
        </td>
        <td
          className={`
            relative
            !text-right
          `}
        >
          <ChangedPriceCell orderDeliveryFee={orderDeliveryFee} />
        </td>
        <td className="!pl-s">
          <Icon icon="chevron" $size="m" fill="deepPurple" />
        </td>
      </StyledRow>
      <ExpandedContent isDeliveryFee>
        <td className="col-span-full">
          <InputContainer margin={Theme.Spacings.xxs}>
            <Typography variant="inputLabel" id={`price-${_id}`} mb="xxs">
              {t('producer:deliveryFee')}
            </Typography>
            <NumberInput
              id={`price-${_id}`}
              value={price}
              unit="kr"
              decimal
              onChange={value => {
                setPrice(value);
                setReq(REQ.INIT);
              }}
              width={328}
            />
          </InputContainer>

          <ButtonGroup.NoWrap>
            {price !== initialPrice && (
              <ConfirmButton
                req={req}
                isOrderAdjustment={isOrderAdjustment}
                onClick={updateLines}
                disabled={isNaN(Number(price))}
              />
            )}
            {req !== REQ.PENDING && (
              <Button
                variant="borderless"
                color="error"
                onClick={() => toggleEditing?.()}
              >
                {t('common:Cancel')}
              </Button>
            )}
          </ButtonGroup.NoWrap>
        </td>
      </ExpandedContent>
    </>
  );
};
