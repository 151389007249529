import { Trans, useTranslation } from '@dagens/frontend-i18n';
import Typography from '@carrot-deprecated/typography';
import { B } from 'utils/fonts';

type SearchResultCountProps = {
  count: number;
  countProducers: number;
};

const SearchResultCount = ({
  count,
  countProducers
}: SearchResultCountProps) => {
  const { t } = useTranslation();

  return (
    <Trans
      t={t}
      i18nKey="consumer:AmountOfProductsFound"
      count={count}
      values={{ count, countProducers }}
      components={{
        P: <Typography variant="paragraphSmall" />,
        B: <B />
      }}
    />
  );
};

export default SearchResultCount;
