import { tv } from '../../utils/tv';

const style = tv({
  slots: {
    root: ``,
    multiple_months: ``,
    with_weeknumber: ``,
    vhidden: ``,
    button_reset: ``,
    button: ``,
    caption: `
      grid
      grid-cols-[max-content,1fr,max-content]
    `,
    caption_start: `
      flex
      flex-col
      gap-s
    `,
    caption_end: `
      flex
      flex-col
      gap-s
    `,
    caption_between: `
      flex
      flex-col
      gap-s
    `,
    caption_label: `
      col-start-2
      text-center
      text-paragraphSmallBold
    `,
    caption_dropdowns: ``,
    dropdown: ``,
    dropdown_month: ``,
    dropdown_year: ``,
    dropdown_icon: ``,
    months: `
      flex
      gap-s
    `,
    month: ``,
    table: `border-collapse`,
    tbody: ``,
    tfoot: ``,
    head: `mb-xxs`,
    head_row: `text-allCaps`,
    head_cell: `text-secondary`,
    nav: `contents`,
    nav_button: ``,
    nav_button_previous: `
      col-start-1
      row-start-1
    `,
    nav_button_next: `
      col-start-3
      row-start-1
      flex
      items-center
      justify-end
    `,
    nav_icon: ``,
    row: ``,
    weeknumber: ``,
    cell: `
      px-0
      py-[2px]
      text-center
      text-button
    `,
    day: `
      size-full
      rounded
      p-[12px]

      hover:bg-brightPurple
    `,
    day_outside: ``,
    day_selected: `!bg-availability`,
    day_disabled: ``,
    day_hidden: ``,
    day_range_start: `
      !rounded-l
      rounded-r-none
    `,
    day_range_end: `
      !rounded-r
      rounded-l-none
    `,
    day_range_middle: `rounded-none`,
    day_today: `font-[600]`,
    other_ranges: `bg-availability/20`
  }
});

export const classNames = () => {
  const slots = style();
  return {
    root: slots.root(),
    multiple_months: slots.multiple_months(),
    with_weeknumber: slots.with_weeknumber(),
    vhidden: slots.vhidden(),
    button_reset: slots.button_reset(),
    button: slots.button(),
    caption: slots.caption(),
    caption_start: slots.caption_start(),
    caption_end: slots.caption_end(),
    caption_between: slots.caption_between(),
    caption_label: slots.caption_label(),
    caption_dropdowns: slots.caption_dropdowns(),
    dropdown: slots.dropdown(),
    dropdown_month: slots.dropdown_month(),
    dropdown_year: slots.dropdown_year(),
    dropdown_icon: slots.dropdown_icon(),
    months: slots.months(),
    month: slots.month(),
    table: slots.table(),
    tbody: slots.tbody(),
    tfoot: slots.tfoot(),
    head: slots.head(),
    head_row: slots.head_row(),
    head_cell: slots.head_cell(),
    nav: slots.nav(),
    nav_button: slots.nav_button(),
    nav_button_previous: slots.nav_button_previous(),
    nav_button_next: slots.nav_button_next(),
    nav_icon: slots.nav_icon(),
    row: slots.row(),
    weeknumber: slots.weeknumber(),
    cell: slots.cell(),
    day: slots.day(),
    day_outside: slots.day_outside(),
    day_selected: slots.day_selected(),
    day_disabled: slots.day_disabled(),
    day_hidden: slots.day_hidden(),
    day_range_start: slots.day_range_start(),
    day_range_end: slots.day_range_end(),
    day_range_middle: slots.day_range_middle(),
    day_today: slots.day_today(),
    other_ranges: slots.other_ranges()
  };
};
