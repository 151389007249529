import styled from 'styled-components';
import { useMediaQuery } from '@dagens/carrot';
import ProductReference from '../product-reference/ProductReference';
import Theme from '../../../carrot-deprecated/theme';
import Container from '../../../carrot-deprecated/container';
import { DELIVERY_FEE_ROW_CLASS } from './DeliveryFeeRow';
import { ChangedQuantityCell } from './ChangedQuantityCell';
import { ChangedTotalCell } from './ChangedTotalCell';
import Typography from '@carrot-deprecated/typography';
import Icon from '_common/components/utils/Icon';
import PricingDisplay from '_common/components/pricing/PricingDisplay';
import { OrderLine } from 'types/Order';

const StyledRow = styled.tr<{
  isReadOnly?: boolean;
}>`
  cursor: ${({ isReadOnly }) => (isReadOnly ? 'default' : 'pointer')};
  &:has(+ .${DELIVERY_FEE_ROW_CLASS}) {
    border-bottom: 1px solid ${Theme.Colours.black} !important;
  }
`;

type OrderLineTableRowProps = {
  line: OrderLine;
  originalLine?: OrderLine;
  onClick: () => void;
  isReadOnly?: boolean;
};

const OrderLineTableRow = ({
  line,
  originalLine,
  onClick,
  isReadOnly = false
}: OrderLineTableRowProps) => {
  const { mobileOnly } = useMediaQuery();
  const { pricingAtTimeOfOrder, product, batchIdentification } = line;
  const { pricingAtTimeOfOrder: originalPricingAtTimeOfOrder } =
    originalLine ?? {};

  return (
    <StyledRow isReadOnly={isReadOnly} onClick={onClick}>
      <td>
        <ProductReference product={product} variant="small" />
        <Typography variant="paragraphSmall" color="secondary">
          {batchIdentification}
        </Typography>
      </td>
      {mobileOnly ? (
        <td>
          <Container flex direction="column" wrap="nowrap">
            <ChangedQuantityCell line={line} />
            <PricingDisplay
              price={pricingAtTimeOfOrder}
              previousPrice={originalPricingAtTimeOfOrder}
              color="secondary"
            />
          </Container>
        </td>
      ) : (
        <>
          <td>
            <Container flex gap="s" wrap="nowrap">
              <ChangedQuantityCell line={line} />
            </Container>
          </td>
          <td>
            <PricingDisplay
              price={pricingAtTimeOfOrder}
              previousPrice={originalPricingAtTimeOfOrder}
              color="secondary"
            />
          </td>
        </>
      )}

      <td className="!text-right">
        <ChangedTotalCell line={line} originalLine={originalLine} />
      </td>
      {!isReadOnly && (
        <td className="!pl-s">
          <Icon icon="edit" $size="m" fill="deepPurple" />
        </td>
      )}
    </StyledRow>
  );
};

export default OrderLineTableRow;
