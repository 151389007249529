import { Form, useWatch } from '@dagens/frontend-forms';
import { useTranslation } from '@dagens/frontend-i18n';
import { BillingFormValues } from '.';

type BillingFeedbackProps = {
  defaultBillingName?: string | null;
};

export const BillingFeedback = ({
  defaultBillingName
}: BillingFeedbackProps) => {
  const { t } = useTranslation();
  const billingName = useWatch<BillingFormValues>().billingName;

  if (!defaultBillingName || billingName !== defaultBillingName) {
    return null;
  }

  return (
    <Form.FeedbackAlert>
      {t('producer:AddCustomer:retrievedFromCvrRegistry')}
    </Form.FeedbackAlert>
  );
};
