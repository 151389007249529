import { formFieldFactory } from '@dagens/frontend-forms';
import { useTranslation } from '@dagens/frontend-i18n';
import { ProductFormValues } from '..';

const { Field, ImageInput } = formFieldFactory<ProductFormValues>();

export const ProductFormImage = () => {
  const { t } = useTranslation();
  return (
    <Field
      to="image"
      label={t('producer:productImageLabel')}
      helpText={t('producer:ImageHelperText')}
      explanation={t('producer:productImageText')}
    >
      <ImageInput name="image" />
    </Field>
  );
};
