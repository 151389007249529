import { forwardRef, Ref } from 'react';
import { Simplify } from 'type-fest';
import { tv } from '../../../utils/tv';

import { LinkComponentInternal, LinkComponentProps } from '../components';
import { ButtonStyleProps } from '../../button/styles';

const style = tv({
  base: `
    !text-paragraphBold
    text-black
  `,
  variants: {
    type: {
      secondary: '!text-paragraph'
    }
  }
});

type Props = Simplify<
  Omit<LinkComponentProps, ButtonStyleProps | 'className'> & {
    secondary?: boolean;
  }
>;

const Component = (
  { secondary, ...props }: Props,
  ref: Ref<HTMLAnchorElement>
) => {
  return (
    <LinkComponentInternal
      {...props}
      ref={ref}
      variant="borderless"
      size="small"
      alignment="left"
      className={style({ type: secondary ? 'secondary' : undefined })}
    />
  );
};

export const MenuItem = forwardRef(Component);
