import { useTranslation } from '@dagens/frontend-i18n';
import { addDays, isBefore } from 'date-fns';
import { useEffect, useState } from 'react';

import { Button } from '@dagens/carrot';
import ResponsiveImage from '../../../../_common/components/utils/ResponsiveImage.style';
import { useMediaQuery } from '../../../../_common/hooks/useMediaQueries';
import { OnboardingHistoryKeys } from '../../../../types/Producer';
import {
  getItemFromLocalStorage,
  updateLocalStorage
} from '../../../../utils/clientcache';
import { Illustrations } from '../../../../utils/Illustrations';
import ProducerMessagesOnboardingStep from './ProducerMessagesOnboardingStep';
import Typography from '@carrot-deprecated/typography';
import Container from '@carrot-deprecated/container';
import {
  getUserFirstName,
  updateOnboardingHistory
} from '_common/reducers/auth';
import { useAppDispatch, useAppSelector } from '_common/hooks/reduxHooks';

const HAS_PRODUCER_SEEN_GRAND_GREETING_LOCAL_STORAGE_KEY =
  'hasProducerSeenGrandGreeting';

const isOnboardingSpecialCase = (
  key: OnboardingHistoryKeys,
  value: string | null | undefined
) => {
  return (
    key === OnboardingHistoryKeys.receivedFirstPayment &&
    value &&
    isBefore(new Date(), addDays(new Date(value), 7))
  );
};

const ProducerMessagesOnboarding = () => {
  const [shouldRender, setShouldRender] = useState(false);
  const dispatch = useAppDispatch();
  const mediaQuery = useMediaQuery();
  const { t } = useTranslation();
  const { auth } = useAppSelector(({ auth: producerAuth }) => {
    return {
      auth: producerAuth
    };
  });

  useEffect(() => {
    const timerId = setTimeout(() => {
      setShouldRender(true);
    }, 500);

    return () => {
      return clearTimeout(timerId);
    };
  }, []);

  const hasProducerCompletedOnboardingProcess = Object.values(
    OnboardingHistoryKeys
  ).every(key => {
    if (isOnboardingSpecialCase(key, auth.onboardingHistory?.[key]))
      return false;
    return Boolean(auth.onboardingHistory?.[key]);
  });

  if (hasProducerCompletedOnboardingProcess) return null;

  if (!shouldRender) return null;

  const hasProducerNotStartedOnboardingProcess =
    Object.values(OnboardingHistoryKeys).every(key => {
      return !auth.onboardingHistory?.[key];
    }) &&
    !getItemFromLocalStorage(
      `${HAS_PRODUCER_SEEN_GRAND_GREETING_LOCAL_STORAGE_KEY}_${auth._id}}`
    );

  if (hasProducerNotStartedOnboardingProcess) {
    return (
      <Container borderColor="lightGrey" px="m" py="m">
        <Container mb="m" maxWidth={mediaQuery.tabletUp ? '40%' : '100%'}>
          <ResponsiveImage
            src={Illustrations.PRODUCERS_COMMUNITY_NETWORKING}
            alt="Many producers gathered"
          />
        </Container>
        <Typography variant="paragraphBold" as="p" mb="s">
          {t('producer:onboardingGrandGreetingTitle', {
            name: getUserFirstName(auth)
          })}
        </Typography>
        <Typography variant="paragraph" mb="s">
          {t('producer:onboardingGrandGreetingTextOne')}
        </Typography>
        <Typography variant="paragraph" mb="s">
          {t('producer:onboardingGrandGreetingTextTwo')}
        </Typography>
        <Button
          onClick={() => {
            updateLocalStorage(
              `${HAS_PRODUCER_SEEN_GRAND_GREETING_LOCAL_STORAGE_KEY}_${auth._id}}`,
              true
            );
            dispatch(updateOnboardingHistory(auth._id));
          }}
        >
          {t('common:LetsGo')}
        </Button>
      </Container>
    );
  }

  const onboardingKeyStepToShow = Object.values(OnboardingHistoryKeys).find(
    key => {
      if (isOnboardingSpecialCase(key, auth.onboardingHistory?.[key]))
        return true;
      return !auth.onboardingHistory?.[key];
    }
  );

  return (
    <ProducerMessagesOnboardingStep
      onboardingHistoryStepKey={onboardingKeyStepToShow}
    />
  );
};

export default ProducerMessagesOnboarding;
