import { i18n, TOptions } from 'i18next';
import { SetRequired } from 'type-fest';

import { BaseTranslateKey, TranslateKey } from './types';

export function tr<Output = string>(
  i18next: i18n,
  key: BaseTranslateKey,
  options: SetRequired<TOptions, 'count'>
): Output;
export function tr<Output = string>(
  i18next: i18n,
  key: TranslateKey,
  options?: TOptions
): Output;
export function tr<Output = string>(
  i18next: i18n,
  key: string,
  options?: TOptions
): Output;
export function tr<Output = string>(
  i18next: i18n,
  key: string,
  options?: TOptions
): Output {
  return i18next.t(key, options) as Output;
}

export type TR = typeof tr;
