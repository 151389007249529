import styled from 'styled-components';
import Theme from '@carrot-deprecated/theme';

export default styled.textarea<{
  height?: string;
}>`
  position: relative;
  box-sizing: border-box;
  border: 2px solid ${Theme.Colours.lightGrey};
  border-radius: 5px;
  padding: ${Theme.Spacings.xxs + Theme.Spacings.xs}px;
  color: ${Theme.Colours.black};
  font-size: ${Theme.FontSizes.inputText}px;
  }};
  font-weight: ${Theme.FontWeights.medium};

  width: 100%;
  resize: vertical;
  overflow-y: scroll;
  min-height: 6em;
  height: ${({ height = '6em' }) => {
    return height;
  }};
  line-height: 1.5;

  ::placeholder {
    color: ${Theme.Colours.secondary};
  }

  &:hover {
    border-color: ${Theme.Colours.secondary};
  }

  &:focus:enabled {
    color: ${Theme.Colours.black};
    border-color: ${Theme.Colours.black};
  }

  transition: border-color ${Theme.Animations.regular};
`;
