enum Colours {
  dagensPurple = '#DDCCFF',
  black = '#000000',
  white = '#FFFFFF',
  noteworthy = '#FFE1BA',
  error = '#FF4713',
  warning = '#FFAC59',
  success = '#77B36B',
  deepPurple = '#322172',
  brightPurple = '#F1EBFF',
  messages = '#CCBFA3',
  availability = '#DEE5CF',
  secondary = '#767676',
  lightGrey = '#E6EBE9',
  transparent = 'transparent'
}
export default Colours;

export type ColourNames = keyof typeof Colours;
