import { useTranslation } from '@dagens/frontend-i18n';
import SignUpToTradeButton from './SignUpToTradeButton';
import Theme from '@carrot-deprecated/theme';
import Button from '_common/components/button/Button.style';
import LikeButton from '_common/components/button/LikeButton';
import {
  CardImageContainer as ImageContainer,
  Content,
  RegularCardContainer as Container
} from '_common/components/product/Product.style';
import ProductInfo from '_common/components/product/ProductInfo';
import ProductTag from '_common/components/product/ProductTag';
import { ProductForSale } from 'types/Product';
import { getImageUrl } from 'utils/imageUrls';
import { PRODUCT_ORDER_ITEM_CARD_CLICKED, track } from 'utils/mixpanel';

type ProductOnRequestCardProps = {
  isGuest?: boolean;
  isAuthenticated?: boolean;
  product: ProductForSale;
  onClick: () => void;
  isInSameDA: boolean;
  isProducer: boolean;
  isOwnShop?: boolean;
};

const ProductOnRequestCard = ({
  product,
  isGuest,
  isAuthenticated,
  isInSameDA,
  isProducer,
  onClick,
  isOwnShop
}: ProductOnRequestCardProps) => {
  const { _id: productId, image, name } = product;

  const imageUrl = getImageUrl(image, { width: 500 });

  const { t } = useTranslation();
  const buttonDisabled = !isInSameDA || isProducer || isGuest;

  return (
    <Container
      onClick={() => {
        track(PRODUCT_ORDER_ITEM_CARD_CLICKED);
        onClick();
      }}
    >
      <ImageContainer imageUrl={imageUrl} name={name} />
      <LikeButton productId={productId} />
      <ProductTag product={product} />
      <Content>
        <ProductInfo
          product={product}
          isAuthenticated={isAuthenticated}
          isInSameDA={isInSameDA}
          isOwnShop={isOwnShop}
          isGuest={isGuest}
          isProducer={isProducer}
        />
        {!isAuthenticated ? (
          <SignUpToTradeButton />
        ) : (
          <Button my={Theme.Spacings.xs} disabled={buttonDisabled}>
            {t('common:writeRequest')}
          </Button>
        )}
      </Content>
    </Container>
  );
};

export default ProductOnRequestCard;
