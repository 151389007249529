import { getOrderLineAggregates } from '@dagensmat/core';
import OverviewTable from './OverviewTable';
import Typography from '@carrot-deprecated/typography';
import Container from '@carrot-deprecated/container';
import { Order } from 'types/Order';

type OrderOverviewGroupProps = {
  header: string;
  orders: Order[];
};
const OrderOverviewGroup = ({
  header,
  orders = []
}: OrderOverviewGroupProps) => {
  return (
    <Container my="xl">
      <Typography variant="secondaryHeading">{header}</Typography>
      <OverviewTable orderLineAggregates={getOrderLineAggregates(orders)} />
    </Container>
  );
};

export default OrderOverviewGroup;
