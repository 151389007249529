import { Trans, useTranslation } from '@dagens/frontend-i18n';
import { isEmpty } from 'lodash';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { useEffect, useState } from 'react';
import { useMediaQuery } from '@dagens/carrot';
import { B } from '../../../../utils/fonts';
import { useAppSelector } from '../../../../_common/hooks/reduxHooks';
import { fetchBasketDeliveryTypes } from '../../../../api';
import { DeliveryType } from '../../../../types/Logistics';
import Icon from '../../../../_common/components/utils/Icon';
import DeliveryTypeTag from '../../../../_common/components/tags/DeliveryTypeTag';
import { MediaQuery } from '../../../../utils/mediaQueries';
import Theme from '@carrot-deprecated/theme';
import Container from '@carrot-deprecated/container';
import Typography from '@carrot-deprecated/typography';
import { Producer } from 'types/Producer';
import { getImageUrl } from 'utils/imageUrls';

const ClickContainer = styled.button`
  display: flex;
  width: 100%;
  align-items: flex-start;
  flex-wrap: nowrap;
  margin-bottom: ${Theme.Spacings.xs}px;
  border-radius: ${Theme.BorderRadius.regular}px;
  padding: ${Theme.Spacings.xs}px 0;
  background-color: ${Theme.Colours.transparent};

  > div {
    text-align: left;
  }

  :focus {
    background-color: ${Theme.Colours.brightPurple};

    > div p,
    > div h3 {
      color: ${Theme.Colours.deepPurple};
    }
  }

  transition: background-color, padding, color, ${Theme.Animations.regular};
  :hover {
    cursor: pointer;
    background-color: ${Theme.Colours.brightPurple};
    padding: ${Theme.Spacings.xs}px;

    > div p,
    > div h3 {
      color: ${Theme.Colours.deepPurple};
    }
  }
`;

const ProducerImage = styled.img`
  width: ${Theme.Spacings.l * 2}px;
  height: ${Theme.Spacings.l * 2}px;
  object-fit: cover;
  border-radius: ${Theme.BorderRadius.regular}px;
  margin-right: ${Theme.Spacings.xs}px;
`;

const HeaderTitleContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: ${Theme.Spacings.xxs}px ${Theme.Spacings.xs}px;
  align-items: center;
  ${MediaQuery.mobileOnly} {
    flex-direction: column-reverse;
    align-items: flex-start;
  }
`;

const HeaderTitleTextContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: baseline;
  gap: 0px ${Theme.Spacings.xs}px;
`;

type MinimumOrderAmountTextProps = {
  producerInBasket: boolean;
  isAboveMinimumOrderThreshold?: boolean;
  minimumOrderAmountEnforced?: boolean;
  minimumOrderAmount?: number;
};

const MinimumOrderAmountText = ({
  producerInBasket,
  isAboveMinimumOrderThreshold,
  minimumOrderAmountEnforced,
  minimumOrderAmount
}: MinimumOrderAmountTextProps) => {
  const { t } = useTranslation();
  if (!minimumOrderAmount) {
    return null;
  }

  return (
    <Container flex gap="xs">
      <Typography variant="paragraphSmall">
        <Trans
          t={t}
          i18nKey="common:MinOrderAmount"
          values={{ minimumOrderAmount }}
          components={{ B: <B /> }}
        />
      </Typography>
      {producerInBasket &&
        (isAboveMinimumOrderThreshold ? (
          <Icon icon="success" fill="success" $size="m" />
        ) : minimumOrderAmountEnforced ? (
          <Icon icon="alert" fill="error" $size="m" />
        ) : (
          <Icon icon="alert" fill="warning" $size="m" />
        ))}
    </Container>
  );
};

type DeliveryTypeTextProps = {
  deliveryType: DeliveryType | undefined;
};

const DeliveryTypeText = ({ deliveryType }: DeliveryTypeTextProps) => {
  if (!deliveryType) {
    return null;
  }
  return <DeliveryTypeTag deliveryType={deliveryType} pointOfView="consumer" />;
};

type ProducerGroupHeaderProps = {
  producer: Pick<
    Producer,
    | '_id'
    | 'image'
    | 'name'
    | 'profileArea'
    | 'handle'
    | 'minimumOrderAmount'
    | 'minimumOrderAmountEnforced'
  >;
  deliveryDate: string | undefined;
};

const ProducerGroupHeader = ({
  producer,
  deliveryDate
}: ProducerGroupHeaderProps) => {
  const {
    image = {},
    profileArea,
    name,
    handle,
    minimumOrderAmount,
    minimumOrderAmountEnforced
  } = producer;
  const [deliveryType, setDeliveryType] = useState<DeliveryType | undefined>();
  const navigate = useNavigate();
  const { desktopUp, mobileOnly } = useMediaQuery();

  const { distributionAreaId, producerInBasket, isAboveMinimumOrderThreshold } =
    useAppSelector(
      ({
        auth,
        basket: { producersAboveMinimumOrderAmount },
        productsForSale
      }) => {
        const deliveryDate = productsForSale.selectedDeliveryDate;
        const producerDates = producersAboveMinimumOrderAmount?.[producer._id];
        if (deliveryDate === undefined || producerDates === undefined) {
          return {
            distributionAreaId: auth.distributionAreaId,
            producerInBasket: false
          };
        }

        const isAboveMinimumOrderForProducerAndDate =
          producerDates[deliveryDate];
        return {
          distributionAreaId: auth.distributionAreaId,
          producerInBasket: true,
          isAboveMinimumOrderThreshold: isAboveMinimumOrderForProducerAndDate
        };
      }
    );

  useEffect(() => {
    if (!distributionAreaId || !deliveryDate) {
      setDeliveryType(undefined);
      return;
    }

    const query = {
      distributionAreaId,
      deliveryDays: [
        {
          producerId: producer._id,
          date: deliveryDate
        }
      ]
    };

    fetchBasketDeliveryTypes(query).then(data => {
      setDeliveryType(data.at(0)?.deliveryType);
    });
  }, [deliveryDate]);

  return (
    <ClickContainer
      onClick={() => {
        return navigate(`/${handle}`, { state: { returnPath: true } });
      }}
    >
      {!isEmpty(image) && (
        <ProducerImage
          alt={producer.name}
          src={
            getImageUrl(image, { width: Theme.Spacings.l * 4, fit: 'max' }) ??
            undefined
          }
        />
      )}
      <div>
        <HeaderTitleContainer>
          <HeaderTitleTextContainer>
            <Typography variant="paragraphBold" as="h3">
              {name}
            </Typography>
            {mobileOnly && profileArea && (
              <Typography variant="paragraphSmall" color="secondary">
                {profileArea}
              </Typography>
            )}
          </HeaderTitleTextContainer>
          <DeliveryTypeText deliveryType={deliveryType} />
        </HeaderTitleContainer>
        {!mobileOnly && profileArea && (
          <Typography variant="paragraphSmall" color="secondary">
            {profileArea}
          </Typography>
        )}
        {(Boolean(minimumOrderAmount) || Boolean(deliveryType)) && (
          <Container
            flex
            direction={desktopUp ? 'row' : 'column'}
            wrap="nowrap"
            gap="xxs"
          >
            <MinimumOrderAmountText
              producerInBasket={producerInBasket}
              isAboveMinimumOrderThreshold={isAboveMinimumOrderThreshold}
              minimumOrderAmountEnforced={minimumOrderAmountEnforced}
              minimumOrderAmount={minimumOrderAmount}
            />
          </Container>
        )}
      </div>
    </ClickContainer>
  );
};
export default ProducerGroupHeader;
