import { PropsWithChildren } from 'react';

import { Callout } from '../../callout';

type Props = PropsWithChildren;

export const FormFieldExplanation = ({ children }: Props) => {
  return (
    <Callout small variant="lightGrey">
      {children}
    </Callout>
  );
};
