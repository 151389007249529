import { formFieldFactory, useWatch } from '@dagens/frontend-forms';
import { useTranslation } from '@dagens/frontend-i18n';
import { Icon } from '@dagens/carrot';
import styled from 'styled-components';
import { ProductFormValues } from '..';
import Loader from '../../../../_common/components/loader/Loader';
import { Category } from '../../../../types/Product';
import { useCategories } from '../hooks/use-categories';
import { useAppSelector } from '../../../../_common/hooks/reduxHooks';
import Container from '../../../../carrot-deprecated/container';
import Typography from '../../../../carrot-deprecated/typography';
import { PricingFormValues } from '../../pricing-form';

const { Field, CheckboxesTree } = formFieldFactory<
  ProductFormValues & PricingFormValues
>();

const WarningMessageTypography = styled(Typography)`
  flex: 1;
`;

const WarningMessage = ({ defaultValue }: Props) => {
  const { t } = useTranslation();
  const category = useWatch<ProductFormValues>({
    name: 'category',
    defaultValue
  }) as Category[];

  if (!category || category.every(c => !c.isNonFood)) {
    return null;
  }

  return (
    <Container flex gap="xs" my="s">
      <Icon icon="alert" color="warning" />
      <WarningMessageTypography variant="paragraph">
        {t('producer:productPickCategoryNonFoodWarning')}
      </WarningMessageTypography>
    </Container>
  );
};

type Props = {
  defaultValue?: Category[];
};

export const ProductFormCategories = ({ defaultValue }: Props) => {
  const { t } = useTranslation();
  const roleLang = useAppSelector(({ auth: { roleLang } }) => roleLang);
  const { categories, req: categoriesReq } = useCategories();

  if (categoriesReq !== 'SUCCESS') {
    return <Loader />;
  }

  return (
    <div>
      <Field
        to="category"
        label={t('producer:productPickCategoryHeader')}
        required
        deps={['measureValue', 'measureUnit']}
      >
        <CheckboxesTree
          name="category"
          options={categories}
          displayValue={category => {
            const name =
              (roleLang ? category?.[`name_${roleLang}`] : null) ??
              category?.name ??
              '';
            return name.charAt(0).toUpperCase() + name.slice(1);
          }}
          by={(a, b) => a?._id === b?._id}
        />
      </Field>
      <WarningMessage defaultValue={defaultValue} />
    </div>
  );
};
