enum Spacings {
  none = 0,
  xxs = 4,
  xs = 8,
  s = 16,
  m = 24,
  l = 32,
  xl = 48,
  jumbo = 96
}
export default Spacings;

export type SpacingNames = keyof typeof Spacings;
