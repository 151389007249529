import { BottomActions, Box, Nav, PageHeader } from '@dagens/carrot';
import { useTranslation } from '@dagens/frontend-i18n';
import { Form } from '@dagens/frontend-forms';
import { Page } from '../../../../components/page';
import REQ from '../../../../utils/REQ';
import {
  ContactFormValues,
  CustomerContactForm
} from '../../../components/customer-contact-form';
import { ManagedCustomer } from '../../../../api';
import { useFetchCustomer } from '../shared/use-fetch-customer';
import { useMediaQuery } from '../../../../_common/hooks/useMediaQueries';
import { useOnSave } from './use-on-save';
import { ContactBottomActions } from './bottom-actions';

const consumerToForm = (consumer: ManagedCustomer) => {
  return {
    customerName: consumer.name,
    contactPersonName: consumer.contactPerson,
    contactPhone: consumer.phone,
    notificationEmail: consumer.emails.at(0) ?? null
  } satisfies ContactFormValues;
};

type Props = {
  consumer: ManagedCustomer;
};

const PageContent = ({ consumer }: Props) => {
  const { desktopUp } = useMediaQuery();
  const { t } = useTranslation();
  const { onSave, saveReq } = useOnSave(consumer._id);

  const title = t('producer:EditCustomerContactInformation');
  const subtitle = consumer?.name;

  return (
    <Form.Provider defaultValue={consumerToForm(consumer)}>
      <Page
        navMobileText={
          <>
            <Nav.ContextTitle>{title}</Nav.ContextTitle>
            <Nav.ContextSubtitle>{subtitle}</Nav.ContextSubtitle>
          </>
        }
        header={
          desktopUp && (
            <Box.FullWidth>
              <PageHeader title={title} subtitle={subtitle} />
            </Box.FullWidth>
          )
        }
        bottom={
          <BottomActions border>
            <ContactBottomActions
              consumerId={consumer._id}
              saveReq={saveReq}
              onSubmit={onSave}
            />
          </BottomActions>
        }
      >
        <Box.FormWrapper>
          <Form onSubmit={onSave}>
            <CustomerContactForm />
          </Form>
        </Box.FormWrapper>
      </Page>
    </Form.Provider>
  );
};

export const EditCustomerContactPage = () => {
  const { req, consumer } = useFetchCustomer();

  if (req !== REQ.SUCCESS) {
    return <Page.Status req={req} />;
  }

  if (!consumer) {
    return <Page.NotFound />;
  }

  return <PageContent consumer={consumer} />;
};
