import { useTranslation } from '@dagens/frontend-i18n';
import { useAppSelector } from '../../../../_common/hooks/reduxHooks';
import Container from '@carrot-deprecated/container';
import MultiProductSeasonCalendar from '_common/components/season/MultiProductSeasonCalendar';
import { splitProductsBySaleStatus } from '_producer/reducers/products';
import { byClosestDate } from 'utils/array';
import { OPEN_PRODUCT_FROM_PRODUCER_LANDING_PAGE, track } from 'utils/mixpanel';

const ProducerProductsSection = () => {
  const { products } = useAppSelector(
    ({ producerProducts: { items: producerProductItems } }) => {
      const { activeProducts } =
        splitProductsBySaleStatus(producerProductItems);
      const byClosestToToday = byClosestDate(new Date());
      const products = activeProducts?.sort(byClosestToToday);

      return {
        products
      };
    }
  );
  const { t } = useTranslation();
  if (!products || products.length === 0) {
    return null;
  }

  return (
    <Container mt="xl">
      <MultiProductSeasonCalendar
        products={products}
        isProducer
        heading={t('common:productsForSale')}
        onClick={() => {
          track(OPEN_PRODUCT_FROM_PRODUCER_LANDING_PAGE);
        }}
      />
    </Container>
  );
};

export default ProducerProductsSection;
