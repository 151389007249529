import { useTranslation } from '@dagens/frontend-i18n';
import Tag from '../utils/Tag';
import Theme from '@carrot-deprecated/theme';
import { DeliveryType } from 'types/Logistics';

type IDeliveryTypeTag = {
  deliveryType: DeliveryType;
  pointOfView?: 'consumer' | 'producer';
};

const DeliveryTypeTag = ({
  deliveryType,
  pointOfView = 'producer'
}: IDeliveryTypeTag) => {
  const { t } = useTranslation();
  let text;
  if (deliveryType === DeliveryType.SELF_DELIVERY) {
    text =
      pointOfView === 'producer'
        ? t('common:SelfDelivery')
        : t('common:ProducerDelivery');
  } else {
    text = t('common:DagensDelivery');
  }

  return (
    <Tag
      bgColor={
        deliveryType === DeliveryType.SELF_DELIVERY
          ? Theme.Colours.noteworthy
          : Theme.Colours.dagensPurple
      }
      text={text}
      mr={Theme.Spacings.xxs}
    />
  );
};
export default DeliveryTypeTag;
