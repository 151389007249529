import { useTranslation } from '@dagens/frontend-i18n';
import { useState } from 'react';
import styled from 'styled-components';
import Container from '@carrot-deprecated/container';
import FormField from '_common/components/input/FormField';
import SectionHOC from '_common/components/section-hoc/SectionHOC';
import { ActionButton } from '@components/action-button';
import { postProducerUpdate } from 'api';
import REQ, { ReqType } from 'utils/REQ';

const MaxWidthContainer = styled.form`
  width: 100%;
  max-width: 490px;
`;

type ProfileContactInfoSetcionProps = {
  onChange: (val: { contactPerson: string; phone: string }) => void;
  userId?: string;
  contactPerson?: string;
  phone?: string;
};

const ProfileContactInfoSetcion = ({
  onChange,
  userId,
  contactPerson = '',
  phone = ''
}: ProfileContactInfoSetcionProps) => {
  const { t } = useTranslation();
  const [fieldsChanged, setFieldsChanged] = useState(false);
  const [editableContactPerson, setEditableContactPerson] =
    useState(contactPerson);
  const [editablePhone, setEditablePhone] = useState(phone);
  const [req, setReq] = useState<ReqType>(REQ.INIT);

  const onSubmit = async () => {
    setReq(REQ.PENDING);

    try {
      await postProducerUpdate({
        userId,
        contactPerson: editableContactPerson,
        phone: editablePhone
      });
      setReq(REQ.SUCCESS);
      onChange({
        contactPerson: editableContactPerson,
        phone: editablePhone
      });
    } catch (e) {
      setReq(REQ.ERROR);
    }
  };

  return (
    <MaxWidthContainer>
      <FormField
        margin={{ mt: 'm' }}
        name="contactPerson"
        type="text"
        labelText={t('producer:myCompanyContactPersonLabelText')}
        placeholder={t('producer:myCompanyContactPersonPlaceholder')}
        onChange={({ target: { value } }) => {
          setEditableContactPerson(value);
          setFieldsChanged(true);
          setReq(REQ.INIT);
        }}
        value={editableContactPerson}
      />
      <FormField
        name="phone"
        type="tel"
        labelText={t('common:phoneNumber')}
        placeholder={t('producer:myCompanyPhonePlaceholder')}
        onChange={({ target: { value } }) => {
          setEditablePhone(value);
          setFieldsChanged(true);
          setReq(REQ.INIT);
        }}
        value={editablePhone}
      />
      {fieldsChanged && (
        <Container py="s">
          <ActionButton.Save size="regular" onClick={onSubmit} saveReq={req}>
            {t('common:SaveChanges')}
          </ActionButton.Save>
        </Container>
      )}
    </MaxWidthContainer>
  );
};

export default SectionHOC(ProfileContactInfoSetcion);
