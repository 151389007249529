import { DateRange } from '@dagens/carrot';
import { useEffect, useState } from 'react';

export type PeriodPickerValue = DateRange[] | 'ALL' | null;

const sortRanges = (ranges: DateRange[]) =>
  ranges.sort((a, b) => (a.from?.getTime() || 0) - (b.from?.getTime() || 0));

const addAndMergeRanges = (ranges: DateRange[], range: DateRange) => {
  const mergedRange = {
    from: range.from,
    to: range.to
  };

  const unchangedRanges = ranges.filter(r => {
    if (
      r.from === undefined ||
      r.to === undefined ||
      mergedRange.from === undefined ||
      mergedRange.to === undefined
    ) {
      return true;
    }

    const overlaps =
      (r.from <= mergedRange.from && r.to >= mergedRange.from) ||
      (r.from <= mergedRange.to && r.to >= mergedRange.to) ||
      (r.from >= mergedRange.from && r.to <= mergedRange.to);

    if (!overlaps) {
      return true;
    }

    mergedRange.from = new Date(
      Math.min(r.from.getTime(), mergedRange.from.getTime())
    );
    mergedRange.to = new Date(
      Math.max(r.to.getTime(), mergedRange.to.getTime())
    );
    return false;
  });

  return [...unchangedRanges, mergedRange];
};

export const useValues = (
  value: PeriodPickerValue,
  onChange: (value: PeriodPickerValue) => void,
  mergeOverlapping?: boolean
) => {
  const [adding, setAdding] = useState(false);
  const [activePeriodIndex, setActivePeriodIndex] = useState<number>();
  const [activePeriod, setActivePeriod] = useState<DateRange>();

  const addPeriod = (period?: DateRange) => {
    if (value === 'ALL' || !period) {
      return;
    }
    const newPeriods = mergeOverlapping
      ? addAndMergeRanges(value ?? [], period)
      : [...(value ?? []), period];
    onChange(sortRanges(newPeriods));
    reset();
  };

  const deletePeriod = (index: number) => {
    if (!value || value === 'ALL') {
      return;
    }
    onChange([...value.slice(0, index), ...value.slice(index + 1)]);
    reset();
  };

  const toggleAdding = () => {
    setAdding(adding => !adding);
    setActivePeriodIndex(undefined);
    setActivePeriod(undefined);
  };

  const toggleAllSelected = () => {
    onChange(value === 'ALL' ? [] : 'ALL');
    reset();
  };

  const togglePeriod = (index: number) => {
    setActivePeriodIndex(activePeriodIndex === index ? undefined : index);
  };

  const updatePeriod = (index: number, period?: DateRange) => {
    if (!value || value === 'ALL' || !period) {
      return;
    }

    const newPeriods = mergeOverlapping
      ? addAndMergeRanges(
          [...value.slice(0, index), ...value.slice(index + 1)],
          period
        )
      : [...value.slice(0, index), period, ...value.slice(index + 1)];
    onChange(sortRanges(newPeriods));
    reset();
  };

  const reset = () => {
    setAdding(false);
    setActivePeriodIndex(undefined);
    setActivePeriod(undefined);
  };

  useEffect(() => {
    if (value === 'ALL') {
      return;
    }

    setActivePeriod(
      activePeriodIndex === undefined ? undefined : value?.[activePeriodIndex]
    );
  }, [activePeriodIndex]);

  return {
    activePeriod,
    activePeriodIndex,
    adding,
    addPeriod,
    deletePeriod,
    setActivePeriod,
    setActivePeriodIndex,
    toggleAdding,
    toggleAllSelected,
    togglePeriod,
    updatePeriod
  };
};
