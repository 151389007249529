import { initI18n, tr } from '@dagens/frontend-i18n';
import i18n from 'i18next';

initI18n(i18n);

type Address = {
  addressLine1?: string;
  addressLine2?: string;
  city?: string;
  country?: string;
  postalCode?: string;
};

const toString = (address?: Address) => {
  if (!address) {
    return '';
  }

  const line2 = address.addressLine2 ? `, ${address.addressLine2}` : '';
  const country =
    address.country === 'no'
      ? tr(i18n, 'common:Norway')
      : address.country === 'dk'
        ? tr(i18n, 'common:Denmark')
        : '';
  return `${address.addressLine1}${line2}, ${address.postalCode} ${address.city}, ${country}`;
};

export const AddressUtils = {
  toString
};
