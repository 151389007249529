import { Input as InputComponent } from './components';
import { InputClear } from './parts/clear';
import { InputIcon } from './parts/icon';
import { InputLoader } from './parts/loader';

export const Input = Object.assign(InputComponent, {
  Clear: InputClear,
  Icon: InputIcon,
  Loader: InputLoader
});
