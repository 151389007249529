import { useTranslation } from '@dagens/frontend-i18n';
import { FC, useState } from 'react';
import styled from 'styled-components';
import { Button } from '@dagens/carrot';
import Icon from '../utils/Icon';
import Container from '@carrot-deprecated/container';
import REQ, { ReqType } from 'utils/REQ';
import Typography from '@carrot-deprecated/typography';

const EmailContainer = styled(Container)<{ removing?: boolean }>`
  text-decoration: ${({ removing }) => {
    return removing ? 'line-through' : 'none';
  }};
`;

type Props = {
  email: string;
  hideRemoveButton: boolean;
  onRemove: (email: string) => Promise<void>;
  onRemoveSuccess: (email: string) => void;
  onRemoveError: (email: string) => void;
};

export const Email: FC<Props> = ({
  email,
  hideRemoveButton,
  onRemove,
  onRemoveSuccess,
  onRemoveError
}) => {
  const { t } = useTranslation();
  const [requestStatus, setRequestStatus] = useState<ReqType>(REQ.INIT);

  const onClickRemove = async () => {
    try {
      setRequestStatus(REQ.PENDING);
      await onRemove(email);
      setRequestStatus(REQ.SUCCESS);
      setTimeout(() => {
        onRemoveSuccess(email);
      }, 3000);
    } catch (e) {
      onRemoveError(email);
      setRequestStatus(REQ.ERROR);
    }
  };

  return (
    <Container flex gap="xs" alignItems="center" mb="xs" minHeight="32px">
      <EmailContainer removing={requestStatus === REQ.SUCCESS}>
        <Typography variant="paragraph" as="li">
          {email}
        </Typography>
      </EmailContainer>
      {!hideRemoveButton && requestStatus === REQ.INIT && (
        <Button
          variant="borderless"
          size="small"
          /* color="error" */
          onClick={onClickRemove}
        >
          <Icon icon="clear" fill="deepPurple" />
          {t('common:Remove')}
        </Button>
      )}
      {requestStatus === REQ.PENDING && (
        <Typography variant="paragraphSmall" color="secondary">
          {t('common:Removing')}
        </Typography>
      )}
      {requestStatus === REQ.SUCCESS && (
        <Container flex gap="xxs" alignItems="center">
          <Icon icon="success" fill="success" />
          <Typography variant="paragraphSmall" color="secondary">
            {t('common:Removed')}
          </Typography>
        </Container>
      )}
      {requestStatus === REQ.ERROR && (
        <Container flex gap="xxs" alignItems="center">
          <Icon icon="alert" fill="error" />
          <Typography variant="paragraphSmall" color="secondary">
            {t('common:FailedToRemove')}
          </Typography>
        </Container>
      )}
    </Container>
  );
};
