import { formatPhoneNumber } from '@dagensmat/core';
import Typography from '@carrot-deprecated/typography';
import Container from '@carrot-deprecated/container';
import { getFirstConsumerEmail } from 'utils/order';

type ConsumerContactInfoProps = {
  contactPerson: string;
  phone: string;
  emails: string[];
};

const ConsumerContactInfo = ({
  consumer: { contactPerson, phone, emails }
}: {
  consumer: ConsumerContactInfoProps;
}) => {
  const consumerEmail = getFirstConsumerEmail(emails);
  return (
    <Container my="l">
      <Typography variant="paragraph">{contactPerson}</Typography>
      {consumerEmail && (
        <Typography variant="paragraph">
          <Typography variant="link" href={`mailTo:${consumerEmail}`}>
            {consumerEmail}
          </Typography>
        </Typography>
      )}
      <Typography variant="paragraph">
        <Typography variant="link" href={`tel:${phone}`}>
          {formatPhoneNumber(phone)}
        </Typography>
      </Typography>
    </Container>
  );
};

export default ConsumerContactInfo;
