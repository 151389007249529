import styled from 'styled-components';
import { DELIVERY_FEE_ROW_CLASS } from '../orderline-table/DeliveryFeeRow';
import Theme from '@carrot-deprecated/theme';
import { MediaQuery } from 'utils/mediaQueries';

export const Table = styled.table`
  table-layout: fixed;
  width: 100%;
  border-collapse: collapse;

  tr {
    border-bottom: 1px solid ${Theme.Colours.lightGrey};
  }

  th,
  td {
    text-align: left;
    padding: ${Theme.Spacings.xs}px 0;
    word-break: break-word;
    vertical-align: top;

    :first-child {
      padding-right: ${Theme.Spacings.xxs}px;
    }

    :nth-child(2) {
      width: 30%;
    }

    ${MediaQuery.desktopUp} {
      :first-child {
        padding-left: ${Theme.Spacings.xs}px;
      }
      :last-child {
        padding-right: ${Theme.Spacings.xs}px;
      }

      :nth-child(2) {
        width: 12%;
      }
    }
  }

  thead {
    tr {
      th {
        color: ${Theme.Colours.secondary};
        text-transform: capitalize;
      }
    }
  }

  tbody {
    tr:hover {
      background-color: ${Theme.Colours.brightPurple};
    }
  }

  tfoot {
    td {
      vertical-align: top;
    }
  }
`;

export const ExpandedContent = styled.tr<{
  isDeliveryFee?: boolean;
}>`
  :hover {
    background-color: ${Theme.Colours.white} !important;
  }

  td {
    padding-bottom: ${Theme.Spacings.m}px;
  }

  ${MediaQuery.desktopUp} {
    td:first-child {
      padding: ${Theme.Spacings.m}px;
    }
  }

  &:has(+ .${DELIVERY_FEE_ROW_CLASS}) {
    border-bottom: 1px solid ${Theme.Colours.black} !important;
  }

  ${({ isDeliveryFee }) =>
    isDeliveryFee &&
    `
      border-bottom: 1px solid ${Theme.Colours.black} !important;
    `}
`;
