import { slugifyDate } from './format';
import { Period } from 'types/Product';

export const slugifyPeriod = (
  period: Partial<Period> | undefined
): Period | {} => {
  if (!period || Object.values(period).filter(Boolean).length === 0) return {};

  return {
    from: slugifyDate(period.from),
    to: slugifyDate(period.to)
  };
};
