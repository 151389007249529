import { useTranslation } from '@dagens/frontend-i18n';
import { formatPhoneNumber } from '@dagensmat/core';
import { addDays, isAfter, isBefore, subDays } from 'date-fns';
import { shuffle } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import Container from '@carrot-deprecated/container';
import Typography from '@carrot-deprecated/typography';
import { getOrderHistory } from 'api';
import SalesModule from '_producer/pages/landing-page/producer-sales-section/SalesModule';
import { useAppSelector } from '_common/hooks/reduxHooks';
import LinkListElement from '_common/components/link-list-element/LinkListElement';
import { Order } from 'types/Order';
import {
  getFirstDayOfWeek,
  getLastDayOfWeek,
  parseDate
} from 'utils/date/format';
import { PRODUCER_HOME_REMIND_CUSTOMER_LINK, track } from 'utils/mixpanel';
import REQ, { ReqType } from 'utils/REQ';

const getFilteringDates = () => {
  const mondayThisWeek = getFirstDayOfWeek(new Date());
  const sundayThisWeek = getLastDayOfWeek(new Date());
  const sundayLastWeek = subDays(mondayThisWeek, 1);
  const historyStartDate = subDays(sundayLastWeek, 31);
  return {
    mondayThisWeek,
    sundayThisWeek,
    sundayLastWeek,
    historyStartDate
  };
};

const isNotCancelled = (order: Order) => {
  return Boolean(order.statuses?.cancelled) === false;
};
const isWithinDateRange = (order: Order, startDate: Date, endDate: Date) => {
  const deliveryDate = parseDate(order.deliveryDate);
  return isAfter(deliveryDate, startDate) && isBefore(deliveryDate, endDate);
};

const filterOrders = (
  orders: Order[],
  startDate: Date,
  endDate: Date
): Order[] => {
  return orders.filter(order => {
    return (
      isNotCancelled(order) && isWithinDateRange(order, startDate, endDate)
    );
  });
};

const getReminderOrdersHistory = (orders: Order[]) => {
  const { mondayThisWeek, historyStartDate } = getFilteringDates();
  return filterOrders(orders, historyStartDate, mondayThisWeek);
};

const getReminderOrdersThisWeek = (orders: Order[]) => {
  const { sundayThisWeek, sundayLastWeek } = getFilteringDates();
  return filterOrders(orders, sundayLastWeek, addDays(sundayThisWeek, 1));
};

const getCustomerOrderCount = (orders: Order[]) => {
  const orderCountByCustomer = new Map<string, number>();
  orders.forEach(order => {
    const customerId = order.consumer._id;
    orderCountByCustomer.set(
      customerId,
      (orderCountByCustomer.get(customerId) || 0) + 1
    );
  });
  return orderCountByCustomer;
};

const getCustomersInHistoryAndNotThisWeek = (
  filteredOrdersHistory: Order[],
  filteredOrdersThisWeek: Order[]
) => {
  const historyOrderCountByCustomer = getCustomerOrderCount(
    filteredOrdersHistory
  );

  const sortedCustomersIdsByOrderCount = Array.from(
    historyOrderCountByCustomer.entries()
  )
    .sort((a, b) => {
      return b[1] - a[1];
    })
    .map(customer => {
      return customer[0];
    });

  const customerIdsThisWeek = filteredOrdersThisWeek.map(order => {
    return order.consumer._id;
  });

  const customerIdsNotOrderedThisWeek = sortedCustomersIdsByOrderCount.filter(
    id => {
      return !customerIdsThisWeek.includes(id);
    }
  );

  const customersHistory = filteredOrdersHistory.map(order => {
    return order.consumer;
  });

  const customerObjectsNotOrderedThisWeek = customerIdsNotOrderedThisWeek.map(
    customerId => {
      return customersHistory.find(customer => {
        return customer._id === customerId;
      });
    }
  );

  return customerObjectsNotOrderedThisWeek;
};

const ProducerSalesModuleReminder = () => {
  const [req, setReq] = useState<ReqType>(REQ.INIT);
  const [filteredOrdersHistory, setOrdersHistory] = useState<Order[]>([]);
  const { t } = useTranslation();

  const { filteredOrdersThisWeek, producerId } = useAppSelector(
    ({ auth, orders: { items } }) => {
      return {
        producerId: auth._id,
        filteredOrdersThisWeek: getReminderOrdersThisWeek(items)
      };
    }
  );

  const fetchOrderHistory = async () => {
    setReq(REQ.PENDING);
    const { mondayThisWeek, historyStartDate } = getFilteringDates();
    const orderHistory = await getOrderHistory({
      producerId,
      startDate: historyStartDate.toISOString(),
      endDate: mondayThisWeek.toISOString()
    });
    const orders = orderHistory.filter(order => {
      return order._type === 'orders';
    });
    setOrdersHistory(getReminderOrdersHistory(orders));
    setReq(REQ.SUCCESS);
  };

  const twoRandomCustomers = useMemo(() => {
    const customers = getCustomersInHistoryAndNotThisWeek(
      filteredOrdersHistory,
      filteredOrdersThisWeek
    );
    return shuffle(customers.slice(0, 5)).slice(0, 2);
  }, [
    JSON.stringify(filteredOrdersThisWeek),
    JSON.stringify(filteredOrdersHistory)
  ]);

  useEffect(() => {
    fetchOrderHistory();
  }, []);

  if (twoRandomCustomers.length === 0 || req !== REQ.SUCCESS) return null;

  return (
    <SalesModule>
      <Container>
        <Typography variant="paragraphBold">
          {t('producer:ProducerSalesModuleReminderTitle')}
        </Typography>
        <Typography variant="paragraphSmall" color="secondary">
          {t('producer:ProducerSalesModuleReminderDescription')}
        </Typography>
      </Container>
      <Container>
        {twoRandomCustomers.map(customer => {
          const trackClick = () => {
            track(PRODUCER_HOME_REMIND_CUSTOMER_LINK, {
              customer: customer?.name
            });
          };

          return (
            <LinkListElement
              key={customer?._id}
              header={customer?.name}
              subTitle={`${customer?.contactPerson} - ${formatPhoneNumber(
                customer?.phone
              )}`}
              to="/orders/add/delivery-date"
              state={{ returnPath: true, consumer: customer }}
              onClick={trackClick}
            />
          );
        })}
      </Container>
    </SalesModule>
  );
};
export default ProducerSalesModuleReminder;
