import Typography from '@carrot-deprecated/typography';
import Container from '@carrot-deprecated/container';
import HorizontalScrollContainer from '_common/components/utils/HorisontalScrollContainer';

type HighlightedContentSectionProps = {
  sectionTitle: string;
  children: React.ReactChild;
};

const HighlightedContentSection = ({
  sectionTitle,
  children
}: HighlightedContentSectionProps) => {
  return (
    <Container mt="xs">
      <Typography variant="tertiaryHeading">{sectionTitle}</Typography>
      <HorizontalScrollContainer>{children}</HorizontalScrollContainer>
    </Container>
  );
};

export default HighlightedContentSection;
