import { Form } from '@dagens/frontend-forms';
import { StructuredAddress } from '../../../types/Logistics';
import { ValidTimeSlot } from '../../../types/Consumer';
import { CustomerDeliveryInstructions as Instructions } from './delivery-instructions';
import { CustomerDeliveryWindow as Window } from './delivery-window';
import { CustomerDeliveryAddress as Address } from './delivery-address';

export type DeliveryFormValues = {
  deliveryAddress: StructuredAddress | string | null;
  deliveryWindowFrom: ValidTimeSlot | null;
  deliveryWindowTo: ValidTimeSlot | null;
  deliveryInstructions: string | null;
};

type Props = {
  defaultDeliveryAddress?: StructuredAddress | string | null;
};

export const CustomerDeliveryForm = ({ defaultDeliveryAddress }: Props) => {
  return (
    <Form.Section>
      <Address defaultDeliveryAddress={defaultDeliveryAddress} />
      <Window />
      <Instructions />
    </Form.Section>
  );
};
