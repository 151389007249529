import { Nav, NavLinkProps } from '@dagens/carrot';
import { ReactNode } from 'react';
import { useMediaQuery } from '../../../../_common/hooks/useMediaQueries';
import { RouteHistory } from '../../../../router/components/context';
import { BackButton } from '../back-button';

// If the page is one of the main pages, they show the Dagens logo (only on desktop)
// The back button is shown in the other pages (on all devices)
type LeftArgs = {
  backButton?: ReactNode;
  backButtonOnlyOnMobile?: boolean;
  withLogo?: boolean;
  returnTo?: RouteHistory;
  onReturn?: () => void;
};

export const useHeaderNavLeft = ({
  backButton,
  backButtonOnlyOnMobile,
  withLogo,
  returnTo
}: LeftArgs) => {
  const { desktopUp } = useMediaQuery();
  if (desktopUp && (withLogo || backButtonOnlyOnMobile)) {
    return <Nav.LogoHome />;
  }
  if (backButton && backButtonOnlyOnMobile) {
    return backButton;
  }
  if (withLogo) {
    return null;
  }
  if (backButton) {
    return backButton;
  }
  if (returnTo) {
    return <BackButton returnTo={returnTo} />;
  }

  return null;
};

// On desktop the main links are shown
// On mobile the context name is shown, if it exists
type CenterArgs = {
  links: readonly NavLinkProps[];
  mobileText?: ReactNode;
};

export const useHeaderNavCenter = ({ links, mobileText }: CenterArgs) => {
  const { desktopUp } = useMediaQuery();
  if (desktopUp) {
    return <Nav.Links links={links} />;
  }
  if (mobileText) {
    return <Nav.Context>{mobileText}</Nav.Context>;
  }
  return null;
};

// On desktop the menu is always shown on the right
// On mobile, the menu is shown if the alwaysShowMenu is true,
// otherwise the mobileAction is shown, if it exists
type RightArgs = {
  menu: ReactNode;
  mobileAction?: ReactNode;
  alwaysShowMenu?: boolean;
};

export const useHeaderNavRight = ({
  menu,
  mobileAction,
  alwaysShowMenu
}: RightArgs) => {
  const { desktopUp } = useMediaQuery();
  if (desktopUp || alwaysShowMenu) {
    return menu;
  }
  return mobileAction;
};
