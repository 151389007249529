import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { Button } from '@dagens/carrot';
import { useMediaQuery } from '../../../../_common/hooks/useMediaQueries';
import Typography from '@carrot-deprecated/typography';
import Container from '@carrot-deprecated/container';

const Illustration = styled.img`
  width: auto;
  height: auto;
  max-height: 220px;
  max-width: 240px;
  object-fit: contain;
`;

type IHomePageOnboardingMessage = {
  title: string;
  buttonText: string;
  buttonToLink: string;
  imageSrc?: string;
  children: JSX.Element;
};
const HomePageOnboardingMessage = ({
  title,
  buttonText,
  buttonToLink,
  imageSrc,
  children
}: IHomePageOnboardingMessage) => {
  const navigate = useNavigate();
  const mediaQuery = useMediaQuery();

  return (
    <Container
      flex
      flexBasis="100%"
      direction={mediaQuery.desktopUp ? 'row' : 'column-reverse'}
      wrap="nowrap"
      gap="s"
      borderColor="lightGrey"
      px="m"
      py="m"
    >
      <Container
        flex
        direction="column"
        justify="space-between"
        alignItems="baseline"
      >
        <Typography variant="paragraphBold" as="p" mb="s">
          {title}
        </Typography>
        <Container mb="s">{children}</Container>
        <Button
          onClick={() => {
            navigate(buttonToLink, { state: { returnPath: true } });
          }}
        >
          {buttonText}
        </Button>
      </Container>
      {imageSrc && (
        <Container
          flex
          alignItems="center"
          px={mediaQuery.desktopUp ? 'l' : undefined}
        >
          <Illustration src={imageSrc} alt="Onboarding illustration" />
        </Container>
      )}
    </Container>
  );
};

export default HomePageOnboardingMessage;
