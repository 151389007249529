import { useTranslation } from '@dagens/frontend-i18n';
import { useState } from 'react';

import { postConsumerUpdate, postProducerUpdate } from '../../../api';
import { useAppDispatch, useAppSelector } from '../../hooks/reduxHooks';
import { userUpdated } from '../../reducers/auth';

export const useEmails = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { emails, roleType, userId } = useAppSelector(({ auth }) => {
    return {
      emails: auth.emails ?? [],
      roleType: auth._type,
      userId: auth._id
    };
  });
  const [feedback, setFeedback] = useState<{
    message: string;
    isError: boolean;
  } | null>(null);

  const [removing, setRemoving] = useState<string[]>([]);
  const hideRemoveButton = emails && emails.length - removing.length <= 1;

  const onAdd = async (value: string) => {
    try {
      const newEmails = [...emails, value];
      if (roleType === 'consumers') {
        await postConsumerUpdate({
          consumerId: userId as string,
          emails: newEmails
        });
      } else if (roleType === 'producers') {
        await postProducerUpdate({ userId, emails: newEmails });
      }
      dispatch(userUpdated({ emails: newEmails }));
      setFeedback({
        message: t('common:AddNotificationEmailSuccessText', { email: value }),
        isError: false
      });
    } catch (e) {
      setFeedback({
        message: t('common:AddNotificationEmailErrorText', { email: value }),
        isError: true
      });
      throw e;
    }
  };

  const onAddInputChange = () => {
    setFeedback(null);
  };

  const onRemove = async (email: string) => {
    setFeedback(null);
    setRemoving(prev => {
      return [...prev, email];
    });

    const newEmails = emails?.filter(e => {
      return e !== email && !removing.includes(e);
    });

    if (roleType === 'consumers') {
      await postConsumerUpdate({
        consumerId: userId as string,
        emails: newEmails
      });
    } else if (roleType === 'producers') {
      await postProducerUpdate({ userId, emails: newEmails });
    }
  };

  const onRemoveSuccess = (email: string) => {
    setRemoving(prev => {
      return prev.filter(e => {
        return e !== email;
      });
    });

    const newEmails = emails?.filter(e => {
      return e !== email && !removing.includes(e);
    });
    dispatch(userUpdated({ emails: newEmails }));
  };

  const onRemoveError = (email: string) => {
    setRemoving(prev => {
      return prev.filter(e => {
        return e !== email;
      });
    });
  };

  return {
    emails,
    hideRemoveButton,
    feedback,
    onAdd,
    onAddInputChange,
    onRemove,
    onRemoveSuccess,
    onRemoveError
  };
};
