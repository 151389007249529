import { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ProducerProduct } from '../../../types/Product';
import Logger from '../../../services/Logger';
import { useAppDispatch } from '_common/hooks/reduxHooks';
import { productEdited as updateProductInStore } from '_producer/reducers/products';
import { deleteProduct } from 'api';

// Deletes product and updates the store
export const useOnDelete = (originalProduct: ProducerProduct) => {
  const [isDeleting, setIsDeleting] = useState(false);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const onDelete = useCallback(async () => {
    setIsDeleting(true);
    const productId = originalProduct._id;

    try {
      await deleteProduct({ productId });
      dispatch(
        updateProductInStore({
          _id: productId,
          _updatedAt: new Date().toISOString(),
          deleted: true
        })
      );
      navigate('/my-products');
    } catch (error) {
      Logger.error(error as Error);
    }
  }, [dispatch, navigate]);

  return { isDeleting, onDelete };
};
