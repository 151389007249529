import { InputHTMLAttributes } from 'react';
import { ByComparator, ComparisonUtils } from '@dagens/utils';
import { tv } from '../../utils/tv';
import { Icon } from '../../theme/icon';
import { nbsp } from '../../utils/nbsp';
import { Checkbox } from '../checkbox';
import { TreeValue } from '../../utils/tree-value';

const style = tv({
  base: `
    flex
    flex-wrap
    gap-xxs
  `
});

type InputProps = InputHTMLAttributes<HTMLInputElement>;

export type CheckboxesTreeProps<T> = {
  name?: InputProps['name'];
  options: TreeValue<T>[];
  displayValue?: (value?: TreeValue<T>) => string | undefined;
  by?: ByComparator<TreeValue<T>>;

  value: TreeValue<T>[];
  onChange: (value: TreeValue<T>[]) => void;
};

export const CheckboxesTree = <T,>({
  value,
  options,
  onChange,
  displayValue,
  by
}: CheckboxesTreeProps<T>) => {
  const lastValue = value.at(-1);
  const resolvedOptions = lastValue
    ? [...value, ...(lastValue.children ?? [])]
    : options;

  const handleChange = (checked: boolean, option: TreeValue<T>) => {
    if (checked) {
      onChange([...value, option]);
      return;
    }

    const index = value.findIndex(v => ComparisonUtils.equal(by, v, option));
    if (index !== -1) {
      onChange(value.slice(0, index));
    }
  };

  return (
    <div className={style()}>
      {resolvedOptions.map(option => {
        const text = displayValue ? displayValue(option) : option?.toString();
        const checked = value.some(v => ComparisonUtils.equal(by, v, option));
        return (
          <Checkbox
            key={text}
            variant="button"
            checked={checked}
            onChange={checked => handleChange(checked, option)}
          >
            {text ?? nbsp}
            {checked && <Icon icon="clear" size="small" />}
          </Checkbox>
        );
      })}
    </div>
  );
};
