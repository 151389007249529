import { PropsWithChildren } from 'react';
import {
  DefaultValues,
  FieldValues,
  FormProvider as RHFProvider,
  useForm
} from 'react-hook-form';

type Props<Form extends FieldValues> = PropsWithChildren<{
  defaultValue?: DefaultValues<Form>;
}>;

// Initializes the form with the default values
export const FormProvider = <Form extends FieldValues>({
  defaultValue,
  children
}: Props<Form>) => {
  const methods = useForm<Form>({ defaultValues: defaultValue ?? undefined });
  return <RHFProvider {...methods}>{children}</RHFProvider>;
};
