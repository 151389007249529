import { useTranslation } from '@dagens/frontend-i18n';
import { getOrderLineAggregates } from '@dagensmat/core';
import React from 'react';
import { Select } from '../../../carrot-deprecated/select';
import OverviewTable from './OverviewTable';
import Container from '@carrot-deprecated/container';
import Typography from '@carrot-deprecated/typography';
import { Order } from 'types/Order';
import { getItemFromLocalStorage, updateLocalStorage } from 'utils/clientcache';

import { getOrdersForNthWeek } from '_common/reducers/orders';

const ALL_ORDERS = 'ALL_ORDERS';
const THIS_WEEK = 'THIS_WEEK';
const NEXT_WEEK = 'NEXT_WEEK';

type OrderOverviewProps = {
  orders: Order[];
};

const OrderOverview = ({ orders = [] }: OrderOverviewProps) => {
  const { t } = useTranslation();

  const SELECTABLE_OPTIONS = [
    {
      value: ALL_ORDERS,
      name: t('common:allOrders')
    },
    {
      value: THIS_WEEK,
      name: t('common:thisWeek')
    },
    {
      value: NEXT_WEEK,
      name: t('common:nextWeek')
    }
  ];

  const [selectedValue, setSelectedValue] = React.useState<string>(
    getItemFromLocalStorage('defaultOverviewGroup') ||
      SELECTABLE_OPTIONS[0].value
  );

  const weeksFromNow = selectedValue === NEXT_WEEK ? 1 : 0;

  const ordersToRender =
    selectedValue === ALL_ORDERS
      ? orders
      : getOrdersForNthWeek(orders, weeksFromNow);
  return (
    <Container mt="m">
      <Container flex mb="m" direction="column">
        <Typography variant="secondaryHeading" mb="s">
          {t('common:OrderedProducts')}
        </Typography>
        <Select
          id="orderOverviewSelect"
          options={SELECTABLE_OPTIONS}
          selectedValue={selectedValue}
          onChange={newValue => {
            const newOption = SELECTABLE_OPTIONS.find(({ value }) => {
              return value === newValue;
            })?.value;
            if (!newOption) {
              return;
            }
            setSelectedValue(newOption);
            updateLocalStorage('defaultOverviewGroup', newOption);
          }}
          width="auto"
        />
      </Container>
      <OverviewTable
        orderLineAggregates={getOrderLineAggregates(ordersToRender)}
      />
    </Container>
  );
};

export default OrderOverview;
