import { Dinero } from 'dinero.js';
import { capitalize } from 'lodash';
import { Icon } from '@dagens/carrot';
import Typography from '@carrot-deprecated/typography';
import Container from '@carrot-deprecated/container';
import Separator from '_common/components/utils/Separator.style';
import { formatDate } from 'utils/date/format';
import { fmtMoney } from 'utils/texts';

type CheckoutFooterDateLineProps = {
  deliveryDate: string;
  dayTotal: Dinero<number>;
  error: boolean;
  warning: boolean;
};

const CheckoutFooterDateLine = ({
  deliveryDate,
  dayTotal,
  error,
  warning
}: CheckoutFooterDateLineProps) => {
  return (
    <>
      <Container flex justify="space-between" alignItems="baseline" mb="xs">
        <Typography variant="paragraphSmall">
          {capitalize(formatDate(deliveryDate))}
          {error && <Icon icon="alert" color="error" />}
          {warning && <Icon icon="alert" color="warning" />}
        </Typography>
        <Typography variant="paragraphSmall">{fmtMoney(dayTotal)}</Typography>
      </Container>
      <Separator />
    </>
  );
};

export default CheckoutFooterDateLine;
