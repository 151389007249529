const heartbeatKeyframes = {
  '0%, 80%': {
    opacity: '1'
  },
  '0%, 60%': {
    transform: 'scale(1)'
  },
  '30%': {
    transform: 'scale(1.3)'
  },
  '100%': {
    opacity: '0'
  }
};

const pingKeyframes = {
  '75%, 100%': {
    transform: 'scale(1.8)',
    opacity: '0'
  }
};

const heartbeat = 'heartbeatKeyframes ease-in-out 0.5s';

const ping = 'pingKeyframes cubic-bezier(0, 0, 0.2, 1) 0.8s infinite';

export const themeKeyframes = {
  heartbeatKeyframes,
  pingKeyframes
} as const;

export const themeAnimations = {
  heartbeat,
  ping
} as const;

export type ThemeAnimation = keyof typeof themeAnimations;
