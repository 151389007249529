import { useWatch } from '@dagens/frontend-forms';
import { PriceCard } from '@dagens/carrot';
import { useTranslation } from '@dagens/frontend-i18n';
import { PricingFormValues } from '..';
import { truncate } from '../../../../utils/array';
import { usePricingToString } from '../hooks/use-pricing-to-string';

export const PricingFormPricePreview = () => {
  const { t } = useTranslation();
  const {
    customers,
    priceValue,
    priceUnit,
    orderValue,
    orderUnit,
    orderEqualsPrice
  } = useWatch<PricingFormValues>();
  const value = usePricingToString(
    priceValue,
    priceUnit,
    orderValue,
    orderUnit,
    orderEqualsPrice
  );

  if (!value) {
    return null;
  }

  return (
    <PriceCard
      title={
        customers && customers.length > 0
          ? truncate({
              list: customers.map(c => c.name),
              numToShow: 1,
              addendum: t('common:more')
            })
          : t('common:AllBuyers')
      }
      tag={t('common:CustomOffer')}
      value={value}
    />
  );
};
