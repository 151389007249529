import { Dialog, DialogBackdrop, DialogPanel } from '@headlessui/react';
import { PropsWithChildren } from 'react';
import { tv } from '../../utils/tv';

// eslint-disable-next-line tailwindcss/no-custom-classname
const style = tv({
  slots: {
    dialog: `
      relative
      z-20
    `,
    backdrop: `
      fixed
      inset-0
      bg-black/75
      transition-opacity

      data-[closed]:opacity-0

      data-[enter]:duration-300
      data-[enter]:ease-out

      data-[leave]:duration-200
      data-[leave]:ease-in
    `,
    content: `
      fixed
      inset-0
      z-10
      flex
      w-screen
      items-end
      justify-center
      px-s
      py-xl

      desktop:items-center
    `,
    panel: `
      flex
      w-full
      max-w-[480px]
      flex-col
      rounded
      bg-white
    `
  },
  variants: {
    size: {
      small: {
        panel: 'max-w-[320px]'
      },
      medium: {
        panel: 'max-w-[480px]'
      },
      large: {
        panel: 'max-w-[750px]'
      },
      full: {
        panel: 'max-w-[100%]'
      }
    }
  }
});

type Props = PropsWithChildren<{
  open: boolean;
  size?: keyof typeof style.variants.size;
  onClose: () => void;
}>;

export const Modal = ({ open, size = 'medium', children, onClose }: Props) => {
  const { dialog, backdrop, content, panel } = style();
  return (
    <Dialog
      transition
      className={dialog()}
      open={open}
      onClose={() => {
        onClose();
      }}
    >
      <DialogBackdrop transition className={backdrop()} />
      <div className={content()}>
        <DialogPanel className={panel({ size })}>{children}</DialogPanel>
      </div>
    </Dialog>
  );
};
