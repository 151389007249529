import { tv } from '../../utils/tv';

export const styles = tv({
  base: `
    mb-xl
    flex
    flex-col
    gap-xl
  `
});
