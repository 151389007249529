import { ReactNode } from 'react';
import { useAppSelector } from '../../../../_common/hooks/reduxHooks';
import { RootState } from '../../../../store';
import { RouteHistory } from '../../../../router/components/context';
import { HeaderNavAuth } from './auth';
import { HeaderNavConsumer } from './consumer';
import { HeaderNavProducer } from './producer';
import { HeaderNavUnauth } from './unauth';

export type HeaderNavProps = {
  backButton?: ReactNode;
  backButtonOnlyOnMobile?: boolean;
  withLogo?: boolean;
  returnTo?: RouteHistory;
  mobileText?: ReactNode;
  alwaysShowMenu?: boolean;
  mobileAction?: ReactNode;
};

export const HeaderNav = (props: HeaderNavProps) => {
  const { authenticated, role, accountCount } = useAppSelector(
    ({ auth }: RootState) => {
      return {
        authenticated: auth.authenticated,
        role: auth._type,
        accountCount: auth.options?.length
      };
    }
  );

  if (authenticated === undefined) {
    return null;
  }

  if (role === 'consumers') {
    return <HeaderNavConsumer {...props} />;
  }

  if (role === 'producers') {
    return <HeaderNavProducer {...props} />;
  }

  if (accountCount === 0) {
    return <HeaderNavAuth />;
  }

  return <HeaderNavUnauth />;
};
