import { PropsWithChildren } from 'react';
import { tv } from '../../utils/tv';

const style = tv({
  base: `
    flex
    items-center
    gap-xxs
  `
});

type Props = PropsWithChildren;

export const ButtonGroupNoWrap = ({ children }: Props) => {
  return <div className={style()}>{children}</div>;
};
