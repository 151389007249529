import { useTranslation } from '@dagens/frontend-i18n';
import styled from 'styled-components';
import { Illustrations } from '../../../utils/Illustrations';
import { MediaQuery } from '../../../utils/mediaQueries';
import Callout from '@carrot-deprecated/callout';
import Container from '@carrot-deprecated/container';
import Typography from '@carrot-deprecated/typography';
import Theme from '@carrot-deprecated/theme';

const StyledImg = styled.img`
  width: 240px;
  height: auto;

  ${MediaQuery.tabletUp} {
    width: 32%;
    margin-right: ${Theme.Spacings.m}px;
  }
`;

export type LoginCalloutProps = {
  type?: 'verifyEmail' | 'connectAccount' | 'emailVerified';
};

export const LoginCallout = ({ type }: LoginCalloutProps) => {
  const { t } = useTranslation();

  switch (type) {
    case 'verifyEmail':
      return (
        <Callout my={'s'}>
          <Typography variant="paragraph">
            {t('common:VerifyEmailCallout')}
          </Typography>
        </Callout>
      );
    case 'emailVerified':
      return (
        <Callout my={'s'}>
          <Typography variant="paragraph">
            {t('common:WelcomeToDagensEmailVerified')}
          </Typography>
        </Callout>
      );
    case 'connectAccount':
      return (
        <Callout my={'s'}>
          <Typography variant="paragraph">
            {t('common:ConnectAccountCallout')}
          </Typography>
        </Callout>
      );
    default:
      return (
        <Callout colour={'white'} mb={'s'}>
          <Container
            flex
            direction={MediaQuery.tabletUp ? 'row-reverse' : 'column'}
            alignItems={MediaQuery.tabletUp ? 'flex-start' : 'center'}
            justify={MediaQuery.tabletUp ? 'space-between' : undefined}
          >
            <StyledImg
              src={Illustrations.PROUD_PRODUCER_FARM}
              alt={t('common:Illustration')}
            />
            <div>
              <Typography variant="tertiaryHeading" as="h1" my="s">
                {t('common:LoginCalloutHeader')}
              </Typography>
              <Typography variant="paragraph">
                {t('common:LoginCalloutContent')}
              </Typography>
            </div>
          </Container>
        </Callout>
      );
  }
};
