import { useTranslation } from '@dagens/frontend-i18n';
import { PropsWithChildren } from 'react';
import { PageHeader } from '@dagens/carrot';
import { Spacings } from '@carrot-deprecated/theme';
import { ButtonLink } from '_common/components/button/Button.style';
import Tabs from '_common/components/tabs/Tabs';
import Icon from '_common/components/utils/Icon';
import { Page } from '@components/page';

type Props = PropsWithChildren<{
  numUnopenedOrders?: number;
}>;

const OrdersPageHOC = ({ numUnopenedOrders = 0, children }: Props) => {
  const { t } = useTranslation();
  return (
    <Page>
      <PageHeader
        title={t('common:MyOrders')}
        subtitle={t('common:newOrders', {
          count: numUnopenedOrders
        })}
      />
      <ButtonLink to="/orders/add/start" mb={Spacings.m} variant="primary">
        <Icon icon="add" mr={Spacings.xs} />
        {t('common:MakeNewOrder')}
      </ButtonLink>
      <Tabs
        tabs={[
          {
            text: t('common:Product_other'),
            to: '/orders/overview'
          },
          { text: t('common:Delivery_other'), to: '/orders' },
          { text: t('producer:Invoicing'), to: '/orders/invoice' }
        ]}
      />
      {children}
    </Page>
  );
};

export default OrdersPageHOC;
