import { useTranslation } from '@dagens/frontend-i18n';
import React from 'react';
import Typography from '@carrot-deprecated/typography';
import Container from '@carrot-deprecated/container';
import TextArea from '_common/components/text-area/TextArea.style';
import { useAppSelector } from '_common/hooks/reduxHooks';
import { ActionButton } from '@components/action-button';
import { postRequestProduct } from 'api';
import REQ, { ReqType } from 'utils/REQ';

type SendRequestProps = {
  productId: string;
};

const SendRequest = ({ productId }: SendRequestProps) => {
  const { t } = useTranslation();
  const consumerId = useAppSelector(({ auth: { _id } }) => {
    return _id;
  });

  const [req, setReq] = React.useState<ReqType>(REQ.INIT);
  const [edited, setEdited] = React.useState(false);
  const [onRequestText, setOnRequestText] = React.useState('');

  const sendRequest = () => {
    const payload = {
      consumerId,
      productId,
      message: onRequestText
    };

    setReq(REQ.PENDING);

    postRequestProduct(payload)
      .then(() => {
        setReq(REQ.SUCCESS);
        setEdited(false);
      })
      .catch(() => {
        setReq(REQ.ERROR);
      });
  };

  return (
    <Container my="m">
      <Typography variant="inputLabel" mb="xxs">
        {t('common:RequestProductMessageInputLabel')}
      </Typography>
      <Typography variant="paragraphSmall" color="secondary" mb="xxs">
        {t('common:RequestProductMessageInputHelpText')}
      </Typography>
      <TextArea
        placeholder={t('common:EnterDesiredQuanitityDateOrQuestion')}
        value={onRequestText}
        onChange={({ target: { value } }) => {
          setOnRequestText(value);
          setEdited(true);
          setReq(REQ.INIT);
        }}
      />
      <Container mt="s">
        <ActionButton.Save
          saveReq={req}
          disabled={!edited || onRequestText.length < 5}
          onClick={sendRequest}
          feedbackText={{
            [REQ.PENDING]: t('common:SendingRequest'),
            [REQ.SUCCESS]: t('common:RequestSentConfirmation')
          }}
        >
          {t('common:SendRequest')}
        </ActionButton.Save>
      </Container>
    </Container>
  );
};

export default SendRequest;
