import { useTranslation } from '@dagens/frontend-i18n';
import styled from 'styled-components';
import { useMediaQuery } from '@dagens/carrot';
import { ProducerDeliveryFee } from '../../../types/Producer';
import { OrderProducerDeliveryFee } from '../../../types/Order';
import Theme from '../../../carrot-deprecated/theme';
import { ChangedPriceCell } from './ChangedPriceCell';
import Typography from '@carrot-deprecated/typography';
import Icon from '_common/components/utils/Icon';

export const DELIVERY_FEE_ROW_CLASS = 'delivery-fee-row';

const StyledRow = styled.tr<{
  isReadOnly?: boolean;
}>`
  cursor: ${({ isReadOnly }) => (isReadOnly ? 'default' : 'pointer')};
  border-bottom: 1px solid ${Theme.Colours.black} !important;
`;

type DeliveryFeeRowProps = {
  orderDeliveryFee: OrderProducerDeliveryFee;
  originalDeliveryFee?: ProducerDeliveryFee;
  onClick: () => void;
  isReadOnly?: boolean;
};

export const DeliveryFeeRow = ({
  orderDeliveryFee,
  onClick,
  isReadOnly = false
}: DeliveryFeeRowProps) => {
  const { t } = useTranslation();
  const { mobileOnly } = useMediaQuery();
  return (
    <StyledRow
      isReadOnly={isReadOnly}
      className={DELIVERY_FEE_ROW_CLASS}
      onClick={onClick}
    >
      <td className={mobileOnly ? 'col-span-2' : 'col-span-3'}>
        <Typography variant="paragraphSmall">
          {t('producer:deliveryFee')}
        </Typography>
      </td>
      <td
        className={`
          relative
          !text-right
        `}
      >
        <ChangedPriceCell orderDeliveryFee={orderDeliveryFee} />
      </td>
      {!isReadOnly && (
        <td className="!pl-s">
          <Icon icon="edit" $size="m" fill="deepPurple" />
        </td>
      )}
    </StyledRow>
  );
};
