import { css } from 'styled-components';
import MediaQueries from './media-queries';

enum WidthEnum {
  full = 100,
  half = 49,
  third = 33,
  auto = 'auto'
}

enum MaxWidthEnum {
  full = 992,
  half = 488,
  third = 320,
  fullMobileOnly = 388,
  auto = 'auto'
}

export type WidthNames =
  | 'full'
  | 'half'
  | 'third'
  | 'fullMobileOnly'
  | 'halfTabletUp'
  | 'halfDesktopUp'
  | 'thirdDesktopUp';

export type WidthProps = {
  width?: WidthNames;
  maxWidth?: string;
};

const Widths = css<WidthProps>`
  ${({ width }) => {
    if (width === 'full') {
      return css`
        width: ${WidthEnum.full}%;
        max-width: ${MaxWidthEnum.full}px;
      `;
    }
    if (width === 'half') {
      return css`
        width: ${WidthEnum.half}%;
        max-width: ${MaxWidthEnum.half}px;
      `;
    }
    if (width === 'third') {
      return css`
        width: ${WidthEnum.third}%;
        max-width: ${MaxWidthEnum.third}px;
      `;
    }
    if (width === 'fullMobileOnly') {
      return css`
        ${MediaQueries.mobileOnly} {
          width: ${WidthEnum.full}%;
          max-width: ${MaxWidthEnum.fullMobileOnly}px;
        }
      `;
    }
    if (width === 'halfTabletUp') {
      return css`
        ${MediaQueries.tabletUp} {
          width: ${WidthEnum.half}%;
          max-width: ${MaxWidthEnum.half}px;
        }
      `;
    }
    if (width === 'halfDesktopUp') {
      return css`
        ${MediaQueries.desktopUp} {
          width: ${WidthEnum.half}%;
          max-width: ${MaxWidthEnum.half}px;
        }
      `;
    }
    if (width === 'thirdDesktopUp') {
      return css`
        ${MediaQueries.desktopUp} {
          width: ${WidthEnum.third}%;
          max-width: ${MaxWidthEnum.third}px;
        }
      `;
    }
    return css``;
  }}

  ${({ maxWidth }) => {
    return (
      maxWidth &&
      css`
        max-width: ${maxWidth};
      `
    );
  }}
`;

export default Widths;
