import { PropsWithChildren } from 'react';
import { tv } from '../../../utils/tv';
import { Icon } from '../../../theme/icon';
import { inputStyle } from '../../input/styles';

const contentStyle = tv({
  base: `
    flex
    flex-1
    flex-wrap
    gap-xxs
  `
});

type Props = PropsWithChildren<{
  inputId: string;
  error?: boolean;
  open: boolean;
}>;

export const ComboboxContainer = ({
  inputId,
  error,
  open,
  children
}: Props) => {
  const { container } = inputStyle({ error });
  return (
    <label className={container()} htmlFor={inputId}>
      <div className={contentStyle()}>{children}</div>
      <Icon icon={open ? 'collapse' : 'expand'} size="small" />
    </label>
  );
};
