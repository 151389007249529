import './index.css';
import '@dagens/carrot/tailwind.css';

import { initI18n } from '@dagens/frontend-i18n';
import i18n from 'i18next';
import { Suspense } from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { ThemeProvider } from 'styled-components';

import App from './App';
import { initiateCorrelationId } from './services/CookieService';
import theme from './theme';
import { loadHelpScout } from './utils/loadHelpScout';
import setupAirbrake from 'utils/errorHandling';
import { store } from 'store';
import ErrorBoundary from '_common/components/error-boundary/ErrorBoundary';

initI18n(i18n);

loadHelpScout();

const airbrake = setupAirbrake(store);

/** Remove the fallback loader element */
const loaderEl = document.getElementById('loader');
if (loaderEl) {
  loaderEl.remove();
}

/** Refresh page every 2nd hour. Some users stay on Dagens for days and never refresh the page... */
const REFRESH_INTERVAL = 2 * 60 * 60 * 1000;
setInterval(() => {
  window.location.reload();
}, REFRESH_INTERVAL);

initiateCorrelationId();

const container = document.getElementById('root');
if (!container) {
  throw new Error('Could not find root element');
}

const root = createRoot(container);
root.render(
  <Suspense fallback={null}>
    <Provider store={store}>
      <ErrorBoundary airbrake={airbrake}>
        <ThemeProvider theme={theme}>
          <App />
        </ThemeProvider>
      </ErrorBoundary>
    </Provider>
  </Suspense>
);
