import { useMemo } from 'react';
import { differenceInCalendarDays } from 'date-fns';
import { useAppSelector } from '../../../_common/hooks/reduxHooks';
import { RootState } from '../../../store';
import {
  ProducerProduct,
  ProductCapacity,
  SeasonCalendar
} from '../../../types/Product';
import { getImageUrl } from '../../../utils/imageUrls';
import { ProductFormValues } from '../../components/product-form';
import REQ from '../../../utils/REQ';
import { ProductFormPricesValues } from '../../components/product-form/edit';

// Convert season calendar to {from, to} periods,
// or 'ALL' if the season has a period with only a 'from' date
const seasonCalendarToPeriods = (seasonCalendar: SeasonCalendar) => {
  const seasonCalendarDates = seasonCalendar.map(period => {
    if (!('to' in period)) {
      return null;
    }

    return {
      from: new Date(period.from),
      to: new Date(period.to)
    };
  });

  const periods = seasonCalendarDates.includes(null)
    ? 'ALL'
    : seasonCalendarDates.filter(v => v !== null);

  return periods;
};

// Convert a list of individual dates to a list of date ranges
const datesToRanges = (dates: Date[]) => {
  const sortedDates = dates.sort((a, b) => a.getTime() - b.getTime());

  const ranges = [];
  let range = { from: sortedDates[0], to: sortedDates[0] };
  for (let i = 1; i < sortedDates.length; i++) {
    const date = sortedDates[i];
    if (differenceInCalendarDays(date, range.to) === 1) {
      range.to = date;
    } else {
      ranges.push(range);
      range = { from: date, to: date };
    }
  }
  ranges.push(range);
  return ranges;
};

// Convert ProductCapacities to a date ranges
const capacitiesToRanges = (capacities: ProductCapacity[]) => {
  const dates = capacities
    .map(({ deliveryDate, units }) => {
      if (units === 0) {
        return new Date(deliveryDate);
      }
    })
    .filter(date => date !== undefined);
  return dates.length > 0 ? datesToRanges(dates) : [];
};

// Resolve default values for the product form, from an existing product
export const useDefaultValue = (product: ProducerProduct) => {
  const { categories, categoriesReq } = useAppSelector(
    ({ producerCategories: { categories, req } }: RootState) => ({
      categories,
      categoriesReq: req
    })
  );

  const previewUrl = useMemo(
    () => (product.image ? getImageUrl(product.image) : null),
    [product.image]
  );

  const category = categories.find(
    category => category._id === product.categoryId
  );

  const defaultValue: ProductFormValues & ProductFormPricesValues = {
    name: product.name,
    image: {
      previewUrl: previewUrl ?? undefined
    },
    category: category ? [category] : [],
    type: product.type,
    certifications: product.certifications ?? [],
    processedState: product.processedState,
    temperatureZone: product.temperatureZone,
    description: product.description,
    salesStatus: product.forSaleStatus,
    seasonCalendar: seasonCalendarToPeriods(product.seasonCalendar),
    capacities: capacitiesToRanges(product.capacities ?? []),
    prices: [...product.prices].sort((a, b) => {
      return (
        (a.specialConsumers?.length ?? 0) - (b.specialConsumers?.length ?? 0)
      );
    })
  };
  return {
    defaultValue,
    req: categories.length !== 0 ? REQ.SUCCESS : categoriesReq
  };
};
