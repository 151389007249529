import { useTranslation } from '@dagens/frontend-i18n';
import styled from 'styled-components';
import DeferChangeHOC from '_common/components/defer-change-hoc/DeferChangeHOC';
import InputWithLabel from '_common/components/input/InputWithLabel';
import RadioButtons from '_common/components/radio-buttons/RadioButtons';
import SectionHOC from '_common/components/section-hoc/SectionHOC';

const MaxWidthContainer = styled.div`
  width: 100%;
  max-width: 490px;
`;

const DeferredInputWithLabel = DeferChangeHOC(InputWithLabel);

const selectedExplanationText = (codeNumber: string | null) => {
  const { t } = useTranslation();
  switch (codeNumber) {
    case 'DK-ØKO-050':
      return t('producer:ExplanationTextDK050');
    case 'DK-ØKO-100':
      return t('producer:ExplanationTextDK100');
    default:
      return null;
  }
};

type ProfileEcologicSectionProps = {
  ecologicNumber?: string;
  ecologicCodeNumber?: string | null;
  onFieldsChange: (fields: any) => Promise<any>;
};

const ProfileEcologicSection = ({
  ecologicNumber = '',
  ecologicCodeNumber = null,
  onFieldsChange
}: ProfileEcologicSectionProps) => {
  const { t } = useTranslation();
  return (
    <MaxWidthContainer>
      <RadioButtons
        useRawString
        headerText={t('producer:CodeNumber')}
        unitOptions={[
          {
            key: 'None',
            value: t('producer:CodeNumberNone')
          },
          {
            key: 'DK-ØKO-050',
            value: t('producer:CodeNumberDK050')
          },
          {
            key: 'DK-ØKO-100',
            value: t('producer:CodeNumberDK100')
          }
        ]}
        unit={ecologicCodeNumber}
        unitChanged={value => {
          onFieldsChange({
            ecologicCodeNumber: value === 'None' ? null : value
          });
        }}
        explanationText={selectedExplanationText(ecologicCodeNumber)}
      />
      {ecologicCodeNumber ? (
        <DeferredInputWithLabel
          labelText={t('producer:EcologicNumber')}
          placeholder={t('producer:EcologicNumberPlaceholder')}
          value={ecologicNumber}
          savedText={t('producer:EcologicNumberSavedText')}
          validationFunction={val => {
            return (val && val.length >= 5) || val === '';
          }}
          onChange={value => {
            onFieldsChange({
              ecologicNumber: value || null
            });
          }}
          explanationText={t('producer:ExplanationTextEcologicalNumber')}
        />
      ) : null}
    </MaxWidthContainer>
  );
};

export default SectionHOC(ProfileEcologicSection);
