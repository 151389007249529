import { useTranslation } from '@dagens/frontend-i18n';
import styled from 'styled-components';
import { MediaQuery } from '../../../utils/mediaQueries';
import Container from '@carrot-deprecated/container';
import Theme from '@carrot-deprecated/theme';
import Picker from '_common/components/picker/Picker';
import ProductReference from '_common/components/product-reference/ProductReference';
import Tag from '_common/components/utils/Tag';
import PricingDisplay from '_common/components/pricing/PricingDisplay';
import { ProducerProduct } from 'types/Product';
import { findRightConsumerPrice, isCustomOffer } from 'utils/product-pricing';

const StyledContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  padding: ${Theme.Spacings.s}px;
  gap: ${Theme.Spacings.xs}px;
  border-bottom: 1px solid ${Theme.Colours.lightGrey};
  ${MediaQuery.mobileOnly} {
    grid-template-columns: auto;
    padding-right: 0;
    padding-left: 0;
  }
`;

type ProductPickerListElementProps = {
  product: ProducerProduct;
  units: number;
  onPick: (productId: string, newUnits: number) => void;
  consumerId?: string;
};

const ProductPickerListElement = ({
  product,
  units,
  onPick,
  consumerId
}: ProductPickerListElementProps) => {
  const { t } = useTranslation();

  const { _id: productId, prices = [] } = product;

  const pricing = findRightConsumerPrice(prices, consumerId);

  return (
    <StyledContainer>
      <div>
        <Container flex justify="flex-start" wrap="wrap-reverse">
          <ProductReference
            product={product}
            variant="regular"
            mr={Theme.Spacings.xxs}
            mt={Theme.Spacings.xxs}
          />
          {pricing && isCustomOffer(pricing) && (
            <Tag
              bgColor={Theme.Colours.noteworthy}
              text={t('common:CustomOffer')}
            />
          )}
        </Container>
        <PricingDisplay price={pricing} color="secondary" />
      </div>
      <Picker
        nofUnits={units}
        decimal
        unit={pricing?.orderedUnit}
        setNofUnits={(_origin: string, newUnits: number) => {
          onPick(productId, newUnits);
        }}
      />
    </StyledContainer>
  );
};

export default ProductPickerListElement;
