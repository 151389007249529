import { tv } from '../../utils/tv';
import { NavLink, NavLinkProps } from './link';

const style = tv({
  base: `
    grid
    auto-cols-fr
    grid-flow-col
    gap-xxs
    border-t-thin
    border-t-lightGrey
    px-xxs
    pb-m
  `
});

type Props = {
  links: readonly NavLinkProps[];
};

export const NavTabs = ({ links }: Props) => {
  return (
    <div className={style()}>
      {links.map(link => (
        <div key={link.label}>
          <NavLink mobile {...link} />
        </div>
      ))}
    </div>
  );
};
