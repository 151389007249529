import { i18n } from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import HttpApi from 'i18next-http-backend';
import resourcesToBackend from 'i18next-resources-to-backend';
import { initReactI18next } from 'react-i18next';

export const initI18n = (instance: i18n) => {
  void instance
    .use(HttpApi)
    .use(LanguageDetector)
    .use(initReactI18next)
    .use(
      resourcesToBackend(
        (language: string, namespace: string) =>
          import(`../public/locales/${language}/${namespace}.json`)
      )
    )
    .init({
      ns: ['signup', 'producer', 'calendar', 'common', 'consumer', 'units'],
      initImmediate: false,
      fallbackLng: {
        nn: ['nb'],
        no: ['nb'],
        default: ['en']
      },
      defaultNS: 'common',
      nonExplicitSupportedLngs: true,
      interpolation: {
        escapeValue: false
      }
    });
};
