import { format, getDayOfYear, Locale } from 'date-fns';

type DateFormatOptions = {
  locale?: Locale;
};

type DateRange = {
  from?: Date;
  to?: Date;
};

const dayOfYear = (date: Date) => {
  return getDayOfYear(date);
};

const formatDate = (date: Date, options?: DateFormatOptions) => {
  return format(date, 'dd.MM.yy', options);
};

const getMonths = (options?: DateFormatOptions) => {
  const monthIndexes = Array.from({ length: 12 }, (_, i) => i);
  return monthIndexes.map(monthIndex => {
    return format(new Date(2024, monthIndex), 'MMM', options);
  });
};

const periodToString = (period?: DateRange, options?: DateFormatOptions) => {
  if (!period?.from) {
    return '';
  }

  if (!period.to) {
    return `${formatDate(period.from, options)} - `;
  }

  return `${formatDate(period.from, options)} - ${formatDate(period.to, options)}`;
};

export const DateUtils = {
  dayOfYear,
  getMonths,
  periodToString
};
