/* eslint-disable @typescript-eslint/no-duplicate-enum-values */
import { ColourNames } from '../theme/colours';
import { MarginProps } from '../utils/margin-props';
import { PaddingProps } from '../utils/padding-props';

export enum LineHeight {
  primaryHeading = 40,
  secondaryHeading = 32,
  tertiaryHeading = 32,
  paragraph = 24,
  paragraphBold = 24,
  paragraphSmall = 24,
  paragraphSmallBold = 24,
  leadText = 32,
  leadTextBold = 32,
  inputLabel = 24,
  inputText = 24,
  button = 16,
  allCapsTitle = 16
}

export enum LetterSpacing {
  primaryHeading = -0.4,
  secondaryHeading = -0.4,
  tertiaryHeading = 0,
  paragraph = 0.1,
  paragraphBold = 0.1,
  paragraphSmall = 0.1,
  paragraphSmallBold = 0.1,
  leadText = 0,
  leadTextBold = 0,
  inputLabel = 0.1,
  inputText = 0.1,
  button = 0.25,
  allCapsTitle = 1.25
}

export enum TypographyNamesEnum {
  primaryHeading = 'primaryHeading',
  secondaryHeading = 'secondaryHeading',
  tertiaryHeading = 'tertiaryHeading',
  paragraph = 'paragraph',
  paragraphBold = 'paragraphBold',
  paragraphSmall = 'paragraphSmall',
  paragraphSmallBold = 'paragraphSmallBold',
  leadText = 'leadText',
  leadTextBold = 'leadTextBold',
  inputLabel = 'inputLabel',
  inputText = 'inputText',
  button = 'button',
  link = 'link',
  routerLink = 'routerLink',
  allCapsTitle = 'allCapsTitle',
  helpText = 'helpText'
}

export type TypographyNames = keyof typeof TypographyNamesEnum;

export type TypographyProps<C extends React.ElementType> = {
  variant?: TypographyNames;
  children?: React.ReactNode;
  to?: string;
  color?: ColourNames;
  id?: string;
  href?: string;
  target?: string;
  rel?: string;
  as?: C;
} & MarginProps &
  PaddingProps;
