import { useTranslation } from '@dagens/frontend-i18n';
import { useNavigate } from 'react-router-dom';
import Typography from '@carrot-deprecated/typography';
import {
  CardImageContainer as ImageContainer,
  Content,
  RegularCardContainer as Container
} from '_common/components/product/Product.style';
import { ProducerWithNumProductsForSale } from 'types/Producer';
import { getImageUrl } from 'utils/imageUrls';

type ProducerCardProps = {
  producer: ProducerWithNumProductsForSale;
};

const ProducerCard = ({ producer }: ProducerCardProps) => {
  const {
    name,
    image,
    profileArea,
    contactPerson,
    numProductsForSale = 0,
    handle
  } = producer;

  const navigate = useNavigate();
  const imageUrl = image ? getImageUrl(image, { width: 500 }) : null;
  const { t } = useTranslation();

  if (numProductsForSale === 0) {
    return null;
  }
  return (
    <Container
      onClick={() => {
        return navigate(`/${handle}`, { state: { returnPath: true } });
      }}
    >
      <Content>
        {profileArea && (
          <Typography variant="paragraph">{profileArea}</Typography>
        )}
        <Typography variant="primaryHeading">{name}</Typography>
        <Typography variant="paragraph" color="secondary">
          {contactPerson}
        </Typography>
        <Typography variant="paragraph" color="secondary">
          {t('consumer:numProducts', { count: numProductsForSale })}
        </Typography>
      </Content>
      <ImageContainer imageUrl={imageUrl} roundedCorners="bottom" />
    </Container>
  );
};

export default ProducerCard;
