import { Form as CarrotForm } from '@dagens/carrot';
import { PropsWithChildren } from 'react';
import { FieldValues, SubmitHandler, useFormContext } from 'react-hook-form';

type Props<Form extends FieldValues> = PropsWithChildren<{
  onSubmit: SubmitHandler<Form>;
}>;

// Creates a form element and handles the form submission
export const Form = <Form extends FieldValues>({
  onSubmit,
  children
}: Props<Form>) => {
  const { handleSubmit } = useFormContext<Form>();
  return (
    <CarrotForm.Container onSubmit={handleSubmit(onSubmit)}>
      {children}
    </CarrotForm.Container>
  );
};
