import { OnboardingHistoryKeys } from '../../../types/Producer';
import { type Onboarding as ConsumerOnboarding } from 'utils/role';
import { environment } from 'config';

export type MessageMapping = {
  producer: {
    [key in keyof typeof OnboardingHistoryKeys]?: string | undefined;
  };
  buyer: {
    [key in keyof ConsumerOnboarding]?: string;
  };
};

type EnvironmentMessageMapping = {
  [key in typeof environment]: MessageMapping;
};

export const HELP_SCOUT_MESSAGES_MAPPING: EnvironmentMessageMapping = {
  development: {
    producer: {
      filledOutAboutInfo: undefined,
      addedFirstProduct: undefined,
      setUpInitialDeliveryRoute: undefined,
      addedWebshopLink: undefined,
      receivedFirstOrder: undefined,
      deliveredFirstOrder: undefined,
      addedBankAccount: undefined,
      receivedFirstPayment: undefined
    },
    buyer: {
      hasSeenIntroScreen: undefined
    }
  },
  test: {
    producer: {
      filledOutAboutInfo: '0bb02cff-19c2-43a6-a6fa-edd195de5824',
      addedFirstProduct: '67a5b618-000e-4730-bcae-61db55d28530',
      setUpInitialDeliveryRoute: undefined,
      addedWebshopLink: undefined,
      receivedFirstOrder: undefined,
      deliveredFirstOrder: undefined,
      addedBankAccount: undefined,
      receivedFirstPayment: undefined
    },
    buyer: {
      hasSeenIntroScreen: undefined
    }
  },
  production: {
    producer: {
      filledOutAboutInfo: undefined,
      addedFirstProduct: undefined,
      setUpInitialDeliveryRoute: undefined,
      addedWebshopLink: undefined,
      receivedFirstOrder: undefined,
      deliveredFirstOrder: undefined,
      addedBankAccount: undefined,
      receivedFirstPayment: undefined
    },
    buyer: {
      hasSeenIntroScreen: undefined
    }
  }
};

export const HELP_SCOUT_MESSAGES = HELP_SCOUT_MESSAGES_MAPPING[environment];
