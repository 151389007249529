import { capitalize } from '@dagensmat/core';
import React from 'react';
import ProductDeliveryDateSelection from './ProductDeliveryDateSelection';
import Container from '@carrot-deprecated/container';
import Typography from '@carrot-deprecated/typography';
import ProductBuyer from '_common/components/product-buyer/ProductBuyer';
import {
  chooseDeliveryDateFromContext,
  DELIVERY_DATE_CONTEXT_DEFAULT,
  DeliveryDateContext
} from '_common/context/DeliveryDateContext';
import { useAppDispatch, useAppSelector } from '_common/hooks/reduxHooks';
import {
  calculateMinimumOrderThreshold,
  updateBasket
} from '_consumer/reducers/basket';
import { updateSelectedDeliveryDate } from '_consumer/reducers/productsForSale';
import { ProductForSale } from 'types/Product';
import { formatDate } from 'utils/date/format';
import { SELECT_DELIVERY_DAY_PRODUCT, track } from 'utils/mixpanel';

type ModalBuyerWrapperProps = {
  product: ProductForSale;
};

const ModalBuyerWrapper = ({ product }: ModalBuyerWrapperProps) => {
  const { selectedDeliveryDate, availableDeliveryDates } = useAppSelector(
    ({ productsForSale }) => {
      return {
        selectedDeliveryDate: productsForSale.selectedDeliveryDate,
        availableDeliveryDates: productsForSale.availableDeliveryDates
      };
    }
  );
  const dispatch = useAppDispatch();

  const context = React.useContext(DeliveryDateContext);

  const { pageContext, setIsolatedContextDeliveryDate } = context;

  const { _id: productId, hasSpecialPricing } = product;

  const deliveryDate = chooseDeliveryDateFromContext(
    context,
    selectedDeliveryDate
  );

  const setDeliveryDate = (date: string) => {
    dispatch(
      updateBasket({
        deliveryDate: date,
        productId,
        units: 1,
        hasSpecialPricing
      })
    );
    dispatch(calculateMinimumOrderThreshold());

    if (pageContext === DELIVERY_DATE_CONTEXT_DEFAULT) {
      dispatch(updateSelectedDeliveryDate(date));
    } else {
      setIsolatedContextDeliveryDate?.(date);
    }
    track(SELECT_DELIVERY_DAY_PRODUCT);
  };

  return (
    <Container my="m">
      {deliveryDate ? (
        <>
          <Typography variant="inputLabel">
            Ordering for {capitalize(formatDate(deliveryDate, 'iii d. MMM'))}
          </Typography>
          <ProductBuyer product={product} selectedDeliveryDate={deliveryDate} />
        </>
      ) : (
        <ProductDeliveryDateSelection
          product={product}
          availableDeliveryDates={availableDeliveryDates}
          setDeliveryDate={setDeliveryDate}
        />
      )}
    </Container>
  );
};

export default ModalBuyerWrapper;
