import { routesProducers } from './routes-producers';
import { routesConsumers } from './routes-consumers';
import { useAppSelector } from '_common/hooks/reduxHooks';

export const usePageResults = (query: string) => {
  const { role } = useAppSelector(({ auth }) => {
    return {
      role: auth._type
    };
  });

  const routes =
    role === 'producers'
      ? routesProducers
      : role === 'consumers'
        ? routesConsumers
        : null;

  const searchableRoutes = routes?.filter(route => 'title' in route);

  if (!searchableRoutes) {
    return { pageResults: [] };
  }

  if (query === '') {
    return { pageResults: searchableRoutes.map(route => ({ route })) };
  }

  const keywords = query.toLowerCase().split(' ');

  const matches = searchableRoutes.filter(route => {
    const title = route.title?.toLowerCase();
    return keywords.some(keyword => title?.includes(keyword));
  });

  const matchesWithWeight = matches.map(route => {
    const matchCount = keywords.filter(keyword =>
      route.title?.toLowerCase().includes(keyword)
    ).length;
    return {
      route,
      matchCount
    };
  });

  const sortedMatches = matchesWithWeight.sort(
    (a, b) =>
      b.matchCount - a.matchCount ||
      a.route.title!.localeCompare(b.route.title!)
  );
  return { pageResults: sortedMatches };
};
