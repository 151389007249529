import { AvailableLanguages } from '@dagensmat/core';
import i18n from 'i18next';
import { useEffect, useState } from 'react';
import { useAppSelector } from '_common/hooks/reduxHooks';
import useRedirectLogin from '_common/hooks/useRedirectLogin';
import ProducerPage from '_consumer/pages/producer-page/ProducerPage';
import { Page } from '@components/page';
import { getProducers } from 'api';
import { getProducerProductsFromAlgolia } from 'api/consumer';
import { Producer } from 'types/Producer';
import { ProductForSale } from 'types/Product';
import {
  getViewerForDataTracking,
  PRODUCER_SHOP_PAGE_VIEW,
  track
} from 'utils/mixpanel';
import REQ, { ReqType } from 'utils/REQ';

type ProducerPageFetcherProps = {
  producerId: string;
};

const ProducerPageFetcher = ({ producerId }: ProducerPageFetcherProps) => {
  const [req, setReq] = useState<ReqType>(REQ.INIT);
  const { set: setRedirectLogin, unset: unsetRedirectLogin } =
    useRedirectLogin();

  useEffect(() => {
    setRedirectLogin();
    return () => {
      unsetRedirectLogin();
    };
  }, []);

  const { daName, daId, _type, viewerId, isMeyersAccount } = useAppSelector(
    ({ auth }) => {
      return {
        daName: auth?.distributionAreaName,
        daId: auth?.distributionAreaId,
        _type: auth?._type,
        viewerId: auth?._id,
        isMeyersAccount: auth?.isMeyersAccount
      };
    }
  );

  const [products, setProducts] = useState<ProductForSale[]>([]);
  const [producer, setProducer] = useState<Producer>();

  useEffect(() => {
    const fetchProducerPageData = async () => {
      setReq(REQ.PENDING);
      try {
        const data = await getProducers({ handle: producerId });
        if (data.length !== 1) {
          setReq(REQ.NOT_FOUND);
        } else {
          const fetchedProducer = data[0];
          setProducer(fetchedProducer);

          const isInSameDA =
            fetchedProducer.distributionAreas?.includes(daName);

          track(PRODUCER_SHOP_PAGE_VIEW, {
            shopOwnerName: fetchedProducer.name,
            viewer: getViewerForDataTracking(
              _type,
              isInSameDA,
              producerId,
              viewerId
            )
          });

          setReq(REQ.PENDING);
          const roleId = viewerId || 'guest';
          const productData = await getProducerProductsFromAlgolia({
            producerId,
            roleLang: i18n.resolvedLanguage as AvailableLanguages,
            roleId,
            ...(isInSameDA && {
              distributionAreaId: daId
            }),
            isMeyersAccount
          });
          setProducts(productData);
          setReq(REQ.SUCCESS);
        }
      } catch (error) {
        setReq(REQ.ERROR);
      }
    };
    fetchProducerPageData();
  }, []);

  if (req !== REQ.SUCCESS) {
    return <Page.Status req={req} />;
  }
  if (!producer) {
    return <Page.NotFound />;
  }
  return <ProducerPage producer={producer} products={products} />;
};

export default ProducerPageFetcher;
