/* eslint no-param-reassign: ["error", { "props": true, "ignorePropertyModificationsFor": ["state"] }] */
import { forSaleStatuses } from '@dagensmat/core';
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getProducerProducts } from 'api';
import { type ProducerProduct } from 'types/Product';
import REQ, { ReqType } from 'utils/REQ';

/** Action creators */

const name = 'producerProducts';
export const fetchProducerProducts = createAsyncThunk(
  `${name}/fetchProducts`,
  async (producerId: string | undefined) => {
    const response = await getProducerProducts({ producerId });
    return response;
  }
);

type ProducerProductsState = {
  req: ReqType;
  items: ProducerProduct[];
};

const initialState: ProducerProductsState = { req: REQ.INIT, items: [] };

const producerProductsSlice = createSlice({
  initialState,
  name,
  reducers: {
    clearProducerProducts() {
      return initialState;
    },
    productEdited(state, action: PayloadAction<Partial<ProducerProduct>>) {
      state.req = REQ.SUCCESS;
      state.items = state.items.map(product => {
        return product._id === action.payload._id
          ? { ...product, ...action.payload }
          : product;
      });
    }
  },
  extraReducers(builder) {
    builder
      .addCase(
        fetchProducerProducts.fulfilled,
        (state, action: PayloadAction<ProducerProduct[]>) => {
          state.req = REQ.SUCCESS;
          state.items = action.payload;
        }
      )
      .addCase(fetchProducerProducts.pending, state => {
        state.req = REQ.PENDING;
        state.items = initialState.items;
      })
      .addCase(fetchProducerProducts.rejected, state => {
        state.req = REQ.ERROR;
        state.items = initialState.items;
      });
  }
});

export default producerProductsSlice.reducer;
export const { clearProducerProducts, productEdited } =
  producerProductsSlice.actions;

/** Selectors */

export const splitProductsBySaleStatus = (
  products: ProducerProduct[]
): Record<
  'forSale' | 'onRequest' | 'notForSale' | 'activeProducts',
  ProducerProduct[] | undefined
> => {
  const activeProducts = products.filter(({ deleted }) => {
    return !deleted;
  });

  const forSale = activeProducts.filter(({ forSaleStatus }) => {
    return forSaleStatus === forSaleStatuses.FOR_SALE;
  });
  const onRequest = activeProducts.filter(({ forSaleStatus }) => {
    return forSaleStatus === forSaleStatuses.ON_REQUEST;
  });
  const notForSale = activeProducts.filter(({ forSaleStatus }) => {
    return forSaleStatus === forSaleStatuses.NOT_FOR_SALE;
  });

  return { forSale, onRequest, notForSale, activeProducts };
};
