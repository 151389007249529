/* eslint no-param-reassign: ["error", { "props": true, "ignorePropertyModificationsFor": ["state"] }] */
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  fetchProductsForSale,
  getBasketByDeliveryDateAndProducer,
  getProductsInBasket,
  sumPriceForProductsInBasket
} from './productsForSale';
import { type RootState } from 'store';
import { BasketItem, BasketState } from 'types/Basket';
import {
  getBasketFromLocalStorage,
  updateLocalStorage
} from 'utils/clientcache/index';

const isBasketItem = (
  item: BasketItem,
  deliveryDate: BasketItem['deliveryDate'],
  productId: BasketItem['productId']
) => {
  return item.deliveryDate === deliveryDate && item.productId === productId;
};

export const calculateMinimumOrderThreshold = createAsyncThunk(
  'basket/calculateMinimumOrderThreshold',
  async (_, thunkAPI) => {
    const state = thunkAPI.getState() as RootState;
    const { productsForSale, basket, producers } = state;
    const prodInBasket = getProductsInBasket(
      productsForSale.items,
      basket.items
    );
    const prodByProducerAndDeliveryDate =
      getBasketByDeliveryDateAndProducer(prodInBasket);

    let isAboveMinimumOrderThreshold = true;
    const producersAboveMinimumOrderAmount: Record<
      string,
      Record<string, boolean>
    > = {};

    prodByProducerAndDeliveryDate.forEach(
      ({ producer: basketProducer, products, deliveryDate }) => {
        const producer = producers.items.find(({ _id }) => {
          return _id === basketProducer._id;
        });
        const total = sumPriceForProductsInBasket(products);
        const isAbove = producer?.minimumOrderAmount
          ? total >= producer.minimumOrderAmount
          : true;

        const producerId = producer?._id;
        const deliveryDaysMap = producerId
          ? producersAboveMinimumOrderAmount[producerId]
          : undefined;

        if (deliveryDaysMap) {
          deliveryDaysMap[deliveryDate] = isAbove;
        } else if (producerId) {
          producersAboveMinimumOrderAmount[producerId] = {
            [deliveryDate]: isAbove
          };
        }

        isAboveMinimumOrderThreshold &&= isAbove;
      }
    );

    return {
      isAboveMinimumOrderThreshold,
      producersAboveMinimumOrderAmount
    };
  }
);

const initialState: BasketState = {
  producersAboveMinimumOrderAmount: {},
  isAboveMinimumOrderThreshold: true,
  items: []
};

const basketSlice = createSlice({
  name: 'basket',
  initialState,
  reducers: {
    updateBasket(
      state,
      action: PayloadAction<{
        deliveryDate: string;
        productId: string;
        units: number;
        hasSpecialPricing?: boolean;
      }>
    ) {
      const { deliveryDate, productId, units } = action.payload;
      const newItems = state.items.filter(item => {
        return !isBasketItem(item, deliveryDate, productId);
      });

      if (units <= 0) {
        const newState = { ...state, items: newItems };
        updateLocalStorage('basket', newState);
        return newState;
      }

      newItems.push({
        deliveryDate,
        productId,
        units
      });

      const newState = { ...state, items: newItems };
      updateLocalStorage('basket', newState);
      return newState;
    },

    completeOrder() {
      updateLocalStorage('basket', {
        items: [],
        isAboveMinimumOrderThreshold: true
      } as BasketState);
      return initialState;
    },

    clearBasket() {
      return initialState;
    }
  },
  extraReducers(builder) {
    builder
      .addCase(fetchProductsForSale.fulfilled, (_state, action) => {
        const { items } = action.payload;
        const basket = getBasketFromLocalStorage(items) as BasketState;
        return basket;
      })
      .addCase(calculateMinimumOrderThreshold.fulfilled, (state, action) => {
        const {
          isAboveMinimumOrderThreshold,
          producersAboveMinimumOrderAmount
        } = action.payload;
        state.isAboveMinimumOrderThreshold = isAboveMinimumOrderThreshold;
        state.producersAboveMinimumOrderAmount =
          producersAboveMinimumOrderAmount;

        updateLocalStorage('basket', state);
      });
  }
});
export default basketSlice.reducer;

export const { updateBasket, completeOrder, clearBasket } = basketSlice.actions;

/** Selectors */

export const countProductsInBasket = (basketItems: BasketItem[]) => {
  return Math.ceil(
    basketItems.reduce((acc, { units }) => {
      return acc + units;
    }, 0)
  );
};

export const countUnitsOfProductInBasketForDeliveryDate = (
  basketItems: BasketItem[],
  deliveryDate: string,
  productId: string
) => {
  const basketItem = basketItems.find(item => {
    return isBasketItem(item, deliveryDate, productId);
  });
  return basketItem ? basketItem.units : 0;
};
