import { css } from 'styled-components';
import Spacings, { SpacingNames } from '../theme/spacings';

export type PaddingProps = {
  px?: SpacingNames;
  py?: SpacingNames;
  pt?: SpacingNames;
  pr?: SpacingNames;
  pb?: SpacingNames;
  pl?: SpacingNames;
};

const Paddings = css<PaddingProps>`
  ${({ px }) => {
    return (
      px &&
      `padding-left: ${Spacings[px]}px;
      padding-right: ${Spacings[px as SpacingNames]}px;`
    );
  }}
  ${({ py }) => {
    return (
      py &&
      `padding-top: ${Spacings[py]}px;
      padding-bottom: ${Spacings[py]}px;`
    );
  }}
  ${({ pt }) => {
    return pt && `padding-top: ${Spacings[pt]}px;`;
  }}
  ${({ pr }) => {
    return pr && `padding-right: ${Spacings[pr]}px;`;
  }}
  ${({ pb }) => {
    return pb && `padding-bottom: ${Spacings[pb]}px;`;
  }}
  ${({ pl }) => {
    return pl && `padding-left: ${Spacings[pl]}px;`;
  }}
`;

export default Paddings;
