import { useTranslation } from '@dagens/frontend-i18n';
import RequestButton, {
  RequestButtonProps
} from '_common/components/button/RequestButton';
import { ReqType } from 'utils/REQ';

type SignupRequestButtonProps = {
  req: ReqType;
  onSubmit: RequestButtonProps['onSubmit'];
  buttonLabel?: string;
  successMessage?: string;
  pendingMessage?: string;
  errorMessage?: string;
  validationErrorMessage?: string;
};

const SignupRequestButton = ({
  req,
  onSubmit,
  buttonLabel,
  successMessage,
  pendingMessage,
  errorMessage,
  validationErrorMessage
}: SignupRequestButtonProps) => {
  const { t } = useTranslation();
  return (
    <RequestButton
      req={req}
      onSubmit={onSubmit}
      buttonLabel={buttonLabel || t('signup:userRegister')}
      successMessage={successMessage || t('signup:signupSuccessMessage')}
      pendingMessage={pendingMessage || t('signup:userPendingMessage')}
      errorMessage={errorMessage || t('signup:userErrorMessage')}
      validationErrorMessage={validationErrorMessage}
    />
  );
};

export default SignupRequestButton;
