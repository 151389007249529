import { useFormContext } from '@dagens/frontend-forms';
import { useTranslation } from '@dagens/frontend-i18n';
import { BottomActions } from '@dagens/carrot';
import REQ, { ReqType } from '../../../../utils/REQ';
import { BillingFormValues } from '../../../components/customer-billing-form';

type Props = {
  consumerId?: string;
  saveReq: ReqType;
  onSubmit: (data: BillingFormValues) => void;
};

export const BillingBottomActions = ({
  consumerId,
  onSubmit,
  saveReq
}: Props) => {
  const { t } = useTranslation();
  const { handleSubmit } = useFormContext<BillingFormValues>();

  if (saveReq === REQ.PENDING) {
    return (
      <BottomActions.Feedback>
        {t('common:SavingChanges')}
      </BottomActions.Feedback>
    );
  }

  if (saveReq === REQ.ERROR) {
    return (
      <BottomActions.Feedback error>
        {t('consumer:SomethingWentWrongTryAgain')}
      </BottomActions.Feedback>
    );
  }

  if (saveReq === REQ.SUCCESS) {
    return (
      <BottomActions.Feedback success>
        {t('common:ChangesSaved')}
      </BottomActions.Feedback>
    );
  }

  return (
    <>
      <BottomActions.Cancel to={`/customers/${consumerId}`}>
        {t('common:Cancel')}
      </BottomActions.Cancel>
      <BottomActions.Submit onClick={handleSubmit(onSubmit)}>
        {t('common:SaveChanges')}
      </BottomActions.Submit>
    </>
  );
};
