import { Box, PageHeader } from '@dagens/carrot';
import { useTranslation } from '@dagens/frontend-i18n';
import { capitalize } from '@dagensmat/core';
import { addMonths } from 'date-fns';
import { useEffect, useMemo, useState } from 'react';
import { getOrderHistory } from '../../../api';
import REQ, { ReqType } from '../../../utils/REQ';
import { useAppSelector } from '../../hooks/reduxHooks';
import Callout from '@carrot-deprecated/callout';
import Typography from '@carrot-deprecated/typography';
import Container from '@carrot-deprecated/container';
import OrderGroup from '_common/components/order-group/OrderGroup';
import SearchInput from '@components/search-input/SearchInput';
import { useQueryParams } from '_common/hooks/useQueryParams';
import {
  groupOrdersByDeliveryDate,
  orderKindIsOrder,
  splitActiveAndPastOrders
} from '_common/reducers/orders';
import { ContentLoader } from '@components/content-loader';
import { Page } from '@components/page';
import { Order } from 'types/Order';
import { formatDate, getDate } from 'utils/date/format';

type OrderListProps = {
  searchQuery: string | null;
};

const OrderList = ({ searchQuery }: OrderListProps) => {
  const { t } = useTranslation();
  const [req, setReq] = useState<ReqType>(REQ.INIT);
  const [orders, setOrders] = useState<Order[]>([]);
  const { roleId } = useAppSelector(({ auth }) => {
    return {
      roleId: auth._id
    };
  });

  const fetchOrderHistory = async () => {
    setReq(REQ.PENDING);

    const FETCH_PERIOD = 24;
    const FETCH_INTERVAL = 3;

    const today = new Date();
    const history = await Promise.all(
      Array.from({ length: FETCH_PERIOD / FETCH_INTERVAL }).map(
        async (_, i) => {
          const startDate = addMonths(
            today,
            -(i + 1) * FETCH_INTERVAL
          ).toISOString();
          const endDate = addMonths(today, -i * FETCH_INTERVAL).toISOString();
          const orderHistory = await getOrderHistory({
            consumerId: roleId,
            startDate,
            endDate
          });
          return orderHistory.filter(orderKindIsOrder);
        }
      )
    );

    setOrders(history.flat());
    setReq(REQ.SUCCESS);
  };

  const orderGroups = useMemo(() => {
    const { pastOrders } = splitActiveAndPastOrders(orders);

    const filteredOrders = pastOrders.filter(order => {
      if (!searchQuery || searchQuery.length < 1) {
        return true;
      }
      const orderSearchableFields = [
        order.orderNumberString,
        order.consumer.name,
        getDate(order.deliveryDate)
      ].filter(Boolean);
      const searchKeywords = searchQuery.toLowerCase().split(' ');

      return searchKeywords.every(keyword => {
        return orderSearchableFields.some(field => {
          return field?.toLowerCase().includes(keyword);
        });
      });
    });

    return groupOrdersByDeliveryDate(filteredOrders);
  }, [orders, searchQuery]);

  useEffect(() => {
    fetchOrderHistory();
  }, []);

  return (
    <ContentLoader req={req}>
      <Container mt="m">
        {orderGroups.length < 1 ? (
          <Callout>
            <Typography variant="paragraph">
              {t('common:NoOrderHistoryYet')}
            </Typography>
          </Callout>
        ) : (
          orderGroups.map(({ deliveryDate, orders: consumerOrders }) => {
            return (
              <OrderGroup
                key={deliveryDate}
                header={capitalize(formatDate(deliveryDate))}
                orders={consumerOrders}
                roleType="consumers"
              />
            );
          })
        )}
      </Container>
    </ContentLoader>
  );
};

const QUERY_KEY = 'query';

const OrdersHistoryPage = () => {
  const { getQueryValue, setQueryValue } = useQueryParams();
  const searchQuery = getQueryValue(QUERY_KEY);
  const { t } = useTranslation();

  return (
    <Page
      header={
        <Box.FullWidth>
          <PageHeader
            title={t('common:OrderHistory')}
            subtitle={t('common:DeliveredAndCanceledOrders')}
          />
          <SearchInput
            searchPlaceholder={t('common:SearchInOrderHistory')}
            searchStringInput={searchQuery || ''}
            onSearchChange={({ target: { value } }) => {
              return setQueryValue(QUERY_KEY, value);
            }}
            onResetSearchString={() => {
              return setQueryValue(QUERY_KEY, '');
            }}
          />
        </Box.FullWidth>
      }
    >
      <OrderList searchQuery={searchQuery} />
    </Page>
  );
};

export default OrdersHistoryPage;
