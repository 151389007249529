import Animations from './animations';
import BorderRadius from './border-radius';
import BorderWeights from './border-weights';
import Colours from './colours';
import FontSizes from './font-sizes';
import FontWeights from './font-weights';
import Spacings from './spacings';

const Theme = {
  Animations: Animations,
  BorderRadius: BorderRadius,
  BorderWeights: BorderWeights,
  Colours: Colours,
  FontSizes: FontSizes,
  FontWeights: FontWeights,
  Spacings: Spacings
};
export default Theme;

export { default as Animations } from './animations';
export { default as BorderRadius } from './border-radius';
export { default as BorderWeights } from './border-weights';
export { default as Colours } from './colours';
export { default as FontSizes } from './font-sizes';
export { default as FontWeights } from './font-weights';
export { default as Spacings } from './spacings';
