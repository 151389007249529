import { PropsWithChildren } from 'react';
import { tv } from '../../utils/tv';

const style = tv({
  base: `
    relative
    w-full
    max-w-[490px]
  `
});

type Props = PropsWithChildren;

export const BoxFormWrapper = ({ children }: Props) => {
  return <div className={style()}>{children}</div>;
};
