import styled from 'styled-components';
import Widths from '../utils/width-props';
import Borders from '../utils/border-props';
import Paddings from '../utils/padding-props';
import Margins from '../utils/margin-props';
import Flex from '../utils/flex-props';
import Positions from '../utils/position-props';
import { BorderRadius, Colours } from '../theme';
import { ContainerProps } from './types';

const StyledContainer = styled('div').withConfig({
  shouldForwardProp: props => props === 'children'
})<ContainerProps>`
  box-sizing: border-box;
  border-radius: ${BorderRadius['regular']}px;

  ${Positions};

  ${Widths};

  ${Borders};

  ${Paddings};

  ${Margins};

  ${({ flex }) => flex && Flex};

  ${({ flexGrow }) => {
    return flexGrow && `flex-grow: ${flexGrow};`;
  }};

  ${({ flexBasis }) => {
    return flexBasis && `flex-basis: ${flexBasis};`;
  }};

  ${({ backgroundColour }) => {
    return (
      backgroundColour && `background-color: ${Colours[backgroundColour]};`
    );
  }};
`;

const Container = ({ children, ...props }: ContainerProps) => {
  return <StyledContainer {...props}>{children}</StyledContainer>;
};

export default Container;
