import { useTranslation } from '@dagens/frontend-i18n';
import { PropsWithChildren } from 'react';
import { PageHeader } from '@dagens/carrot';
import Typography from '@carrot-deprecated/typography';
import Container from '@carrot-deprecated/container';
import ExpandableCallout from '_common/components/callout/ExpandableCallout';
import Tabs from '_common/components/tabs/Tabs';
import TradeReportLink from '_common/pages/profile/TradeReportLink';
import { Page } from '@components/page';

type Props = PropsWithChildren;

export const ConsumerOrdersPageHOC = ({ children }: Props) => {
  const { t } = useTranslation();
  return (
    <Page>
      <PageHeader title={t('common:MyOrders')} />
      <Container mb="m">
        <ExpandableCallout
          expandedContent={<TradeReportLink variant="callout" />}
        >
          {' '}
          <Typography variant="paragraphBold">
            {t('consumer:TradeReportHype')}
          </Typography>
        </ExpandableCallout>
      </Container>
      <Tabs
        tabs={[
          {
            text: t('consumer:Requests'),
            to: '/orders/product-requests'
          },
          { text: t('consumer:Products'), to: '/orders/overview' },
          { text: t('common:Orders'), to: '/orders' }
        ]}
      />
      {children}
    </Page>
  );
};
