import { useTranslation } from '@dagens/frontend-i18n';
import { useState } from 'react';
import { Box, PageHeader } from '@dagens/carrot';
import { Form } from '@dagens/frontend-forms';
import {
  useAppDispatch,
  useAppSelector
} from '../../../../_common/hooks/reduxHooks';
import { PlanService } from '../../../../services/PlanService';
import { MinimumOrderPageCallout } from './callout';
import { MinimumOrderForm, MinimumOrderFormValues } from './form';
import { MinimumOrderAmountFormSubmitButton as Submit } from './submit-button';
import usePageView from '_common/hooks/usePageView';
import { minimumOrderAmountUpdated } from '_common/reducers/auth';
import { Page } from '@components/page';
import { postProducerMinimumOrder } from 'api';
import {
  MINIMUM_ORDER_FORM_PAGE_VIEW,
  MINIMUM_ORDER_SAVED_WITH_FLEXIBLE,
  MINIMUM_ORDER_SAVED_WITH_STRICT,
  track
} from 'utils/mixpanel';
import REQ, { ReqType } from 'utils/REQ';

const MinimumOrderPage = () => {
  const dispatch = useAppDispatch();
  usePageView(MINIMUM_ORDER_FORM_PAGE_VIEW);
  const {
    producerId,
    minimumOrderAmount,
    minimumOrderAmountEnforced,
    hasWebshop
  } = useAppSelector(
    ({
      auth: {
        _id: producerId,
        minimumOrderAmount,
        minimumOrderAmountEnforced,
        plan
      }
    }) => {
      return {
        producerId,
        minimumOrderAmount,
        minimumOrderAmountEnforced,
        hasWebshop: PlanService.hasFeature(plan, 'WEBSHOP')
      };
    }
  );

  const { t } = useTranslation();
  const [req, setReq] = useState<ReqType>(REQ.INIT);

  const onSave = async ({
    minimumOrderAmount,
    minimumOrderAmountEnforced
  }: MinimumOrderFormValues) => {
    setReq(REQ.PENDING);
    const value = minimumOrderAmount ?? 0;
    try {
      await postProducerMinimumOrder({
        userId: producerId,
        producerId,
        minimumOrderAmount: value,
        minimumOrderAmountEnforced
      });
      track(
        minimumOrderAmountEnforced
          ? MINIMUM_ORDER_SAVED_WITH_STRICT
          : MINIMUM_ORDER_SAVED_WITH_FLEXIBLE
      );
      dispatch(
        minimumOrderAmountUpdated({
          minimumOrderAmount: value,
          minimumOrderAmountEnforced
        })
      );
      setReq(REQ.SUCCESS);
    } catch (e) {
      setReq(REQ.ERROR);
    }
  };

  if (!hasWebshop) {
    return <Page.NotFound />;
  }

  return (
    <Form.Provider
      defaultValue={{ minimumOrderAmount, minimumOrderAmountEnforced }}
    >
      <Page
        bottom={
          <Box.BottomSheet>
            <Submit saveReq={req} onSubmit={onSave} />
          </Box.BottomSheet>
        }
      >
        <PageHeader
          title={t('producer:MinimumOrderPageHeader')}
          subtitle={t('producer:MinimumOrderPageInfoMessage')}
        />
        <Box.FormWrapper>
          <Form.Section>
            <MinimumOrderPageCallout />
            <MinimumOrderForm onSubmit={onSave} />
          </Form.Section>
        </Box.FormWrapper>
      </Page>
    </Form.Provider>
  );
};

export default MinimumOrderPage;
