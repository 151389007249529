import { useFormContext } from '@dagens/frontend-forms';
import { useTranslation } from '@dagens/frontend-i18n';
import { ActionButton } from '../../../../components/action-button';
import REQ, { type ReqType } from '../../../../utils/REQ';
import { ProductFormValues } from '..';
import { PricingFormValues } from '../../pricing-form';
import { ProductFormPricesValues } from '../edit';

const createTranslateKeys = {
  pending: 'producer:ProductSavingButtonFeedback',
  success: 'producer:ProductSavedButtonFeedback',
  invalid: 'producer:ProductSaveButtonMissingFieldsFeedback',
  submit: 'producer:ProductSaveButtonFeedback'
};

const editTranslateKeys = {
  pending: 'producer:ProductSavingChangesButtonFeedback',
  success: 'producer:ProductChangesSavedButtonFeedback',
  invalid: 'producer:ProductSaveChangesMissingFieldsFeedback',
  submit: 'producer:ProductSaveChangesButtonFeedback'
};

type FormData =
  | (ProductFormValues & ProductFormPricesValues)
  | (ProductFormValues & PricingFormValues);

type Props<T extends FormData> = {
  flow: 'create' | 'edit';
  saveReq: ReqType;
  onSubmit: (data: T) => void;
};

export const ProductFormSubmitButton = <T extends FormData>({
  flow,
  saveReq,
  onSubmit
}: Props<T>) => {
  const { t } = useTranslation();
  const {
    formState: { isValid, isSubmitted },
    handleSubmit
  } = useFormContext<T>();

  const translateKeys =
    flow === 'create' ? createTranslateKeys : editTranslateKeys;

  return (
    <ActionButton.Save
      onClick={handleSubmit(onSubmit)}
      feedbackText={{
        [REQ.PENDING]: t(translateKeys.pending),
        [REQ.SUCCESS]: t(translateKeys.success)
      }}
      saveReq={saveReq}
    >
      {!isValid && isSubmitted
        ? t(translateKeys.invalid)
        : t(translateKeys.submit)}
    </ActionButton.Save>
  );
};
