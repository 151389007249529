import { forwardRef, PropsWithChildren, Ref } from 'react';
import { Link, LinkProps } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';
import { Simplify } from 'type-fest';

import { buttonStyle, ButtonStyles } from '../../button/styles';

export type LinkComponentProps = Simplify<
  PropsWithChildren<
    ButtonStyles & {
      className?: LinkProps['className'];
      state?: LinkProps['state'];
      tabIndex?: LinkProps['tabIndex'];
      to: LinkProps['to'];
      onClick?: LinkProps['onClick'];
    }
  >
>;

const InternalComponent = (
  {
    // style props
    alignment,
    fillParent,
    focused,
    size,
    variant,
    color,

    // link props
    state,
    to,

    // react props
    children,
    className,
    tabIndex,
    onClick
  }: LinkComponentProps,
  ref: Ref<HTMLAnchorElement>
) => {
  return (
    <Link
      ref={ref}
      className={twMerge(
        buttonStyle({
          alignment,
          fillParent,
          focused,
          size,
          variant,
          color
        }),
        className
      )}
      to={to}
      state={state}
      onClick={onClick}
      tabIndex={tabIndex}
    >
      {children}
    </Link>
  );
};

export const LinkComponentInternal = forwardRef(InternalComponent);

const Component = (
  props: Omit<LinkComponentProps, 'className'>,
  ref: Ref<HTMLAnchorElement>
) => {
  return <LinkComponentInternal {...props} ref={ref} />;
};

export const LinkComponent = forwardRef(Component);
