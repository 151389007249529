export const useProducerPlans = (
  transactionCut: number,
  transactionCutForSelfDelivery: number
) => {
  return {
    platformFee: transactionCutForSelfDelivery,
    deliveryFee: Number(
      (transactionCut - transactionCutForSelfDelivery).toFixed(1)
    ),
    platformFeePercentage: Number(
      (transactionCutForSelfDelivery * 100).toFixed(1)
    ),
    deliveryFeePercentage: Number(
      ((transactionCut - transactionCutForSelfDelivery) * 100).toFixed(1)
    )
  };
};
