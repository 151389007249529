import { uniq } from 'lodash';

import { ProductForSale } from '../types/Product';

export const getCountProducersInSearch = (
  productsInSearch: ProductForSale[] = []
) => {
  return productsInSearch.length === 0
    ? 0
    : uniq(
        productsInSearch
          .map(({ producer: { _id } }) => {
            return _id;
          })
          .filter(Boolean)
      ).length;
};
