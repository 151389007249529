import { Button, Icon, Link, Tag } from '@dagens/carrot';
import { tv } from 'tailwind-variants';
import { useTranslation } from '@dagens/frontend-i18n';
import { formatDate, isSameYear } from 'date-fns';
import { useAppSelector } from '../../../_common/hooks/reduxHooks';
import { PlanService } from '../../../services/PlanService';
import { CustomerActionsModal } from '_producer/pages/customer-list-page/customer-actions-modal';
import { Consumer } from 'types/Consumer';

const style = tv({
  slots: {
    list: `
      -mt-s
      flex
      flex-col
    `,
    item: `
      transition-regular
      relative
      flex
      border-b-thin
      border-b-lightGrey
      py-s
      transition-[color,background-color,border-color,opacity]

      first:border-t-thin
      first:border-t-lightGrey

      hover:bg-brightPurple
    `,
    itemContent: `flex-1`,
    itemNameTag: `
      flex
      flex-row
      gap-xs
      pr-xl
    `,
    itemButton: `mt-[-12px]`,
    itemName: `
      truncate
      text-paragraphSmallBold
    `,
    itemContactPerson: `text-paragraphSmall`,
    itemEmails: `
      mb-xxs
      grid
      w-full
      grid-cols-[max-content,1fr]
      items-center
      gap-x-s
      gap-y-xs
      overflow-hidden
      text-ellipsis
      text-paragraphSmall
    `,
    itemEmail: `
      mb-xxs
      truncate
      text-paragraphSmall
      text-secondary
    `,
    itemPhone: `
      mb-xxs
      text-paragraphSmall
      text-secondary
    `,
    itemAddress: `
      text-paragraphSmall
      text-secondary
    `,
    itemLastOrder: `
      mt-xxs
      text-paragraphSmall
      text-secondary
    `
  }
});

type Props = {
  consumers: Consumer[];
  activeConsumer?: Consumer | null;
  onConsumerClick?: (consumer: Consumer) => void;
  onModalClose?: () => void;
  linkTo?: string;
};

export const CustomerList = ({
  consumers,
  activeConsumer,
  onConsumerClick,
  onModalClose,
  linkTo
}: Props) => {
  const { t } = useTranslation();
  const {
    item,
    itemContent,
    itemButton,
    itemContactPerson,
    itemNameTag,
    itemName,
    itemEmails,
    itemEmail,
    itemPhone,
    itemAddress,
    itemLastOrder,
    list
  } = style();

  const { producerId, hasMarketAccess } = useAppSelector(({ auth }) => {
    return {
      producerId: auth._id,
      hasMarketAccess: PlanService.hasFeature(auth.plan, 'MARKET_ACCESS')
    };
  });

  return (
    <>
      <ul className={list()}>
        {consumers.map(consumer => {
          const {
            _id,
            name,
            contactPerson,
            emails,
            phone,
            structuredDeliveryAddress: { addressLine1, postalCode, city },
            lastOrder
          } = consumer;

          return (
            <li key={_id} className={item()}>
              <div className={itemContent()}>
                <div className={itemNameTag()}>
                  <div className={itemName()}>{name}</div>
                  {consumer.createdByAccount === producerId &&
                    hasMarketAccess && (
                      <Tag
                        text={t('producer:CustomerListManagedTag')}
                        color={'lightGrey'}
                      />
                    )}
                </div>
                <div className={itemContactPerson()}>{contactPerson}</div>
                <div className={itemEmails()}>
                  <div className={itemEmail()}>
                    {emails.map((email, index, array) => (
                      <div key={`${email}.${index}`}>
                        <span>{email}</span>
                        {index < array.length - 1 && <br />}
                      </div>
                    ))}
                  </div>
                </div>
                <div className={itemPhone()}>{phone}</div>
                <div className={itemAddress()}>
                  {`${addressLine1}, ${postalCode} ${city}`}
                </div>
                {lastOrder && (
                  <div className={itemLastOrder()}>
                    {t('producer:LastActivity')}:{' '}
                    {isSameYear(lastOrder, new Date())
                      ? formatDate(lastOrder, 'd. MMMM')
                      : formatDate(lastOrder, 'd. MMMM yyyy')}
                  </div>
                )}
              </div>
              <div className={itemButton()}>
                {linkTo ? (
                  <div>
                    <Link.NavIcon
                      key={consumer._id}
                      to={linkTo}
                      state={{ consumer }}
                      fillParent
                    >
                      <Icon icon="forward" size="medium" />
                    </Link.NavIcon>
                  </div>
                ) : (
                  <Button.NavIcon onClick={() => onConsumerClick?.(consumer)}>
                    <Icon icon="meatballs" size="small" />
                  </Button.NavIcon>
                )}
              </div>
            </li>
          );
        })}
      </ul>
      {activeConsumer && onModalClose && (
        <CustomerActionsModal
          activeConsumer={activeConsumer}
          onClose={onModalClose}
        />
      )}
    </>
  );
};
