import { tr } from '@dagens/frontend-i18n';
import i18n from 'i18next';
import React from 'react';
import { useAppDispatch, useAppSelector } from '_common/hooks/reduxHooks';
import { loggedOut } from '_common/reducers/auth';
import { Page } from '@components/page';
import { getLogout } from 'api';

const LogoutPage = () => {
  const dispatch = useAppDispatch();

  const isAuthenticated = useAppSelector(({ auth: { authenticated } }) => {
    return authenticated;
  });

  React.useEffect(() => {
    if (isAuthenticated) {
      getLogout({}).then(() => {
        dispatch(loggedOut());
      });
    }
  }, [isAuthenticated]);

  if (!isAuthenticated) {
    window.location.href = '/';
  }

  return <Page>{tr(i18n, 'common:LoggingOut')}</Page>;
};

export default LogoutPage;
