import {
  Checkboxes as CarrotCheckboxes,
  CheckboxesProps as CarrotCheckboxesProps
} from '@dagens/carrot';
import { Path, PathValue, FieldValues } from 'react-hook-form';
import { FieldProps, useField } from '../utils/use-field';

// Fields from the Carrot component that are handled by react-hook-form
type ExcludedFields = 'value' | 'onChange';

export type CheckboxesProps<
  Form extends FieldValues,
  Name extends Path<Form>,
  Value extends PathValue<Form, Name>
> = Omit<CarrotCheckboxesProps<Value[number]>, ExcludedFields> &
  FieldProps<Form, Name>;

export const Checkboxes = <
  Form extends FieldValues,
  Name extends Path<Form>,
  Value extends PathValue<Form, Name>
>({
  name,
  required,
  validate,
  shouldUnregister,
  disabled,
  ...props
}: CheckboxesProps<Form, Name, Value>) => {
  const { field } = useField({
    name,
    required,
    validate,
    shouldUnregister,
    disabled
  });
  const toValue = (v: Value) => (!v ? [] : v);

  return (
    <CarrotCheckboxes
      name={name}
      value={toValue(field.value)}
      onChange={v => field.onChange(v)}
      {...props}
    />
  );
};
