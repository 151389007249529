import { BottomSheet } from './bottom-sheet';
import { BoxFormWrapper } from './form-wrapper';
import { BoxFullWidth } from './full-width';
import { PageContent } from './page-content';

export const Box = {
  BottomSheet: BottomSheet,
  FormWrapper: BoxFormWrapper,
  FullWidth: BoxFullWidth,
  PageContent: PageContent
};
