import { useTranslation } from '@dagens/frontend-i18n';
import CheckboxWithLabel from '_common/components/checkbox/CheckboxWithLabel';
import SpacedContainer from '_common/components/container/FormContainer.style';
import FormField from '_common/components/input/FormField';
import OrgNumberInput from '_common/components/input/OrgNumberInput';
import TermsLink from '_common/components/terms-link/TermsLink';
import { OrganizationAPIResponse } from '_common/hooks/useCreateRole';
import {
  validateEmail,
  validatePassword,
  validatePasswordRepeat
} from 'utils/validation';

type SignupProducerFieldsProps = {
  name?: string;
  contactPerson?: string;
  address?: string;
  phone?: string;
  orgNumber?: string;
  email?: string;
  password?: string;
  passwordRepeat?: string;
  howDidYouHearAboutUs?: string;
  acceptTerms?: boolean;
  validationErrorField?: string;
  isLoggedIn?: boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  organization: OrganizationAPIResponse;
  onOrganizationChanged: (org: OrganizationAPIResponse) => void;
};

const SignupProducerFields = ({
  name = '',
  contactPerson = '',
  address = '',
  phone = '',
  orgNumber = '',
  email = '',
  password = '',
  passwordRepeat = '',
  howDidYouHearAboutUs = '',
  acceptTerms = false,
  validationErrorField = '',
  isLoggedIn = false,
  onChange,
  organization,
  onOrganizationChanged
}: SignupProducerFieldsProps) => {
  const { t } = useTranslation();

  return (
    <SpacedContainer>
      <OrgNumberInput
        value={orgNumber}
        validationErrorField={validationErrorField}
        onChange={onChange}
        organization={organization}
        onOrganizationChanged={onOrganizationChanged}
      />
      <FormField
        labelText={t('signup:companyLabelText')}
        type="text"
        placeholder={t('signup:companyPlaceholderProducer')}
        success={name.length > 1}
        error={validationErrorField === 'name'}
        name="name"
        value={name}
        onChange={onChange}
      />
      <FormField
        labelText={t('signup:contactPersonLabelText')}
        type="text"
        placeholder={t('signup:contactPersonPlaceholder')}
        success={contactPerson.length > 1}
        error={validationErrorField === 'contactPerson'}
        name="contactPerson"
        value={contactPerson}
        onChange={onChange}
      />
      <FormField
        labelText={t('signup:deliveryAddressLabelTextProducer')}
        type="text"
        placeholder={t('signup:deliveryAddressPlaceholderProducer')}
        success={address.length > 1}
        error={validationErrorField === 'address'}
        name="address"
        value={address}
        onChange={onChange}
      />
      <FormField
        labelText={t('signup:phoneLabelText')}
        type="text"
        placeholder={t('signup:phonePlaceholder')}
        success={phone.length >= 8}
        error={validationErrorField === 'phone'}
        name="phone"
        value={phone}
        onChange={onChange}
      />
      {!isLoggedIn && (
        <>
          <FormField
            labelText={t('signup:emailLabelText')}
            type="text"
            placeholder={t('signup:emailPlaceholder')}
            success={validateEmail(email)}
            warning={Boolean(email && !validateEmail(email))}
            error={validationErrorField === 'email'}
            name="email"
            value={email}
            onChange={onChange}
            autoComplete="email"
          />
          <FormField
            labelText={t('signup:passwordLabelText')}
            type="password"
            placeholder={t('signup:passwordPlaceholder')}
            success={validatePassword(password)}
            warning={Boolean(password && !validatePassword(password))}
            error={validationErrorField === 'password'}
            name="password"
            value={password}
            onChange={onChange}
            autoComplete="new-password"
          />
          <FormField
            labelText={t('signup:passwordRepeatLabelText')}
            type="password"
            placeholder={t('signup:passwordRepeatPlaceholder')}
            success={Boolean(
              passwordRepeat && validatePasswordRepeat(password, passwordRepeat)
            )}
            warning={Boolean(
              passwordRepeat &&
                !validatePasswordRepeat(password, passwordRepeat)
            )}
            error={validationErrorField === 'passwordRepeat'}
            name="passwordRepeat"
            value={passwordRepeat}
            onChange={onChange}
            autoComplete="new-password"
          />
          <FormField
            labelText={t('signup:howDidYouHearAboutUsLabelText')}
            type="text"
            placeholder={t('signup:howDidYouHearAboutUsPlaceholder')}
            success={howDidYouHearAboutUs.length > 1}
            name="howDidYouHearAboutUs"
            value={howDidYouHearAboutUs}
            onChange={onChange}
          />
        </>
      )}
      <CheckboxWithLabel
        label={
          <>
            {t('signup:acceptTermsAccept')}{' '}
            <TermsLink>{t('signup:acceptTermsTermsLink')}</TermsLink>
          </>
        }
        checked={acceptTerms}
        name="acceptTerms"
        onChange={onChange}
      />
    </SpacedContainer>
  );
};

export default SignupProducerFields;
