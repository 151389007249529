import { PropsWithChildren } from 'react';

import { tv } from '../../../utils/tv';
import { Icon } from '../../../theme/icon';

const style = tv({
  base: `
    flex
    gap-xs
    text-paragraph
  `
});

type Props = PropsWithChildren;

export const FormFieldErrorMessage = ({ children }: Props) => {
  return (
    <div className={style()}>
      <Icon icon="alert" size="medium" color="error" />
      {children}
    </div>
  );
};
