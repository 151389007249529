import { PageLoading } from './components/loading';
import { PageNotFound } from './components/not-found';
import { PageStatus } from './components/status';
import { PageError } from './components/error';
import { PageBase, PageBaseProps } from './components/base';

const PageComponent = (props: Omit<PageBaseProps, 'req'>) => {
  return <PageBase {...props} />;
};

export const Page = Object.assign(PageComponent, {
  Error: PageError,
  Loading: PageLoading,
  NotFound: PageNotFound,
  Status: PageStatus
});
