import { Input } from '@dagens/carrot';
import { ComboboxInput } from '@headlessui/react';
import { useTranslation } from '@dagens/frontend-i18n';
import { ChangeEvent } from 'react';

type Props = {
  query: string;
  onChange: (query: ChangeEvent<HTMLInputElement>) => void;
};

/**
 * @package
 */
export const DeliveryAddressInput = ({ query, onChange }: Props) => {
  const { t } = useTranslation();
  return (
    <ComboboxInput
      as={Input}
      value={query}
      onChange={onChange}
      displayValue={(deliveryAddress: string) => deliveryAddress}
      placeholder={t(
        'common:deliveryInformationFormFieldDeliveryAddressPlaceholder'
      )}
    />
  );
};
