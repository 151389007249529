import { Trans, useTranslation } from '@dagens/frontend-i18n';
import { roundNumber } from '@dagensmat/core';
import { add, dinero, isPositive, subtract, toSnapshot } from 'dinero.js';
import { useEffect } from 'react';
import { isSameDay } from 'date-fns';
import { GetDeliveryTypesResponse } from '../../../api';
import { DeliveryType } from '../../../types/Logistics';
import BasketOrderLinesByProducer from './BasketOrderLinesByProducer';
import DeliveryLine from './DeliveryLine';
import OrdersForDayTotal from './OrdersForDayTotal';
import Callout from '@carrot-deprecated/callout';
import Typography from '@carrot-deprecated/typography';
import Container from '@carrot-deprecated/container';
import { DeliveryDayFee } from 'types/Basket';
import { toMinorUnit } from 'utils/money';
import { fmtMoney, formatNok2Decimals } from 'utils/texts';

import {
  IBasketWithProducer,
  sumProducersInBasket
} from '_consumer/reducers/productsForSale';

type DagensDeliveredOrderLinesProps = {
  basket: IBasketWithProducer[];
  deliveryDate: string;
  deliveryFees: DeliveryDayFee;
  deliveryTypes?: GetDeliveryTypesResponse;
  exemptionCallback: (date: string, over: boolean) => void;
};

const DagensDeliveredOrderLines = ({
  basket,
  deliveryDate,
  deliveryFees,
  deliveryTypes,
  exemptionCallback
}: DagensDeliveredOrderLinesProps) => {
  const { t } = useTranslation();

  const dagensDeliveryProducers = deliveryFees.producers
    .filter(producer =>
      deliveryTypes?.find(deliveryType => {
        return (
          isSameDay(deliveryType.date, deliveryDate) &&
          producer.id === deliveryType.producerId &&
          deliveryType.deliveryType === DeliveryType.DAGENS_DELIVERY
        );
      })
    )
    .map(p => p.id);

  const dagensDeliveries = basket.filter(product => {
    return dagensDeliveryProducers.includes(product.producer._id);
  });

  const dagensDaySubtotal = sumProducersInBasket(dagensDeliveries);

  const threshold = dinero(deliveryFees?.deliveryFeeSteps[0]?.threshold);

  const remaining = subtract(
    threshold,
    add(
      dinero({
        amount: roundNumber(toMinorUnit(dagensDaySubtotal)),
        currency: toSnapshot(threshold).currency
      }),
      dinero(deliveryFees.previousOrdersTotal)
    )
  );

  const underThreshold = isPositive(remaining);

  useEffect(() => {
    exemptionCallback(deliveryFees.date, !underThreshold);
  }, [dagensDaySubtotal]);

  if (!dagensDeliveries.length) {
    return null;
  }

  return (
    <Container mb="xl">
      <BasketOrderLinesByProducer
        basket={dagensDeliveries}
        deliveryDate={deliveryDate}
        deliveryTagTextKey="DagensDelivery"
      />
      <OrdersForDayTotal
        label={t('common:DagensDeliverySubtotal')}
        subtotal={formatNok2Decimals(dagensDaySubtotal)}
      />
      <DeliveryLine
        deliveryFee={deliveryFees.total}
        underThreshold={underThreshold}
      />
      {underThreshold && (
        <Callout colour="noteworthy" mt="xs">
          <Typography variant="paragraphSmallBold">
            <Trans
              t={t}
              i18nKey="consumer:FreeShippingThresholdRemaining"
              values={{
                formattedAmountRemaining: fmtMoney(remaining)
              }}
            />
          </Typography>
          <Typography variant="paragraphSmall">
            {t('consumer:FreeShippingExplainer', {
              freeThreshold: fmtMoney(threshold)
            })}
          </Typography>
        </Callout>
      )}
    </Container>
  );
};

export default DagensDeliveredOrderLines;
