/* eslint no-param-reassign: ["error", { "props": true, "ignorePropertyModificationsFor": ["state"] }] */
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getCategories } from 'api';
import { type Category } from 'types/Product';
import REQ, { ReqType } from 'utils/REQ';

/** Action creators */

const name = 'producerCategories';

export const fetchCategories = createAsyncThunk(
  `${name}/fetchCategories`,
  async () => {
    const response = await getCategories();
    return response;
  }
);

type ProducerCategoriesState = {
  req: ReqType;
  categories: Category[];
};

const initialState: ProducerCategoriesState = {
  req: REQ.INIT,
  categories: []
};

const producerCategoriesSlice = createSlice({
  initialState,
  name,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(
        fetchCategories.fulfilled,
        (state, action: PayloadAction<Category[]>) => {
          state.categories = action.payload;
        }
      )
      .addCase(fetchCategories.pending, state => {
        state.categories = initialState.categories;
      })
      .addCase(fetchCategories.rejected, state => {
        state.categories = initialState.categories;
      });
  }
});

export default producerCategoriesSlice.reducer;
