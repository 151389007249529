import { useTranslation } from '@dagens/frontend-i18n';
import { PageHeader } from '@dagens/carrot';
import {
  useAppDispatch,
  useAppSelector
} from '../../../_common/hooks/reduxHooks';
import { RootState } from '../../../store';
import { StructuredAddress } from '../../../types/Logistics';
import ConsumerProfileFields from './components/ConsumerProfileFields';
import { userUpdated } from '_common/reducers/auth';
import { Page } from '@components/page';
import { postConsumerUpdate } from 'api';

type ConsumerProfilePageProps = Pick<
  RootState['auth'],
  | 'name'
  | 'deliveryAddress'
  | 'deliveryInfo'
  | 'deliveryWindow'
  | 'contactPerson'
  | 'phone'
  | 'unsubscribeFavoriteNotification'
  | 'unsubscribeWeeklyReminderNotification'
  | 'roleLang'
>;

export type ConsumerProfileChanger = (
  fields: Partial<ConsumerProfilePageProps>
) => Promise<any>;

const ConsumerProfilePage = () => {
  const dispatch = useAppDispatch();
  const {
    userId,
    name,
    structuredDeliveryAddress,
    deliveryInfo,
    deliveryWindow,
    contactPerson,
    phone,
    unsubscribeFavoriteNotification,
    unsubscribeWeeklyReminderNotification,
    roleLang
  } = useAppSelector(
    ({
      auth: {
        _id: userId,
        name,
        structuredDeliveryAddress,
        deliveryInfo,
        deliveryWindow,
        loginEmail,
        contactPerson,
        phone,
        unsubscribeFavoriteNotification,
        unsubscribeWeeklyReminderNotification,
        roleLang
      }
    }) => {
      return {
        userId,
        name,
        structuredDeliveryAddress:
          structuredDeliveryAddress as StructuredAddress,
        deliveryInfo,
        deliveryWindow,
        loginEmail,
        contactPerson,
        phone,
        unsubscribeFavoriteNotification,
        unsubscribeWeeklyReminderNotification,
        roleLang
      };
    }
  );

  const onFieldsChange: ConsumerProfileChanger = fields => {
    dispatch(userUpdated(fields));

    return postConsumerUpdate({
      consumerId: userId as string,
      ...fields
    });
  };

  const { t } = useTranslation();

  return (
    <Page>
      <PageHeader title={t('consumer:MyProfile')} subtitle={name} />
      <ConsumerProfileFields
        contactPerson={contactPerson}
        name={name}
        phone={phone}
        deliveryAddress={structuredDeliveryAddress}
        deliveryInfo={deliveryInfo}
        deliveryWindow={deliveryWindow}
        unsubscribeFavoriteNotification={Boolean(
          unsubscribeFavoriteNotification
        )}
        unsubscribeWeeklyReminderNotification={Boolean(
          unsubscribeWeeklyReminderNotification
        )}
        onFieldsChange={onFieldsChange}
        roleLang={roleLang}
        isGuest={userId === 'guest-consumer'}
      />
    </Page>
  );
};

export default ConsumerProfilePage;
