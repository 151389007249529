import { Nav } from '@dagens/carrot';
import { useNavLinksConsumer } from '../../hooks/use-nav-links-consumer';
import { MenuConsumer } from '../menu/consumer';
import {
  useHeaderNavCenter,
  useHeaderNavLeft,
  useHeaderNavRight
} from './shared';
import { HeaderNavProps } from '.';

export const HeaderNavConsumer = ({
  backButton,
  backButtonOnlyOnMobile,
  withLogo,
  returnTo,
  mobileText,
  alwaysShowMenu,
  mobileAction
}: HeaderNavProps) => {
  const links = useNavLinksConsumer();
  const left = useHeaderNavLeft({
    withLogo,
    backButton,
    backButtonOnlyOnMobile,
    returnTo
  });
  const center = useHeaderNavCenter({ links, mobileText });
  const right = useHeaderNavRight({
    menu: <MenuConsumer />,
    alwaysShowMenu,
    mobileAction
  });

  return <Nav.Bar left={left} center={center} right={right} />;
};
