import { Form, formFieldFactory } from '@dagens/frontend-forms';
import { useTranslation } from '@dagens/frontend-i18n';
import { FormField } from '@dagens/carrot';
import { VALID_TIME_SLOTS, ValidTimeSlot } from '../../../types/Consumer';
import { DeliveryFormValues } from '.';

const MINIMUM_WINDOW = 2;

const { Field, Select } = formFieldFactory<DeliveryFormValues>();

export const CustomerDeliveryWindow = () => {
  const { t } = useTranslation();

  const validateWindow = (
    value: string,
    deliveryWindowFrom: ValidTimeSlot | null,
    deliveryWindowTo: ValidTimeSlot | null,
    type: 'from' | 'to'
  ) => {
    const from = type === 'from' ? value : deliveryWindowFrom;
    const to = type === 'to' ? value : deliveryWindowTo;
    if (!from || !to) {
      return true;
    }

    const fromHours = parseInt(from.split(':')[0], 10);
    const toHours = parseInt(to.split(':')[0], 10);
    if (toHours < fromHours) {
      return t(
        'common:deliveryInformationFormFieldDeliveryWindowErrorStartBeforeEnd'
      );
    }
    const fulfillsMinimumWindow = toHours - fromHours >= MINIMUM_WINDOW;
    return (
      fulfillsMinimumWindow ||
      t(
        'common:deliveryInformationFormFieldDeliveryWindowErrorTooShortDuration'
      )
    );
  };

  return (
    <Field
      to={['deliveryWindowFrom', 'deliveryWindowTo']}
      label={t('common:deliveryInformationFormFieldDeliveryWindowLabel')}
      helpText={t(
        'common:deliveryInformationFormFieldDeliveryWindowDescription',
        { minimumHours: MINIMUM_WINDOW }
      )}
      required
    >
      <Form.Group twoColumns>
        <FormField
          label={t(
            'common:deliveryInformationFormFieldDeliveryWindowFromLabel'
          )}
          small
        >
          <Select
            name="deliveryWindowFrom"
            options={[...VALID_TIME_SLOTS]}
            validate={(value, data) =>
              value
                ? validateWindow(
                    value,
                    data.deliveryWindowFrom,
                    data.deliveryWindowTo,
                    'from'
                  )
                : true
            }
            required
            deps={['deliveryWindowTo']}
          ></Select>
        </FormField>
        <FormField
          label={t('common:deliveryInformationFormFieldDeliveryWindowToLabel')}
          small
        >
          <Select
            name="deliveryWindowTo"
            options={[...VALID_TIME_SLOTS]}
            validate={(value, data) =>
              value
                ? validateWindow(
                    value,
                    data.deliveryWindowFrom,
                    data.deliveryWindowTo,
                    'to'
                  )
                : true
            }
            required
            deps={['deliveryWindowFrom']}
          ></Select>
        </FormField>
      </Form.Group>
    </Field>
  );
};
