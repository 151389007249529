import { add, Currency, Dinero, dinero } from 'dinero.js';

export const addMany = (addsends: Dinero<number>[]): Dinero<number> => {
  return addsends.reduce(add);
};

export const zeroDinero = (currency: Currency<number>) => {
  return dinero({ amount: 0, currency });
};

export const toMinorUnit = (majorUnitAmount: number) => {
  return majorUnitAmount * 100;
};

export type Money = {
  amount: number;
  currency: Currency<number>;
  scale: number;
};
