import { PropsWithChildren } from 'react';
import { tv } from '../../utils/tv';

const style = tv({
  base: `
    transition-regular
    col-span-full
    grid
    grid-cols-subgrid
    border-b-thin
    border-b-lightGrey
    py-s
    transition-[color,background-color,border-color,opacity]

    first:border-t-thin
    first:border-t-lightGrey
  `,
  variants: {
    withHover: {
      true: 'hover:bg-brightPurple'
    },
    relative: {
      true: 'relative'
    },
    header: {
      true: `
        !border-y-0
        py-xs
      `
    }
  }
});

type Props = PropsWithChildren<{
  relative?: boolean;
  header?: boolean;
  noHover?: boolean;
}>;

export const Row = ({ noHover, relative, header, children }: Props) => {
  return (
    <tr className={style({ withHover: !noHover, relative, header })}>
      {children}
    </tr>
  );
};
