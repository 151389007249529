import { OrderUnit, UnitUtils } from '@dagens/utils';
import { ProducerProduct } from '../../../types/Product';
import { PricingFormValues } from '../../components/pricing-form';
import {
  ConsumersWithChildren,
  useCreatePricingConsumerOptions
} from '../../components/pricing-form/hooks/use-consumers';
import { useAppSelector } from '../../../_common/hooks/reduxHooks';
import { SanityRef } from '../../../types/Sanity';

// Find pricing in product by key
const getPricing = (pricingKey?: string, product?: ProducerProduct) => {
  if (!pricingKey) {
    return null;
  }
  return product?.prices.find(price => price._key === pricingKey) ?? null;
};

// Get customer the price should apply to, from their ids in the pricing object
// If the current price doesn't have any customers, we assume it's for all customers
const getSpecialConsumers = (
  consumersWithAll: ConsumersWithChildren[],
  specialConsumers?: SanityRef[]
) => {
  if (!consumersWithAll) {
    return [];
  }

  if (!specialConsumers || specialConsumers.length === 0) {
    const allConsumer = consumersWithAll.at(0);
    return allConsumer ? [allConsumer] : [];
  }

  const matchingConsumers = consumersWithAll
    .at(0)
    ?.children?.filter(consumer =>
      specialConsumers.find(
        productConsumer => productConsumer._ref === consumer._id
      )
    );

  return matchingConsumers;
};

// Resolve default values for the pricing form, from the product and pricing key
export const useDefaultValue = (
  pricingKey?: string,
  product?: ProducerProduct
) => {
  const pricing = getPricing(pricingKey, product);

  const { consumers, req } = useAppSelector(
    ({ consumers: { items = [], req } }) => {
      return { consumers: items, req };
    }
  );
  const consumersWithAll = useCreatePricingConsumerOptions(consumers);
  const specialConsumers =
    getSpecialConsumers(consumersWithAll, pricing?.specialConsumers) ?? [];

  // If the order unit is the same as the price unit,
  // the order units should be the fallback (null crates)
  // because that's what should be shown when switching the radio to "No"
  const orderEqualsPrice = pricing?.pricedUnit === pricing?.orderedUnit;
  const orderValue = orderEqualsPrice
    ? null
    : (pricing?.pricedUnitsPerOrderedUnit ?? null);
  const orderUnit = orderEqualsPrice
    ? UnitUtils.packed.crate
    : ((pricing?.orderedUnit as OrderUnit) ?? UnitUtils.packed.crate);

  const defaultValue: PricingFormValues = {
    customers: specialConsumers,
    priceValue: pricing?.nokPerPricedUnit ?? null,
    priceUnit: pricing?.pricedUnit ?? UnitUtils.measure.kg,
    measureValue: pricing?.inputMeasureUnitValue ?? null,
    measureUnit: pricing?.inputMeasureUnit ?? UnitUtils.measure.kg,
    orderEqualsPrice,
    orderValue,
    orderUnit,
    additionalInfo: pricing?.unitSizeDescription ?? null
  };

  return { defaultValue, req };
};
