import { useTranslation } from '@dagens/frontend-i18n';
import { UnitUtils } from '@dagens/utils';
import { Form, formFieldFactory, useWatch } from '@dagens/frontend-forms';
import { PricingFormValues } from '..';

// Validation function for order unit fields
// These fields are required if the price unit is a measure unit (kg, l, etc.) or piece
// and if the "orderEqualsPrice" is set to false
// If the price unit is a packed unit, the order unit is assumed to be the same
const orderUnitRequired = (
  priceUnit: string | undefined,
  orderEqualsPrice: boolean | undefined
) => {
  const isOrderUnit = UnitUtils.isPackedUnit(priceUnit);
  if (isOrderUnit) {
    return false;
  }
  return !orderEqualsPrice;
};

const { Field, NumberInput, Select, Radio } =
  formFieldFactory<PricingFormValues>();

type Props = {
  defaultValue?: PricingFormValues;
};

// Order unit inputs for the pricing form
// When the price unit is a packed unit, we assume the order unit is the same
// So we hide this
export const PricingFormOrderUnit = ({ defaultValue }: Props) => {
  const { t } = useTranslation();
  const { orderEqualsPrice, priceUnit } = useWatch<PricingFormValues>({
    defaultValue
  });

  // Packed units don't need an order unit
  const isPackedUnit = UnitUtils.isPackedUnit(priceUnit);
  if (isPackedUnit) {
    return null;
  }

  // Piece can only be an order unit for (kg, l, etc.).
  // To avoid having piece as both the price and order unit
  const packedUnits = [...Object.values(UnitUtils.packed)];
  const pieceUnit =
    priceUnit === UnitUtils.combined.piece ? [] : [UnitUtils.combined.piece];
  const orderUnits = [...pieceUnit, ...packedUnits];

  const required = orderUnitRequired(priceUnit, orderEqualsPrice);

  return (
    <>
      <Field
        to="orderEqualsPrice"
        label={t('producer:productOrderingUnitsHeader', {
          pricedUnit: priceUnit
        })}
        required
      >
        <Radio
          name="orderEqualsPrice"
          options={[false, true]}
          displayValue={value => ({
            label: value ? t(`common:Yes`) : t(`common:No`)
          })}
        />
      </Field>
      {!orderEqualsPrice && (
        <Field
          to={['orderValue', 'orderUnit']}
          label={t('producer:productListOrderingUnitsLabelText')}
          required={required}
        >
          <Form.Group numberWithSelect>
            <NumberInput
              name="orderValue"
              type="float"
              unit={priceUnit && t(`units:${priceUnit}`, { count: 1 })}
              required={required}
            />
            <Select
              name="orderUnit"
              options={orderUnits}
              displayValue={value =>
                t(`units:${value}_long`, {
                  count: 1
                })
              }
              required={required}
            />
          </Form.Group>
        </Field>
      )}
    </>
  );
};
