import { uuid } from '@sanity/uuid';

const CORRELATION_ID_HEADER_NAME = 'correlation-id';

const isCookieExpired = (cookie: string): boolean => {
  const cookieParts = cookie.split(';');
  const expiresPart = cookieParts.find(part => {
    return part.trim().startsWith('expires=');
  });
  if (expiresPart) {
    const expiresString = expiresPart.split('=')[1];
    const expiresDate = new Date(Date.parse(expiresString));

    return expiresDate.getTime() < Date.now();
  }
  return false;
};

const getCookie = (name: string): string | null => {
  const cookieString = document.cookie;
  const cookie = cookieString
    .split(';')
    .map(c => {
      return c.trim();
    })
    .find(c => {
      return c.startsWith(`${name}=`);
    });
  if (cookie) {
    const value = cookie.substring(name.length + 1, cookie.length);
    return isCookieExpired(cookie) ? null : value;
  }
  return null;
};

const setCookie = (name: string, value: string, options: any = {}): void => {
  const cookieOptions = {
    path: '/',
    ...options
  };
  let cookieString = `${name}=${encodeURIComponent(value)}`;
  if (cookieOptions.expires) {
    const expires = cookieOptions.expires.toUTCString();
    cookieString += `; expires=${expires}`;
  }
  if (cookieOptions.maxAge) {
    cookieString += `; max-age=${cookieOptions.maxAge}`;
  }
  if (cookieOptions.domain) {
    cookieString += `; domain=${cookieOptions.domain}`;
  }
  if (cookieOptions.secure) {
    cookieString += '; secure';
  }
  if (cookieOptions.sameSite) {
    cookieString += `; samesite=${cookieOptions.sameSite}`;
  }
  document.cookie = cookieString;
};

export const initiateCorrelationId = (): string => {
  const newCorrelationId = uuid();
  setCookie(CORRELATION_ID_HEADER_NAME, newCorrelationId, { maxAge: 3600 });
  return newCorrelationId;
};

export const getCorrelationIdCookie = (): string => {
  const correlationId = getCookie(CORRELATION_ID_HEADER_NAME);
  if (!correlationId) {
    return initiateCorrelationId();
  }
  return correlationId;
};
