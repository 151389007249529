import { tv } from '../../../utils/tv';

const style = tv({
  base: `
    row-span-full
    h-full
    border-x-thin
    border-r-0
    border-dashed
    border-availability
  `,
  variants: {
    last: {
      true: `!border-r-thin`
    }
  }
});

export const SeasonCalendarColumns = () => {
  return Array.from({ length: 12 }).map((_, index, array) => (
    <div key={index} className={style({ last: index === array.length - 1 })}>
      &nbsp;
    </div>
  ));
};
