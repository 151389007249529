import { Navigate, useLocation } from 'react-router-dom';
import {
  BottomActions,
  Box,
  Nav,
  useMediaQuery,
  PageHeader
} from '@dagens/carrot';
import { useTranslation } from '@dagens/frontend-i18n';
import { Form } from '@dagens/frontend-forms';
import { Page } from '../../../../components/page';
import { AddCustomerFormValues, defaultValue } from '../types';
import { CustomerBillingForm } from '../../../components/customer-billing-form';
import { useOnSave } from './use-on-save';
import { AddCustomerBillingNextButton as Next } from './next-button';
import { AddCustomerBillingBackButton as Back } from './back-button';

export const AddCustomerBillingPage = () => {
  const { desktopUp } = useMediaQuery();
  const { t } = useTranslation();
  const { onSave } = useOnSave();
  const { state }: { state: AddCustomerFormValues } = useLocation();

  if (!state?.organization) {
    return <Navigate to="/customers/add/start" />;
  }

  const title = t('producer:AddCustomer.reviewBillingInformationPageHeader');
  const subtitle = `${t('producer:AddCustomer.headerAnnotation')} (2/4)`;

  return (
    <Form.Provider defaultValue={{ ...defaultValue, ...state }}>
      <Page
        backButton={<Back />}
        navMobileText={
          <>
            <Nav.ContextTitle>{title}</Nav.ContextTitle>
            <Nav.ContextSubtitle>{subtitle}</Nav.ContextSubtitle>
          </>
        }
        header={
          desktopUp && (
            <Box.FullWidth>
              <PageHeader title={title} subtitle={subtitle} />
            </Box.FullWidth>
          )
        }
        bottom={
          <BottomActions border>
            <BottomActions.Cancel to="/customers">
              {t('common:Cancel')}
            </BottomActions.Cancel>
            <Next onSubmit={onSave} />
          </BottomActions>
        }
      >
        <Box.FormWrapper>
          <Form onSubmit={onSave}>
            <CustomerBillingForm
              variant="add"
              country={state.organization.country}
              defaultBillingName={state.billingName}
              defaultPrimaryEmail={state.primaryEmail}
              defaultBillingAddress={state.organization.address}
            />
          </Form>
        </Box.FormWrapper>
      </Page>
    </Form.Provider>
  );
};
