import { DateRange, DayPicker } from 'react-day-picker';
import { Locale } from '@dagens/utils';
import { useSharedProps } from '../date-picker/props';

export type DatePickerRangeProps = {
  value?: DateRange;
  otherRanges?: DateRange[];
  onChange: (value?: DateRange) => void;
  locale: Locale;
};

export const DatePickerRange = ({
  value,
  otherRanges,
  onChange,
  locale
}: DatePickerRangeProps) => {
  const { containerRef, props: sharedProps } = useSharedProps({
    locale,
    otherRanges
  });
  return (
    <div ref={containerRef}>
      <DayPicker
        mode="range"
        selected={value}
        onSelect={onChange}
        {...sharedProps}
      />
    </div>
  );
};
