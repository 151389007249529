import { Form, formFieldFactory } from '@dagens/frontend-forms';
import { useTranslation } from '@dagens/frontend-i18n';
import { InfoSection } from '@dagens/carrot';
import { validateEmail } from '../../../utils/validation';
import { isValidEan } from '../../../utils/billingAccount';
import { StructuredAddress } from '../../../types/Logistics';
import { CustomerBillingAddress as Address } from './billing-address';
import { PrimaryEmailFeedback } from './primary-email-feedback';
import { BillingFeedback } from './billing-feedback';

export type BillingFormValues = {
  billingName: string | null;
  department: string | null;
  eanNumber: string | null;
  primaryEmail: string | null;
  reminderEmail: string | null;
  billingAddress: StructuredAddress | string | null;
};

const { Field, Input } = formFieldFactory<BillingFormValues>();

type AddProps = {
  variant: 'add';
  country?: string;
  defaultBillingName?: string | null;
  defaultPrimaryEmail?: string | null;
  defaultBillingAddress?: StructuredAddress | string | null;
};

type EditProps = {
  variant: 'edit';
  organizationNumber: string;
};

export const CustomerBillingForm = (props: AddProps | EditProps) => {
  const { t } = useTranslation();

  const addProps = props.variant === 'add' ? props : null;
  const editProps = props.variant === 'edit' ? props : null;

  const {
    country,
    defaultBillingName,
    defaultPrimaryEmail,
    defaultBillingAddress
  } = addProps ?? {};
  const { organizationNumber } = editProps ?? {};

  return (
    <Form.Section>
      {organizationNumber && (
        <div>
          <InfoSection.Term>
            {t('producer:EditCustomerCVRNumber')}
          </InfoSection.Term>
          <InfoSection.Details>{organizationNumber}</InfoSection.Details>
        </div>
      )}

      <Field
        to="billingName"
        label={t('producer:AddCustomer.reviewBillingInformationFieldLabel')}
        helpText={t(
          'producer:AddCustomer:reviewBillingInformationFieldDescription'
        )}
        feedback={<BillingFeedback defaultBillingName={defaultBillingName} />}
        required
      >
        {/* eslint-disable-next-line jsx-a11y/no-autofocus */}
        <Input name="billingName" autoFocus />
      </Field>

      <Field
        to="department"
        label={t(
          'producer:AddCustomer.reviewBillingInformationFieldDepartmentNameLabel'
        )}
        helpText={t(
          'producer:AddCustomer:reviewBillingInformationFieldDepartmentNameDescription'
        )}
      >
        <Input name="department" />
      </Field>

      {country === 'dk' && (
        <Field
          to="eanNumber"
          label="EAN nummer"
          helpText={t('common:EanNumberExplanation')}
          maxLength={13}
        >
          <Input
            name="eanNumber"
            placeholder={t('common:EanNumberInputPlaceholder')}
            validate={value => {
              const valid = value === null || isValidEan(value);
              return valid || t('common:EanNumberErrorMessage');
            }}
            inputMode="numeric"
          />
        </Field>
      )}

      <Field
        to="primaryEmail"
        label={t(
          'producer:AddCustomer:reviewBillingInformationFieldPrimaryInvoiceLabel'
        )}
        feedback={
          <PrimaryEmailFeedback defaultPrimaryEmail={defaultPrimaryEmail} />
        }
        required
      >
        <Input
          name="primaryEmail"
          validate={value => {
            const valid = value !== null && validateEmail(value);
            return valid;
          }}
          type="email"
        />
      </Field>

      <Field
        to="reminderEmail"
        label={t(
          'producer:AddCustomer:reviewBillingInformationFieldReminderInvoiceLabel'
        )}
        helpText={t(
          'producer:AddCustomer.reviewBillingInformationFieldReminderInvoiceDescription'
        )}
        required
      >
        <Input
          name="reminderEmail"
          validate={value => {
            const valid = value !== null && validateEmail(value);
            return valid;
          }}
          type="email"
        />
      </Field>
      <Address defaultBillingAddress={defaultBillingAddress} />
    </Form.Section>
  );
};
