import { PropsWithChildren, useEffect, useRef, useState } from 'react';
import { tv } from '../../utils/tv';

const style = tv({
  slots: {
    container: `
      relative
      left-1/2
      w-screen
      -translate-x-1/2
      overflow-hidden

      desktop:w-full
    `,
    content: `
      flex
      w-full
      gap-xxs
      overflow-x-auto
      px-s
      pb-[12px]

      desktop:px-0
    `
  }
});

const gradientStyles = tv({
  base: `
    pointer-events-none
    absolute
    inset-0

    desktop:from-white
    desktop:from-0%
    desktop:to-transparent
    desktop:to-[48px]
  `,
  variants: {
    left: {
      true: 'desktop:bg-gradient-to-l'
    },
    right: {
      true: 'desktop:bg-gradient-to-r'
    }
  }
});

type Props = PropsWithChildren;

export const ButtonGroupHorizontalScroll = ({ children }: Props) => {
  const { container, content } = style();
  const [atStart, setAtStart] = useState(true);
  const [atEnd, setAtEnd] = useState(false);
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const scrollHandler = () => {
      if (ref.current) {
        setAtStart(ref.current.scrollLeft === 0);
        const scrollEnd = ref.current.scrollWidth - ref.current.clientWidth;
        setAtEnd(Math.abs(ref.current.scrollLeft - scrollEnd) <= 1); // some weirdness can occur with rounding
      }
    };

    if (ref.current) {
      ref.current.addEventListener('scroll', scrollHandler);
    }

    return () => {
      if (ref.current) {
        ref.current.removeEventListener('scroll', scrollHandler);
      }
    };
  }, []);

  return (
    <div className={container()}>
      <div className={content()} ref={ref}>
        {children}
      </div>
      {!atEnd && <div className={gradientStyles({ left: true })}></div>}
      {!atStart && <div className={gradientStyles({ right: true })}></div>}
    </div>
  );
};
