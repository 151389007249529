import { tv } from '../../../utils/tv';
import { FormFieldCharCount } from './character-count';
import { FormFieldHelpText } from './help-text';

const style = tv({
  base: `
    flex
    justify-between
    text-paragraphSmall
  `
});

type Props = {
  helpText?: string;
  maxLength?: number;
  valueLength?: number;
};

export const HelpTextContainer = ({
  helpText,
  maxLength,
  valueLength
}: Props) => {
  if (!helpText && maxLength === undefined) {
    return null;
  }

  return (
    <div className={style()}>
      <FormFieldHelpText>{helpText}</FormFieldHelpText>
      {maxLength !== undefined && (
        <FormFieldCharCount
          maxLength={maxLength}
          valueLength={valueLength ?? 0}
        />
      )}
    </div>
  );
};
