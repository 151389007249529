import { DatePickerRange, DateRange } from '@dagens/carrot';
import { useTranslation } from '@dagens/frontend-i18n';
import { LocaleUtils } from '@dagens/utils';

type Props = {
  value: DateRange | undefined;
  otherPeriods?: DateRange[];
  onChange: (value: DateRange | undefined) => void;
};

export const PeriodPickerCalendar = ({
  value,
  otherPeriods,
  onChange
}: Props) => {
  const { i18n } = useTranslation();
  return (
    <DatePickerRange
      value={value}
      otherRanges={otherPeriods}
      onChange={onChange}
      locale={LocaleUtils.localeFromLanguage(i18n.resolvedLanguage)}
    />
  );
};
