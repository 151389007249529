import { useTranslation } from '@dagens/frontend-i18n';
import React, { ChangeEvent } from 'react';
import FormField from '_common/components/input/FormField';
import { getOrganization } from 'api';
import { AUTO_REQUEST_TIMEOUT_MS } from 'config';
import {
  isBetween,
  OrganizationResponse,
  validateOrganization
} from 'utils/validation';

type OrgNumberInputProps = {
  value: string;
  validationErrorField: string;
  onChange: (val: ChangeEvent<HTMLInputElement>) => void;
  organization: OrganizationResponse;
  onOrganizationChanged: (val: any) => void;
};

const OrgNumberInput = ({
  value,
  validationErrorField,
  onChange,
  organization,
  onOrganizationChanged
}: OrgNumberInputProps) => {
  const { t } = useTranslation();
  const fieldName = 'orgNumber';
  let timeout: NodeJS.Timeout;
  const [isFetching, setIsFetching] = React.useState(false);
  const constraints = { min: 8, max: 9 };

  const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const orgNumber = e.target.value.replace(/[ \D]/g, '');
    onChange({
      ...e,
      target: { ...e.target, value: orgNumber, name: fieldName }
    });
    clearTimeout(timeout);

    onOrganizationChanged({});

    if (!isBetween(orgNumber.length, constraints)) return;
    setIsFetching(true);
    timeout = setTimeout(async () => {
      const org = await getOrganization({ orgNumber });
      setIsFetching(false);
      onOrganizationChanged(org);
    }, AUTO_REQUEST_TIMEOUT_MS);
  };

  const getExplanationText = () => {
    const { status = '', name = '', exists = false } = organization;
    if (isFetching) return `${t('common:Searching')}...`;
    if (status === 'ORGANIZATION_FOUND' && !exists)
      return t('signup:validation.foundOrganization', {
        name
      });
    if (exists)
      return t('signup:validation.organizationExists', { orgName: name });
    return validateOrganization(organization)?.message || '';
  };

  return (
    <FormField
      labelText={t('signup:orgNumberLabelText')}
      type="text"
      placeholder={t('signup:orgNumberPlaceholder')}
      success={isBetween(value.length, constraints)}
      error={validationErrorField === fieldName}
      name={fieldName}
      value={value}
      helpText={getExplanationText()}
      onChange={onInputChange}
    />
  );
};

export default OrgNumberInput;
