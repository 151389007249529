import { twMerge } from 'tailwind-merge';
import { tv } from '../../utils/tv';
import { buttonStyle } from '../button/styles';
import { Icon } from '../../theme/icon';

const style = tv({
  slots: {
    container: `
      pointer-events-none
      h-m

      hover:!bg-secondary
    `,
    remove: `
      pointer-events-auto

      hover:text-brightPurple
    `
  }
});

type Props = {
  text: string;
  onRemove?: () => void;
};

export const Chip = ({ text, onRemove }: Props) => {
  const { container, remove } = style();
  return (
    <div
      className={twMerge(
        buttonStyle({
          variant: 'secondary',
          size: 'small',
          alignment: 'left'
        }),
        container()
      )}
    >
      {text}
      {onRemove && (
        <button
          type="button"
          className={remove()}
          onClick={() => {
            onRemove?.();
          }}
        >
          <Icon icon="close" size="small" />
        </button>
      )}
    </div>
  );
};
