import ProducerSalesModuleReminder from './ProducerSalesModuleReminder';
import ProducerSalesModuleStatistics from './ProducerSalesModuleStatistics';
import ProducerSalesModuleTopUp from './ProducerSalesModuleTopUp';
import Container from '@carrot-deprecated/container';

const ProducerSalesModules = () => {
  return (
    <Container
      width="full"
      flex
      justify="flex-start"
      wrap="wrap"
      alignItems="stretch"
      gap="s"
      py="s"
    >
      <ProducerSalesModuleStatistics />
      <ProducerSalesModuleTopUp />
      <ProducerSalesModuleReminder />
    </Container>
  );
};
export default ProducerSalesModules;
