import { forwardRef, PropsWithChildren, Ref } from 'react';
import { NavLink as ReactRouterNavLink, NavLinkProps } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';
import { Simplify } from 'type-fest';
import { tv } from '../../../utils/tv';

import { buttonStyle, ButtonStyles } from '../../button/styles';

const NAV_LINK_ACTIVE_CUSTOM_CLASS = 'active';

const contentStyle = tv({
  base: `
    relative
    inline-flex
    size-full
    items-center
    justify-center
    gap-x-xxs
    p-xxs
  `
});

export type NavLinkComponentProps = Simplify<
  PropsWithChildren<
    ButtonStyles & {
      active?: boolean;
      className?: NavLinkProps['className'];
      to: NavLinkProps['to'];
      tabIndex?: NavLinkProps['tabIndex'];
    }
  >
>;

const InternalComponent = (
  {
    // style props
    active,
    alignment,
    fillParent,
    focused,
    size,
    variant,

    // link props
    to,

    // react props
    children,
    className,
    tabIndex
  }: NavLinkComponentProps,
  ref: Ref<HTMLAnchorElement>
) => {
  return (
    <ReactRouterNavLink
      ref={ref}
      className={({ isActive, isPending, isTransitioning }) => {
        const navLinkActive = (isActive || active) ?? false;
        return twMerge(
          buttonStyle({
            alignment,
            fillParent,
            focused,
            size,
            variant
          }),
          navLinkActive && NAV_LINK_ACTIVE_CUSTOM_CLASS,
          typeof className === 'function'
            ? className({ isActive: navLinkActive, isPending, isTransitioning })
            : className
        );
      }}
      to={to}
      tabIndex={tabIndex}
    >
      <div className={contentStyle()}>{children}</div>
    </ReactRouterNavLink>
  );
};

export const NavLinkComponentInternal = forwardRef(InternalComponent);

const Component = (
  props: Omit<NavLinkComponentProps, 'className'>,
  ref: Ref<HTMLAnchorElement>
) => {
  return <NavLinkComponentInternal {...props} ref={ref} />;
};

export const NavLinkComponent = forwardRef(Component);
