import { Form } from '@dagens/frontend-forms';
import { PricingFormValues } from '../pricing-form';
import { ProductFormFields as Fields } from './parts/fields';
import { ProductFormValues } from '.';

type Props = {
  defaultValue?: ProductFormValues & PricingFormValues;
  onSubmit: (data: ProductFormValues & PricingFormValues) => void;
};

// The create form has both the product fields
// and the pricing fields for it's default price
export const ProductCreateForm = ({ defaultValue, onSubmit }: Props) => {
  return (
    <Form onSubmit={onSubmit}>
      <Fields flow="create" defaultValue={defaultValue} />
    </Form>
  );
};
