import { useMemo } from 'react';
import { useAppSelector } from '../../../../_common/hooks/reduxHooks';
import { RootState } from '../../../../store';
import REQ from '../../../../utils/REQ';
import { Category } from '../../../../types/Product';

// Converts a flat array of categories to a tree structure
// Non-food categories are sorted to the end of the list (and each child list)
const categoryArrayToTree = (categories: Category[]) => {
  const map = new Map<string, Category>(
    categories.map(category => [category._id, { ...category, children: [] }])
  );

  categories.forEach(category => {
    if (category.parent) {
      const parent = map.get(category.parent._ref);
      if (parent) {
        if (!parent.children) {
          parent.children = [category];
        } else {
          parent.children.push(category);
          parent.children.sort((a, b) =>
            a.isNonFood && !b.isNonFood
              ? 1
              : !a.isNonFood && b.isNonFood
                ? -1
                : 0
          );
        }
      }
    }
  });

  return [...map.values()]
    .filter(category => !category.parent)
    .sort((a, b) =>
      a.isNonFood && !b.isNonFood ? 1 : !a.isNonFood && b.isNonFood ? -1 : 0
    );
};

// Fetches all product categories and converts them to a tree structure,
// where each category has a list of children
export const useCategories = () => {
  const { categories, req } = useAppSelector(
    ({ producerCategories }: RootState) => {
      return {
        categories: producerCategories.categories,
        req: producerCategories.req
      };
    }
  );

  const categoryTree = useMemo(() => {
    return categoryArrayToTree(categories);
  }, [categories]);

  return {
    categories: categoryTree,
    req: categories && categories.length > 0 ? REQ.SUCCESS : req
  };
};
