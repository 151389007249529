import React from 'react';
import Container from '@carrot-deprecated/container';
import CloseButton from '_common/components/button/CloseButton';
import { WidthNames } from '@carrot-deprecated/utils/width-props';
import Callout from '@carrot-deprecated/callout';
import Typography from '@carrot-deprecated/typography';

type CloseableCalloutProps = {
  header: React.ReactNode;
  message: string;
  onClose: React.MouseEventHandler<HTMLButtonElement>;
  width?: WidthNames;
};

const CloseableCallout = ({
  header,
  message,
  onClose,
  width = 'full'
}: CloseableCalloutProps) => {
  return (
    <Callout width={width}>
      <Container
        flex
        wrap="nowrap"
        justify="space-between"
        alignItems="flex-start"
        mb="xxs"
      >
        <Typography variant="paragraph">{header}</Typography>
        <CloseButton onClick={onClose} />
      </Container>
      <Typography variant="paragraph">{message}</Typography>
    </Callout>
  );
};

export default CloseableCallout;
