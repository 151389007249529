const DeviceBreakpoint = {
  tablet: 420,
  desktop: 1024,
  largeDesktop: 1440
};

const MediaQueries = {
  mobileOnly: `@media screen and (max-width: ${DeviceBreakpoint.tablet}px)`,
  tabletUp: `@media screen and (min-width: ${DeviceBreakpoint.tablet}px)`,
  desktopUp: `@media screen and (min-width: ${DeviceBreakpoint.desktop}px)`,
  largeDesktops: `@media screen and (min-width: ${DeviceBreakpoint.largeDesktop}px)`,
  print: '@media print'
};

export default MediaQueries;
