import { Trans, useTranslation } from '@dagens/frontend-i18n';
import { useState } from 'react';
import Icon from '../utils/Icon';
import Container from '@carrot-deprecated/container';
import Typography from '@carrot-deprecated/typography';
import { B } from 'utils/fonts';
import {
  MINIMUM_ORDER_MESSAGE_SHOWN,
  MINIMUM_ORDER_ERROR_SHOWN,
  track
} from 'utils/mixpanel';

type MinimumOrderAmountCalloutProps = {
  producerName?: string;
  minimumOrderAmount?: number;
  minimumOrderAmountEnforced?: boolean;
  underMinimum: number | false;
};

const MinimumOrderAmountCallout = ({
  producerName,
  minimumOrderAmount,
  minimumOrderAmountEnforced,
  underMinimum
}: MinimumOrderAmountCalloutProps) => {
  const [eventSent, setEventSent] = useState(false);
  if (!eventSent && underMinimum !== false) {
    if (minimumOrderAmountEnforced) {
      track(MINIMUM_ORDER_ERROR_SHOWN, { producerName });
    } else {
      track(MINIMUM_ORDER_MESSAGE_SHOWN, { producerName });
    }
    setEventSent(true);
  }
  const { t } = useTranslation();

  if (underMinimum === false) {
    return null;
  }

  return (
    <Container flex direction="row" wrap="nowrap" gap="xxs" my="xs">
      {minimumOrderAmountEnforced ? (
        <>
          <Icon icon="alert" $size="m" fill="error" />
          <Typography variant="paragraphSmall" color="secondary">
            <Trans
              t={t}
              i18nKey="consumer:ProducerMinimumOrderAmountThresholdMessageEnforced"
              values={{
                producerName,
                amount: minimumOrderAmount,
                underMinimum
              }}
              components={{ B: <B />, BR: <br /> }}
            />
          </Typography>
        </>
      ) : (
        <>
          <Icon icon="alert" $size="m" fill="warning" />
          <Typography variant="paragraphSmall" color="secondary">
            <Trans
              t={t}
              i18nKey="consumer:ProducerMinimumOrderAmountThresholdMessage"
              values={{
                producerName,
                amount: minimumOrderAmount,
                underMinimum
              }}
              components={{ B: <B />, BR: <br /> }}
            />
          </Typography>
        </>
      )}
    </Container>
  );
};

export default MinimumOrderAmountCallout;
