import Theme from '@carrot-deprecated/theme';
import { HorisontalScrollRowButton } from '_common/components/button/Button.style';
import Icon from '_common/components/utils/Icon';

type FilterButtonProps = {
  label: string;
  isActive: boolean;
  handleButtonClick: () => void;
  isSelected: boolean;
};

const FilterButton = ({
  label,
  isActive,
  handleButtonClick,
  isSelected
}: FilterButtonProps) => {
  return (
    <HorisontalScrollRowButton
      onClick={handleButtonClick}
      variant={isActive ? 'secondary' : 'ghost'}
    >
      {label}
      <Icon
        icon="chevronSmall"
        ml={Theme.Spacings.xs}
        fill={isActive ? 'white' : 'black'}
        rotate={isSelected ? 0 : 180}
      />
    </HorisontalScrollRowButton>
  );
};

export default FilterButton;
