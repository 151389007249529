import { useTranslation } from '@dagens/frontend-i18n';
import { SeasonCalendar } from '@dagens/carrot';
import {
  formFieldFactory,
  PeriodPickerValue,
  useWatch
} from '@dagens/frontend-forms';
import { ProductFormValues } from '..';

const { Field, PeriodPicker } = formFieldFactory<ProductFormValues>();

type Props = {
  defaultValue?: PeriodPickerValue;
};

const Calendar = ({ defaultValue }: Props) => {
  const { t } = useTranslation();
  const today = new Date();
  const seasonCalendar = useWatch<ProductFormValues>({
    name: 'seasonCalendar',
    defaultValue
  }) as PeriodPickerValue;
  const fullYearRange = [
    {
      from: new Date(today.getFullYear(), 0, 1),
      to: new Date(today.getFullYear(), 11, 31)
    }
  ];

  const ranges =
    seasonCalendar === 'ALL' ? fullYearRange : (seasonCalendar ?? []);

  return (
    <SeasonCalendar
      title={t('producer:productSeasonCalendarYear')}
      ranges={ranges}
    />
  );
};

export const ProductFormAvailablePeriods = ({ defaultValue }: Props) => {
  const { t } = useTranslation();
  return (
    <>
      <PeriodPicker
        name="seasonCalendar"
        mergeOverlapping
        showAlways
        required
        formField={({ children }) => (
          <Field
            to="seasonCalendar"
            label={t('producer:productSeasonCalendarLabel')}
            helpText={t('producer:SeasonCalendarEmptyValidationMessage')}
            required
          >
            {children}
          </Field>
        )}
      />
      <Calendar defaultValue={defaultValue} />
    </>
  );
};
