import { Icon, InfoSection, Link } from '@dagens/carrot';
import { useTranslation } from '@dagens/frontend-i18n';
import { AddressUtils } from '@dagens/utils';
import { Consumer } from '../../../../types/Consumer';
import { BillingAccount } from '../../../../types/BillingAccount';

const { Title, Items, Term, Details } = InfoSection;

type Props = {
  consumer: (Consumer & { billingAccount: BillingAccount }) | undefined;
};

export const BillingInfo = ({ consumer }: Props) => {
  const { t } = useTranslation();
  const {
    organizationNumber,
    name,
    department,
    eanNumber,
    address,
    emails,
    reminderEmail
  } = consumer?.billingAccount || {};
  const primaryEmail = emails?.at(0);

  return (
    <InfoSection>
      <Title text={t('producer:EditCustomerBillingInformation')} />
      <Items>
        <div>
          <Term>{t('producer:EditCustomerCVRNumber')}</Term>
          <Details>{organizationNumber}</Details>
        </div>
        <div>
          <Term>{t('producer:EditCustomerBillingCompany')}</Term>
          <Details>{name}</Details>
        </div>
        <div>
          <Term>{t('common:Department')}</Term>
          <Details secondary={!department}>
            {department ?? t('common:None')}
          </Details>
        </div>
        {eanNumber && (
          <div>
            <Term>{t('common:EanNumber')}</Term>
            <Details>{eanNumber}</Details>
          </div>
        )}
        <div>
          <Term>
            {t(
              'producer:AddCustomer.reviewBillingInformationFieldPrimaryInvoiceLabel'
            )}
          </Term>
          <Details>{primaryEmail}</Details>
        </div>
        <div>
          <Term>
            {t(
              'producer:AddCustomer.reviewBillingInformationFieldReminderInvoiceLabel'
            )}
          </Term>
          <Details>{reminderEmail}</Details>
        </div>
        <div>
          <Term>{t('producer:EditCustomerBillingAddress')}</Term>
          <Details>{AddressUtils.toString(address)}</Details>
        </div>
      </Items>
      <div>
        <Link variant="ghost" to={`/customers/${consumer?._id}/billing`}>
          <Icon icon="edit" />
          {t('producer:EditCustomerEditBillingInformation')}
        </Link>
      </div>
    </InfoSection>
  );
};
