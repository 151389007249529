import { useState } from 'react';

type Args<T> = {
  onChange?: (value: T) => void;
  displayValue?: (value?: T) => string | undefined;
};

export const useComboboxState = <T,>({ onChange, displayValue }: Args<T>) => {
  const [query, setQuery] = useState('');

  const valueToString = (value?: T) => {
    return displayValue?.(value) ?? value?.toString() ?? '';
  };

  const resetQuery = () => {
    setQuery('');
  };

  const onChangeInternal = (value: T) => {
    resetQuery();
    onChange?.(value);
  };

  return {
    query,
    setQuery,
    valueToString,
    resetQuery,
    onChangeInternal
  };
};
