import { Pricing } from '../../../../types/Product';
import { allCustomersOption } from './use-consumers';

// Resolves customer IDs from prices
// If a price doesn't have special customers, it's for "All customers"
// Useful for resolving customers that already have special prices for the product
// (since these customers should be disabled from getting a new price)
export const useConsumersWithPrice = (prices?: Pricing[]) => {
  if (!prices) {
    return [];
  }

  const consumerIds = prices
    .flatMap(p => p.specialConsumers)
    .filter(v => v !== undefined)
    .map(v => v._ref);

  const hasPriceForAll = prices.some(
    p =>
      !p.specialConsumers ||
      p.specialConsumers.every(consumer => {
        return consumer._ref === allCustomersOption._id;
      }) ||
      p.specialConsumers.length === 0
  );

  const consumers = consumerIds.concat(
    hasPriceForAll ? [allCustomersOption._id] : []
  );

  return consumers.map(consumerId => ({
    _id: consumerId,
    name: ''
  }));
};
