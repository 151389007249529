import { PropsWithChildren } from 'react';
import { tv } from '../../../utils/tv';

const style = tv({
  base: `
    flex
    flex-col
    gap-m
  `
});

type Props = PropsWithChildren;

export const InfoSectionContainer = ({ children }: Props) => {
  return <div className={style()}>{children}</div>;
};
