import { ChangeEvent, useState } from 'react';
import { Combobox } from '@headlessui/react';
import { useTranslation } from '@dagens/frontend-i18n';
import { Icon } from '@dagens/carrot';
import { useAddressResults } from '../delivery-information-form/use-address-results';
import { DeliveryAddressResults } from './DeliveryAddressResults';
import { DeliveryAddressInput } from './DeliveryAddressInput';
import Typography from '@carrot-deprecated/typography';

type Props = {
  deliveryAddress: string;
  onDeliveryAddressChange: (address: string) => void;
  onErrorChange?: (hasError: boolean) => void;
};

export const DeliveryAddressField = ({
  deliveryAddress,
  onDeliveryAddressChange,
  onErrorChange
}: Props) => {
  const [query, setQuery] = useState('');
  const [error, setError] = useState<string | null>(null);

  const { t } = useTranslation();
  const { addressesReq, addressesResults, resetAddressResults, search } =
    useAddressResults();

  const handleDeliveryAddressChange = (address: string | null) => {
    if (!address || address === '') {
      if (!deliveryAddress) {
        setError(
          t(
            'common:deliveryInformationFormFieldDeliveryAddressErrorRequiredField'
          )
        );
        onErrorChange && onErrorChange(true);
      }
    } else {
      onErrorChange && onErrorChange(false);
      setError(null);
      onDeliveryAddressChange(address || '');
      setQuery(address);
      resetAddressResults();
    }
  };

  const handleOnAddressInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    setQuery(event.target.value);
    search(event.target.value);
  };

  const handleOnClose = () => {
    if (deliveryAddress) {
      setQuery(deliveryAddress);
    }
    resetAddressResults();
  };

  return (
    <div>
      <Typography variant="inputLabel">
        {t('common:deliveryInformationFormFieldDeliveryAddressLabel')}
      </Typography>
      <Typography variant="paragraphSmall" color="secondary">
        {t('common:deliveryInformationFormFieldDeliveryAddressDescription')}
      </Typography>
      <Combobox
        value={deliveryAddress}
        onChange={handleDeliveryAddressChange}
        onClose={handleOnClose}
      >
        <DeliveryAddressInput
          query={query}
          onChange={handleOnAddressInputChange}
        />
        <DeliveryAddressResults req={addressesReq} results={addressesResults} />
      </Combobox>
      {/* TODO: Create this into feedback comp */}
      {error && (
        <Typography variant="paragraph" mt="s">
          <Icon color="error" icon="alert" />
          {error}
        </Typography>
      )}
    </div>
  );
};
