import { Body } from './body';
import { Cell } from './cell';
import { Foot } from './foot';
import { FooterCell } from './footer-cell';
import { Head } from './head';
import { HeaderCell } from './header-cell';
import { Row } from './row';
import { Table as TableContainer } from './table';

export const Table = Object.assign(TableContainer, {
  Body,
  Cell,
  Foot,
  FooterCell,
  Head,
  HeaderCell,
  Row
});
