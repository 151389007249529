/* eslint no-param-reassign: ["error", { "props": true, "ignorePropertyModificationsFor": ["state"] }] */
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { filter } from 'lodash';
import getProducerSearchFunction from 'algolia/producerIndex';
import {
  type Producer,
  type ProducerWithNumProductsForSale
} from 'types/Producer';
import { type ProducerProduct, type ProductForSale } from 'types/Product';
import REQ, { ReqType } from 'utils/REQ';

const getProducersFromAlgolia = async ({
  distributionAreaId,
  isMeyersAccount = false
}: {
  distributionAreaId: string;
  isMeyersAccount?: boolean;
}) => {
  const producerIndexSearch = getProducerSearchFunction({
    distributionAreaId,
    isMeyersAccount
  });

  return producerIndexSearch('');
};

export const fetchProducers = createAsyncThunk(
  'consumers/fetchProducers',
  async (options: {
    distributionAreaId: string;
    isMeyersAccount?: boolean;
  }) => {
    return getProducersFromAlgolia(options);
  }
);

type ProducersState = {
  req: ReqType;
  items: Producer[];
};

const initialState: ProducersState = { req: REQ.INIT, items: [] };

const producersSlice = createSlice({
  name: 'producers',
  initialState,
  reducers: {
    clearProducers() {
      return initialState;
    }
  },
  extraReducers(builder) {
    builder
      .addCase(
        fetchProducers.fulfilled,
        (state, action: PayloadAction<any[]>) => {
          state.req = REQ.SUCCESS;
          state.items = action.payload;
        }
      )
      .addCase(fetchProducers.pending, state => {
        state.req = REQ.PENDING;
        state.items = [];
      })
      .addCase(fetchProducers.rejected, state => {
        state.req = REQ.ERROR;
        state.items = [];
      });
  }
});

export default producersSlice.reducer;

export const { clearProducers } = producersSlice.actions;

/** Selectors */

export const countProducerProducts = (
  producers: Producer[],
  products: (ProducerProduct | ProductForSale)[]
): ProducerWithNumProductsForSale[] => {
  return producers.map(producer => {
    return {
      ...producer,
      numProductsForSale: filter(products, ['producer._id', producer._id])
        .length
    };
  });
};
