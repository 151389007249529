import { PropsWithChildren, useState } from 'react';
import { twMerge } from 'tailwind-merge';
import { ThemeColor } from '../../../theme/colors';
import { calloutStyle } from '../styles';
import { tv } from '../../../utils/tv';
import { Icon } from '../../../theme/icon';

const expandableStyle = tv({
  slots: {
    container: `!p-0`,
    trigger: `
      flex
      w-full
      items-center
      justify-between
      p-s
    `,
    content: `
      h-0
      overflow-hidden
    `
  },
  variants: {
    open: {
      true: {
        content: `
          h-auto
          px-s
          pb-s
        `
      }
    }
  }
});

type Props = PropsWithChildren<{
  title: string;
  small?: boolean;
  variant?: Extract<ThemeColor, 'lightGrey' | 'noteworthy'>;
}>;

export const CalloutExpandable = ({
  small,
  title,
  variant,
  children
}: Props) => {
  const [open, setOpen] = useState(false);
  const { base, title: titleStyle } = calloutStyle({ small, variant });
  const { container, trigger, content } = expandableStyle();
  return (
    <div className={twMerge(base(), container())}>
      <button className={trigger()} onClick={() => setOpen(o => !o)}>
        <div className={titleStyle()}>{title}</div>
        <Icon icon={open ? 'collapse' : 'expand'} size="small" />
      </button>
      <div className={content({ open })}>{children}</div>
    </div>
  );
};
