import { sortBy } from 'lodash';
import styled from 'styled-components';
import Theme from '@carrot-deprecated/theme';
import Icon, { IconType } from '_common/components/utils/Icon';
import { Certification } from 'types/Product';

type CertificationsViewProps = {
  variant: 'small' | 'regular' | 'large';
  certifications: Certification[];
  display?: string;
};

const CertificationsViewContainer = styled.div<{
  height?: number;
  display?: string;
}>`
  display: ${({ display = 'inline-flex' }) => {
    return `${display}`;
  }};
  height: ${({ height = Theme.Spacings.m }) => {
    return `${height}px`;
  }};
  vertical-align: middle;
  align-items: center;
`;

const convertFromVariantToSize = (variant: string) => {
  switch (variant) {
    case 'small':
      return 's';
    case 'regular':
      return 'm';
    case 'large':
      return 'l';
    default:
      throw new Error('Not a supported variant');
  }
};

const CertificationsView = ({
  certifications,
  variant,
  display
}: CertificationsViewProps) => {
  const size = convertFromVariantToSize(variant);
  const sortedCertificationsByName = sortBy(certifications, ['displayNameKey']);
  return (
    <CertificationsViewContainer
      height={variant === 'large' ? 32 : undefined}
      display={display}
    >
      {sortedCertificationsByName.map(cert => {
        const name = cert.displayNameKey?.toLowerCase();
        return (
          <Icon
            icon={name as IconType}
            variant={variant}
            $size={size}
            key={cert.displayNameKey}
            mr={variant !== 'large' ? Theme.Spacings.xxs : undefined}
          />
        );
      })}
    </CertificationsViewContainer>
  );
};

export default CertificationsView;
