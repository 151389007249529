import { TOptions } from 'i18next';
import { useTranslation as useI18nTranslation } from 'react-i18next';
import { SetRequired } from 'type-fest';

import { BaseTranslateKey, TranslateKey } from './types';

type NotBaseTranslateKey<T extends string> = T extends BaseTranslateKey
  ? never
  : T;

export const useTranslation = () => {
  const { t, i18n } = useI18nTranslation();

  function typedT<Output = string>(
    key: BaseTranslateKey,
    options: SetRequired<TOptions, 'count'>
  ): Output;
  function typedT<Output = string>(
    key: TranslateKey,
    options?: TOptions
  ): Output;
  function typedT<Key extends string, Output = string>(
    key: NotBaseTranslateKey<Key>,
    options?: TOptions
  ): Output;
  function typedT<Key extends string, Output = string>(
    key: BaseTranslateKey | TranslateKey | NotBaseTranslateKey<Key>,
    options?: TOptions
  ): Output {
    return t(key, options) as Output;
  }

  return { t: typedT, i18n };
};
