import { AvailableLanguages } from '@dagensmat/core';
import { omit } from 'lodash';

import Logger from '../services/Logger';
import { ProductForSale } from '../types/Product';
import { SearchFunctionOptions, setupAlgolia } from './shared';
import { productsSearchIndex } from 'config';

const getTagsKey = (roleLang = AvailableLanguages.ENGLISH) => {
  return `tags_${roleLang}`;
};

const getProductAttributesToRetrieve = ({
  distributionAreaId,
  onlyIds,
  roleLang,
  roleId
}: SearchFunctionOptions) => {
  return onlyIds
    ? ['_id']
    : [
        '_id',
        '_updatedAt',
        '_createdAt',
        'name',
        'type',
        'image',
        'producer',
        'certifications',
        'description',
        'tags_en',
        getTagsKey(roleLang),
        'forSaleStatus',
        'pricing',
        `pricing_${roleId}`,
        'temperatureZone',
        'seasonCalendar',
        `deliveryDates_${distributionAreaId}`,
        `deadlines_${distributionAreaId}`,
        'soldOutDates',
        'processedState',
        'newnessRank',
        'availableIn',
        'prices',
        'justAdded',
        'seasonStart'
      ];
};

const buildFilterQuery = ({
  filters = {},
  distributionAreaId,
  roleId,
  isMeyersAccount
}: SearchFunctionOptions & {
  filters?: Record<string, string[]>;
}) => {
  const algoliaFilters = [`availableTo:${roleId} OR availableTo:CONSUMERS`];

  if (isMeyersAccount) {
    algoliaFilters.push('NOT hideForMeyers:true');
  }

  if (distributionAreaId) {
    algoliaFilters.push(`availableIn:${distributionAreaId}`);
  }

  if (Object.keys(filters).length > 0) {
    algoliaFilters.push(
      ...Object.entries(filters).map(([key, arr]) => {
        return arr
          .map(filterValue => {
            return `${key}:"${filterValue}"`;
          })
          .join(' OR ');
      })
    );
  }
  return algoliaFilters
    .filter(Boolean)
    .map(filter => {
      return `(${filter})`;
    })
    .join(' AND ');
};

const getSearchOptions = (
  options: SearchFunctionOptions & { filters: any }
) => {
  const { roleLang } = options;

  const restrictSearchableAttributes = [
    'name',
    'type',
    'producer.name',
    getTagsKey(roleLang)
  ];

  return {
    filters: buildFilterQuery(omit(options, 'filters.facetFilters')),
    ...(options.filters &&
      options.filters.facetFilters && {
        facetFilters: options.filters.facetFilters
      }),
    attributesToRetrieve: getProductAttributesToRetrieve(options),
    restrictSearchableAttributes
  };
};

const handleConsumerSpecialPricing = (
  product: ProductForSale,
  roleId?: string
) => {
  const pricingKey = `pricing_${roleId}`;
  const consumersSpecialPricing =
    pricingKey in product ? product[pricingKey as keyof ProductForSale] : null;
  if (!consumersSpecialPricing) return { ...product, hasSpecialPricing: false };

  return omit(
    {
      ...product,
      pricing: consumersSpecialPricing,
      hasSpecialPricing: true
    },
    `pricing_${roleId}`
  );
};

export const getCertificationFacets = async (
  distributionAreaId: string | undefined
) => {
  const client = setupAlgolia(productsSearchIndex, distributionAreaId);
  const facetFilters = [`availableIn:${distributionAreaId}`];
  const { facets } = await client.search('', {
    facetFilters,
    facets: ['certifications.displayNameKey']
  });
  if (facets?.['certifications.displayNameKey'])
    return Object.keys(facets['certifications.displayNameKey']);
  return [];
};

export const getProductsSearchFunction = ({
  roleId,
  onlyIds,
  distributionAreaId,
  roleLang,
  isMeyersAccount
}: {
  roleId?: string;
  onlyIds: boolean;
  distributionAreaId?: string;
  roleLang?: AvailableLanguages;
  isMeyersAccount?: boolean;
}) => {
  const client = setupAlgolia(productsSearchIndex, roleId);
  return async (searchString?: string, filters?: any) => {
    let hits: ProductForSale[] = [];
    await client
      .browseObjects({
        query: searchString,
        ...getSearchOptions({
          roleId,
          onlyIds,
          distributionAreaId,
          roleLang,
          isMeyersAccount,
          filters
        }),
        batch: batch => {
          hits = hits.concat(batch as unknown as ProductForSale[]);
        }
      })
      .then(() => {
        if (onlyIds) return;
        const tagsKey = getTagsKey(roleLang);
        // @ts-expect-error: TODO(strict)
        hits = hits.map(hit => {
          const updatedHit = handleConsumerSpecialPricing(hit, roleId);
          // @ts-expect-error: TODO(strict)
          return { tags: updatedHit[tagsKey], ...updatedHit };
        });
      })
      .catch(e => {
        Logger.error(
          new Error(
            `Unable to fetch products from Algolia for consumer with roleId ${roleId}. Error: ${e}`
          )
        );
      });
    return hits;
  };
};
