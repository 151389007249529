import { Button, Icon } from '@dagens/carrot';
import { useTranslation } from '@dagens/frontend-i18n';
import { MouseEventHandler, PropsWithChildren, useEffect } from 'react';
import { To, useNavigate } from 'react-router-dom';
import { tv } from 'tailwind-variants';
import { FadeOut } from '_common/components/input/FormField';
import REQ, { ReqType } from 'utils/REQ';

const style = tv({
  slots: {
    saving: `
      py-[12px]
      text-paragraph
    `,
    success: `
      flex
      items-center
      gap-xxs
      py-[12px]
      text-paragraph
    `,
    error: `
      py-[12px]
      text-paragraph
      text-error
    `
  }
});

type Props = PropsWithChildren<{
  redirectTo?: To | number;
  saveReq: ReqType;
  size?: 'regular' | 'fullWidth';
  feedbackText?: {
    [key in ReqType]?: string;
  };
  disabled?: boolean;
  onClick?: MouseEventHandler;
}>;

export const SaveButton = ({
  redirectTo,
  saveReq,
  size,
  feedbackText,
  disabled,
  onClick,
  children
}: Props) => {
  const { error, saving, success } = style();
  const { t } = useTranslation();

  const navigate = useNavigate();
  useEffect(() => {
    if (saveReq === REQ.SUCCESS && redirectTo) {
      setTimeout(() => {
        navigate(redirectTo as To);
      }, 3000); // lines up with fadeout animation
    }
  }, [saveReq, redirectTo]);

  if (saveReq === REQ.PENDING) {
    return (
      <div className={saving()}>
        {feedbackText?.[saveReq] ?? t('common:SavingChanges')}
      </div>
    );
  }

  if (saveReq === REQ.SUCCESS) {
    return (
      <FadeOut saved>
        <div className={success()}>
          <Icon icon="success" size="medium" />
          <div>{feedbackText?.[saveReq] ?? t('common:ChangesSaved')}</div>
        </div>
      </FadeOut>
    );
  }

  if (saveReq === REQ.ERROR) {
    return (
      <div className={error()}>
        {feedbackText?.[saveReq] ?? t('common:errorOccured')}
      </div>
    );
  }

  // tbd if text should fallback to t('common:SaveChanges') or not
  return (
    <Button
      variant="primary"
      size={size ?? 'fullWidth'}
      disabled={disabled}
      onClick={onClick}
    >
      {children}
    </Button>
  );
};
