import { post } from './HttpService';
import { environment, LOG_LEVEL } from 'config';

enum LogLevels {
  ERROR = 'error',
  WARN = 'warn',
  INFO = 'info',
  DEBUG = 'debug'
}

const LogLevelsAllowances = {
  silent: -1,
  error: 0,
  warn: 1,
  info: 2,
  debug: 3
};

export default class {
  private static log = (level: LogLevels, message: string) => {
    if (this.isAllowedLogLevel(level)) {
      post('/logger', {
        level,
        message
      });
    }
  };

  static isAllowedLogLevel = (level: LogLevels): boolean => {
    const requestedLogLevel = LogLevelsAllowances[level];
    return environment !== 'development' && requestedLogLevel <= LOG_LEVEL;
  };

  static debug = (message: string) => {
    this.log(LogLevels.DEBUG, message);
  };

  static info = (message: string) => {
    this.log(LogLevels.INFO, message);
  };

  static warn = (message: string) => {
    this.log(LogLevels.WARN, message);
  };

  static error = (error: Error) => {
    if (this.isAllowedLogLevel(LogLevels.ERROR)) {
      post('/logger/error', {
        name: error.name,
        message: `${error.name} ${error.message}`,
        stacktrace: error.stack
      });
    }
  };
}
