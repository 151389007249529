import { useEffect, useState, useCallback } from 'react';
import { getAddress } from '../../api';
import { formatStructuredAddress } from '../../utils/address';
import REQ, { ReqType } from 'utils/REQ';
import { AUTO_REQUEST_TIMEOUT_MS } from 'config'; // Import timeout duration

export const useAddressResults = () => {
  const [req, setReq] = useState<ReqType>(REQ.INIT);
  const [addressesResults, setAddressesResults] = useState<string[]>([]);
  let timeout: NodeJS.Timeout;

  const resetAddressResults = () => {
    setReq(REQ.INIT);
    setAddressesResults([]);
  };

  const search = useCallback((query: string): void => {
    if (query === '') {
      setReq(REQ.SUCCESS);
      setAddressesResults([]);
      return;
    }

    setReq(REQ.PENDING);
    clearTimeout(timeout);

    timeout = setTimeout(() => {
      getAddress({ address: query })
        .then(address => {
          setReq(REQ.SUCCESS);
          setAddressesResults([formatStructuredAddress(address)]);
        })
        .catch(() => {
          setReq(REQ.ERROR);
        });
    }, AUTO_REQUEST_TIMEOUT_MS);
  }, []);

  useEffect(() => {
    return () => clearTimeout(timeout);
  }, []);

  return { addressesReq: req, addressesResults, resetAddressResults, search };
};
