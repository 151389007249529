export type ByComparator<T> =
  | (keyof T & string)
  | ((a: T | undefined, z: T | undefined) => boolean);

const equal = <T>(
  by: ByComparator<T> | undefined,
  a: T | undefined,
  b: T | undefined
): boolean => {
  if (by) {
    if (typeof by === 'function') {
      return by(a, b);
    }
    return a?.[by] === b?.[by];
  }
  return a === b;
};

export const ComparisonUtils = {
  equal
};
