import { useTranslation } from '@dagens/frontend-i18n';
import ConfirmButton from '../../../../_common/components/confirm-button/ConfirmButton';

type Props = {
  onDelete: () => void;
};

export const ProductFormDeleteButton = ({ onDelete }: Props) => {
  const { t } = useTranslation();
  return (
    <ConfirmButton
      confirmText={t('producer:productDeleteParagraph')}
      confirmedText={t('producer:productDeleteButtonConfirmedText')}
      buttonText={t('producer:productDeleteButtonText')}
      rejectButtonText={t('common:NoWait')}
      confirmButtonText={t('producer:productConfirmDeleteButtonText')}
      confirmButtonVariant="error"
      onConfirm={onDelete}
    />
  );
};
