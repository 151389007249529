import { getCorrelationIdCookie } from './CookieService';
import { baseUrl } from 'config';
import { getItemFromLocalStorage } from 'utils/clientcache';

type THeaders = Record<string, string>;

const validateStatus = (status: number) => {
  return status >= 200 && status < 300;
};

const getUrl = (url: string, params?: Record<string, any>) => {
  const queryParams = params ? new URLSearchParams(params).toString() : '';
  return `${baseUrl}${url}?${queryParams}`;
};

const getDefaultHeaders = (headers: THeaders) => {
  return new Headers({
    Accept: 'application/json',
    From: getItemFromLocalStorage('fromHeader') as string,
    'X-Correlation-Id': getCorrelationIdCookie(),
    ...headers
  });
};

const handleResponse = async (response: Response) => {
  if ([204, 401, 409].includes(response.status)) return response;
  if (!validateStatus(response.status)) {
    // TODO: return Promise.reject(error); and handle in Error Boundary / Top level
    try {
      const contentType = response.headers.get('content-type');
      if (contentType && contentType.includes('application/json')) {
        const responseBody = await response.json();
        throw new Error(responseBody);
      }
      throw new Error(response.statusText);
    } catch (error) {
      throw new Error(response.statusText);
    }
  }
  const contentType = response.headers.get('content-type');
  if (contentType && contentType.includes('application/json')) {
    const responseBody = await response.json();
    return responseBody;
  }
  return response.text();
};

const request = async (
  url: string,
  method: string,
  data?: any,
  headers?: THeaders,
  params?: Record<string, any>
) => {
  const fetchOptions: RequestInit = {
    method,
    credentials: 'include',
    headers: getDefaultHeaders({
      ...headers,
      'Content-Type': 'application/json'
    }),
    body: JSON.stringify(data)
  };

  const response = await fetch(getUrl(url, params), fetchOptions);
  return handleResponse(response);
};

export const get = async (
  url: string,
  params?: Record<string, any>,
  headers?: THeaders
) => {
  return request(url, 'GET', undefined, headers, params);
};

export const post = async (url: string, data?: any, headers?: THeaders) => {
  return request(url, 'POST', data, headers);
};

export const patch = async (url: string, data?: any, headers?: THeaders) => {
  return request(url, 'PATCH', data, headers);
};

export const del = async (
  url: string,
  data?: Record<string, any>,
  headers?: THeaders
) => {
  return request(url, 'DELETE', data, headers);
};

export const postRaw = async (
  url: string,
  data?: FormData,
  headers?: THeaders
) => {
  const response = await fetch(getUrl(url), {
    method: 'POST',
    credentials: 'include',
    headers: headers ? getDefaultHeaders(headers) : undefined,
    body: data
  });
  return handleResponse(response);
};
