import { Icon, InfoSection, Link } from '@dagens/carrot';
import { useTranslation } from '@dagens/frontend-i18n';
import { Consumer } from '../../../../types/Consumer';

const { Title, Items, Term, Details } = InfoSection;

type Props = {
  consumer: Consumer | undefined;
};

export const ContactInfo = ({ consumer }: Props) => {
  const { t } = useTranslation();
  return (
    <InfoSection>
      <Title text={t('common:ContactInformation')} />
      <Items>
        <div>
          <Term>{t('producer:EditCustomerName')}</Term>
          <Details>{consumer?.name}</Details>
        </div>
        <div>
          <Term>
            {t(
              'producer:AddCustomer.reviewContactInformationFieldContactPersonFullNameLabel'
            )}
          </Term>
          <Details>{consumer?.contactPerson}</Details>
        </div>
        <div>
          <Term>
            {t(
              'producer:AddCustomer.reviewContactInformationFieldContactPersonPhoneLabel'
            )}
          </Term>
          <Details>{consumer?.phone}</Details>
        </div>
        <div>
          <Term>
            {t(
              'producer:AddCustomer.reviewContactInformationFieldNotificationEmailLabel'
            )}
          </Term>
          <Details>{consumer?.emails}</Details>
        </div>
      </Items>
      <div>
        <Link variant="ghost" to={`/customers/${consumer?._id}/contact`}>
          <Icon icon="edit" />
          {t('producer:EditCustomerContactInformation')}
        </Link>
      </div>
    </InfoSection>
  );
};
