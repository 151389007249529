/* eslint-disable tailwindcss/no-custom-classname */
import { tr } from '@dagens/frontend-i18n';
import heic2any from 'heic2any';
import i18n from 'i18next';
import React from 'react';
import styled from 'styled-components';
import Theme from '@carrot-deprecated/theme';
import { MediaQuery } from 'utils/mediaQueries';

const Input = styled.input`
  border: 0;
  clip: rect(0, 0, 0, 0);
  width: 1px;
  height: 1px;
  overflow: hidden;
  padding: 0;
  position: absolute !important;
  white-space: nowrap;
  z-index: -1;
`;

const Button = styled.label<{ fillButton?: boolean }>`
  display: inline-block;
  padding: ${Theme.Spacings.s - 2}px;

  border: ${Theme.BorderWeights.regular}px solid ${Theme.Colours.black};
  border-radius: ${Theme.BorderRadius.regular}px;

  background-color: ${({ fillButton }) => {
    return fillButton ? Theme.Colours.black : Theme.Colours.white;
  }};
  color: ${({ fillButton }) => {
    return fillButton ? Theme.Colours.white : Theme.Colours.black;
  }};

  &.disabled {
    color: ${Theme.Colours.secondary};
    border-color: ${Theme.Colours.lightGrey};
    background-color: ${Theme.Colours.lightGrey};
    cursor: initial;
  }

  line-height: 1;
  white-space: nowrap;

  position: relative;
  cursor: pointer;

  text-align: center;
  width: 100%;

  ${MediaQuery.tabletUp} {
    width: auto;
    text-align: left;
  }
`;

const convertPossibleHeicFile = async (file: File) => {
  if (file.type === 'image/heic') {
    const resultBlob = await heic2any({
      blob: file,
      toType: 'image/jpeg',
      quality: 0.3
    });
    return new File([resultBlob as BlobPart], 'image.jpeg', {
      type: 'image/jpeg'
    });
  }
  return file;
};

type ImageInputProps = {
  buttonText?: string;
  disabled?: boolean;
  fillButton?: boolean;
  onChange: (file: File, reader: string | null) => void;
};

const ImageInput = ({
  onChange,
  buttonText = tr(i18n, 'common:addImage'),
  disabled,
  fillButton
}: ImageInputProps) => {
  const inputRef = React.useRef<HTMLInputElement | null>(null);

  const handleImageChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const inputFile = e.target.files?.[0];
    if (!inputFile) {
      return;
    }

    const reader = new FileReader();
    const file = await convertPossibleHeicFile(inputFile);

    reader.onloadend = () => {
      onChange(file, reader.result as string | null);
      if (inputRef.current) {
        inputRef.current = null;
      }
    };

    reader.readAsDataURL(file);
  };

  return (
    <div>
      <Input
        ref={inputRef}
        id="image-file-upload"
        type="file"
        onChange={handleImageChange}
        disabled={disabled}
        accept="image/*"
      />
      <Button
        htmlFor="image-file-upload"
        fillButton={fillButton}
        className={disabled ? 'disabled' : undefined}
      >
        {buttonText}
      </Button>
    </div>
  );
};

export default ImageInput;
