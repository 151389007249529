import { useTranslation } from '@dagens/frontend-i18n';
import ImageFormField from '_common/components/image-uploader/ImageFormField';
import { postAsset, postProducerUpdate } from 'api';
import { SanityImage } from 'types/Sanity';

type ProfileImageInputProps = {
  profileImage: SanityImage;
  producerId: string;
  imageUpdated: (fields?: any) => void;
};

const ProfileImageInput = ({
  producerId,
  profileImage,
  imageUpdated
}: ProfileImageInputProps) => {
  const saveImage = async (file: File) => {
    if (file === null) {
      imageUpdated();
      return;
    }
    const formData = new FormData();
    formData.append('image', file, file.name);
    const { image } = await postAsset(formData);
    await postProducerUpdate({ userId: producerId, image });
    imageUpdated({ image });
    formData.delete('image');
  };
  const { t } = useTranslation();
  return (
    <ImageFormField
      image={profileImage}
      onSave={saveImage}
      label={t('producer:profileImage')}
      helpText={t('producer:profileImageHelpText')}
    />
  );
};

export default ProfileImageInput;
