import React from 'react';
import { createPortal } from 'react-dom';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';

import { useAppDispatch, useAppSelector } from '../../hooks/reduxHooks';
import ProductModal from './ProductModal';
import Theme from '@carrot-deprecated/theme';
import { MediaQuery } from 'utils/mediaQueries';
import { hideModal } from '_common/reducers/modal';

const toggleBodyStyles = (isOpen = false) => {
  const bodyStyle = document.body.style;
  const hasScrollbar = window.innerWidth > document.documentElement.clientWidth;

  if (isOpen) {
    bodyStyle.paddingRight = hasScrollbar ? '15px' : '';
    bodyStyle.overflow = 'hidden';
  } else {
    document.body.removeAttribute('style');
  }
};

const ModalBackdrop = styled.div`
  background: rgba(0, 0, 0, 0.6);
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  height: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
  z-index: 1010;
  ${MediaQuery.tabletUp} {
    padding-top: ${Theme.Spacings.l}px;
    padding-bottom: ${Theme.Spacings.l}px;
  }
`;

const ModalContent = styled.div`
  background-color: ${Theme.Colours.white};
  margin: 0;
  max-width: 720px;
  width: 100%;
  margin-left: 50%;
  transform: translate(-50%, 0);
  border-radius: ${Theme.BorderRadius.regular}px;

  ${MediaQuery.tabletUp} {
    min-height: 100vh;
  }
`;

const ModalContainer = () => {
  const { modalProps, modalType } = useAppSelector(
    ({ modal: { modalType, modalProps } }) => {
      return {
        modalProps,
        modalType
      };
    }
  );
  const dispatch = useAppDispatch();

  const root = document.getElementById('modal-root');
  const modalBackdropEl = React.useRef<HTMLDivElement | null>(null);
  const closeModal = React.useCallback(() => {
    dispatch(hideModal());
    toggleBodyStyles(false);
  }, [dispatch]);

  const escFunction = React.useCallback(
    (event: KeyboardEvent) => {
      if (event.keyCode === 27) {
        closeModal();
      }
    },
    [closeModal, modalProps, modalType]
  );

  const location = useLocation();
  React.useEffect(() => {
    closeModal();
  }, [location]);

  React.useEffect(() => {
    document.addEventListener('keydown', escFunction, false);

    return () => {
      return document.removeEventListener('keydown', escFunction, false);
    };
  }, [escFunction]);

  if (!modalType || !root) {
    return null;
  }

  toggleBodyStyles(true);

  return createPortal(
    <ModalBackdrop
      ref={modalBackdropEl}
      onClick={e => {
        if (e.target === modalBackdropEl.current) {
          closeModal();
        }
      }}
    >
      <ModalContent>
        <ProductModal {...modalProps} closeModal={closeModal} />
      </ModalContent>
    </ModalBackdrop>,
    root
  );
};

export default ModalContainer;
