import { PropsWithChildren } from 'react';
import { tv } from '../../../utils/tv';

const style = tv({
  base: `text-inputLabel`,
  variants: {
    small: {
      true: `text-paragraphSmall`
    }
  }
});

type Props = PropsWithChildren<{
  small?: boolean;
}>;

export const FormFieldLabel = ({ small, children }: Props) => {
  return <div className={style({ small })}>{children}</div>;
};
