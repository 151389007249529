import { useMediaQuery } from '@dagens/carrot';
import { useTranslation } from '@dagens/frontend-i18n';
import Container from '../../../../carrot-deprecated/container';
import Typography from '../../../../carrot-deprecated/typography';

type PricingPlanProps = {
  title: string;
  cut: number;
  description: React.ReactNode;
  helpDescription: string;
};

export const PricingPlan = ({
  title,
  cut,
  description,
  helpDescription
}: PricingPlanProps) => {
  const { t } = useTranslation();
  const { mobileOnly } = useMediaQuery();

  return (
    <Container
      borderColor="lightGrey"
      px="s"
      py="s"
      width={mobileOnly ? 'full' : 'half'}
    >
      <Typography variant="paragraphSmallBold" as="p" mb="xs">
        {title}
      </Typography>
      <Container mb="xs">
        <Typography variant="paragraphBold" mr="xxs">
          {`${cut ?? 0}%`}
        </Typography>
        <Typography variant="paragraphSmall" as="span" color="secondary">
          {t('common:OfOrderTotal')}
        </Typography>
      </Container>
      <Typography variant="paragraphSmall">{description}</Typography>
      <Typography variant="paragraphSmall" color="secondary">
        {helpDescription}
      </Typography>
    </Container>
  );
};
