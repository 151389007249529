import { useTranslation } from '@dagens/frontend-i18n';
import { FC, useState } from 'react';
import Container from '@carrot-deprecated/container';
import FormField from '_common/components/input/FormField';
import Button from '_common/components/button/Button.style';
import REQ, { ReqType } from 'utils/REQ';

type Props = {
  onAdd: (email: string) => Promise<void>;
  onAddInputChange: () => void;
};

export const AddEmail: FC<Props> = ({ onAdd, onAddInputChange }) => {
  const MIN_EMAIL_LENGTH = 7;
  const [requestStatus, setRequestStatus] = useState<ReqType>(REQ.INIT);
  const [value, setValue] = useState<string>('');
  const { t } = useTranslation();

  const onSubmit = async (email: string) => {
    try {
      setRequestStatus(REQ.PENDING);
      await onAdd(email);
      setValue('');
      setRequestStatus(REQ.SUCCESS);
    } catch (e) {
      setRequestStatus(REQ.ERROR);
    }
  };

  return (
    <Container mt="l" mb="xs">
      <FormField
        name="invite-user-input"
        type="text"
        labelText={t('common:AddNotificationEmail')}
        placeholder={t('common:AddNotificationEmailPlaceholderText')}
        value={value}
        onChange={e => {
          if (requestStatus === REQ.PENDING) return;
          setRequestStatus(REQ.INIT);
          setValue(e.target.value);
          onAddInputChange();
        }}
        margin={{ mb: 'xs' }}
      />
      {value.length > 0 && (
        <Button
          variant="primary"
          onClick={() => {
            return onSubmit(value);
          }}
          disabled={
            value.length < MIN_EMAIL_LENGTH || requestStatus !== REQ.INIT
          }
        >
          {t('common:AddNotificationEmailSubmitText')}
        </Button>
      )}
    </Container>
  );
};
