import { useTranslation } from '@dagens/frontend-i18n';
import { ComboboxOption } from '@headlessui/react';
import { Fragment } from 'react/jsx-runtime';
import { tv } from 'tailwind-variants';
import { CommandPaletteResult } from '@components/command-palette/result';
import { ExtendedRouteObject } from 'types/routes';

const style = tv({
  slots: {
    title: `
      mb-xxs
      px-xs
      text-microText
    `,
    list: `
      flex
      flex-col
      justify-start
    `
  }
});

type Props = {
  pageResults: { route: ExtendedRouteObject; matchCount?: number }[];
};

export const CommandPaletteResultsPages = ({ pageResults }: Props) => {
  const { title, list } = style();
  const { t } = useTranslation();
  if (pageResults.length === 0) {
    return null;
  }

  return (
    <li key="pages">
      <h2 className={title()}>{t('common:Pages')}</h2>
      <ul className={list()}>
        {pageResults.map(item => {
          const to = item.route.index ? '/' : item.route.path;
          return (
            <ComboboxOption as={Fragment} key={item.route.title} value={to}>
              {({ focus }) => (
                <CommandPaletteResult
                  icon={item.route.icon}
                  text={item.route.title}
                  focused={focus}
                  to={to}
                />
              )}
            </ComboboxOption>
          );
        })}
      </ul>
    </li>
  );
};
