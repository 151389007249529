import { Simplify } from 'type-fest';
import { ForwardedRef, forwardRef } from 'react';
import { tv } from '../../../utils/tv';

import { NavLinkComponentInternal, NavLinkComponentProps } from '../components';
import { ButtonStyleProps } from '../../button/styles';

const style = tv({
  base: `
    size-auto
    p-xs
    !text-paragraph
    text-secondary
  `,
  variants: {
    mobile: {
      true: `
        size-full
        rounded-none
        !text-microText
      `
    },
    isActive: {
      true: 'text-black'
    }
  }
});

const contentStyle = tv({
  base: 'contents',
  variants: {
    vertical: {
      true: `
        flex
        size-full
        flex-col
        items-center
      `
    }
  }
});

const badgeStyle = tv({
  base: `
    absolute
    -top-xxs
    left-full
    -ml-xs
    -mt-xxs
  `,
  variants: {
    mobile: {
      true: `
        left-1/2
        ml-0
        mt-xxs
      `
    }
  }
});

const underlineStyle = tv({
  base: `
    absolute
    inset-x-0
    bottom-[-2px]
    top-0

    group-[.active]:border-b
    group-[.active]:border-currentColor
  `
});

type Props = Simplify<
  Omit<NavLinkComponentProps, ButtonStyleProps | 'className'> & {
    mobile?: boolean;
    badge?: React.ReactNode;
  }
>;

const Component = (
  { mobile, badge, children, ...props }: Props,
  ref: ForwardedRef<HTMLAnchorElement>
) => {
  return (
    <NavLinkComponentInternal
      {...props}
      ref={ref}
      variant="borderless"
      size="regular"
      className={({ isActive }) => style({ isActive, mobile })}
    >
      <div className={contentStyle({ vertical: mobile })}>{children}</div>
      <div className={badgeStyle({ mobile })}>{badge}</div>
      {!mobile && <div className={underlineStyle()} />}
    </NavLinkComponentInternal>
  );
};

export const NavbarLink = forwardRef(Component);
