import { useTranslation } from '@dagens/frontend-i18n';
import { UnitUtils } from '@dagens/utils';
import { Form, formFieldFactory, useWatch } from '@dagens/frontend-forms';
import { PricingFormValues } from '..';
import { useAppSelector } from '../../../../_common/hooks/reduxHooks';
import { ProductFormValues } from '../../product-form';

// Resolves if a product is a food product or not, based on its category
const useIsFoodProduct = (categoryId: string | undefined) => {
  const categories = useAppSelector(
    ({ producerCategories: { categories } }) => categories
  );

  const productCategories = categories?.filter(category => {
    return category._id === categoryId;
  });

  return !productCategories?.every(category => {
    return category.isNonFood;
  });
};

type Props = {
  defaultValue?: PricingFormValues;
  categoryId: string | undefined;
};

const { Field, NumberInput, Select } = formFieldFactory<
  PricingFormValues & Partial<ProductFormValues>
>();

// Measure unit for the pricing form
// If the price unit is a measure unit, we assume the measure unit is the same
// So we hide this
export const PricingFormMeasureUnit = ({ defaultValue, categoryId }: Props) => {
  const { t } = useTranslation();
  const { priceUnit } = useWatch<
    PricingFormValues & Partial<ProductFormValues>
  >({ defaultValue });
  const isProductFoodProduct = useIsFoodProduct(categoryId);

  // The measure field definitions need to be overridden from the default pricing form field definitions
  // While in the pricing form these fields are validated based on the (external) product category,
  // in the product form they are validated based on the form data itself
  const measureValueRequired = (
    _: unknown,
    data: PricingFormValues & Partial<ProductFormValues>
  ) => {
    const isFormFoodProduct = !data.category?.some(c => c.isNonFood);
    const isFoodProduct = categoryId ? isProductFoodProduct : isFormFoodProduct;
    const required = isFoodProduct && !UnitUtils.isMeasureUnit(data.priceUnit);
    return required ? t('producer:productInputMeasureUnitCalloutText') : false;
  };

  const measureUnitRequired = (
    _: unknown,
    data: PricingFormValues & Partial<ProductFormValues>
  ) => {
    const isFormFoodProduct = !data.category?.some(c => c.isNonFood);
    const isFoodProduct = categoryId ? isProductFoodProduct : isFormFoodProduct;
    const required = isFoodProduct && !UnitUtils.isMeasureUnit(data.priceUnit);
    return required;
  };

  if (UnitUtils.isMeasureUnit(priceUnit)) {
    return null;
  }

  const measureUnits = [
    ...Object.values(UnitUtils.measure),
    ...Object.values(UnitUtils.scaledMeasure)
  ];

  return (
    <Field
      to={['measureValue', 'measureUnit']}
      label={t('producer:WeightPerPricedUnit', {
        pricedUnit: priceUnit
      })}
      helpText={t('producer:productInputMeasureUnitHelperText')}
      required={[measureValueRequired, measureUnitRequired]}
    >
      <Form.Group numberWithSelect>
        <NumberInput<'measureValue'>
          name="measureValue"
          type="float"
          required={measureValueRequired}
        />
        <Select<'measureUnit', (typeof measureUnits)[number]>
          name="measureUnit"
          options={measureUnits}
          displayValue={value =>
            t(`units:${value}_long`, {
              count: 1
            })
          }
          required={measureUnitRequired}
        />
      </Form.Group>
    </Field>
  );
};
