import { useTranslation } from '@dagens/frontend-i18n';
import { AvailableLanguages } from '@dagensmat/core';
import React from 'react';
import SelectWithLabel from '_common/components/select/SelectWithLabel';
import SectionHOC from '_common/components/section-hoc/SectionHOC';

type ProfileLanguageSectionProps = {
  roleLang?: AvailableLanguages;
  onFieldsChange: (fields: { roleLang: AvailableLanguages }) => Promise<any>;
};

const ProfileLanguageSection = ({
  roleLang,
  onFieldsChange
}: ProfileLanguageSectionProps) => {
  const { t, i18n } = useTranslation();
  const [saved, setSaved] = React.useState(false);
  return (
    <SelectWithLabel
      labelText={t('common:ChangeLanguage')}
      selectedValue={roleLang}
      options={Object.keys(AvailableLanguages).map(lng => {
        return {
          value: AvailableLanguages[lng as keyof typeof AvailableLanguages],
          name: t(`common:${lng}`)
        };
      })}
      onChange={async option => {
        await onFieldsChange({ roleLang: option as AvailableLanguages });
        await i18n.changeLanguage(option);
        setSaved(true);
      }}
      savedText={t('common:Saved')}
      saved={saved}
    />
  );
};
export default SectionHOC(ProfileLanguageSection);
