import { DateRange, DayPickerProps } from 'react-day-picker';
import { useEffect, useRef, useState } from 'react';
import { Locale } from '@dagens/utils';
import { Icon } from '../../theme/icon';
import { classNames } from './style';

const WIDTH = 280 + 16;

type Props = {
  locale: Locale;
  otherRanges?: DateRange[];
};

export const useSharedProps = ({ locale, otherRanges }: Props) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [numberOfMonths, setNumberOfMonths] = useState(1);

  useEffect(() => {
    const element = containerRef.current;
    if (!element) {
      return;
    }

    const resizeObserver = new ResizeObserver(() => {
      const width = element.getBoundingClientRect().width;
      const newNumberOfMonths = Math.floor(width / WIDTH);
      setNumberOfMonths(newNumberOfMonths ? newNumberOfMonths : 1);
    });
    resizeObserver.observe(containerRef.current);
    return () => resizeObserver.disconnect();
  }, []);

  return {
    containerRef,
    props: {
      classNames: classNames(),
      weekStartsOn: 1,
      locale: locale,
      numberOfMonths,
      components: {
        IconLeft: () => <Icon icon="chevronLeft" color="deepPurple" />,
        IconRight: () => <Icon icon="chevronRight" color="deepPurple" />
      },
      formatters: {
        formatWeekdayName: date =>
          date
            .toLocaleDateString(locale.code, { weekday: 'short' })
            .slice(0, 1),
        formatCaption: date => {
          const month = date.toLocaleDateString(locale.code, { month: 'long' });
          const capitalizedMonth =
            month.charAt(0).toLocaleUpperCase() + month.slice(1);
          const year = date.getFullYear();
          return `${capitalizedMonth} ${year}`;
        }
      },
      modifiers: otherRanges && {
        otherRanges
      },
      modifiersClassNames: otherRanges && {
        otherRanges: classNames().other_ranges
      }
    } satisfies DayPickerProps
  };
};
