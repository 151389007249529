import {
  NumberInput as CarrotNumberInput,
  type NumberInputProps as CarrotNumberProps
} from '@dagens/carrot';
import { FieldValues, get, useFormContext } from 'react-hook-form';
import { useTranslation } from '@dagens/frontend-i18n';
import { useState } from 'react';
import { MatchingKeys } from '../types/fields';
import { FieldProps, useField } from '../utils/use-field';
import { validationResolver } from '../utils/validation';

// Fields from the Carrot component that are handled by react-hook-form
type ExcludedFields = 'error' | 'value' | 'onChange';

export type NumberInputProps<
  Form extends FieldValues,
  Name extends MatchingKeys<Form, number | null>
> = Omit<CarrotNumberProps, ExcludedFields> & FieldProps<Form, Name>;

export const NumberInput = <
  Form extends FieldValues,
  Name extends MatchingKeys<Form, number | null>
>({
  name,
  required,
  validate,
  shouldUnregister,
  disabled,
  ...props
}: NumberInputProps<Form, Name>) => {
  const { t } = useTranslation();
  const { getValues } = useFormContext<Form>();
  const [internalValue, setInternalValue] = useState<number | string | null>(
    get(getValues(), name)
  );
  const {
    field,
    fieldState: { error }
  } = useField({
    name,
    required,
    validate: v => {
      return validationResolver({
        value: v,
        required,
        validate,
        formData: getValues,
        defaultRequiredError: t('common:requiredField'),
        defaultValidationError: t('common:invalidValue')
      });
    },
    shouldUnregister,
    disabled
  });

  const onChange = (v: string | number | null) => {
    if (v === internalValue) {
      return;
    }

    setInternalValue(v);
    if (typeof v !== 'string') {
      field.onChange(v);
    } else {
      field.onChange(null);
    }
  };

  return (
    <CarrotNumberInput
      error={Boolean(error)}
      onChange={onChange}
      value={internalValue}
      {...props}
    />
  );
};
