import { UnitUtils } from '@dagens/utils';

// Basic default values for the pricing form
export const useDefaultValue = () => {
  const defaultValue = {
    customers: [],
    priceValue: null,
    priceUnit: UnitUtils.measure.kg,
    measureValue: null,
    measureUnit: UnitUtils.measure.kg,
    orderEqualsPrice: false,
    orderValue: null,
    orderUnit: UnitUtils.packed.crate,
    additionalInfo: null
  };
  return defaultValue;
};
