import { useEffect, useState } from 'react';

import { DeviceBreakpoint } from '../../utils/mediaQueries';

type IWindowSize = {
  width: number;
  height: number;
};

const getWindowSize = (): IWindowSize => {
  return {
    width: window?.innerWidth,
    height: window?.innerHeight
  };
};

const isIPhone = (): boolean => {
  return /iPhone/.test(navigator.userAgent);
};

export function useMediaQuery() {
  const [windowSize, setWindowSize] = useState(getWindowSize());

  useEffect(() => {
    if (!window) {
      return;
    }

    function handleResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener('resize', handleResize);

    return () => {
      return window.removeEventListener('resize', handleResize);
    };
  }, []);

  return {
    width: windowSize.width,
    height: windowSize.height,
    mobileOnly: windowSize.width < DeviceBreakpoint.tablet,
    tabletUp: windowSize.width >= DeviceBreakpoint.tablet,
    desktopUp: windowSize.width >= DeviceBreakpoint.desktop,
    isIphone: isIPhone()
  };
}
