import { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ProductFormValues } from '../../components/product-form';
import { RootState } from '../../../store';
import { formValuesToPricing } from '../../components/pricing-form/utils/to-pricing';
import { PricingFormValues } from '../../components/pricing-form';
import { formValuesToProduct } from '../../components/product-form/utils/to-product';
import { uploadImage } from '../../components/product-form/utils/upload-image';
import { useAppDispatch, useAppSelector } from '_common/hooks/reduxHooks';
import { fetchProducerProducts } from '_producer/reducers/products';
import { postProduct } from 'api';
import REQ, { ReqType } from 'utils/REQ';

// Save Add product form
// Sends new product to the API
// Refetches products and updates the store
export const useOnSave = () => {
  const dispatch = useAppDispatch();
  const [saveReq, setSaveReq] = useState<ReqType>(REQ.INIT);
  const producerId = useAppSelector(({ auth: { _id } }: RootState) => _id);
  const navigate = useNavigate();

  const onSave = useCallback(
    async (data: ProductFormValues & PricingFormValues) => {
      if (saveReq !== REQ.INIT) {
        return;
      }

      try {
        setSaveReq(REQ.PENDING);
        const image = await uploadImage(data.image.file);

        const prices = [formValuesToPricing(data)];
        const product = formValuesToProduct({
          data,
          producerId,
          image,
          prices
        });

        await postProduct(product);
        dispatch(fetchProducerProducts(producerId));
        setSaveReq(REQ.SUCCESS);
        setTimeout(() => {
          navigate('/my-products');
        }, 2000);
      } catch (error) {
        setSaveReq(REQ.ERROR);
      }
    },
    [producerId]
  );

  return { onSave, saveReq };
};
