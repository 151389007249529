import { useTranslation } from '@dagens/frontend-i18n';
import queryString from 'query-string';
import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { LoginCallout, LoginCalloutProps } from './LoginCallout';
import Theme from '@carrot-deprecated/theme';
import Typography from '@carrot-deprecated/typography';
import Button, { ButtonLink } from '_common/components/button/Button.style';
import FormField from '_common/components/input/FormField';
import FaqsLinkText from '_common/components/text-link/FaqsLinkText';
import { useAppDispatch, useAppSelector } from '_common/hooks/reduxHooks';
import usePageView from '_common/hooks/usePageView';
import { ChangeRoleDTO, loggedIn } from '_common/reducers/auth';
import { clearBasket } from '_consumer/reducers/basket';
import { Page } from '@components/page';
import { postLogin } from 'api';
import { MediaQuery } from 'utils/mediaQueries';
import { LOGIN_PAGE_VIEW, SUCCESSFUL_LOGIN, track } from 'utils/mixpanel';

const LoginAndPasswordButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: ${Theme.Spacings.xl}px;

  a,
  button {
    width: 100%;
    text-align: center;
  }

  ${MediaQuery.tabletUp} {
    flex-direction: row;
    justify-content: space-between;
    a,
    button {
      width: fit-content;
    }
  }
`;

const SignupButton = styled(ButtonLink)`
  width: 100%;
  text-align: center;

  ${MediaQuery.tabletUp} {
    width: fit-content;
  }
`;

const LoginPage = () => {
  const { t } = useTranslation();
  const [email, setEmail] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [error, setError] = React.useState(false);
  const { search } = useLocation();

  const { redirectLogin = '', calloutType } = queryString.parse(search) as {
    redirectLogin?: string;
    calloutType: LoginCalloutProps['type'];
  };

  usePageView(LOGIN_PAGE_VIEW);
  const dispatch = useAppDispatch();

  const isAuthenticated = useAppSelector(({ auth: { authenticated } }) => {
    return authenticated;
  });

  const onSubmitHandler = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!email || !password) return;

    postLogin({ email, password })
      .then(
        (
          response: Response & { email?: string; roles?: any[]; uid?: string }
        ) => {
          if (response.ok || response.uid) {
            track(SUCCESSFUL_LOGIN);
            dispatch(loggedIn(response as ChangeRoleDTO));
            dispatch(clearBasket());
          } else {
            setError(true);
          }
        }
      )
      .catch(() => {
        setError(true);
      });
  };

  if (isAuthenticated) {
    return <Navigate to={`/${redirectLogin}`} />;
  }

  return (
    <Page>
      <LoginCallout type={calloutType} />
      <form onSubmit={onSubmitHandler}>
        <FormField
          name="email"
          labelText={t('common:Username')}
          type="email"
          placeholder={t('common:WriteYourEmail')}
          autoComplete="email"
          tabIndex={0}
          value={email}
          onChange={({ target: { value } }) => {
            setEmail(value);
          }}
          margin={{
            mb: 's'
          }}
        />
        <FormField
          labelText={t('signup:passwordLabelText')}
          name="password"
          type="password"
          autoComplete="current-password"
          placeholder={t('common:WriteYourPassword')}
          tabIndex={0}
          value={password}
          onChange={({ target: { value } }) => {
            setPassword(value);
          }}
          margin={{
            mt: 's',
            mb: 's'
          }}
        />
        {error && (
          <Typography variant="paragraphSmall" color="error">
            {t('common:ProblemWithLogin')}
          </Typography>
        )}
        <LoginAndPasswordButtonContainer>
          <Button tabIndex={0} type="submit" variant="primary">
            {t('common:SignIn')}
          </Button>
          <ButtonLink tabIndex={-1} to="/reset-password" variant="borderless">
            {t('common:ForgotPassword')}
          </ButtonLink>
        </LoginAndPasswordButtonContainer>
      </form>
      <Typography variant="paragraphBold" as="p" mt="m" mb="xs">
        {t('common:NewToDagens')}
      </Typography>
      <SignupButton tabIndex={-1} to="/letsgo" variant="secondary">
        {t('common:CreateUser')}
      </SignupButton>
      <FaqsLinkText />
    </Page>
  );
};

export default LoginPage;
