import { formFieldFactory } from '@dagens/frontend-forms';
import { useTranslation } from '@dagens/frontend-i18n';
import { ProductFormValues } from '..';

const { Field, Radio } = formFieldFactory<ProductFormValues>();

export const ProductFormProcessedState = () => {
  const { t } = useTranslation();
  return (
    <Field
      to="processedState"
      label={t('producer:ProcessedStateLabel')}
      required
    >
      <Radio
        name="processedState"
        options={['RAW', 'PROCESSED']}
        displayValue={value =>
          value &&
          {
            RAW: {
              label: t('producer:Raw'),
              description: t('producer:ExplanationTextRawProcessedState')
            },
            PROCESSED: {
              label: t('producer:Processed'),
              description: t('producer:ExplanationTextProcessedProcessState')
            }
          }[value]
        }
      />
    </Field>
  );
};
