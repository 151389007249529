import plugin from 'tailwindcss/plugin';

export const focusStylePlugin = plugin(({ addBase }) => {
  addBase({
    '.focus-style': {
      outlineOffset: '2px',
      outlineColor: 'black',
      outlineStyle: 'solid',
      outlineWidth: '2px'
    }
  });
});
