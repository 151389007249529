import { uuid } from '@sanity/uuid';
import { DateRange } from 'react-day-picker';
import { ProductFormValues } from '..';
import { Pricing } from '../../../../types/Product';
import { SanityImage } from '../../../../types/Sanity';
import { slugifyDate } from '../../../../utils/date/format';

const periodToDays = (period: DateRange) => {
  if (!period.from || !period.to) {
    return [];
  }

  const days = [];
  for (
    let day = period.from;
    day <= period.to;
    day.setDate(day.getDate() + 1)
  ) {
    days.push({
      _key: uuid(),
      _type: 'capacity' as const,
      deliveryDate: slugifyDate(day),
      units: 0
    });
  }
  return days;
};

type Args = {
  data: ProductFormValues;
  producerId?: string;
  image?: SanityImage | null;
  prices: Pricing[];
  productId?: string;
};

// Converts form values to product object for the API
export const formValuesToProduct = ({
  data,
  image,
  prices,
  productId,
  producerId
}: Args) => {
  // The create and edit endpoints in the API have a couple of different fields
  // Due to our request body validation, the keys can't even be in the request body
  // even if the values are undefined, so we need to conditionally add them
  const apiProductId = productId ? { productId } : {};
  const apiProducer = producerId ? { producerId } : {};
  const apiCapacities = data.capacities
    ? {
        capacities: Array.isArray(data.capacities)
          ? data.capacities.flatMap(periodToDays)
          : undefined
      }
    : {};

  return {
    ...apiProductId,
    ...apiProducer,
    ...apiCapacities,

    name: data.name ?? '',
    type: data.type ?? '',
    description: data.description ?? '',
    categoryId: data.category.at(0)?._id,
    certificationIds: data.certifications.map(
      certification => certification._id
    ),
    forSaleStatus: data.salesStatus ?? 'NOT_FOR_SALE',
    prices,
    seasonCalendar: Array.isArray(data.seasonCalendar)
      ? data.seasonCalendar.map(({ from, to }) => ({
          _key: uuid(),
          from: slugifyDate(from),
          to: slugifyDate(to)
        }))
      : [{ _key: uuid(), from: slugifyDate(new Date()) }],
    image: image ?? undefined,
    temperatureZone: data.temperatureZone ?? undefined,
    processedState: data.processedState ?? undefined
  };
};
