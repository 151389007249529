import { formFieldFactory } from '@dagens/frontend-forms';
import { useTranslation } from '@dagens/frontend-i18n';
import { PricingFormValues } from '..';

const { Field, Input } = formFieldFactory<PricingFormValues>();

export const PricingFormAdditionalInfo = () => {
  const { t } = useTranslation();

  return (
    <Field to="additionalInfo" label={t('producer:productExtraInfo')}>
      <Input
        name="additionalInfo"
        placeholder={t('producer:unitSizeDescriptionPlaceholder')}
      />
    </Field>
  );
};
