import { Icon, Link } from '@dagens/carrot';
import { useContext } from 'react';
import {
  RouteContext,
  RouteHistory
} from '../../../../router/components/context';

type Props = {
  returnTo: RouteHistory;
};

export const BackButton = ({ returnTo }: Props) => {
  const { popHistory } = useContext(RouteContext);
  return (
    <Link.NavIcon to={returnTo} state={returnTo.state} onClick={popHistory}>
      <Icon icon="back" />
    </Link.NavIcon>
  );
};
