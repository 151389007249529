import { ComboboxOption } from '@headlessui/react';
import { tv } from 'tailwind-variants';
import Typography from '@carrot-deprecated/typography';

const style = tv({
  base: `
    p-xs

    data-[focus]:bg-brightPurple
  `
});

type Props = {
  value: string;
  disabled?: boolean;
};

/**
 * @package
 */
export const DeliveryAddressResultElement = ({
  value,
  disabled = false
}: Props) => {
  return (
    <ComboboxOption
      key={value}
      value={value}
      disabled={disabled}
      className={style()}
    >
      <Typography>{value}</Typography>
    </ComboboxOption>
  );
};
