import React, { ReactNode } from 'react';
import { tv } from '../../utils/tv';
import { useMediaQuery } from '../../utils/media-query';

const style = tv({
  slots: {
    container: `mb-m`,
    title: `
      mb-xs
      text-primaryHeading
    `,
    subtitle: `
      text-paragraphSmall
      text-secondary
    `
  },
  variants: {
    tabletUp: {
      true: {
        container: `mt-m`
      }
    }
  }
});

type Props = {
  title?: ReactNode;
  subtitle?: ReactNode;
};

export const PageHeader = ({ title, subtitle }: Props) => {
  const { tabletUp } = useMediaQuery();
  const {
    container,
    title: titleStyle,
    subtitle: subtitleStyle
  } = style({ tabletUp });
  return (
    <div className={container()}>
      <div className={titleStyle()}>{title}</div>
      {React.isValidElement(subtitle) ? (
        subtitle
      ) : (
        <div className={subtitleStyle()}>{subtitle}</div>
      )}
    </div>
  );
};
