import { useTranslation } from '@dagens/frontend-i18n';
import Typography from '@carrot-deprecated/typography';
import DeliveryDateOptions from '_consumer/pages/landing-page/parts/DeliveryDateOptions';

type ProductDeliveryDateSelectionProps = {
  product: any;
  availableDeliveryDates?: string[];
  setDeliveryDate: (deliveryDate: string) => void;
};

const getUnavailableDeliveryDates = (
  product: any,
  availableDeliveryDates?: string[]
) => {
  const { deliveryDates = [], soldOutDates = [] } = product;
  return Array.from(
    new Set<string>(
      availableDeliveryDates?.concat(deliveryDates).filter(deliveryDate => {
        return (
          !deliveryDates.includes(deliveryDate) ||
          soldOutDates.includes(deliveryDate)
        );
      })
    )
  );
};

const ProductDeliveryDateSelection = ({
  product,
  availableDeliveryDates,
  setDeliveryDate
}: ProductDeliveryDateSelectionProps) => {
  const { t } = useTranslation();

  return (
    <>
      <Typography variant="inputLabel" mt="m" mb="xxs">
        {t('consumer:WhenAreYouOrderingFor')}
      </Typography>
      <DeliveryDateOptions
        availableDeliveryDates={availableDeliveryDates}
        unavailableDeliveryDates={getUnavailableDeliveryDates(
          product,
          availableDeliveryDates
        )}
        setDeliveryDate={setDeliveryDate}
      />
    </>
  );
};

export default ProductDeliveryDateSelection;
