import { useTranslation } from '@dagens/frontend-i18n';
import { ComboboxOptions } from '@headlessui/react';
import { tv } from 'tailwind-variants';
import { CommandPaletteResultsOrders } from '@components/command-palette/results-orders';
import { CommandPaletteResultsPages } from '@components/command-palette/results-pages';
import { CommandPaletteResultsProducts } from '@components/command-palette/results-products';
import { useOrderResults } from '@components/command-palette/use-order-results';
import { usePageResults } from '@components/command-palette/use-page-results';
import { useProductResults } from '@components/command-palette/use-product-results';

const style = tv({
  slots: {
    options: `
      mt-s
      max-h-[300px]
      scroll-pb-xxs
      scroll-pt-s
      space-y-xxs
      overflow-y-auto
      pb-xxs
    `,
    notFound: `
      mt-xs
      p-xs
      text-paragraphSmallBold
      text-secondary
    `
  }
});

type Props = {
  query: string;
};

export const CommandPaletteResults = ({ query }: Props) => {
  const { notFound, options } = style();
  const { t } = useTranslation();
  const { pageResults } = usePageResults(query);
  const { ordersReq, orderResults } = useOrderResults(query);
  const { productsReq, productResults } = useProductResults(query);
  const resultCount =
    pageResults.length + orderResults.length + productResults.length;

  if (resultCount === 0 && query !== '') {
    return <div className={notFound()}>{t('common:pageNotFound')}</div>;
  }

  const page = (
    <CommandPaletteResultsPages key="pages" pageResults={pageResults} />
  );
  const other = [
    <CommandPaletteResultsOrders
      key="orders"
      req={ordersReq}
      results={orderResults}
    />,
    <CommandPaletteResultsProducts
      key="products"
      req={productsReq}
      results={productResults}
    />
  ];

  const results = [
    ...(query === '' ? [] : [other]),
    page,
    ...(query === '' ? [other] : [])
  ];

  return (
    <ComboboxOptions static as="ul" className={options()}>
      {results}
    </ComboboxOptions>
  );
};

// Search
// - orders
// - delivery
// - product
