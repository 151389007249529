import { useTranslation } from '@dagens/frontend-i18n';
import { formFieldFactory, useWatch } from '@dagens/frontend-forms';
import { ProductFormValues } from '..';

const { Field, Input } = formFieldFactory<ProductFormValues>();

type Props = {
  defaultProductName?: string | null;
};

// Label needs to be a separate component because it uses useWatch,
// and we don't want the input re-rendering a bunch of times
const Label = ({ defaultProductName }: Props) => {
  const { t } = useTranslation();

  const name = useWatch<ProductFormValues>({
    name: 'name',
    defaultValue: defaultProductName
  }) as string;
  const productName = name && name.length > 0 ? name : t('common:product_one');

  return t('producer:productTypeLabelText', { productName });
};

export const ProductFormType = ({ defaultProductName }: Props) => {
  const { t } = useTranslation();

  return (
    <Field
      to="type"
      label={<Label defaultProductName={defaultProductName} />}
      helpText={t('producer:productTypeHelperText')}
    >
      <Input
        name="type"
        placeholder={t('producer:productTypePlaceholder')}
        maxLength={30}
      />
    </Field>
  );
};
