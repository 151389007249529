import { Combobox, DialogPanel } from '@headlessui/react';
import { PropsWithChildren, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { tv } from 'tailwind-variants';

// eslint-disable-next-line tailwindcss/no-custom-classname
const style = tv({
  base: `
    mx-auto
    max-w-[512px]
    overflow-hidden
    rounded
    bg-white
    p-xs
    shadow-2xl
    ring-1
    ring-black/5
    transition-all

    data-[closed]:scale-95
    data-[closed]:opacity-0

    data-[enter]:duration-300
    data-[enter]:ease-out

    data-[leave]:duration-200
    data-[leave]:ease-in
  `
});

type Props = PropsWithChildren<{
  onValueChange: () => void;
}>;

export const CommandPalettePanel = ({ onValueChange, children }: Props) => {
  const navigate = useNavigate();
  const [state, setState] = useState<string | null>(null);
  useEffect(() => {
    if (!state) {
      return;
    }
    if (state) {
      navigate(state);
      onValueChange();
    }
    setState(null);
  }, [state]);
  return (
    <DialogPanel transition className={style()}>
      <Combobox value={state} onChange={setState}>
        {children}
      </Combobox>
    </DialogPanel>
  );
};
