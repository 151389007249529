import { tv } from 'tailwind-variants';
import { FieldValues } from 'react-hook-form';
import { Fragment, FunctionComponent, ReactNode } from 'react';
import { MatchingKeys } from '../../types/fields';
import { FieldProps, useField } from '../../utils/use-field';
import { PeriodPickerSelected } from './selected';
import { PeriodPickerValue, useValues } from './use-values';
import { PeriodPickerCalendar } from './calendar';
import { PeriodPickerActionsAdd } from './actions-add';
import { PeriodPickerActionsEdit } from './actions-edit';

const style = tv({
  slots: {
    container: `
      flex
      flex-col
      gap-m
    `,
    calendar: `
      flex
      flex-col
      gap-xs
    `
  }
});

export type PeriodPickerProps<
  Form extends FieldValues,
  Name extends MatchingKeys<Form, PeriodPickerValue>
> = {
  mergeOverlapping?: boolean;
  formField: FunctionComponent<{ children: ReactNode }>;
  showAlways?: boolean;
} & FieldProps<Form, Name>;

export const PeriodPicker = <
  Form extends FieldValues,
  Name extends MatchingKeys<Form, PeriodPickerValue>
>({
  name,
  required,
  validate,
  shouldUnregister,
  disabled,
  mergeOverlapping,
  formField,
  showAlways
}: PeriodPickerProps<Form, Name>) => {
  const { field } = useField({
    name,
    required,
    validate,
    shouldUnregister,
    disabled
  });

  const { container, calendar } = style();

  const {
    activePeriod,
    activePeriodIndex,
    adding,
    addPeriod,
    deletePeriod,
    setActivePeriod,
    toggleAdding,
    toggleAllSelected,
    togglePeriod,
    updatePeriod
  } = useValues(
    field.value,
    field.onChange as (v: PeriodPickerValue) => void,
    mergeOverlapping
  );

  const SelectedWrapper = formField ?? Fragment;

  return (
    <div className={container()}>
      <SelectedWrapper>
        <PeriodPickerSelected
          adding={adding}
          activePeriodIndex={activePeriodIndex}
          value={field.value}
          showAlways={showAlways}
          onClickAll={toggleAllSelected}
          onClickPeriod={togglePeriod}
          onClickAdd={toggleAdding}
        />
      </SelectedWrapper>

      {(adding || activePeriodIndex !== undefined) && (
        <div className={calendar()}>
          <PeriodPickerCalendar
            value={activePeriod}
            otherPeriods={field.value}
            onChange={setActivePeriod}
          />

          {adding && (
            <PeriodPickerActionsAdd
              activePeriod={activePeriod}
              onClickAdd={() => addPeriod(activePeriod)}
              onClickReset={() => setActivePeriod(undefined)}
            />
          )}

          {activePeriodIndex !== undefined && (
            <PeriodPickerActionsEdit
              activePeriod={activePeriod}
              onClickSave={() => updatePeriod(activePeriodIndex, activePeriod)}
              onClickDelete={() => deletePeriod(activePeriodIndex)}
            />
          )}
        </div>
      )}
    </div>
  );
};
