import { PropsWithChildren } from 'react';
import { Button } from '../../../style-components/button';
import { tv } from '../../../utils/tv';
import { ButtonComponentProps } from '../../../style-components/button/components';

const style = tv({
  base: '',
  variants: {
    standalone: {
      true: `
        grid
        w-full
        grid-cols-1

        tablet:w-auto
        tablet:min-w-[25%]
        tablet:max-w-max-content
      `
    }
  }
});

type Props = PropsWithChildren<{
  standalone?: boolean;
}> &
  Pick<ButtonComponentProps, 'onClick' | 'disabled'>;

export const BottomActionsSubmit = ({
  standalone,
  disabled,
  onClick,
  children
}: Props) => {
  return (
    <div className={style({ standalone })}>
      <Button
        variant="primary"
        size="regular"
        type="submit"
        disabled={disabled}
        onClick={onClick}
      >
        {children}
      </Button>
    </div>
  );
};
