import { PropsWithChildren } from 'react';
import { tv } from '../../utils/tv';

const style = tv({
  base: `
    mb-xs
    border-b-thin
    border-lightGrey
    py-s
    text-center
    text-paragraphBold
    text-secondary
  `
});

type Props = PropsWithChildren;

export const ModalTitle = ({ children }: Props) => {
  return <div className={style()}>{children}</div>;
};
