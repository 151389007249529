import { Box, Nav, PageHeader } from '@dagens/carrot';
import { useTranslation } from '@dagens/frontend-i18n';
import { Page } from '../../../../components/page';
import REQ from '../../../../utils/REQ';
import { ManagedCustomer } from '../../../../api';
import { useFetchCustomer } from '../shared/use-fetch-customer';
import { useMediaQuery } from '../../../../_common/hooks/useMediaQueries';
import { BillingInfo } from './billing';
import { ContactInfo } from './contact';
import { DeliveryInfo } from './delivery';

type Props = {
  consumer: ManagedCustomer;
};

const PageContent = ({ consumer }: Props) => {
  const { desktopUp } = useMediaQuery();
  const { t } = useTranslation();

  const title = t('producer:EditCustomer');
  const subtitle = consumer?.name;

  return (
    <Page
      navMobileText={
        <>
          <Nav.ContextTitle>{title}</Nav.ContextTitle>
          <Nav.ContextSubtitle>{subtitle}</Nav.ContextSubtitle>
        </>
      }
      header={
        desktopUp && (
          <Box.FullWidth>
            <PageHeader title={title} subtitle={subtitle} />
          </Box.FullWidth>
        )
      }
    >
      <Box.PageContent>
        <ContactInfo consumer={consumer} />
        <DeliveryInfo consumer={consumer} />
        <BillingInfo consumer={consumer} />
      </Box.PageContent>
    </Page>
  );
};

export const EditCustomerPage = () => {
  const { req, consumer } = useFetchCustomer();

  if (req !== REQ.SUCCESS) {
    return <Page.Status req={req} />;
  }

  if (!consumer) {
    return <Page.NotFound />;
  }

  return <PageContent consumer={consumer} />;
};
