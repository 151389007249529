import {
  Listbox,
  ListboxButton,
  ListboxOption,
  ListboxOptions
} from '@headlessui/react';
import { twMerge } from 'tailwind-merge';
import { ByComparator, ComparisonUtils } from '@dagens/utils';
import { tv } from '../../utils/tv';
import { inputStyle } from '../input/styles';
import { Button } from '../button';
import { Icon } from '../../theme/icon';
import { nbsp } from '../../utils/nbsp';

const style = tv({
  slots: {
    value: `
      justify-between
      overflow-hidden
      whitespace-nowrap
      text-black

      focus:outline-none
    `,
    valueText: `
      flex
      flex-grow
      items-center
      justify-start
      truncate
    `,
    placeholder: `text-secondary`,
    options: `
      z-10
      flex
      w-[var(--button-width)]
      flex-col
      rounded
      border
      border-lightGrey
      bg-white

      [--anchor-gap:theme(spacing.xxs)]

      [--anchor-max-height:220px]

      focus:outline-none
    `,
    option: `
      group
      grid
    `,
    icon: `
      flex
      flex-shrink-0
      items-center
      justify-end
    `
  },
  variants: {
    empty: {
      true: {
        value: `text-secondary`
      }
    },
    fullHeight: {
      true: {
        options: `[--anchor-max-height:100%]`
      }
    }
  }
});

export type SelectProps<T> = {
  placeholder?: string;
  error?: boolean;
  value?: T;
  options: T[];
  onChange?: (value: T) => void;
  displayValue?: (value?: T) => string | undefined;
  by?: ByComparator<T>;
  fullHeight?: boolean;
};

export const Select = <T,>({
  error,
  placeholder,
  value,
  options,
  onChange,
  displayValue,
  by,
  fullHeight
}: SelectProps<T>) => {
  const { container } = inputStyle({ error });
  const {
    placeholder: placeholderStyle,
    value: valueStyle,
    valueText: valueTextStyle,
    options: optionsStyle,
    option: optionStyle,
    icon: iconStyle
  } = style();

  const valueToString = (value?: T) => {
    return displayValue?.(value) ?? value?.toString();
  };

  return (
    <Listbox value={value} onChange={onChange} by={by}>
      {({ open }) => {
        const valueText = valueToString(value);
        return (
          <>
            <ListboxButton
              className={twMerge(
                container(),
                valueStyle({ empty: value === undefined })
              )}
            >
              <div
                className={twMerge(
                  valueTextStyle(),
                  valueText ? undefined : placeholderStyle()
                )}
              >
                {valueText ?? placeholder ?? nbsp}
              </div>
              <div className={iconStyle()}>
                <Icon icon={open ? 'collapse' : 'expand'} size="small" />
              </div>
            </ListboxButton>
            <ListboxOptions
              className={optionsStyle({ fullHeight })}
              anchor="bottom"
              as="ul"
              modal={false}
            >
              {options.map(option => (
                <ListboxOption
                  key={valueToString(option)}
                  value={option}
                  as="li"
                  className={optionStyle()}
                >
                  <Button.DropdownItem
                    active={ComparisonUtils.equal(by, option, value)}
                  >
                    {valueToString?.(option) ?? option?.toString()}
                  </Button.DropdownItem>
                </ListboxOption>
              ))}
            </ListboxOptions>
          </>
        );
      }}
    </Listbox>
  );
};
