import { PropsWithChildren, ReactNode } from 'react';
import { tv } from '../../../utils/tv';

const style = tv({
  base: `
    flex
    flex-col
    gap-xl
  `,
  slots: {
    header: `
      flex
      flex-col
      gap-xs
    `,
    title: `text-secondaryHeading`,
    description: `text-paragraph`,
    info: `mt-xs`
  }
});

type Props = PropsWithChildren<{
  title?: string;
  description?: string;
  info?: ReactNode;
}>;

export const FormSection = ({ title, description, info, children }: Props) => {
  const {
    base,
    header,
    title: titleStyle,
    description: descriptionStyle,
    info: infoStyle
  } = style();
  return (
    <div className={base()}>
      {title && (
        <div className={header()}>
          <h2 className={titleStyle()}>{title}</h2>
          {description && <p className={descriptionStyle()}>{description}</p>}
          {info && <div className={infoStyle()}>{info}</div>}
        </div>
      )}
      {children}
    </div>
  );
};
