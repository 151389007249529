import { Box, PageHeader } from '@dagens/carrot';
import { useTranslation } from '@dagens/frontend-i18n';
import _ from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  useAppDispatch,
  useAppSelector
} from '../../../_common/hooks/reduxHooks';
import { DeliveryWindow } from '../../../types/Consumer';
import { StructuredAddress } from '../../../types/Logistics';
import { BackButton } from '../../../components/page/parts/back-button';
import { SubmitActionBlocker } from './CheckoutPage.style';
import { useDeliveryDayTypes } from './use-delivery-day-type';
import EmptyBasket from '_common/components/empty-basket/EmptyBasket';
import OrderConfirmation from '_common/components/order-confirmation/OrderConfirmation';
import BasketOrderLinesByDeliveryDate from '_common/components/products-in-basket/BasketOrderLinesByDeliveryDate';
import { AuthState, getUserFirstName } from '_common/reducers/auth';
import { clearCheckout } from '_common/reducers/checkout';
import { fetchOrders } from '_common/reducers/orders';
import CheckoutFooter from '_consumer/components/checkout-footers/CheckoutFooter';
import { completeOrder } from '_consumer/reducers/basket';
import {
  countProducersInBasket,
  getBasketByDeliveryDate,
  getOrderPayloads,
  getProductsInBasket,
  ProductWithBasket,
  sumPriceForProductsInBasket
} from '_consumer/reducers/productsForSale';
import { Page } from '@components/page';
import { fetchDeliveryFees, getHasFirstOrder, postCheckout } from 'api';
import { DeliveryFeesResponse } from 'types/Basket';
import {
  BASKET_CLICK_SEND_ORDER,
  BASKET_PAGE_VIEW,
  track
} from 'utils/mixpanel';
import { basketContainsCustomOffer } from 'utils/product-pricing';
import REQ, { ReqType } from 'utils/REQ';
import { isGuestConsumer } from 'utils/role';

type Props = {
  consumer: AuthState;
  deliveryInfo: string | undefined;
  deliveryAddress: StructuredAddress;
  deliveryWindow: DeliveryWindow | undefined;
  productsInBasket: ProductWithBasket[];
  totalPrice: number;
  numProducts: number;
  numProducers: number;
  messages: Record<string, string> | undefined;
  deliveryDateCount: number;
  distributionAreaId: string | undefined;
};

const CheckoutPage = ({
  consumer,
  deliveryInfo,
  deliveryAddress,
  deliveryWindow,
  productsInBasket = [],
  totalPrice,
  numProducts = 0,
  numProducers = 0,
  messages,
  deliveryDateCount = 0,
  distributionAreaId
}: Props) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [submitReq, setSubmitReq] = useState<ReqType>(REQ.INIT);
  const [hasFirstOrder, setHasFirstOrder] = useState<boolean | undefined>(
    undefined
  );
  const [basketServiceReq, setBasketServiceReq] = useState<ReqType>(REQ.INIT);
  const [deliveryFees, setDeliveryFees] = useState<
    DeliveryFeesResponse | undefined
  >(undefined);
  const [daysOverThreshold, setDaysOverThreshold] = useState<string[]>([]);
  const navigate = useNavigate();

  useEffect(() => {
    const consumerId = consumer._id;
    getHasFirstOrder({ consumerId }).then(res => {
      setHasFirstOrder(res);
    });

    setBasketServiceReq(REQ.PENDING);
    track(BASKET_PAGE_VIEW, {
      numProducts,
      numProducers,
      numDeliveryDays: deliveryDateCount,
      orderTotal: totalPrice
    });
  }, []);

  const exemptByThreshold = useCallback((date: string, over: boolean) => {
    if (over) setDaysOverThreshold([...daysOverThreshold, date]);
    else
      setDaysOverThreshold(
        daysOverThreshold.filter(d => {
          return d !== date;
        })
      );
  }, []);

  const handlePlaceOrderSuccess = (containsCustomOffer: any) => {
    setSubmitReq(REQ.SUCCESS);
    track(BASKET_CLICK_SEND_ORDER, {
      numProducts,
      numProducers,
      numDeliveryDays: deliveryDateCount,
      orderTotal: totalPrice,
      containsCustomOffer
    });
    dispatch(completeOrder());
    dispatch(clearCheckout());
    dispatch(
      fetchOrders({ consumerId: consumer._id }, { clearStore: false }) as any
    );
  };

  const basketDeliveryTypes = useDeliveryDayTypes(
    numProducts,
    productsInBasket,
    distributionAreaId
  );

  useEffect(() => {
    if (productsInBasket.length > 0) {
      const strippedBasket = productsInBasket.map(prod => {
        return {
          deliveryDate: prod.deliveryDate,
          _id: prod._id,
          producer: {
            _id: prod.producer._id
          }
        };
      });

      fetchDeliveryFees({
        consumerId: consumer._id,
        distributionAreaId,
        basket: strippedBasket
      })
        .then(res => {
          setDeliveryFees(res);
          setBasketServiceReq(REQ.SUCCESS);
        })
        .catch(() => {
          setBasketServiceReq(REQ.ERROR);
        });
    }
  }, [numProducts]);

  const placeOrder = () => {
    setSubmitReq(REQ.PENDING);

    const containsCustomOffer = basketContainsCustomOffer(productsInBasket);

    const payloadCommonFields = {
      consumerId: consumer._id,
      createdAs: consumer._id,
      createdBy: consumer.uid
    };

    const payloads = getOrderPayloads(
      productsInBasket,
      payloadCommonFields,
      messages
    );

    const groupedByDeliveryDay = _.chain(payloads)
      .groupBy('deliveryDate')
      .map(producerBasket => {
        return producerBasket;
      })
      .value();
    Promise.all(
      groupedByDeliveryDay.map(async group => {
        await postCheckout({
          orders: group,
          deliveryDate: group[0].deliveryDate,
          consumerId: consumer._id
        });
      })
    )
      .then(() => {
        handlePlaceOrderSuccess(containsCustomOffer);
      })
      .catch(() => {
        setSubmitReq(REQ.ERROR);
      });
  };

  if (submitReq === REQ.SUCCESS) {
    if (hasFirstOrder === false) {
      navigate('/delivery-information', {
        state: { redirect: '/orders' }
      });
    }
    return (
      <OrderConfirmation
        headerText={t('consumer:ThankUser', {
          userFirstName: getUserFirstName(consumer)
        })}
        subTitle={t('consumer:OrderAdded')}
        deliveryAddress={deliveryAddress}
        deliveryWindow={deliveryWindow}
        deliveryInfo={deliveryInfo}
        deliveryDateCount={deliveryDateCount}
      />
    );
  }

  const basketByDeliveryDate = getBasketByDeliveryDate(productsInBasket);

  const subTitle = t('consumer:numProductsAndProducers', {
    count: numProducers,
    numProducts
  });

  if (productsInBasket.length < 1 || isGuestConsumer(consumer._id))
    return (
      <Page>
        <EmptyBasket />
      </Page>
    );

  return (
    <Page
      backButton={<BackButton returnTo={{ pathname: '/' }} />}
      backButtonOnlyOnMobile
      bottom={
        basketServiceReq === REQ.SUCCESS && (
          <Box.BottomSheet border>
            <CheckoutFooter
              isApprovedToOrder={Boolean(consumer.isApprovedToOrder)}
              submitReq={submitReq}
              placeOrder={placeOrder}
              basketByDeliveryDate={basketByDeliveryDate}
              deliveryFees={deliveryFees}
              deliveryTypes={basketDeliveryTypes}
              newExemptions={daysOverThreshold}
            />
          </Box.BottomSheet>
        )
      }
    >
      {submitReq === REQ.PENDING && <SubmitActionBlocker />}
      <PageHeader title={t('consumer:Checkout')} subtitle={subTitle} />
      {basketServiceReq === REQ.SUCCESS && (
        <BasketOrderLinesByDeliveryDate
          deliveryFees={deliveryFees}
          deliveryTypes={basketDeliveryTypes}
          basketByDeliveryDate={basketByDeliveryDate}
          exemptionCallback={exemptByThreshold}
        />
      )}
    </Page>
  );
};

const CheckoutPageFetcher = () => {
  const { req, ...props } = useAppSelector(
    ({
      auth,
      basket,
      productsForSale: { req, items },
      checkout: { messages }
    }) => {
      const {
        distributionAreaId,
        deliveryInfo,
        structuredDeliveryAddress,
        deliveryWindow
      } = auth;
      const productsInBasket = getProductsInBasket(items, basket.items);

      const deliveryDates = Array.from(
        new Set(
          productsInBasket.map(({ deliveryDate }) => {
            return deliveryDate;
          })
        )
      );

      const deliveryDateCount = deliveryDates.length;

      return {
        req,
        consumer: auth,
        deliveryInfo,
        deliveryAddress: structuredDeliveryAddress as StructuredAddress,
        deliveryWindow,
        productsInBasket,
        totalPrice: sumPriceForProductsInBasket(productsInBasket),
        numProducts: productsInBasket.length,
        numProducers: countProducersInBasket(items, basket.items),
        messages,
        deliveryDateCount,
        distributionAreaId
      };
    }
  );

  if (req !== REQ.SUCCESS) {
    return <Page.Status req={req} />;
  }

  return <CheckoutPage {...props} />;
};

export default CheckoutPageFetcher;
