import { useAppSelector } from '../../../../_common/hooks/reduxHooks';
import { isProducerSelfDelivery } from '../../../../utils/delivery';

// Checks if all producer routes are self delivery
const useIsOnlyProducerDeliveryRoutes = (): boolean => {
  const { producerId, logisticsRoutes } = useAppSelector(
    ({ auth: { _id, logisticsRoutes = [] } }) => {
      return {
        producerId: _id,
        logisticsRoutes
      };
    }
  );

  return logisticsRoutes.every(({ route }) => {
    return isProducerSelfDelivery({
      logisticsRoutes: route,
      producer: { _id: producerId }
    });
  });
};

// Returns the transaction cut for a producer based on their logistics routes
export const useTransactionCut = () => {
  const { transactionCut, transactionCutForSelfDelivery } = useAppSelector(
    ({ auth: { transactionCut, transactionCutForSelfDelivery } }) => {
      return {
        transactionCut,
        transactionCutForSelfDelivery
      };
    }
  );

  const isOnlyProducerDeliveryRoutes = useIsOnlyProducerDeliveryRoutes();
  const hasDagensLogistics = !isOnlyProducerDeliveryRoutes;

  const finalTransactionCut =
    (hasDagensLogistics ? transactionCut : transactionCutForSelfDelivery) ?? 0;

  return finalTransactionCut;
};
