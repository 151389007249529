import { formFieldFactory } from '@dagens/frontend-forms';
import { useTranslation } from '@dagens/frontend-i18n';
import { ProductFormValues } from '..';

const { Field, Input } = formFieldFactory<ProductFormValues>();

export const ProductFormName = () => {
  const { t } = useTranslation();
  return (
    <Field
      to="name"
      label={t('producer:productNameLabelText')}
      helpText={t('producer:productNameHelperText')}
      required
    >
      <Input name="name" placeholder={t('producer:productNamePlaceholder')} />
    </Field>
  );
};
