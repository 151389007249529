import { useTranslation } from '@dagens/frontend-i18n';
import React, { Fragment as F, useContext } from 'react';
import { useInfiniteScroll } from '../../../../utils/infiniteScroll';
import { GridContainer } from './Grid';
import ProducerGroupHeader from './ProducerGroupHeader';
import Theme from '@carrot-deprecated/theme';
import Container from '@carrot-deprecated/container';
import Button from '_common/components/button/Button.style';
import ProductCard from '_common/components/product-card/ProductCard';
import { DeliveryDateContext } from '_common/context/DeliveryDateContext';
import { useAppDispatch } from '_common/hooks/reduxHooks';
import { showProductModal } from '_common/reducers/modal';
import { Producer } from 'types/Producer';
import { ProductForSale } from 'types/Product';
import { PRODUCT_SEARCH_CLICK_SHOW_ALL_PRODUCTS, track } from 'utils/mixpanel';

type GroupedSearchResultContainerProps = {
  producerGroups: {
    producer: Producer;
    numberOfOrders: number;
    products: ProductForSale[];
  }[];
  loadMore: () => void;
  hasMore: boolean;
  deliveryDate: string | undefined;
};

const PRODUCTS_TO_SHOW = 6;

const GroupedSearchResultContainer = ({
  producerGroups,
  loadMore,
  hasMore,
  deliveryDate
}: GroupedSearchResultContainerProps) => {
  const [showAllProductsForProducer, setShowAllProductsForProducer] =
    React.useState<string[]>([]);
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { pageContext: deliveryDateContext } = useContext(DeliveryDateContext);
  const { scrollerRef } = useInfiniteScroll(hasMore, loadMore);

  return (
    <div ref={scrollerRef}>
      <Container mt="xl">
        {producerGroups.map(({ producer, products }) => {
          const slicedProducts = products.slice(0, PRODUCTS_TO_SHOW);
          const onCardClick = (productId: string) => {
            dispatch(
              showProductModal({
                producerProducts: products,
                producer,
                productId,
                deliveryDateContext
              })
            );
          };

          return (
            <F key={producer._id}>
              <Container mb="jumbo">
                <ProducerGroupHeader
                  producer={producer}
                  deliveryDate={deliveryDate}
                />
                <GridContainer>
                  {(showAllProductsForProducer.includes(producer._id)
                    ? products
                    : slicedProducts
                  ).map(product => {
                    return (
                      <ProductCard
                        key={product._id}
                        product={product}
                        onClick={() => {
                          onCardClick(product._id);
                        }}
                      />
                    );
                  })}
                </GridContainer>
                {products.length > PRODUCTS_TO_SHOW &&
                  !showAllProductsForProducer.includes(producer._id) && (
                    <Button
                      style={{
                        whiteSpace: 'normal',
                        textAlign: 'left',
                        lineHeight: 1.2
                      }}
                      mt={Theme.Spacings.xs}
                      onClick={() => {
                        track(PRODUCT_SEARCH_CLICK_SHOW_ALL_PRODUCTS, {
                          producerId: producer._id
                        });
                        setShowAllProductsForProducer(prev => {
                          return [...prev, producer._id];
                        });
                      }}
                    >
                      {t('producer:ShowAllProductsFromProducer', {
                        producerName: producer.name
                      })}
                    </Button>
                  )}
              </Container>
            </F>
          );
        })}
      </Container>
    </div>
  );
};

export default GroupedSearchResultContainer;
