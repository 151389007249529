import { postAsset } from '../../../../api';
import { SanityImage } from '../../../../types/Sanity';

export const uploadImage = async (
  file?: File | null
): Promise<SanityImage | null> => {
  if (!file) {
    return null;
  }

  const formData = new FormData();
  formData.append('image', file, file.name);
  try {
    const { image } = await postAsset(formData);
    return image;
  } catch {
    throw new Error('Failed to upload image.');
  }
};
