import { formFieldFactory } from '@dagens/frontend-forms';
import { useTranslation } from '@dagens/frontend-i18n';
import { PricingFormValues } from '..';
import { useConsumers } from '../hooks/use-consumers';
import { useConsumersWithPrice } from '../hooks/use-consumers-with-price';
import { Pricing } from '../../../../types/Product';
import Loader from '../../../../_common/components/loader/Loader';

const { Field, ComboboxMulti } = formFieldFactory<PricingFormValues>();

type Props = {
  otherPrices?: Pricing[];
};

export const PricingFormCustomers = ({ otherPrices }: Props) => {
  const { t } = useTranslation();
  const { consumers, req: consumersReq } = useConsumers();
  const consumersWithPrice = useConsumersWithPrice(otherPrices);

  if (consumersReq !== 'SUCCESS') {
    return <Loader />;
  }

  return (
    <Field
      to="customers"
      label={t('producer:SelectCustomers')}
      explanation={t('producer:SelectCustomersExplanationText')}
      required
    >
      <ComboboxMulti
        name="customers"
        options={consumers}
        disabledOptions={consumersWithPrice ?? []}
        displayValue={value => value?.name}
        by={(a, b) => a?._id === b?._id}
        emptyOptionsText={t('producer:noCustomersFound')}
      />
    </Field>
  );
};
