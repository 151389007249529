import { PropsWithChildren } from 'react';
import { tv } from '../../utils/tv';

export const styles = tv({
  base: `
    flex
    flex-col
    gap-jumbo
  `
});

type Props = PropsWithChildren;

export const PageContent = ({ children }: Props) => {
  return <div className={styles()}>{children}</div>;
};
