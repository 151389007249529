import { useEffect, useState } from 'react';
import { Navigate, useParams } from 'react-router-dom';
import { useAppSelector } from '_common/hooks/reduxHooks';
import useOnboarding from '_common/hooks/useConsumerOnboarding';
import ProducerPageFetcher from '_consumer/pages/producer-page/ProducerPageFetcher';
import { Page } from '@components/page';
import { getProducerShopUrl } from 'api';
import REQ, { ReqType } from 'utils/REQ';

type Response = {
  _id: string;
  handle: string;
  hideShop: boolean;
};

const RootResolver = () => {
  const { handle } = useParams<{ handle: string }>();
  const [shopUrlResponse, setShopUrlResponse] = useState<Response>();
  const [req, setReq] = useState<ReqType>(REQ.INIT);
  const { hasNotOnboarded, getOnboardingPath } = useOnboarding();

  const isAuthenticated = useAppSelector(({ auth: { authenticated } }) => {
    return authenticated;
  });

  if (hasNotOnboarded()) {
    return <Navigate to={getOnboardingPath()} />;
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        setReq(REQ.PENDING);
        const response = await getProducerShopUrl({ handle });
        if (response.hideShop && !isAuthenticated) {
          setReq(REQ.NOT_FOUND);
        } else {
          setShopUrlResponse(response);
          setReq(REQ.SUCCESS);
        }
      } catch (e) {
        setReq(REQ.NOT_FOUND);
      }
    };
    fetchData();
  }, []);

  if (req !== REQ.SUCCESS) {
    return <Page.Status req={req} />;
  }
  if (shopUrlResponse && shopUrlResponse.handle !== handle) {
    return <Navigate to={`/${shopUrlResponse.handle}`} />;
  }
  if (shopUrlResponse?._id) {
    return <ProducerPageFetcher producerId={shopUrlResponse._id} />;
  }

  return <Page.NotFound />;
};

export default RootResolver;
