import { useTranslation, TFunction } from '@dagens/frontend-i18n';
import Typography from '@carrot-deprecated/typography';
import Container from '@carrot-deprecated/container';
import Theme from '@carrot-deprecated/theme';
import { RoleType } from '_common/reducers/auth';
import { useAppSelector } from '_common/hooks/reduxHooks';
import Icon from '_common/components/utils/Icon';
import Button, { ButtonAnchor } from '_common/components/button/Button.style';
import {
  CLICK_COPY_TRADE_REPORT_LINK,
  PRODUCER_HOME_CLICK_TRADE_REPORT_LINK,
  PROFILE_CLICK_TRADE_REPORT_LINK,
  track
} from 'utils/mixpanel';
import { CONSUMER_REPORT_ID, PRODUCER_REPORT_ID } from 'utils/reportTemplates';

type TradeReportLinkVariants = 'callout' | 'minimal' | 'default';

type TradeReportLinkContexts = 'profile' | 'producerHome';

const getButtonVariant = (tradeReportVariant: TradeReportLinkVariants) => {
  switch (tradeReportVariant) {
    case 'callout':
      return 'secondary';
    case 'minimal':
      return 'borderless';
    case 'default':
      return 'ghost';
    default:
      return 'ghost';
  }
};

const renderExplanation = (roleType: RoleType, t: TFunction) => {
  return (
    <Typography variant="paragraph">
      {roleType === 'consumers'
        ? t('consumer:TradeReportExplainer')
        : t('producer:TradeReportExplainer')}
    </Typography>
  );
};

const renderLinkButton = (
  variant: TradeReportLinkVariants,
  context: TradeReportLinkContexts,
  reportUrlAll: string,
  t: TFunction
) => {
  return (
    <ButtonAnchor
      variant={getButtonVariant(variant)}
      href={reportUrlAll}
      mr={Theme.Spacings.s}
      target="_blank"
      onClick={() => {
        track(
          context === 'producerHome'
            ? PRODUCER_HOME_CLICK_TRADE_REPORT_LINK
            : PROFILE_CLICK_TRADE_REPORT_LINK
        );
      }}
    >
      {t('common:ViewTradeReport')}{' '}
      <Icon
        icon="externalLinkSmall"
        fill={variant === 'callout' ? 'white' : 'deepPurple'}
      />{' '}
    </ButtonAnchor>
  );
};

const renderCopyLinkButton = (reportUrlThis: string, t: TFunction) => {
  return (
    <Button
      onClick={() => {
        track(CLICK_COPY_TRADE_REPORT_LINK);
        navigator.clipboard.writeText(reportUrlThis);
      }}
      variant="borderless"
    >
      {t('common:CopyTradeReportLink')}
    </Button>
  );
};

type TradeReportLinkProps = {
  variant?: TradeReportLinkVariants;
  context?: TradeReportLinkContexts;
};

const TradeReportLink = ({
  variant = 'default',
  context = 'profile'
}: TradeReportLinkProps) => {
  const { t } = useTranslation();

  const { roleId, roleIdOptions, roleType } = useAppSelector(
    ({ auth: { _id, options, _type } }) => {
      return {
        roleIdOptions: options
          ?.filter(op => {
            return op._type === _type;
          })
          .map(op => {
            return op._id;
          }),
        roleId: _id,
        roleType: _type
      };
    }
  );

  const joinedOptions = roleIdOptions?.join();
  const allRoles = {
    'ds6.requestedid': joinedOptions
  };
  const thisRole = {
    'ds6.requestedid': roleId
  };
  const REPORT_ID =
    roleType === 'consumers' ? CONSUMER_REPORT_ID : PRODUCER_REPORT_ID;
  const allRoleParams = encodeURIComponent(JSON.stringify(allRoles));
  const thisRoleParams = encodeURIComponent(JSON.stringify(thisRole));
  const reportUrlAll = `https://lookerstudio.google.com/reporting/${REPORT_ID}/?params=${allRoleParams}`;
  const reportUrlThis = `https://lookerstudio.google.com/reporting/${REPORT_ID}/?params=${thisRoleParams}`;

  if (variant === 'minimal')
    return renderLinkButton(variant, context, reportUrlAll, t);

  return (
    <>
      {roleType ? renderExplanation(roleType, t) : null}
      <Container
        flex
        justify="flex-start"
        alignItems="baseline"
        mt="s"
        mb={variant === 'callout' ? undefined : 'l'}
      >
        {renderLinkButton(variant, context, reportUrlAll, t)}
        {renderCopyLinkButton(reportUrlThis, t)}
      </Container>
    </>
  );
};
export default TradeReportLink;
