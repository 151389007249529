import { ChangeEvent, useContext } from 'react';
import { Icon } from '../../../theme/icon';
import { Button } from '../../button';
import { tv } from '../../../utils/tv';
import { InputContext } from '../utils/context';

const style = tv({
  base: `my-[-10px]`
});

export const InputClear = () => {
  const { onChange } = useContext(InputContext);
  return (
    <div className={style()}>
      <Button
        size="small"
        variant="borderless"
        onClick={() =>
          onChange?.({
            target: { value: '' }
          } as ChangeEvent<HTMLInputElement>)
        }
      >
        <Icon icon="close" size="small" />
      </Button>
    </div>
  );
};
