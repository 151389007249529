import { AvailableLanguages } from '@dagensmat/core';
import algoliasearch, { SearchIndex } from 'algoliasearch';
import { ProductForSale } from 'types/Product';

export type SearchFunctionOptions = {
  roleId?: string;
  onlyIds?: boolean;
  distributionAreaId?: string;
  roleLang?: AvailableLanguages;
  isMeyersAccount?: boolean;
  page?: number;
  hitsPerPage?: number;
};

export const setupAlgolia = (index: string, roleId?: string): SearchIndex => {
  return algoliasearch('QK33H0JTG8', '395606839de81c5ff4fef7b35fa9bbf4', {
    headers: roleId
      ? {
          'X-Algolia-UserToken': roleId
        }
      : undefined
  }).initIndex(index);
};

export const mapAlgoliaDatesAndDeadlines = (
  hits: (ProductForSale & Record<string, any>)[],
  distributionAreaId?: string
) => {
  const deliveryDatesKey = `deliveryDates_${distributionAreaId}`;
  const deadlinesKey = `deadlines_${distributionAreaId}`;
  return hits.map(product => {
    const deliveryDates = distributionAreaId
      ? product[deliveryDatesKey]
      : undefined;
    const deadlines = distributionAreaId ? product[deadlinesKey] : undefined;
    return {
      ...product,
      deliveryDates: deliveryDates ?? [],
      deadlines: deadlines ?? {}
    };
  });
};
