import { useTranslation } from '@dagens/frontend-i18n';
import styled from 'styled-components';
import Theme from '../../../carrot-deprecated/theme';
import Typography from '@carrot-deprecated/typography';
import Container from '@carrot-deprecated/container';

type ProductsInBasketTotalProps = {
  subtotal: string;
};

const StyledContainer = styled(Container)`
  padding: ${Theme.Spacings.xs}px 0;
`;

const ProducerTotal = styled(Typography)`
  order: 1;
  text-align: right;
  flex: auto;
`;

const ProductsInBasketTotal = ({ subtotal }: ProductsInBasketTotalProps) => {
  const { t } = useTranslation();

  return (
    <StyledContainer flex>
      <Typography
        variant="paragraphSmallBold"
        style={{ order: 0, flex: 'none' }}
      >
        {t(`common:OrderTotal`)}
      </Typography>
      <ProducerTotal variant="paragraphSmallBold">{`${subtotal}`}</ProducerTotal>
    </StyledContainer>
  );
};

export default ProductsInBasketTotal;
