import { NumberInput } from '../input-number';
import { Select } from '../select';
import Container from '@carrot-deprecated/container';
import { FormFieldInputProps } from 'carrot-deprecated/form-field/types';

type Props<SelectType extends string> = FormFieldInputProps & {
  placeholder?: string;
  unit?: string | null;
  value?: number | string;
  onInputChange: (value: number | string) => void;
  selectOptions: { value: SelectType; name: string }[];
  selectValue?: SelectType;
  onSelectChange: (value: SelectType) => void;
};

export const InputWithSelect = <SelectType extends string>({
  placeholder = '#',
  unit,
  value,
  onInputChange,
  selectOptions,
  selectValue,
  onSelectChange,
  optional,
  showError,
  invalid
}: Props<SelectType>) => {
  return (
    <Container flex gap="xxs">
      <NumberInput
        value={value}
        unit={unit}
        decimal
        placeholder={placeholder}
        onChange={onInputChange}
        optional={optional}
        invalid={invalid}
        showError={showError}
      />
      <Select
        selectedValue={selectValue ?? selectOptions[0].value}
        options={selectOptions}
        onChange={onSelectChange}
      />
    </Container>
  );
};

export type InputWithSelectProps<SelectType extends string> = Props<SelectType>;
