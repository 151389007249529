const DAYPICKER_MIN_DESKTOP_WIDTH = 605;

export const numberOfMonths = ({
  width,
  mobile = 1,
  desktop = 2
}: {
  width: number;
  mobile?: number;
  desktop?: number;
}) => {
  return width < DAYPICKER_MIN_DESKTOP_WIDTH ? mobile : desktop;
};
