import { forwardRef, Ref } from 'react';

import { Simplify } from 'type-fest';
import { LinkComponentInternal, LinkComponentProps } from '../components';
import { modalActionStyle } from '../../button/presets/modal-action';
import { ButtonStyleProps } from '../../button/styles';

type Props = Simplify<
  Omit<LinkComponentProps, ButtonStyleProps | 'className'> & {
    close?: boolean;
  }
>;

const Component = ({ close, ...props }: Props, ref: Ref<HTMLAnchorElement>) => {
  return (
    <LinkComponentInternal
      {...props}
      ref={ref}
      variant="borderless"
      size="regular"
      className={modalActionStyle({ close })}
    />
  );
};

export const ModalAction = forwardRef(Component);
