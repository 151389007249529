import { useTranslation } from '@dagens/frontend-i18n';
import Container from '../../../../carrot-deprecated/container';
import Typography from '../../../../carrot-deprecated/typography';
import { useProducerPlans } from '../../../hooks/use-producer-plans';
import { useAppSelector } from '../../../../_common/hooks/reduxHooks';
import { PlanService } from '../../../../services/PlanService';
import { PricingPlan } from './PricingPlan';

type ProfilePlanAndPaymentSectionProps = {
  transactionCut: number;
  transactionCutForSelfDelivery: number;
};

export const PricingPlanList = ({
  transactionCut,
  transactionCutForSelfDelivery
}: ProfilePlanAndPaymentSectionProps) => {
  const { t } = useTranslation();
  const { platformFeePercentage, deliveryFeePercentage } = useProducerPlans(
    transactionCut,
    transactionCutForSelfDelivery
  );

  const { producerId, logisticsRoutes, hasLogisticsService } = useAppSelector(
    ({ auth: { _id, logisticsRoutes, plan } }) => ({
      producerId: _id,
      logisticsRoutes,
      hasLogisticsService: PlanService.hasFeature(plan, 'LOGISTICS_SERVICE')
    })
  );
  const hasDagensLogistics = logisticsRoutes?.some(
    ({ route }) =>
      route[0]._type === 'transport' && route[0].partner._ref === producerId
  );

  return (
    <Container
      flex
      width="full"
      alignItems="stretch"
      justify="flex-start"
      gap="xs"
      mb="l"
    >
      <PricingPlan
        cut={platformFeePercentage}
        title={t('producer:SystemFee')}
        description={
          <Typography variant="paragraphSmall">
            {t('producer:pricingPlan.appliesToAllOrders')}
          </Typography>
        }
        helpDescription={t('producer:pricingPlan.systemFeeDescription')}
      />
      {hasDagensLogistics && hasLogisticsService && (
        <PricingPlan
          cut={deliveryFeePercentage}
          title={t('producer:DagensDeliveryLogisticsFee')}
          description={
            <>
              <Typography variant="paragraphSmallBold">
                {t('producer:pricingPlan.added')}{' '}
              </Typography>
              <Typography variant="paragraphSmall" as="span">
                {t('producer:pricingPlan.toOrdersDeliveredByDates')}
              </Typography>
            </>
          }
          helpDescription={t(
            'producer:pricingPlan.dagensDeliveryFeeDescription'
          )}
        />
      )}
    </Container>
  );
};
