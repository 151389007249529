import {
  ComboboxMulti as CarrotComboboxMulti,
  type ComboboxMultiProps as CarrotComboboxMultiProps
} from '@dagens/carrot';
import { Path, PathValue, FieldValues } from 'react-hook-form';
import { FieldProps, useField } from '../utils/use-field';

// Fields from the Carrot component that are handled by react-hook-form
type ExcludedFields = 'error' | 'value' | 'onChange';

export type ComboboxMultiProps<
  Form extends FieldValues,
  Name extends Path<Form>,
  Value extends PathValue<Form, Name>
> = Omit<CarrotComboboxMultiProps<Value[number]>, ExcludedFields> &
  FieldProps<Form, Name>;

export const ComboboxMulti = <
  Form extends FieldValues,
  Name extends Path<Form>,
  Value extends PathValue<Form, Name>
>({
  name,
  required,
  validate,
  shouldUnregister,
  disabled,
  ...props
}: ComboboxMultiProps<Form, Name, Value>) => {
  const {
    field,
    fieldState: { error }
  } = useField({
    name,
    required,
    validate,
    shouldUnregister,
    disabled
  });

  return (
    <CarrotComboboxMulti
      value={field.value}
      error={Boolean(error)}
      onChange={v => field.onChange(v)}
      {...props}
    />
  );
};
