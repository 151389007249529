import { debounce } from 'lodash';
import React from 'react';

// Stolen from https://usehooks.com/useWindowSize/
const useWindowWidth = () => {
  // Initialize state with undefined width/height so server and client renders match
  // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
  const [windowWidth, setWindowWidth] = React.useState<number | undefined>(
    undefined
  );

  React.useEffect(() => {
    const debouncedHandleResize = debounce(
      () => {
        setWindowWidth(window.innerWidth);
      },
      300,
      { maxWait: 1200 }
    );

    window.addEventListener('resize', debouncedHandleResize);
    debouncedHandleResize();

    return () => {
      return window.removeEventListener('resize', debouncedHandleResize);
    };
  }, []);
  return windowWidth;
};

export default useWindowWidth;
