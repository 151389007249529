import { useTranslation } from '@dagens/frontend-i18n';
import { shuffle } from 'lodash';
import { useMemo } from 'react';
import HighlightedContentSection from '_common/components/highlighted-content/HighlightedContentSection';
import SmallProductCard from '_common/components/product-card/SmallProductCard';
import { useAppDispatch, useAppSelector } from '_common/hooks/reduxHooks';
import { showProductModal } from '_common/reducers/modal';
import { RootState } from 'store';
import { ProductForSale } from 'types/Product';
import { CLICK_PRODUCT_NEWS_CARD, track } from 'utils/mixpanel';

type Props = {
  products: ProductForSale[];
};

const ProductNewsSection = ({ products = [] }: Props) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { producers } = useAppSelector(
    ({ producers: { items } }: RootState) => {
      return {
        producers: items
      };
    }
  );

  const onCardClick = (productId: string) => {
    track(CLICK_PRODUCT_NEWS_CARD);
    const product = products.find(({ _id }) => {
      return _id === productId;
    });
    const producer = producers.find(({ _id }) => {
      return _id === product?.producer._id;
    });
    const producerProducts = products.filter(p => {
      return p.producer._id === producer?._id;
    });

    dispatch(
      showProductModal({
        producerProducts,
        producer,
        productId
      })
    );
  };

  const filterProductNews = () => {
    const productNews = products.filter(product => {
      return product.justAdded || product.seasonStart;
    });
    return shuffle(productNews);
  };

  const productNews = useMemo(() => {
    return filterProductNews();
  }, [products]);
  const newProductsCount = productNews.length;
  if (newProductsCount === 0) return null;

  return (
    <HighlightedContentSection
      sectionTitle={t('consumer:ProductNewsSectionHeader', {
        newProductsCount,
        count: newProductsCount
      })}
    >
      <>
        {productNews.map(product => {
          return (
            <SmallProductCard
              product={product}
              key={product._id}
              onClick={() => {
                onCardClick(product._id);
              }}
            />
          );
        })}
      </>
    </HighlightedContentSection>
  );
};

export default ProductNewsSection;
