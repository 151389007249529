import { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import REQ, { ReqType } from '../../../../utils/REQ';
import {
  useAppDispatch,
  useAppSelector
} from '../../../../_common/hooks/reduxHooks';
import { ContactFormValues } from '../../../components/customer-contact-form';
import { fetchConsumers } from '../../../reducers/consumers';
import { patchManagedCustomerContactInformation } from '../../../../api';

export const useOnSave = (customerId: string) => {
  const navigate = useNavigate();
  const [saveReq, setSaveReq] = useState<ReqType>(REQ.INIT);
  const dispatch = useAppDispatch();
  const { producerId, userId } = useAppSelector(({ auth }) => {
    return { producerId: auth._id, userId: auth.uid };
  });

  const onSave = useCallback(async (data: ContactFormValues) => {
    setSaveReq(REQ.PENDING);
    if (!producerId || !userId) {
      setSaveReq(REQ.ERROR);
      return;
    }

    try {
      if (
        !(
          data.customerName &&
          data.contactPersonName &&
          data.contactPhone &&
          data.notificationEmail
        )
      ) {
        setSaveReq(REQ.ERROR);
        return;
      }
      await patchManagedCustomerContactInformation(producerId, customerId, {
        name: data.customerName,
        contactPerson: data.contactPersonName,
        phone: data.contactPhone,
        notificationEmail: data.notificationEmail
      });
      dispatch(fetchConsumers({ producerId }));
      setSaveReq(REQ.SUCCESS);
      setTimeout(() => {
        navigate(`/customers/${customerId}`);
      }, 1000);
    } catch (error) {
      setSaveReq(REQ.ERROR);
    }
  }, []);

  return { onSave, saveReq };
};
