import styled from 'styled-components';
import Theme from '@carrot-deprecated/theme';
import margins from 'utils/style';

const BASE_FONT_SIZE = 16;

export const Label = styled.label`
  display: block;
  line-height: 1;
  margin-bottom: 9px;
  color: ${({ color = 'inherit' }) => {
    return color;
  }};
  font-weight: 800;
  font-size: ${`${BASE_FONT_SIZE * 0.6875}px`};
  text-transform: uppercase;
  letter-spacing: 0.09em;
  ${margins}
`;

export const B = styled.strong<{ decoration?: string }>`
  font-weight: ${Theme.FontWeights.semiBold};
  text-decoration: ${({ decoration }) => {
    return decoration || undefined;
  }};
`;
