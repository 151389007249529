import { Chip } from '../../chip';

type Props<T> = {
  value: T[] | undefined;
  valueToString: (value: T) => string;
  onChipClick: (value: T) => void;
};

export const ComboboxChips = <T,>({
  value,
  valueToString,
  onChipClick
}: Props<T>) => {
  return value?.map(v => (
    <Chip
      key={valueToString(v)}
      text={valueToString(v)}
      onRemove={() => onChipClick(v)}
    />
  ));
};
