import { tv } from '../../utils/tv';

const style = tv({
  slots: {
    container: `
      overflow-hidden
      rounded
    `,
    image: `
      size-full
      object-cover
    `
  },
  variants: {
    square: {
      true: {
        container: `aspect-square`
      }
    }
  }
});

type Props = {
  src: string;
  alt: string;
  square?: boolean;
};

export const Image = ({ src, alt, square }: Props) => {
  const { container, image } = style();
  return (
    <div className={container({ square })}>
      <img src={src} alt={alt} className={image()} />
    </div>
  );
};
