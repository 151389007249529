import { useEffect, useState } from 'react';
import {
  fetchBasketDeliveryTypes,
  GetDeliveryTypesResponse
} from '../../../api';
import { ProductWithBasket } from '../../reducers/productsForSale';

// Keeps track of distribution routes for all producers and days in the basket
export const useDeliveryDayTypes = (
  numProducts: number,
  productsInBasket: ProductWithBasket[],
  distributionAreaId?: string
) => {
  const [basketDeliveryTypes, setBasketDeliveryTypes] =
    useState<GetDeliveryTypesResponse>();

  useEffect(() => {
    if (!distributionAreaId || productsInBasket.length === 0) {
      return;
    }

    const deliveryDays = productsInBasket.map(product => ({
      producerId: product.producer._id,
      date: product.deliveryDate
    }));

    fetchBasketDeliveryTypes({ distributionAreaId, deliveryDays }).then(
      response => {
        setBasketDeliveryTypes(response);
      }
    );
  }, [numProducts]);

  return basketDeliveryTypes;
};
