import { DayTotal, DayTotalContainer } from './OrdersForDayTotal.style';
import Typography from '@carrot-deprecated/typography';
import Container from '@carrot-deprecated/container';

type OrdersForDayTotalProps = {
  label: string;
  subtotal: string;
};

const OrdersForDayTotal = ({ label, subtotal }: OrdersForDayTotalProps) => {
  return (
    <DayTotalContainer>
      <Container flex>
        <Typography
          variant="paragraphSmallBold"
          style={{ order: 0, flex: 'none' }}
        >
          {label}
        </Typography>
        <DayTotal variant="paragraphSmallBold">{`${subtotal}`}</DayTotal>
      </Container>
    </DayTotalContainer>
  );
};

export default OrdersForDayTotal;
