import formsPlugin from '@tailwindcss/forms';
import plugin from 'tailwindcss/plugin';
import { Config } from 'tailwindcss/types/config';

import { tailwindPlugins, tailwindTheme } from './src/theme';

export default {
  content: [
    '.storybook/**/*.{html,js,jsx,ts,tsx}',
    '**/*.{html,js,jsx,ts,tsx}'
  ],
  theme: tailwindTheme,
  plugins: [
    ...tailwindPlugins,
    formsPlugin({
      strategy: 'class'
    }),
    plugin(({ addVariant }) => {
      addVariant('media-hover', '@media (hover: hover)');
    })
  ]
} satisfies Config;
