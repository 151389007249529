import { Form, formFieldFactory } from '@dagens/frontend-forms';
import { useTranslation } from '@dagens/frontend-i18n';

export type SelfDeliveryFeeFormValues = {
  price: number | null;
  automatic: true[];
};

const { Field, Checkboxes, NumberInput } =
  formFieldFactory<SelfDeliveryFeeFormValues>();

type Props = {
  onSubmit: (data: SelfDeliveryFeeFormValues) => void;
};

export const SelfDeliveryFeeForm = ({ onSubmit }: Props) => {
  const { t } = useTranslation();

  return (
    <Form onSubmit={onSubmit}>
      <Form.Section>
        <Field to="price" label={t('producer:SelfDeliveryFeePageHeader')}>
          <NumberInput
            name="price"
            unit={t('producer:SelfDeliveryFeeUnit')}
            small
          />
        </Field>

        <Checkboxes
          name="automatic"
          options={[true]}
          displayValue={() => t('producer:automaticLabel')}
        />
        {/* <Radio
            name="automatic"
            options={[true, false]}
            displayValue={value =>
              value
                ? {
                    label: t('producer:automaticOption'),
                    description: t('producer:automaticOptionDescription')
                  }
                : {
                    label: t('producer:manualOption'),
                    description: t('producer:manualOptionDescription')
                  }
            }
          /> */}
      </Form.Section>
    </Form>
  );
};
