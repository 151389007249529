import CreateConsumerPage from '../../_common/pages/create-role/CreateConsumerPage';
import CreateProducerPage from '../../_common/pages/create-role/CreateProducerPage';
import RootlessUserPage from '../../_authenticated/RootlessUserPage';
import { Routes } from '../types';
import RootResolver from '../../_common/pages/RootResolver';
import { ProducerRedirect } from '../components/producer-redirect';
import CookiesInfoPage from '_common/pages/CookiesInfoPage';
import LogoutPage from '_common/pages/LogoutPage';
import ResetPasswordPage from '_common/pages/ResetPasswordPage';
import VerifyUserEmail from '_common/pages/VerifyUserEmail';

export const authRoutes: Routes = {
  '/': RootlessUserPage,
  '/logout': LogoutPage,
  '/reset-password': ResetPasswordPage,
  '/cookies': CookiesInfoPage,
  '/user/verify/:token': VerifyUserEmail,

  '/create-consumer': CreateConsumerPage,
  '/create-producer': CreateProducerPage,

  '/:handle': RootResolver,
  '/producers/:handle': ProducerRedirect,

  '*': '/'
};
