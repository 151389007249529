import { FC } from 'react';
import { components, DropdownIndicatorProps } from 'react-select';

import Icon from '../../_common/components/utils/Icon';
import { Option } from './types';

type Props = DropdownIndicatorProps<Option, true>;

export const SelectMultiDropdownIndicator: FC<Props> = props => {
  return (
    <components.DropdownIndicator {...props}>
      <Icon icon="chevronSmall" rotate={180} fill="black" />
    </components.DropdownIndicator>
  );
};
