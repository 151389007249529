import { PropsWithChildren, ReactNode } from 'react';
import { To, Link } from 'react-router-dom';
import { tv } from '../../../utils/tv';
import { Range } from '../types';
import { SeasonCalendarRow } from './row';

type WrapperProps = PropsWithChildren<{
  onClick?: () => void;
  to?: To;
  state?: Record<string, unknown>;
  className?: string;
}>;

const Wrapper = ({ onClick, to, state, children, className }: WrapperProps) => {
  return to ? (
    <Link to={to} state={state} onClick={onClick} className={className}>
      {children}
    </Link>
  ) : (
    <button onClick={onClick} disabled={!onClick} className={className}>
      {children}
    </button>
  );
};

const style = tv({
  slots: {
    container: `
      relative
      col-span-12
      flex
      items-center
      gap-xs
      px-s
      py-[12px]
      text-paragraphSmall
    `,
    annotation: `
      text-paragraphSmall
      text-secondary
    `
  },
  variants: {
    clickable: {
      true: {
        container: `hover:bg-availability/20`
      }
    }
  }
});

type Props = PropsWithChildren<{
  name: string;
  icons?: ReactNode;
  annotation?: string;
  ranges: Partial<Range>[];
  onClick?: () => void;
  to?: To;
  state?: Record<string, unknown>;
}>;

export const SeasonCalendarItem = ({
  name,
  icons,
  annotation,
  ranges,
  onClick,
  to,
  state,
  children
}: Props) => {
  const { container, annotation: annotationStyle } = style();

  const clickable = Boolean(onClick || to);

  return (
    <Wrapper
      onClick={onClick}
      to={to}
      state={state}
      className={container({ clickable })}
    >
      <span>{name}</span>
      {icons}
      <span className={annotationStyle()}>{annotation}</span>
      {children}
      <SeasonCalendarRow ranges={ranges} />
    </Wrapper>
  );
};
