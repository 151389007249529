import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { CreateOrderAdjustment } from '_producer/components/create-order-adjustment/CreateOrderAdjustment';
import { Page } from '@components/page';
import { getOrder } from 'api';
import { Order } from 'types/Order';
import REQ, { ReqType } from 'utils/REQ';

const CreateOrderAdjustmentPage = () => {
  const [req, setReq] = useState<ReqType>(REQ.INIT);
  const [order, setOrder] = useState<Order>();
  const { id } = useParams<{ id: string }>();

  const init = async () => {
    if (!id) {
      setReq(REQ.NOT_FOUND);
      return;
    }

    setReq(REQ.PENDING);
    await getOrder(id)
      .then(response => {
        setOrder(response.data);
        setReq(REQ.SUCCESS);
      })
      .catch(() => {
        setReq(REQ.NOT_FOUND);
      });
  };

  useEffect(() => {
    init();
  }, []);

  if (req !== REQ.SUCCESS) {
    return <Page.Status req={req} />;
  }
  if (!order) {
    return <Page.NotFound />;
  }
  return <CreateOrderAdjustment order={order} />;
};

export default CreateOrderAdjustmentPage;
