import { Nav } from '@dagens/carrot';
import { MenuUnauth } from '../menu/unauth';

export const HeaderNavUnauth = () => {
  return (
    <Nav.Bar
      left={<Nav.LogoLandingPage />}
      center={null}
      right={<MenuUnauth />}
    />
  );
};
