import React from 'react';
import { track } from 'utils/mixpanel';

const usePageView = (
  pageView: TrackingEvent,
  data?: {
    productId?: string;
    isOrderItem?: boolean;
    producerId?: string;
    consumerNames?: string[];
    consumerIds?: string[];
    numberOfConsumers?: number;
  }
) => {
  React.useState(() => {
    track(pageView, data);
  });
};

export default usePageView;

type TrackingEvent =
  | 'Basket - Page view'
  | 'Product - Page view'
  | 'Login - Page view'
  | 'User signup - Page view'
  | 'Producer signup - Page view'
  | 'Consumer signup - Page view'
  | 'Product search - Page view'
  | 'Grouped product search - Page view'
  | 'Producer overview - Page view'
  | 'Producer shop - Page view'
  | 'Producer home - Page view'
  | 'My products - Page view'
  | 'Add product - Page view'
  | 'Edit product - Page view'
  | 'Producer order overview - Page view'
  | 'Consumer Create - Page view'
  | 'Producer Create - Page view'
  | 'My favorites - Page view'
  | 'Customer placed first order - Show Edit Delivery Info Modal'
  | 'Order confirmation - Click edit delivery info'
  | 'Edit Delivery Info Modal - View'
  | 'Intro Screen 1 - Page view'
  | 'Intro Screen 2 - Page view'
  | 'Basket - Click send order'
  | 'Product Search - Select Delivery Day'
  | 'Product - Select Delivery Day'
  | 'Delivery Day Banner - Unselect Delivery Day'
  | 'Download Parcel Labels'
  | 'Toggle Order Note Text Area'
  | 'Consumer Signup Submitted'
  | 'Consumer Create Failed'
  | 'Switch Role'
  | 'Add to Basket'
  | 'Toggle Packing List Axes'
  | 'Download Delivery Notes'
  | 'Open Product From Producer Landing Page'
  | 'Download My Data'
  | 'Grouped product search - Click show all products'
  | 'Consumer Signup Failed'
  | 'Producer Signup Submitted'
  | 'Producer Signup Failed'
  | 'Product Search - Click Reset Filters And Delivery Day Button'
  | 'Producer Create Failed'
  | 'Search season cal - Clicked product'
  | 'My favorites season cal - Clicked product'
  | 'Product search card - Clicked product card'
  | 'Product order item search card - Clicked product card'
  | 'Producer season cal - Clicked product'
  | 'Product search - Show filter category'
  | 'Product search - Hide filter category'
  | 'Product search - Add filter'
  | 'Product search - Remove filter'
  | 'Product search - Search'
  | 'Edit Delivery Info Modal - Edit delivery address'
  | 'Edit Delivery Info Modal - Edit delivery instructions'
  | 'Edit Delivery Info Modal - Add delivery instructions'
  | 'Edit Delivery Info Modal - Click save delivery information'
  | 'Intro Screen 1 - Click skip intro'
  | 'Intro Screen 1 - Click next'
  | 'Intro Screen 2 - Click CTA'
  | 'Add offer - Page view'
  | 'Edit offer - Page view'
  | 'Producer home - Click shop link'
  | 'Minimum order form - Page view'
  | 'Self delivery fee form - Page view'
  | 'Customer list - Page view';
