import { useTranslation } from '@dagens/frontend-i18n';
import OrderAdjustmentSummaryTable from './OrderAdjustmentSummaryTable';
import Typography from '@carrot-deprecated/typography';
import Callout from '@carrot-deprecated/callout';
import Container from '@carrot-deprecated/container';
import {
  getTotalOrderPrice,
  getTotalOrdersPrice,
  isOrderInvoiceSentToDagens
} from '_common/reducers/orders';
import { Order, OrderAdjustment, OrderKind } from 'types/Order';
import { getDateShort } from 'utils/date/format';
import { formatNok2Decimals } from 'utils/texts';

type OrderAdjustedFeedbackProps = {
  order: Order;
  orderAndAdjustments: OrderKind[];
};

const OrderAdjustedFeedback = ({
  order,
  orderAndAdjustments
}: OrderAdjustedFeedbackProps) => {
  const { t } = useTranslation();

  const showOrderIsAdjusted =
    orderAndAdjustments.length > 1 && isOrderInvoiceSentToDagens(order);

  if (!showOrderIsAdjusted) {
    return null;
  }

  const initialOrderTotal = getTotalOrderPrice(
    order.orderLines,
    order.producerDeliveryFee?.finalValue
  );

  const adjustments = orderAndAdjustments
    .filter((o): o is OrderAdjustment => {
      return o._type === 'orderAdjustment';
    })
    .sort((a, b) => a._createdAt.localeCompare(b._createdAt))
    .map((adjustment, i, a) => {
      const previous = i === 0 ? order : a[i - 1];
      const total = getTotalOrderPrice(
        adjustment.orderLines,
        (adjustment.producerDeliveryFee?.finalValue ?? 0) -
          (previous.producerDeliveryFee?.finalValue ?? 0)
      );
      return {
        adjustment,
        total
      };
    });

  const adjustedTotal =
    getTotalOrdersPrice([order]) +
    adjustments.reduce((acc, { total }) => acc + total, 0);

  const showAdjustmentSummaryTable = adjustments.length > 0;
  const adjustmentOverviewRows = [
    {
      description: `${t('common:InitialOrder')} (${order.orderNumberString})`,
      amount: formatNok2Decimals(initialOrderTotal)
    },
    ...adjustments.map(({ adjustment, total }) => {
      return {
        description: `${t('common:Adjustment')} ${getDateShort(
          adjustment._createdAt
        )} (${adjustment.orderNumberString})`,
        amount: formatNok2Decimals(total)
      };
    }),
    {
      description: t('common:AdjustedOrderTotal'),
      amount: formatNok2Decimals(adjustedTotal)
    }
  ];

  return (
    <Container my="l">
      <Callout colour="noteworthy" mb="s">
        <Typography variant="paragraph">
          {t('producer:OrderAdjustments.orderHasBeenAdjusted')}
        </Typography>
      </Callout>
      {showAdjustmentSummaryTable && (
        <OrderAdjustmentSummaryTable rows={adjustmentOverviewRows} />
      )}
    </Container>
  );
};
export default OrderAdjustedFeedback;
