import { ButtonGroup, Link } from '@dagens/carrot';
import { useTranslation } from '@dagens/frontend-i18n';

import useMixpanelEvent from '../../../../_common/hooks/useMixpanelEvent';
import useRedirectLogin from '../../../../_common/hooks/useRedirectLogin';
import { track } from '../../../../utils/mixpanel';

export const MenuUnauth = () => {
  const { t } = useTranslation();
  const { get: getRedirectLogin } = useRedirectLogin();
  const trackNavBarSignupCTA = useMixpanelEvent();
  return (
    <ButtonGroup.NoWrap>
      <Link
        size="regular"
        variant="primary"
        to={`/letsgo?redirectLogin=${getRedirectLogin()}`}
        onClick={() => {
          track(trackNavBarSignupCTA);
        }}
      >
        {t('common:header.signUpToTrade')}
      </Link>
      <Link
        size="small"
        variant="borderless"
        to={`/login?redirectLogin=${getRedirectLogin()}`}
      >
        {t('common:header.login')}
      </Link>
    </ButtonGroup.NoWrap>
  );
};
