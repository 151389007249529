import { OrderHistoryLink } from './OrderHistoryLink';
import OrderListHeading from './OrderListHeading';
import Container from '@carrot-deprecated/container';
import { ordersBySettlement } from '_common/reducers/orders';

type SettlementContainerProps = {
  settlement: ReturnType<typeof ordersBySettlement>[number];
};

export const SettlementContainer = ({
  settlement: { key, settlement, orders }
}: SettlementContainerProps) => {
  return (
    <Container key={key} mb="xl">
      <OrderListHeading
        settlementNumber={key}
        attachmentUrl={settlement?.settlementAttachmentUrl}
      />
      {orders?.map(order => {
        return <OrderHistoryLink key={order._id} order={order} />;
      })}
    </Container>
  );
};
