/* eslint-disable react/prop-types */
import styled from 'styled-components';
import Theme, { Animations, Colours } from '../theme';
import Widths from '../utils/width-props';
import Paddings from '../utils/padding-props';
import Margins from '../utils/margin-props';
import MediaQueries from '../utils/media-queries';
// import Icon from '../Icon';
import Typography from '../typography';
import Container from '../container';
import { CalloutProps } from './types';

/*
const CalloutActionButton = styled.button`
  -webkit-appearance: button;
  top: ${Theme.Spacings.s}px;
  right: ${Theme.Spacings.s}px;
  position: absolute;
  margin: 0;
  padding: 0px;
  border: 0;
  cursor: pointer;

  overflow: visible;
  white-space: nowrap;

  text-transform: none;
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
  background-color: transparent;

  transform: all ${Animations.regular};
`;
*/
const StyledCallout = styled.div<Omit<CalloutProps, 'title'>>`
  position: relative;
  padding: ${Theme.Spacings.s}px;

  white-space: pre-line;

  border-radius: ${Theme.BorderRadius.regular}px;
  background-color: ${({ colour = 'noteworthy' }) => {
    return Colours[colour];
  }};
  transform: all ${Animations.regular};

  ${Widths};

  ${Paddings};

  ${Margins};

  ${MediaQueries.print} {
    border: 1pt solid ${Theme.Colours.black};
    background-color: transparent;
  }
`;

/*
const CalloutTitleToggleSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  appearance: none;
  cursor: pointer;
  background: transparent;
`;

const ExpandableCallout = ({ children, title, ...props }: CalloutProps) => {
  const [show, setShow] = React.useState(false);
  const handleOnClose = () => {
    setShow(!show);
  };
  return (
    <StyledCallout {...props}>
      <>
        <CalloutTitleToggleSection onClick={handleOnClose}>
          <b>{title}</b>
          <CalloutActionButton onClick={handleOnClose}>
            <Icon name={show ? 'collapse' : 'expand'} size="s" />
          </CalloutActionButton>
        </CalloutTitleToggleSection>
        {show && children}
      </>
    </StyledCallout>
  );
};

const ClosableCallout = ({ children, onToggle, ...props }: CalloutProps) => {
  const [show, setShow] = React.useState(true);
  const handleOnClose = () => {
    setShow(false);
    if (onToggle) onToggle();
  };
  if (!show) return null;
  return (
    <StyledCallout {...props}>
      <>
        <CalloutActionButton onClick={handleOnClose}>
          <Icon name="clear" size="s" />
        </CalloutActionButton>
        {children}
      </>
    </StyledCallout>
  );
};
*/
const Callout: React.FC<CalloutProps> = ({
  variant = 'default',
  title,
  colour = 'noteworthy',
  children,
  ...props
}) => {
  if (variant === 'default') {
    return (
      <StyledCallout colour={colour} {...props}>
        <>
          {title && (
            <Container mb="s">
              <Typography variant="paragraphBold">{title}</Typography>
            </Container>
          )}
          {children}
        </>
      </StyledCallout>
    );
  }
  /*
  if (variant === 'closeable') {
    return (
      <ClosableCallout title={title} colour={colour} {...props}>
        {children}
      </ClosableCallout>
    );
  }
  if (variant === 'expandable') {
    return (
      <ExpandableCallout title={title} colour={colour} {...props}>
        {children}
      </ExpandableCallout>
    );
  }
  */
  return null;
};

export default Callout;
