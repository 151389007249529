import Typography from '@carrot-deprecated/typography';
import { OrderProducerDeliveryFee } from 'types/Order';

type Props = {
  orderDeliveryFee: OrderProducerDeliveryFee;
};

export const ChangedPriceCell = ({ orderDeliveryFee }: Props) => {
  const price = orderDeliveryFee.finalValue;
  const originalPrice = orderDeliveryFee?.valueAtTimeOfOrder;
  return (
    <>
      <Typography variant="paragraphSmall">{price} kr</Typography>
      {originalPrice !== undefined && price !== originalPrice && (
        <Typography
          variant="paragraphSmall"
          color="secondary"
          style={{ textDecoration: 'line-through' }}
        >
          {originalPrice} kr
        </Typography>
      )}
    </>
  );
};
