import {
  FieldValues,
  Path,
  PathValue,
  UseFormGetValues
} from 'react-hook-form';

export type ValidationFn<Form extends FieldValues, Name extends keyof Form> = (
  value: PathValue<Form, Name & Path<Form>>,
  formValues: Form
) => string | boolean;

export type RequiredRule<Form extends FieldValues, Name extends keyof Form> =
  | boolean
  | string
  | ValidationFn<Form, Name>;

const isNullValue = (value: unknown) => {
  return (
    value === null ||
    value === undefined ||
    value === '' ||
    (Array.isArray(value) && value.length === 0)
  );
};

type Args<Form extends FieldValues, Name extends Path<Form>> = {
  value: PathValue<Form, Name>;
  required: RequiredRule<Form, Name> | undefined;
  validate: ValidationFn<Form, Name> | undefined;
  formData: UseFormGetValues<Form>;
  defaultRequiredError: string;
  defaultValidationError: string;
};

// Returns the error message that needs to be displayed if the field is required
export const getRequiredError = <
  Form extends FieldValues,
  Name extends Path<Form>
>(
  value: PathValue<Form, Name>,
  required: RequiredRule<Form, Name> | undefined,
  formData: UseFormGetValues<Form>,
  defaultRequiredError: string
) => {
  if (typeof required === 'string') {
    return required;
  }

  if (required === true) {
    return defaultRequiredError;
  }

  if (required) {
    const output = required(value, formData());
    if (typeof output === 'string') {
      return output;
    }
    if (output === true) {
      return defaultRequiredError;
    }
  }

  return null;
};

// Validates a field value based on the required rule and validation function
// Receives the field value, the required rule, the validation function,
// a function to retrieve the form data, and the default error message
export const validationResolver = <
  Form extends FieldValues,
  Name extends Path<Form>
>({
  value,
  required,
  validate,
  formData,
  defaultRequiredError,
  defaultValidationError
}: Args<Form, Name>) => {
  const valid = validate?.(value, formData());
  if (typeof valid === 'string') {
    return valid;
  }
  if (valid === false) {
    return defaultValidationError;
  }

  if (isNullValue(value)) {
    const requiredError = getRequiredError(
      value,
      required,
      formData,
      defaultRequiredError
    );
    return requiredError ?? true;
  }

  return true;
};
