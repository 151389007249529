import styled, {
  StyledComponent as IStyledComponent,
  css
} from 'styled-components';
import { Link as ReactRouterLink } from 'react-router-dom';
import { Colours, FontSizes, FontWeights, Spacings } from '../theme';
import { ColourNames } from '../theme/colours';
import Margins from '../utils/margin-props';
import Paddings from '../utils/padding-props';
import {
  TypographyProps,
  TypographyNames,
  LineHeight,
  LetterSpacing
} from './types';

type TypographyCompProps<C extends React.ElementType> = TypographyProps<C> &
  Omit<React.ComponentPropsWithoutRef<C>, keyof TypographyProps<C>>;

type TypographyColour = {
  color?: ColourNames;
};
const colorProp = css<TypographyColour>`
  ${({ color }) => {
    return color && `color: ${Colours[color]};`;
  }}
`;

type StyledTags = 'h1' | 'h2' | 'h3' | 'p' | 'em' | 'a' | 'label' | 'strong';

const styledWithConfig = <T extends StyledTags>(tag: T) =>
  styled(tag).withConfig({
    shouldForwardProp: prop => {
      if (typeof prop === 'string') {
        return ['children', 'to', 'id', 'href', 'target', 'rel', 'as'].includes(
          prop
        );
      } else {
        return false;
      }
    }
  });

const PrimaryHeading = styledWithConfig('h1')`
  font-size: ${FontSizes.primaryHeading}px;
  line-height: ${LineHeight.primaryHeading}px;
  letter-spacing: ${LetterSpacing.primaryHeading}px;
  font-weight: ${FontWeights.regular};
  margin-top: 0;
  margin-bottom: ${Spacings.l}px;
  ${colorProp}
  ${Margins}
  ${Paddings}
`;

const SecondaryHeading = styledWithConfig('h2')`
  font-size: ${FontSizes.secondaryHeading}px;
  line-height: ${LineHeight.secondaryHeading}px;
  letter-spacing: ${LetterSpacing.secondaryHeading}px;
  font-weight: ${FontWeights.regular};
  ${colorProp}
  ${Margins}
  ${Paddings}
`;

const TertiaryHeading = styledWithConfig('h3')`
  font-size: ${FontSizes.tertiaryHeading}px;
  line-height: ${LineHeight.tertiaryHeading}px;
  letter-spacing: ${LetterSpacing.tertiaryHeading}px;
  font-weight: ${FontWeights.regular};
  margin: 0 0 ${Spacings.xs}px;
  ${colorProp}
  ${Margins}
  ${Paddings}
`;

const Paragraph = styledWithConfig('p')`
  font-size: ${FontSizes.paragraph}px;
  line-height: ${LineHeight.paragraph}px;
  letter-spacing: ${LetterSpacing.paragraph}px;
  font-weight: ${FontWeights.regular};
  margin: 0;
  ${colorProp}
  ${Margins}
  ${Paddings}
`;

const ParagraphBold = styledWithConfig('em')`
  font-size: ${FontSizes.paragraphBold}px;
  line-height: ${LineHeight.paragraphBold}px;
  letter-spacing: ${LetterSpacing.paragraphBold}px;
  font-weight: ${FontWeights.semiBold};
  font-style: normal;
  margin: 0;
  ${colorProp}
  ${Margins}
  ${Paddings}
`;

const ParagraphSmall = styledWithConfig('p')`
  font-size: ${FontSizes.paragraphSmall}px;
  line-height: ${LineHeight.paragraphSmall}px;
  letter-spacing: ${LetterSpacing.paragraphSmall}px;
  font-weight: ${FontWeights.medium};
  margin: 0;
  ${colorProp}
  ${Margins}
  ${Paddings}
`;

const ParagraphSmallBold = styledWithConfig('em')`
  font-size: ${FontSizes.paragraphSmallBold}px;
  line-height: ${LineHeight.paragraphSmallBold}px;
  letter-spacing: ${LetterSpacing.paragraphSmallBold}px;
  font-weight: ${FontWeights.bold};
  margin: 0;
  font-style: normal;
  ${colorProp}
  ${Margins}
  ${Paddings}
`;

const LeadText = styledWithConfig('p')`
  font-size: ${FontSizes.leadText}px;
  line-height: ${LineHeight.leadText}px;
  letter-spacing: ${LetterSpacing.leadText}px;
  font-weight: ${FontWeights.regular};
  margin: 0;
  ${colorProp}
  ${Margins}
  ${Paddings}
`;

const LeadTextBold = styledWithConfig('p')`
  font-size: ${FontSizes.leadTextBold}px;
  line-height: ${LineHeight.leadTextBold}px;
  letter-spacing: ${LetterSpacing.leadTextBold}px;
  font-weight: ${FontWeights.semiBold};
  margin: 0;
  ${colorProp}
  ${Margins}
  ${Paddings}
`;

const InputLabel = styledWithConfig('label')`
  font-size: ${FontSizes.inputLabel}px;
  line-height: ${LineHeight.inputLabel}px;
  letter-spacing: ${LetterSpacing.inputLabel}px;
  font-weight: ${FontWeights.semiBold};
  display: block;
  ${colorProp}
  ${Margins}
  ${Paddings}
`;

const InputText = styledWithConfig('label')`
  font-size: ${FontSizes.inputText}px;
  line-height: ${LineHeight.inputText}px;
  letter-spacing: ${LetterSpacing.inputText}px;
  font-weight: ${FontWeights.medium};
  ${colorProp}
  ${Margins}
  ${Paddings}
`;

const Button = styledWithConfig('label')`
  font-size: ${FontSizes.button}px;
  line-height: ${LineHeight.button}px;
  letter-spacing: ${LetterSpacing.button}px;
  font-weight: ${FontWeights.medium};
  display: block;
  ${colorProp}
  ${Margins}
  ${Paddings}
`;

const AnchorLink = styledWithConfig('a')`
  font-size: inherit;
  line-height: inherit;
  letter-spacing: inherit;
  font-weight: inherit;
  text-decoration: underline;
  margin: 0;
  ${colorProp}
  ${Margins}
  ${Paddings}
`;

const RouterLinkRaw = ({
  to = '#',
  children,
  ...rest
}: TypographyCompProps<typeof ReactRouterLink>) => {
  return (
    <ReactRouterLink to={to} {...rest}>
      {children}
    </ReactRouterLink>
  );
};

const RouterLink = styled(RouterLinkRaw)`
  font-size: inherit;
  line-height: inherit;
  letter-spacing: inherit;
  font-weight: inherit;
  text-decoration: underline;
  margin: 0;
  ${colorProp}
  ${Margins}
  ${Paddings}
`;

const AllCapsTitle = styledWithConfig('strong')`
  font-size: ${FontSizes.allCapsTitle}px;
  line-height: ${LineHeight.allCapsTitle}px;
  letter-spacing: ${LetterSpacing.allCapsTitle}px;
  font-weight: ${FontWeights.bold};
  text-transform: uppercase;
  ${colorProp}
  ${Margins}
  ${Paddings}
`;

const HelpText = styledWithConfig('p')`
  font-size: ${FontSizes.paragraphSmall}px;
  line-height: ${LineHeight.paragraph}px;
  letter-spacing: ${LetterSpacing.paragraph}px;
  font-weight: ${FontWeights.medium};
  color: ${Colours.secondary};
  ${colorProp}
  ${Margins}
  ${Paddings}
`;

const components: Record<TypographyNames, IStyledComponent<any, any>> = {
  primaryHeading: PrimaryHeading,
  secondaryHeading: SecondaryHeading,
  tertiaryHeading: TertiaryHeading,
  paragraph: Paragraph,
  paragraphBold: ParagraphBold,
  paragraphSmall: ParagraphSmall,
  paragraphSmallBold: ParagraphSmallBold,
  leadText: LeadText,
  leadTextBold: LeadTextBold,
  inputLabel: InputLabel,
  inputText: InputText,
  button: Button,
  link: AnchorLink,
  routerLink: RouterLink,
  allCapsTitle: AllCapsTitle,
  helpText: HelpText
};

const Typography = <C extends React.ElementType = 'span'>({
  variant = 'paragraph',
  color = 'black',
  children,
  ...rest
}: TypographyCompProps<C>) => {
  const Comp = components[variant];
  return (
    <Comp color={color} {...rest}>
      {children}
    </Comp>
  );
};

export default Typography;
