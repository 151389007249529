import { definedNumber } from '@dagensmat/core';
import { css } from 'styled-components';

export type MarginProps = {
  mx?: number;
  my?: number;
  mt?: number;
  mr?: number;
  mb?: number;
  ml?: number;
};

const margins = css<MarginProps>`
  ${({ mx }) => {
    return definedNumber(mx) && `margin-left: ${mx}px; margin-right: ${mx}px;`;
  }}
  ${({ my }) => {
    return definedNumber(my) && `margin-top: ${my}px; margin-bottom: ${my}px;`;
  }}
  margin-top: ${({ mt }) => {
    return definedNumber(mt) && `${mt}px`;
  }};
  margin-right: ${({ mr }) => {
    return definedNumber(mr) && `${mr}px`;
  }};
  margin-bottom: ${({ mb }) => {
    return definedNumber(mb) && `${mb}px`;
  }};
  margin-left: ${({ ml }) => {
    return definedNumber(ml) && `${ml}px`;
  }};
`;

export default margins;
