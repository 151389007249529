import { type InputMeasureUnit, type UnitType } from '@dagensmat/core';
import { uuid } from '@sanity/uuid';
import { MeasureUnit, OrderUnit, PriceUnit, UnitUtils } from '@dagens/utils';
import { SanityRef } from '../../../../types/Sanity';
import {
  allCustomersOption,
  ConsumersWithChildren
} from '../hooks/use-consumers';
import { PricingFormValues } from '..';

// Remove the ALL_CUSTOMERS special consumer from the list of special consumers
// This is done because the ALL_CUSTOMERS special consumer is necessary to specify if the value
// is set in the form, but it should not be passed to the backend
const toCustomers = (customers: ConsumersWithChildren[]) => {
  const withoutAllCustomers = customers?.filter(customer => {
    return customer._id !== allCustomersOption._id;
  });
  const asSanityRefs = withoutAllCustomers
    ?.map(({ _id }) => ({
      _key: uuid(),
      _ref: _id,
      _type: 'reference'
    }))
    .filter(ref => Boolean(ref._ref)) as SanityRef[];
  return asSanityRefs;
};

// Resolves order unit from form values
// Either uses the price unit, if "Is the order unit also" is set to "Yes",
// or uses the order unit and order value
type OrderUnitArgs = {
  orderEqualsPrice: boolean;
  priceUnit: PriceUnit;
  orderValue: number | null;
  orderUnit: OrderUnit | null;
};
const resolveOrderUnit = ({
  orderEqualsPrice,
  priceUnit,
  orderValue,
  orderUnit
}: OrderUnitArgs) => {
  if (orderEqualsPrice || orderValue === null) {
    return {
      pricedUnitsPerOrderedUnit: 1,
      orderedUnit: priceUnit as UnitType
    };
  }
  return {
    pricedUnitsPerOrderedUnit: orderValue,
    orderedUnit: orderUnit as UnitType
  };
};

// Resolves measure unit from form values
// Measure unit values are ignored if the price unit is a measure unit already
type MeasureUnitArgs = {
  priceUnit: PriceUnit;
  measureValue: number | null;
  measureUnit: MeasureUnit | null;
};
const resolveMeasureUnit = ({
  priceUnit,
  measureValue,
  measureUnit
}: MeasureUnitArgs) => {
  if (
    UnitUtils.isMeasureUnit(priceUnit) ||
    measureUnit === null ||
    measureValue === null
  ) {
    return {
      inputMeasureUnitValue: 1,
      inputMeasureUnit: priceUnit as InputMeasureUnit
    };
  }
  return {
    inputMeasureUnitValue: measureValue,
    inputMeasureUnit: measureUnit as InputMeasureUnit
  };
};

// Converts form values to pricing object for the API
export const formValuesToPricing = (
  data: PricingFormValues,
  pricingKey?: string
) => {
  if (data.priceValue === null) {
    throw new Error('Price value is required');
  }

  const orderUnit = resolveOrderUnit({
    orderEqualsPrice: data.orderEqualsPrice,
    priceUnit: data.priceUnit,
    orderValue: data.orderValue,
    orderUnit: data.orderUnit
  });
  const measureUnit = resolveMeasureUnit({
    priceUnit: data.priceUnit,
    measureValue: data.measureValue,
    measureUnit: data.measureUnit
  });

  return {
    _key: pricingKey ?? uuid(),
    _type: 'productPricing' as const,
    availableTo: ['CONSUMERS'] as ['CONSUMERS'],
    specialConsumers: toCustomers(data.customers),
    nokPerPricedUnit: data.priceValue,
    pricedUnit: data.priceUnit as UnitType,
    ...measureUnit,
    ...orderUnit,
    unitSizeDescription: data.additionalInfo ?? undefined
  };
};
