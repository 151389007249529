import { PropsWithChildren } from 'react';
import { tv } from '../../utils/tv';

const style = tv({
  base: `
    contents
    text-paragraphSmall
    text-secondary
  `
});

type Props = PropsWithChildren;

export const Body = ({ children }: Props) => {
  return <tbody className={style()}>{children}</tbody>;
};
