import { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import REQ, { ReqType } from '../../../../utils/REQ';
import {
  useAppDispatch,
  useAppSelector
} from '../../../../_common/hooks/reduxHooks';
import { DeliveryFormValues } from '../../../components/customer-delivery-form';
import { fetchConsumers } from '../../../reducers/consumers';
import { patchManagedCustomerDeliveryInformation } from '../../../../api';

export const useOnSave = (customerId: string) => {
  const navigate = useNavigate();
  const [saveReq, setSaveReq] = useState<ReqType>(REQ.INIT);
  const dispatch = useAppDispatch();
  const { producerId, userId } = useAppSelector(({ auth }) => {
    return { producerId: auth._id, userId: auth.uid };
  });

  const onSave = useCallback(async (data: DeliveryFormValues) => {
    setSaveReq(REQ.PENDING);
    if (!producerId || !userId) {
      setSaveReq(REQ.ERROR);
      return;
    }

    try {
      if (
        !(
          data.deliveryAddress &&
          typeof data.deliveryAddress != 'string' &&
          data.deliveryWindowFrom &&
          data.deliveryWindowTo
        )
      ) {
        setSaveReq(REQ.ERROR);
        return;
      }
      await patchManagedCustomerDeliveryInformation(producerId, customerId, {
        deliveryAddress: data.deliveryAddress,
        deliveryWindow: {
          start: data.deliveryWindowFrom,
          end: data.deliveryWindowTo
        },
        deliveryInstructions: data.deliveryInstructions || undefined
      });
      dispatch(fetchConsumers({ producerId }));
      setSaveReq(REQ.SUCCESS);
      setTimeout(() => {
        navigate(`/customers/${customerId}`);
      }, 1000);
    } catch (error) {
      setSaveReq(REQ.ERROR);
    }
  }, []);

  return { onSave, saveReq };
};
