import styled from 'styled-components';
import Typography from '@carrot-deprecated/typography';
import Theme from '@carrot-deprecated/theme';

type FromMe = {
  fromMe: boolean;
};
const MessageContainer = styled.div<FromMe>`
  margin: 10px 0;
  width: 100%;
  display: flex;
  justify-content: ${({ fromMe }) => {
    return fromMe ? 'flex-end' : 'flex-start';
  }};
`;

const MessageContent = styled.div<FromMe>`
  max-width: 90%;
  padding: 8px;
  border-radius: 5px;
  background: ${({ fromMe }) => {
    return fromMe ? Theme.Colours.lightGrey : Theme.Colours.messages;
  }};
`;

const Message = ({ fromMe, message }: FromMe & { message: string }) => {
  return (
    <MessageContainer fromMe={fromMe}>
      <MessageContent fromMe={fromMe}>
        <Typography>{message}</Typography>
      </MessageContent>
    </MessageContainer>
  );
};

export default Message;
