import {
  CountryCode,
  forSaleStatuses,
  type InputMeasureUnit,
  type UnitType
} from '@dagensmat/core';
import { type Producer, type ProducerProfile } from './Producer';
import { type SanityDoc, type SanityImage, type SanityRef } from 'types/Sanity';

export type Pricing = {
  _key: string;
  _type: 'productPricing';
  availableTo: ['CONSUMERS'];
  pricedUnit: UnitType;
  nokPerPricedUnit: number;
  orderedUnit: UnitType;
  pricedUnitsPerOrderedUnit: number;
  inputMeasureUnit?: InputMeasureUnit;
  inputMeasureUnitValue?: number;
  unitSizeDescription?: string;
  specialConsumers?: SanityRef[];
};

export type EditablePricing = {
  nokPerPricedUnit: number | '';
  pricedUnitsPerOrderedUnit: number | '';
  inputMeasureUnitValue?: number | '';
  isSimplePricing: boolean;
} & Omit<
  Pricing,
  'nokPerPricedUnit' | 'pricedUnitsPerOrderedUnit' | 'inputMeasureUnitValue'
>;

export type Favorite = {
  _key: string;
  _ref: string;
  _type: 'products';
};

export type Category = {
  _id: string;
  name: string;
  translateKey: string;
  parent?: SanityRef;
  children?: Category[];
  path?: Category[];
  name_en?: string;
  name_nb?: string;
  name_da?: string;
  isNonFood?: boolean;
};

export type Period = {
  to?: string | Date;
  from: string | Date;
};

type SeasonPeriod = {
  _key: string;
} & Required<Period>;

type AlwaysInSeason = {
  _key: string;
  from: string;
};

export const isSeasonPeriod = (
  val: AlwaysInSeason | SeasonPeriod
): val is SeasonPeriod => {
  return val !== undefined && 'to' in val;
};

export const processedStates = ['RAW', 'PROCESSED'] as const;
export type ProcessedState = (typeof processedStates)[number];

export const temperatureZones = ['FROZEN', 'REFRIGERATED', 'DRY'] as const;
export type TemperatureZone = (typeof temperatureZones)[number];

type Capacity = {
  _key: string;
  _type: 'capacity';
  deliveryDate: string;
  units: number;
};

type SoldOutCapacity = {
  _key: string;
  _type: 'capacity';
  deliveryDate: string;
  units: 0;
};

export type ProductCapacity = Capacity | SoldOutCapacity;

export type ProducerProduct = {
  capacities: ProductCapacity[];
  categoryId: string;
  certificationIds: string[];
  certifications: Certification[];
  description: string;
  forSaleStatus: ForSaleStatus;
  image?: SanityImage;
  name: string;
  prices: Pricing[];
  pricing: Pricing;
  processedState: ProcessedState;
  producer: ProducerProfile;
  seasonCalendar: SeasonCalendar;
  temperatureZone: TemperatureZone;
  type: string;
  deleted?: boolean;
} & SanityDoc<'products'>;

export type Certification = {
  _id: string;
  availableIn: CountryCode;
  displayNameKey: string;
  logo: SanityImage;
  parcelLabelAssetUrl: string;
  name: string;
  shortName: string;
  description: string;
} & SanityDoc<'certification'>;

export type ForSaleStatus = keyof typeof forSaleStatuses;

export type SeasonCalendar = (AlwaysInSeason | SeasonPeriod)[];

export type ProductForSale = {
  certifications: Certification[];
  deadlines: Record<string, string>;
  deliveryDates: string[];
  description: string;
  forSaleStatus: ForSaleStatus;
  image: SanityImage;
  name: string;
  newnessRank?: number;
  pricing: Pricing;
  processedState: ProcessedState;
  producer: Producer;
  seasonCalendar: SeasonCalendar;
  soldOutDates?: string[];
  tags: string[];
  temperatureZone: TemperatureZone;
  type: string;
  hasSpecialPricing?: boolean;
  availableIn: string[];
  prices?: Pricing[];
  justAdded: boolean;
  seasonStart: boolean;
} & SanityDoc<'products'>;

export type ProductRequest = {
  _id: string;
  _createdAt: string;
  hasNewMessage: boolean;
  isOpened: boolean;
  counterPartName: string;
  pathname: string;
};
