import { Form, formFieldFactory } from '@dagens/frontend-forms';
import { useTranslation } from '@dagens/frontend-i18n';
import { UnitUtils } from '@dagens/utils';
import { PricingFormValues } from '..';

const { Field, NumberInput, Select } = formFieldFactory<PricingFormValues>();

export const PricingFormPrice = () => {
  const { t } = useTranslation();

  const priceUnits = Object.values({
    ...UnitUtils.measure,
    ...UnitUtils.combined,
    ...UnitUtils.packed
  });

  return (
    <Field
      to={['priceValue', 'priceUnit']}
      label={t('producer:productProductPriceLabelText')}
      helpText={t('producer:productPriceExplanationText')}
      required
    >
      <Form.Group numberWithSelect>
        <NumberInput name="priceValue" type="float" unit="kr" required />
        <Select
          name="priceUnit"
          options={priceUnits}
          displayValue={value => t(`units:${value}_long`, { count: 1 })}
          required
        />
      </Form.Group>
    </Field>
  );
};
