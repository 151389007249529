import { twMerge } from 'tailwind-merge';
import { tv } from '../../../utils/tv';

const style = tv({
  slots: {
    container: `
      grid
      grid-cols-[1fr,auto]
      gap-xxs
      text-paragraphSmall
      text-black
    `,
    separator: `
      col-span-2
      border-t
      border-black
    `,
    values: `
      whitespace-nowrap
      text-right
    `,
    afterCut: `text-paragraphSmallBold`
  }
});

type Props = {
  priceValue: number;
  transactionCut: number;
  text: {
    serviceFee: (value: number) => string;
    serviceFeeAmount: (value: string) => string;
    toCurrency: (value: number) => string;
    youGetExclVat: string;
  };
};

export const PricingStructure = ({
  priceValue,
  transactionCut,
  text: { serviceFee, serviceFeeAmount, toCurrency, youGetExclVat }
}: Props) => {
  const { container, separator, values, afterCut } = style();

  const valueAfterCut = priceValue - priceValue * transactionCut;
  return (
    <div className={container()}>
      <div>{serviceFee(transactionCut * 100)}</div>
      <div className={values()}>
        {serviceFeeAmount(toCurrency(priceValue * transactionCut))}
      </div>
      <hr className={separator()} />
      <div>{youGetExclVat}</div>
      <div className={twMerge(values(), afterCut())}>
        {toCurrency(valueAfterCut)}
      </div>
    </div>
  );
};

/*
import { useTranslation } from '@dagens/frontend-i18n';
import Typography from '@carrot-deprecated/typography';
import Container from '@carrot-deprecated/container';
import Theme from '@carrot-deprecated/theme';
import { EditablePricing } from 'types/Product';
import { formatNok2Decimals } from 'utils/texts';

type DisplayPricingStructureProps = {
  transactionCut: number;
  nokPerPricedUnit: EditablePricing['nokPerPricedUnit'];
};

const serviceFeeAmount = (
  value: EditablePricing['nokPerPricedUnit'],
  transactionCut: number
): string => {
  if (value === '') return '';

  return formatNok2Decimals(value * transactionCut);
};

const getProductValueAfterCut = (
  value: EditablePricing['nokPerPricedUnit'],
  transactionCut: number
): string => {
  if (value === '') return '';
  return formatNok2Decimals(value - value * transactionCut);
};

const DisplayPricingStructure = ({
  transactionCut,
  nokPerPricedUnit
}: DisplayPricingStructureProps) => {
  const { t } = useTranslation();

  const transactionCutPercent = transactionCut * 100;
  const serviceFee = serviceFeeAmount(nokPerPricedUnit, transactionCut);
  const valueAfterCut = getProductValueAfterCut(
    nokPerPricedUnit,
    transactionCut
  );

  return (
    <div style={{ width: 300, marginTop: Theme.Spacings.s, maxWidth: '100%' }}>
      <Container flex justify="space-between" alignItems="baseline" mt="xxs">
        <Typography variant="paragraphSmall">
          {t('producer:ServiceFee', { transactionCutPercent })}
        </Typography>
        <Typography variant="paragraphSmall" mb="xxs" color="black">
          {t('producer:ServiceFeeAmount', { serviceFee })}
        </Typography>
      </Container>
      <div style={{ borderTop: '2px solid black' }}>
        <Container flex justify="space-between" alignItems="baseline">
          <Typography variant="paragraphSmall" mt="xxs">
            {t('producer:YouGetExclVat')}
          </Typography>
          <Typography variant="paragraphSmallBold" as="p" color="black">
            {valueAfterCut}
          </Typography>
        </Container>
      </div>
    </div>
  );
};

export default DisplayPricingStructure;
 */
