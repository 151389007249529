import { PropsWithChildren } from 'react';
import { tv } from '../../../utils/tv';
import { SeasonCalendarHeader } from '../parts/header';
import { SeasonCalendarColumns } from '../parts/columns';
import { SeasonCalendarToday } from '../parts/today';

const style = tv({
  slots: {
    container: `
      relative
      grid
      grid-cols-12
      grid-rows-[auto,auto]
      gap-y-s
      text-center
    `,
    header: `
      col-span-full
      col-start-1
      row-start-1
      grid
      grid-cols-subgrid
    `,
    rows: `
      col-span-full
      col-start-1
      row-start-2
      grid
      grid-cols-subgrid
      gap-y-s
    `,
    columns: `
      -z-20
      col-span-full
      row-span-full
      grid
      grid-cols-subgrid
    `
  },
  variants: {
    withToday: {
      true: { container: `pt-[40px]` }
    }
  }
});

type Props = PropsWithChildren<{
  today?: string;
}>;

export const SeasonCalendarMulti = ({ today, children }: Props) => {
  const { container, header, rows, columns } = style();
  return (
    <div className={container({ withToday: Boolean(today) })}>
      <div className={header()}>
        <SeasonCalendarHeader />
      </div>
      <div className={rows()}>{children}</div>
      <div className={columns()}>
        <SeasonCalendarColumns />
      </div>
      <SeasonCalendarToday text={today} />
    </div>
  );
};
