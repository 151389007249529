import { useTranslation } from '@dagens/frontend-i18n';
import Typography from '@carrot-deprecated/typography';
import { Table } from '_common/components/table/CommonTable.style';

type OrderAdjustmentSummaryTableProps = {
  rows: { description: string; amount: string }[];
};

const OrderAdjustmentSummaryTable = ({
  rows
}: OrderAdjustmentSummaryTableProps) => {
  const { t } = useTranslation();

  if (rows.length < 2) {
    return null;
  }

  const bodyRows = rows.slice(0, -1);
  const footerRow = rows.slice(-1)[0];

  return (
    <>
      <Typography variant="paragraphSmallBold" as="p" color="secondary">
        {t('common:OrderAdjustmentOverview')}
      </Typography>
      <Table>
        <tbody>
          {bodyRows.map(row => {
            return (
              <tr key={row.description}>
                <Typography variant="paragraphSmall" as="td">
                  {row.description}
                </Typography>
                <Typography variant="paragraphSmall" as="td">
                  {row.amount}
                </Typography>
              </tr>
            );
          })}
        </tbody>
        <tfoot>
          <tr>
            <Typography variant="paragraphSmallBold" as="td">
              {footerRow.description}
            </Typography>
            <Typography variant="paragraphSmallBold" as="td">
              {footerRow.amount}
            </Typography>
          </tr>
        </tfoot>
      </Table>
    </>
  );
};
export default OrderAdjustmentSummaryTable;
