import { ReqType } from '../../../utils/REQ';
import { PageBase } from './base';

type Props = {
  req: ReqType | ReqType[];
};

export const PageStatus = ({ req }: Props) => {
  return <PageBase req={req} />;
};
