import { tv } from '../../../utils/tv';

const style = tv({
  base: `
    text-paragraphSmall
    text-black
  `
});

type Props = {
  text: string;
};

export const PricingStructureFallback = ({ text }: Props) => {
  return <div className={style()}>{text}</div>;
};
