import styled from 'styled-components';
import Typography from '@carrot-deprecated/typography';
import Theme from '@carrot-deprecated/theme';

export const DayTotalContainer = styled.div`
  margin-top: ${Theme.Spacings.m}px;
`;

export const DayTotal = styled(Typography)`
  order: 1;
  text-align: right;
  flex: auto;
`;
