import { ComboboxOptions } from '@headlessui/react';
import { tv } from 'tailwind-variants';
import REQ, { ReqType } from '../../utils/REQ';
import { DeliveryAddressResult } from './DeliveryAddressResult';

const style = tv({
  base: `
    flex
    max-h-[200px]
    flex-col
    justify-start
    overflow-y-auto
    rounded-small
    border
    border-solid
    border-brightPurple
    bg-white
    shadow-lg
  `
});

type Props = {
  req: ReqType;
  results: string[];
};

/**
 * @package
 */
export const DeliveryAddressResults = ({ req, results }: Props) => {
  if (req === REQ.INIT) {
    return null;
  }
  return (
    <ComboboxOptions static transition as="ul" className={style()}>
      <DeliveryAddressResult req={req} results={results} />
    </ComboboxOptions>
  );
};
