import { useTranslation } from '@dagens/frontend-i18n';
import React from 'react';
import FormValidationMessage from '../input/FormValidationMessage';
import ImageInput from './ImageInput';
import ImagePreviewSimple from './ImagePreviewSimple';
import FormLabel from '_common/components/input/FormLabel';
import { useAppSelector } from '_common/hooks/reduxHooks';
import Logger from 'services/Logger';
import { SanityImage } from 'types/Sanity';
import { getImageUrl } from 'utils/imageUrls';
import REQ, { ReqType } from 'utils/REQ';

type ImageFormFieldProps = {
  onSave: (file: File) => Promise<void>;
  image: SanityImage | undefined;
  label: string;
  helpText?: string;
  explanationText?: string;
};

const ImageFormField = ({
  onSave,
  image,
  label,
  helpText,
  explanationText
}: ImageFormFieldProps) => {
  const [uploadReq, setUploadReq] = React.useState<ReqType>(REQ.INIT);
  const [previewUrl, setPreviewUrl] = React.useState<string | null>('');
  const { _id } = useAppSelector(state => {
    return state.auth;
  });
  const imageChanged = async (file: File, imagePreviewUrl: string | null) => {
    setUploadReq(REQ.INIT);

    try {
      await onSave(file);
      setPreviewUrl(imagePreviewUrl);
      setUploadReq(REQ.SUCCESS);
    } catch {
      Logger.warn(`Failed to upload image for user profile ${_id}`);
      setUploadReq(REQ.ERROR);
    }
  };

  const hasImage = image && image.asset;
  const imageUrl = previewUrl || (image ? getImageUrl(image) : null);
  const { t } = useTranslation();
  return (
    <>
      <FormLabel
        helpText={helpText}
        labelText={label}
        explanationText={explanationText}
      />
      <ImagePreviewSimple imageUrl={imageUrl} />
      <ImageInput
        disabled={uploadReq === REQ.PENDING}
        onChange={imageChanged}
        fillButton={!hasImage}
        buttonText={hasImage ? t('common:changeImage') : t('common:addImage')}
      />
      {uploadReq === REQ.ERROR && (
        <FormValidationMessage message={t('producer:ImageUploadError')} />
      )}
    </>
  );
};

export default ImageFormField;
