import Typography from '@carrot-deprecated/typography';
import Container from '@carrot-deprecated/container';
import ProductListElement from '_producer/pages/products/ProductListElement';
import { ProducerProduct } from 'types/Product';

type Props = {
  header: string;
  products: ProducerProduct[];
};

const ProductGroup = ({ header, products = [] }: Props) => {
  return (
    <Container mt="xl" mb="jumbo">
      <Typography variant="secondaryHeading" mb="xs">
        {header}
      </Typography>
      <>
        {products.map(product => {
          return <ProductListElement key={product._id} product={product} />;
        })}
      </>
    </Container>
  );
};

export default ProductGroup;
