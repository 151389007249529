import { tv } from 'tailwind-variants';

export const calloutStyle = tv({
  base: `
    rounded
    p-s
    text-paragraph
  `,
  slots: {
    title: `text-paragraphBold`
  },
  variants: {
    variant: {
      lightGrey: `bg-lightGrey`,
      noteworthy: `bg-noteworthy`
    },
    small: {
      true: {
        base: `text-paragraphSmall`,
        title: `text-paragraphSmallBold`
      }
    }
  },
  defaultVariants: {
    variant: 'noteworthy'
  }
});
