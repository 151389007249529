import { FormHTMLAttributes, PropsWithChildren } from 'react';

import { styles } from './styles';

export type FormProps = PropsWithChildren & FormHTMLAttributes<HTMLFormElement>;

export const Form = ({ children }: FormProps) => {
  // TODO: Add form logic
  return <form className={styles()}>{children}</form>;
};
