import { Button, ButtonGroup, Icon } from '@dagens/carrot';
import { useTranslation } from '@dagens/frontend-i18n';
import { DateUtils } from '@dagens/utils';
import { PeriodPickerValue } from './use-values';

type Props = {
  adding: boolean;
  activePeriodIndex: number | undefined;
  value: PeriodPickerValue;
  showAlways?: boolean;
  onClickAll: () => void;
  onClickPeriod: (index: number) => void;
  onClickAdd: () => void;
};

export const PeriodPickerSelected = ({
  adding,
  activePeriodIndex,
  value,
  showAlways,
  onClickAll,
  onClickPeriod,
  onClickAdd
}: Props) => {
  const { t } = useTranslation();

  if (value === 'ALL') {
    return (
      <ButtonGroup.Wrap>
        <Button variant={'secondary'} onClick={onClickAll}>
          {t('producer:productSeasonCalendarButton1')}
          <Icon icon="clear" size="small" />
        </Button>
      </ButtonGroup.Wrap>
    );
  }

  return (
    <ButtonGroup.Wrap>
      {(!value || value?.length === 0) && showAlways && (
        <Button variant="ghost" onClick={onClickAll}>
          {t('producer:productSeasonCalendarButton1')}
        </Button>
      )}

      {value?.map((period, index) => (
        <Button
          key={index}
          variant={activePeriodIndex === index ? 'secondary' : 'ghost'}
          onClick={() => onClickPeriod(index)}
        >
          {DateUtils.periodToString(period)}
        </Button>
      ))}

      <Button variant={adding ? 'secondary' : 'ghost'} onClick={onClickAdd}>
        {t('producer:productSeasonCalendarAddPeriod')}
      </Button>
    </ButtonGroup.Wrap>
  );
};
