import { tr, useTranslation } from '@dagens/frontend-i18n';
import i18n from 'i18next';
import React from 'react';
import Typography from '@carrot-deprecated/typography';
import Button, {
  ButtonContainer
} from '_common/components/button/Button.style';
import FormField from '_common/components/input/FormField';
import { Page } from '@components/page';
import { postResetPassword } from 'api';

const ResetPasswordPage = () => {
  const { t } = useTranslation();
  const statuses = {
    error: {
      code: 'error',
      message: t('common:SomethingWentWrongContactSupport')
    },
    success: {
      code: 'success',
      message: t('common:WeSentYouAnEmailWithInstructions')
    }
  } as const;

  const [email, setEmail] = React.useState('');
  const [status, setStatus] =
    React.useState<(typeof statuses)[keyof typeof statuses]>();

  const onSubmitHandler: React.FormEventHandler<HTMLFormElement> = async e => {
    e.preventDefault();

    try {
      await postResetPassword({ email });
      setStatus(statuses.success);
    } catch {
      setStatus(statuses.error);
    }
  };

  return (
    <Page>
      <form onSubmit={onSubmitHandler}>
        <FormField
          required
          labelText={tr(i18n, 'signup:emailLabelText')}
          type="email"
          placeholder={tr(i18n, 'common:WriteEmailToReset')}
          onChange={({ target: { value } }) => {
            setEmail(value);
          }}
          name="loginEmail"
          value={email}
        />
        {status && status.code === statuses.error.code && (
          <Typography variant="paragraph" color="error">
            {status.message}
          </Typography>
        )}
        {status && status.code === statuses.success.code && (
          <Typography variant="paragraph" color="black">
            {status.message}
          </Typography>
        )}
        {!status && (
          <ButtonContainer>
            <Button type="submit" variant="secondary">
              {tr(i18n, 'common:ResetWithArrow')}
            </Button>
          </ButtonContainer>
        )}
      </form>
    </Page>
  );
};

export default ResetPasswordPage;
