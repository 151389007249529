import { isSameDay } from 'date-fns';
import { GetDeliveryTypesResponse } from '../../../api';
import { DeliveryType } from '../../../types/Logistics';
import BasketOrderLinesByProducer from './BasketOrderLinesByProducer';
import { IBasketWithProducer } from '_consumer/reducers/productsForSale';
import { DeliveryDayFee } from 'types/Basket';

type ProducerDeliveredOrderLinesProps = {
  basket: IBasketWithProducer[];
  deliveryDate: string;
  deliveryFees: DeliveryDayFee;
  deliveryTypes?: GetDeliveryTypesResponse;
};

const ProducerDeliveredOrderLines = ({
  basket,
  deliveryDate,
  deliveryFees,
  deliveryTypes
}: ProducerDeliveredOrderLinesProps) => {
  const selfDeliveryProducers = deliveryFees.producers
    .filter(producer =>
      deliveryTypes?.find(deliveryType => {
        return (
          isSameDay(deliveryType.date, deliveryDate) &&
          producer.id === deliveryType.producerId &&
          deliveryType.deliveryType === DeliveryType.SELF_DELIVERY
        );
      })
    )
    .map(p => p.id);

  const producerDeliveries = basket.filter(product => {
    return selfDeliveryProducers.includes(product.producer._id);
  });

  if (!producerDeliveries.length) {
    return null;
  }

  return (
    <BasketOrderLinesByProducer
      basket={producerDeliveries}
      deliveryDate={deliveryDate}
      deliveryTagTextKey="ProducerDelivery"
      showDeliveryFee={true}
    />
  );
};

export default ProducerDeliveredOrderLines;
