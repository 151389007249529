import { FC } from 'react';
import styled from 'styled-components';
import Typography from '@carrot-deprecated/typography';

const StyledContainer = styled.div`
  white-space: nowrap;
`;

type Props = {
  maxLength: number;
  valueLength: number;
};

export const FormFieldCharCount: FC<Props> = ({ maxLength, valueLength }) => {
  return (
    <StyledContainer>
      <Typography variant="paragraphSmall" color="secondary">
        {valueLength} / {maxLength}
      </Typography>
    </StyledContainer>
  );
};
