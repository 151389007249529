import { useWatch } from '@dagens/frontend-forms';
import { ContactCard, FormField } from '@dagens/carrot';
import { useTranslation } from '@dagens/frontend-i18n';
import { AddCustomerFormValues } from '../types';

export const AddCustomerStartResults = () => {
  const { t } = useTranslation();
  const { organization } = useWatch<AddCustomerFormValues>();
  if (!organization || typeof organization === 'string') {
    return null;
  }

  const details = {
    ...(organization.address && {
      [t('common:Address')]: organization.address
    }),
    ...(organization.email && { [t('common:Email')]: organization.email }),
    ...(organization.phone && { [t('common:phoneNumber')]: organization.phone })
  };

  return (
    <FormField label={t('producer:AddCustomer.informationRegistered')}>
      <ul>
        <li>
          <ContactCard title={organization.name ?? ''} details={details} />
        </li>
      </ul>
    </FormField>
  );
};
