import { useTranslation } from '@dagens/frontend-i18n';
import { find, get } from 'lodash';
import { Link } from 'react-router-dom';
import Typography from '@carrot-deprecated/typography';
import Container from '@carrot-deprecated/container';
import Theme from '@carrot-deprecated/theme';
import PricingDisplay from '_common/components/pricing/PricingDisplay';
import { useAppSelector } from '_common/hooks/reduxHooks';
import Tag from '_common/components/utils/Tag';
import Separator from '_common/components/utils/Separator.style';
import ProductReference from '_common/components/product-reference/ProductReference';
import { type ProducerProduct } from 'types/Product';
import { truncate } from 'utils/array';
import {
  getUniqueConsumers,
  hasCustomOffer,
  isRegularPrice
} from 'utils/product-pricing';

const ProductListElement = ({ product }: { product: ProducerProduct }) => {
  const { t } = useTranslation();

  const { prices = [] } = product;

  const hasAllConsumers = prices.some(isRegularPrice);

  const customOfferConsumerNames = useAppSelector(
    ({ consumers: { items } }) => {
      const uniqueConsumerRefs = getUniqueConsumers(prices);
      return hasAllConsumers
        ? [t('producer:AllBuyers')]
        : [
            ...uniqueConsumerRefs.map(({ _ref }) => {
              return get(find(items, { _id: _ref }), 'name');
            })
          ].filter(Boolean);
    }
  );

  return (
    <Link
      to={{
        pathname: `/my-products/${product._id}`
      }}
      state={{ returnPath: true }}
    >
      <Container
        flex
        justify="space-between"
        alignItems="center"
        py="s"
        gap="xs"
      >
        <div style={{ flex: 1 }}>
          <Container flex justify="flex-start" wrap="wrap-reverse">
            <ProductReference
              product={product}
              variant="regular"
              mr={Theme.Spacings.xxs}
              mt={Theme.Spacings.xxs}
            />
            {hasCustomOffer(prices) && (
              <Tag
                bgColor={Theme.Colours.noteworthy}
                text={t('common:CustomOffer')}
              />
            )}
          </Container>
          <Container flex justify="left">
            <Typography variant="paragraphSmall" color="secondary">
              {t('common:VisibleTo')}
            </Typography>
            <Typography variant="paragraphSmall" as="p" color="black">
              &nbsp;
              {truncate({
                list: customOfferConsumerNames,
                addendum: t('common:more')
              })}
            </Typography>
          </Container>
          <Container flex alignItems="baseline" justify="left">
            <Typography variant="paragraphSmall" color="secondary">
              {t('common:PriceInList')}
            </Typography>
            &nbsp;
            {prices.length === 1 ? (
              <PricingDisplay price={prices[0]} />
            ) : (
              <Typography variant="paragraphSmall">
                {t('producer:productGroupSeveralPrices', {
                  count: prices.length
                })}
              </Typography>
            )}
          </Container>
        </div>
        <span
          style={{
            fontSize: 20
          }}
        >
          →
        </span>
      </Container>
      <Separator />
    </Link>
  );
};

export default ProductListElement;
