import { SelectStyle } from './styles';
import { SelectWrapper } from './wrapper';

type Props<SelectType extends string> = {
  options: { value: SelectType; name: string }[];
  selectedValue?: SelectType;
  onChange: (value: SelectType) => void;
  disabled?: boolean;
  id?: string;
  width?: string;
};

export const Select = <SelectType extends string>({
  options = [],
  selectedValue,
  onChange,
  disabled,
  id,
  width = '150px'
}: Props<SelectType>) => {
  return (
    <SelectWrapper width={width}>
      <SelectStyle
        id={id}
        value={selectedValue}
        onChange={({ target: { value } }) => {
          return onChange(value as SelectType);
        }}
        disabled={disabled}
      >
        {options.map(({ value, name }) => {
          return (
            <option key={value} value={value}>
              {name}
            </option>
          );
        })}
      </SelectStyle>
    </SelectWrapper>
  );
};
