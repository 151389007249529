import { formFieldFactory } from '@dagens/frontend-forms';
import { useTranslation } from '@dagens/frontend-i18n';
import { ProductFormValues } from '..';

const { Field, Textarea } = formFieldFactory<ProductFormValues>();

export const ProductFormDescription = () => {
  const { t } = useTranslation();
  return (
    <Field to="description" label={t('producer:productDescriptionLabelText')}>
      <Textarea
        name="description"
        placeholder={t('producer:productDescriptionPlaceholder')}
        rows={3}
      />
    </Field>
  );
};
