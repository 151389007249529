import { useTranslation } from '@dagens/frontend-i18n';
import { map } from 'lodash';
import { type IOrderGroup } from './OrdersByDeliveryPage';
import Typography from '@carrot-deprecated/typography';
import Callout from '@carrot-deprecated/callout';
import Container from '@carrot-deprecated/container';
import ConfirmButton from '_common/components/confirm-button/ConfirmButton';
import { useAppDispatch, useAppSelector } from '_common/hooks/reduxHooks';
import { fetchOrders } from '_common/reducers/orders';
import { postLeftProducer } from 'api';
import { formatDate } from 'utils/date/format';
import {
  hasAllOrdersLeftProducer,
  hasSomeOrderNotLeftProducer,
  PRODUCER_DELIVERS,
  PRODUCER_TRANSPORTS
} from 'utils/delivery';

const DeliveryInfoText = ({ orderGroup }: { orderGroup: IOrderGroup }) => {
  const { t } = useTranslation();
  const {
    type,
    toName,
    orders = [],
    producerDeliveryDate,
    toDeadline
  } = orderGroup;

  if (hasAllOrdersLeftProducer(orders)) {
    return (
      <Callout colour="lightGrey" mt="s">
        {t('common:deliveryInProgressCallout')}
      </Callout>
    );
  }

  if (type === PRODUCER_DELIVERS) {
    return (
      <Typography variant="paragraph" mt="s">
        {t('producer:YouDeliverDirectlyToTheCustomer', {
          count: orders.length
        })}
      </Typography>
    );
  }

  if (type === PRODUCER_TRANSPORTS) {
    return toDeadline ? (
      <Typography variant="paragraph" mt="s">
        {t('producer:DropOffAtWithDeadline', {
          toName,
          date: formatDate(producerDeliveryDate),
          deadline: toDeadline
        })}
      </Typography>
    ) : (
      <Typography variant="paragraph" mt="s">
        {t('producer:DropOffAt', {
          toName,
          date: formatDate(producerDeliveryDate)
        })}
      </Typography>
    );
  }

  return null;
};

const ProducerActions = ({ orderGroup }: { orderGroup: IOrderGroup }) => {
  const { t } = useTranslation();
  const userId = useAppSelector(({ auth: { _id } }) => {
    return _id;
  });
  const dispatch = useAppDispatch();

  const { orders = [], type: pickupType } = orderGroup || {};

  const producerActionNeeded = hasSomeOrderNotLeftProducer(orders);

  const deliveryVerb = {
    [PRODUCER_DELIVERS]: t('common:delivered'),
    [PRODUCER_TRANSPORTS]: t('common:droppedOff')
  }[pickupType];

  return (
    <>
      <DeliveryInfoText orderGroup={orderGroup} />
      {producerActionNeeded && (
        <Container my="s">
          <ConfirmButton
            buttonText={t('producer:MarkAsDelivered', { deliveryVerb })}
            confirmText={t('producer:AreOrders', {
              deliveryVerb,
              count: orders.length
            })}
            confirmedText=""
            rejectButtonText={t('producer:NotYetDelivered')}
            confirmButtonText={t('producer:YesDelivered', { deliveryVerb })}
            onConfirm={async () => {
              await postLeftProducer({ orderIds: map(orders, '_id') });
              dispatch(
                fetchOrders({ producerId: userId }, { clearStore: false })
              );
            }}
            buttonVariant="primary"
          />
        </Container>
      )}
    </>
  );
};

export default ProducerActions;
